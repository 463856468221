import React from "react";

export const aboutPage3 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="329px"
		height="191px"
		viewBox="0 0 329 191"
		id="frame-3"
	>
		<defs>
			<linearGradient
				id="Gradient_2"
				gradientUnits="userSpaceOnUse"
				x1="172.44999999999993"
				y1="785.2"
				x2="1465.65"
				y2="231.59999999999997"
				spreadMethod="pad"
			>
				<stop offset="4.705882352941177%" stopColor="#2DFCD8" />

				<stop offset="5.882352941176471%" stopColor="#2BF0CE" />

				<stop offset="11.764705882352942%" stopColor="#21BBA1" />

				<stop offset="18.03921568627451%" stopColor="#198C79" />

				<stop offset="24.313725490196077%" stopColor="#136558" />

				<stop offset="30.58823529411765%" stopColor="#0F453C" />

				<stop offset="37.254901960784316%" stopColor="#0D2C27" />

				<stop offset="43.92156862745098%" stopColor="#0C1A18" />

				<stop offset="51.372549019607845%" stopColor="#0C0F0F" />

				<stop offset="59.6078431372549%" stopColor="#0C0C0C" />
			</linearGradient>

			<g id="Layer9_0_FILL">
				<path
					fill="url(#Gradient_2)"
					stroke="none"
					d="
M 1147.65 788.45
L 894.9 73.05 184.75 682.3 285.45 997.85 1147.65 788.45 Z"
				/>
			</g>

			<g id="Layer8_0_FILL">
				<path
					fill="#0C0C0C"
					stroke="none"
					d="
M 426.2 703.75
L 207.6 651.85 -17.35 777.05 -130.75 1054.65 118.55 1261.6 474.7 988.6 426.2 703.75 Z"
				/>
			</g>

			<g id="Layer7_0_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 948.2 524.65
Q 948.25 524.8 948.4 524.85 952.55 526.3 958.45 525.2 961.95 524.5 968.25 522.3 978.2 518.8 987.45 513.15 1005.55 502.05 1019.35 482.9 1029.45 468.75 1034.05 463.6 1042.85 453.7 1052.8 448.45 1062.75 443.2 1073.9 441.35 1085.2 439.45 1096.1 441.25 1109.1 443.45 1118.25 450.4 1128.4 458.05 1131.4 469.85 1132.05 472.3 1134.9 472 1137.8 471.65 1137.45 469.05 1134.65 447.6 1110.55 438.55 1100.55 434.8 1088.75 434.2 1077.55 433.6 1067.8 435.9 1056.65 438.5 1046.75 444.35 1036.75 450.25 1029.2 458.75 1025.4 463 1013.5 479.4 1004.1 492.35 996.45 499 985.85 508.15 972.6 514.3 965.9 517.4 958.6 519.85 954.65 521.1 953.2 521.7 950.3 522.85 948.3 524.45 948.15 524.5 948.2 524.65 Z"
				/>
			</g>

			<g id="Layer7_1_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1031.75 778.15
Q 1035.15 771.95 1042.4 764.3 1053.55 752.4 1069.25 743.75 1083.5 735.9 1100.75 731.2 1118.5 726.4 1141.2 724.45 1155.8 723.2 1182.25 722.65
L 1244.35 721.4
Q 1250.35 721.3 1252.25 721.15 1256.65 720.85 1260 719.9 1267.5 717.8 1271 712.5 1274.9 706.5 1270.9 699.8 1266.5 692.45 1257.6 686.6 1249.9 681.55 1240.5 678.7 1237.7 677.85 1237 680.65 1236.3 683.4 1238.9 684.5 1247.7 688.15 1255.3 694.2 1260.4 698.25 1262.5 700.75 1266.85 705.8 1264.45 709.05 1261.6 712.95 1251.4 713.6 1247 713.9 1240.6 713.95 1233.4 713.95 1229.8 714 1200.7 714.3 1186.1 714.6 1160.55 715.05 1142.5 716.5 1121.55 718.15 1104.55 722.15 1084.7 726.85 1068.55 735.1 1052.95 743.05 1041 754.2 1035.3 759.4 1029.95 766.35 1026.05 771.4 1024.8 773.55 1022.2 778.05 1022.35 781.9 1022.55 789.15 1028.85 794.1 1034.65 798.6 1042.1 798.9 1049.45 799.25 1055.55 795.6
L 1055.55 795.6
Q 1056.003125 800.0087890625 1058.05 803.3 1060.45 807.15 1064.7 808.75 1068.8 810.35 1073.3 809.4 1085.612890625 806.887109375 1088.7 793.45 1089.461328125 794.9880859375 1090.3 796.45 1093.65 802.35 1095.25 804.3 1098.9 808.75 1102.9 808.7 1106.75 808.65 1109.55 804.95 1111.3 802.75 1113.3 797.85 1117.7 787.2 1118.6 775.05 1118.85 771.85 1115.5 771.85 1112.2 771.85 1111.95 775.05 1111.3 782.85 1109.25 789.55 1108.25 792.95 1106.5 796.9 1105.1 799.9 1104.3 800.5 1102.85 801.75 1100.6 799.75 1098.55 797.9 1096.45 793.85 1095.9 792.75 1093.45 787.2 1090.6 780.6 1089 772.85 1088.4 769.9 1085.05 770.35 1081.65 770.8 1082 773.8 1082.051171875 774.28125 1082.1 774.75 1083.0697265625 784.3685546875 1082.45 788.85 1081.3 797.65 1074.95 800.9 1071.9 802.45 1069.05 802.05 1065.85 801.6 1064.35 798.7 1063 796.05 1063.2 791.95 1063.35 789.15 1064.35 785.1 1065.1 781.9 1061.8 780.35 1058.45 778.8 1057 782 1054.55 787.6 1048.55 789.75 1042.6 791.95 1036.9 789.4 1033.35 787.8 1031.75 784.8 1030 781.5 1031.75 778.15 Z"
				/>
			</g>

			<g id="Layer7_2_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 868.95 578.15
Q 862.15 582.5 854.45 588.4 846.75 594.45 840.5 600.7 837 603.9 835.3 605.85 832.25 609.3 832.3 612.1 832.3 612.55 832.7 612.55 835.35 612.45 838.6 609.65 840.4 608.1 843.55 604.95 851.05 598.55 858.25 593.35 865.25 588.2 872.35 583.95 874.65 582.6 879.75 580 884.15 577.5 886.1 574.8 886.95 573.65 886.1 572.3 885.3 570.95 883.9 571 880.5 571.05 876.05 573.55 871.15 576.75 868.95 578.15 Z"
				/>
			</g>

			<g id="Layer7_3_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 883.75 581.25
Q 883.95 581.25 883.9 581.05 883.9048828125 580.9611328125 883.9 580.85 898.2755859375 598.125390625 908.25 617.1 915.7 631.2 917.95 639.6 921.6 652.95 917.05 663.35 916.2 665.35 918.1 666.55 920.05 667.7 921.05 665.7 925.9 655.65 922.95 642.2 920.9 633.15 914.3 619.7 902.45 595.85 883.95 575.9 883 574.9 881.8 575.85 880.6 576.75 881.4 577.9 883.35 580.65 883.5 581.15 883.55 581.3 883.75 581.25 Z"
				/>
			</g>

			<g id="Layer7_4_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 711.85 310.9
Q 700.1 313.5 691.55 317.15 680.95 321.75 673.8 328.6 666.85 335.35 663.45 343.7 659.8 352.65 661.05 361.7 663.45 378.65 682.75 396.7 697.7 410.75 721.7 413.95 736.8 415.95 765.35 413.65 779 412.55 789.7 409.5 802.05 405.95 812.15 399.4 815.75 397.1 821.75 392.65 828.5 387.65 831.3 385.75 836.45 382.3 841.3 379.9 845.35 377.9 847.85 377.1 853.6 376.35 853.3 376.85 853.15 377.1 853.4 377.4 853.6 377.7 853.9 377.6 855.35 377.1 855.25 375.75 855.15 374.55 854 373.45 851.95 371.55 847.7 372.75 846.6 373.05 841.3 375.4 834.2 378.5 824.45 385.05 809.15 395.3 808.3 395.85 798.85 401.6 787.15 404.7 777.3 407.3 764.45 408.35 748.4 409.65 742.5 409.85 730 410.35 720.55 409.2 708.75 407.8 698.9 402.6 689.3 397.5 681.1 388.55 672.2 378.75 668.45 370.25 663.5 358.95 666.85 348.65 673.05 329.35 698.05 320.4 707.25 317.1 720.25 314.7 723.6 314.1 741.5 311.45 775.15 306.4 789.9 306.7 817.9 307.15 835.25 320.35 836.8 321.5 838.05 319.8 839.3 318.15 837.95 316.85 824.4 304.05 800.8 301.85 786.5 300.55 759.7 303.5 731.35 306.7 711.85 310.9 Z"
				/>
			</g>

			<g id="Layer7_5_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 864.6 323.1
Q 864.5 323.3 864.6 323.5 866.05 326.4 870.2 329.05 871.25 329.7 877.35 332.9 884.75 336.9 892.85 340 911.9 347.4 928.1 348.1 931.35 348.25 931.3 345.05 931.25 341.85 928.1 341.5 917.45 340.25 911 339.1 901.8 337.5 894.2 335 885.65 332.2 877.9 328.5 873.8 326.2 871.6 325.05 867.7 323.1 864.9 322.95 864.7 322.9 864.6 323.1 Z"
				/>
			</g>

			<g id="Layer7_6_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 911.7 311.9
Q 912.11328125 311.7287109375 912.55 311.5 914.1 310.75 913.75 309 913.45 307.3 911.95 306.8 907.7 305.4 903.4 305.9 901.25 306.15 899.35 307.45 898.55 308 895.95 310.3 894.85 311.25 895.05 313 895.14140625 313.79921875 895.45 314.35 895.4564453125 314.576171875 895.45 314.8 895.3 319.75 900.65 321.9 906 324.05 908.8 319.75 909.5494140625 318.645703125 909 317.6 909.2861328125 317.57890625 909.55 317.55 911.9 317.3 912.65 315.1 913.2619140625 313.133203125 911.7 311.9 Z"
				/>
			</g>

			<g id="Layer7_7_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1026 328.3
Q 1019.55 329.15 1017.9 329.55 1012.8 330.8 1011.35 333.55 1011.15 333.8 1011.45 334 1013.05 335.05 1015.45 334.8 1016.8 334.7 1019.65 334.05 1029.35 332.65 1034.25 332.8 1040.65 332.95 1046.2 335.05 1052.15 337.3 1056.35 341.55 1060.75 346.05 1061.7 351.5 1062.3 354.9 1060.85 357.75 1059.75 359.9 1056.8 362.8 1052.15 367.35 1047.1 369.7 1042.4 371.9 1035.7 373 1034.2 373.25 1034.25 374.95 1034.25 376.65 1035.7 377 1042.05 378.4 1049.5 374.5 1055.15 371.6 1061 365.85 1066.1 360.85 1066.85 354.9 1067.65 348.9 1063.85 342.7 1060.5 337.2 1054.65 333.45 1049.1 329.85 1042.45 328.55 1034.9 327.1 1026 328.3 Z"
				/>
			</g>

			<g id="Layer7_8_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1020.3 344.05
Q 1019.2 344.3 1019.4 345.4 1019.6 346.5 1020.65 346.6 1024.25 346.85 1026 347.1 1028.95 347.55 1031.2 348.45 1032.65 349.05 1035.55 350.95 1038.25 352.7 1039.9 353.25 1041.05 353.6 1041.75 352.6 1042.5 351.6 1041.95 350.55 1039.55 346.15 1032.2 344.35 1025.65 342.75 1020.3 344.05 Z"
				/>
			</g>

			<g id="Layer7_9_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 938.95 408.5
Q 939.25 408.95 939.8 409 941.1 409.05 944.65 408.7 947.65 408.35 949.5 408.7 955.15 409.8 958.95 414.05 962.9 418.5 963.9 424.85 964.65 429.55 963.9 436.85 963.8 437.85 964.85 437.95 965.9 438.05 966.25 437.15 968.95 430.05 967.65 422.3 966.3 414.05 960.9 409.25 956.35 405.15 949.8 403.95 942.15 402.55 938.95 407.55 938.65 408 938.95 408.5 Z"
				/>
			</g>

			<g id="Layer7_10_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 940.3 426.8
Q 938.3 428.35 933.6 432.7 929.45 436.55 926.7 438.4 919.6 443.3 911.25 446 902 449 891.9 448.8 883.35 448.65 872.5 446.1 862.2 443.75 853.75 443.35 843.95 442.95 835 445.1 824.55 447.7 818.3 451.95 816.55 453 814.8 454.7 812.2 457.3 813.8 458.15 815.95 459.25 820.3 457.2 822.7 456.1 826.65 453.95 836.45 450 846.45 449.35 854.35 448.85 866.4 451.25 882.05 454.4 886.15 454.8 902.4 456.3 918.45 449.55 926.6 446.1 932.65 441.85 940.5 436.35 943.7 430.25 944.5 428.7 943.2 427.2 941.85 425.65 940.3 426.8 Z"
				/>
			</g>

			<g id="Layer7_11_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 813.5 415.45
Q 811.9 414.7 811.25 416.2 807.45 425.1 804.9 436.2 804.3 438.85 802.75 445.25 801.8 450.9 803.25 454.15 803.6 455 804.55 454.9 805.4 454.85 805.9 454.15 807 452.7 807.4 450.6 807.7 448.05 807.9 446.65 808.65 441.65 809.7 436.55 810.8 431.2 814.65 417.65 815.05 416.15 813.5 415.45 Z"
				/>
			</g>

			<g id="Layer7_12_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 869.45 419
Q 869.4 418.8 869.2 418.85 866.85 419.15 865.55 419.7 863.45 420.75 861.85 422 857.3 425.45 857.25 428.7 857.25 430 858.5 430.45 859.7 430.9 860.65 430.1 861.6 429.25 862.6 427.4 863.85 424.9 864.2 424.4 865 423.3 866.45 421.85 868.95 419.5 869.4 419.25 869.55 419.15 869.45 419 Z"
				/>
			</g>

			<g id="Layer7_13_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 899.7 424.3
Q 899.55 424 899.2 424.1 898.85 424.25 898.2 424.2 897.5 424.2 897.15 424.3 896.25 424.5 895.1 425.15 893.8 425.9 893.2 426.2 890.65 427.55 889.45 428.25 887.25 429.55 886.1 430.9 885.1 432.1 885.85 433.6 886.65 435.1 888.25 434.55 889.9 434 891.8 432.6 892.9 431.75 895 430 897.5 427.85 897.95 427.45 898.3 427.05 899.1 426.25 899.25 426.05 899.3 425.55 899.4 425.1 899.6 424.85 899.85 424.6 899.7 424.3 Z"
				/>
			</g>

			<g id="Layer7_14_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 815.3 468.5
Q 813.7 467.75 812.95 469.3 811.95 471.3 809.95 475.2 808.7 477.95 808.6 478.25 808.05 479.9 808.4 481.4 808.65 482.5 809.9 483.05 811.2 483.65 812.1 482.9 813.35 481.95 814.05 480.45 814.4 479.65 815 477.35 816 473 816.55 470.8 816.95 469.2 815.3 468.5 Z"
				/>
			</g>

			<g id="Layer7_15_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 836.5 463.05
Q 835.95 462.9 835.75 463.35 835.55 463.8 834.95 464.55 834.4 465.25 834.2 465.75 833.75 467.15 833.35 470.3 832.7 475.4 833.2 478.8 833.45 480.95 835.95 480.6 838.45 480.25 838.25 478.1 837.45 472.6 837.45 469.8 837.45 469.35 837.6 467.75 837.75 466.4 837.6 465.7 837.55 465.45 837.25 464.7 837 464.1 837 463.65 837 463.2 836.5 463.05 Z"
				/>
			</g>

			<g id="Layer7_16_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 880.75 461.05
Q 880.45 460.9 880.25 461.15 879.1 463.1 878.85 466.95 878.55 471.65 878.15 473 877.1 476.6 875 479.65 874.55 480.3 872.65 482.4 871.1 484 870.55 485.3 869.95 486.6 870.75 487.7 871.6 488.85 873 488.45 876.55 487.55 879.4 483.05 881.6 479.6 882.8 475.05 885.6 464.05 880.75 461.05 Z"
				/>
			</g>

			<g id="Layer7_17_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 841.65 412.1
Q 841.65 411.8 841.45 412 841.05 412.35 840.1 412.9 839.15 413.45 838.7 413.8 837.45 414.9 835.9 417.25 832.85 421.95 831.25 425.55 830.25 427.8 832.3 429.05 834.3 430.3 835.7 428.15 837.95 424.7 840.15 419.7 841.25 417.3 841.5 416 841.65 415.3 841.6 414.05 841.55 412.7 841.65 412.1 Z"
				/>
			</g>

			<g id="Layer7_18_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1065.5 234.55
Q 1063.5 232.9 1059.2 231.8 1048.3 229.05 1036.7 228.45 1025.3 227.8 1013.95 229.3 1004.55 230.55 999.2 233.3 991.35 237.35 990.7 244.85 990.6 246.25 992.1 246.65 993.6 247.05 994.2 245.8 996.6 240.7 1003 238.1 1007.4 236.3 1014.75 235.45 1032.1 233.5 1049.1 236.1 1053.25 236.65 1056.65 237.35 1057.15 237.45 1058.4 237.6 1059.55 237.8 1060.05 238.1 1061.7 239.2 1061.35 240.75 1061.15 241.75 1059.8 243.35 1055.85 248 1050.1 253.05 1043.75 258.7 1037.75 261.7 1030.55 265.25 1023.25 265.25 1021 265.2 1021 267.55 1021 269.9 1023.25 269.9 1032.15 270 1041 265.35 1047.8 261.75 1056.05 254.4 1058 252.65 1062.9 248.35 1066.9 244.25 1067.6 240.75 1068.4 236.9 1065.5 234.55 Z"
				/>
			</g>

			<g id="Layer7_19_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 833.55 177.35
Q 835.05 183.95 840.4 191.55 848.1880859375 202.6361328125 857.8 212.8 826.358984375 194.2814453125 804.75 186 761.95 169.65 724.1 176.15 722.6 176.4 722.25 178.05 721.9 179.6 722.9 180.65 741.6 200.7 772.4 215.35 779.9400390625 218.9205078125 790.5 223.05 747.65859375 215.7728515625 704.25 214.55 703.5 214.5 702.8 215.05 702.1 215.55 701.95 216.3 700.2 224.25 709.1 230.55 713.65 233.8 720.6 237.15 721.95 237.8 732.7 242.55 745.1 248 757 252.05 762.7 253.95 770.2 255.75 772.3 256.3 783.55 258.85 783.75 258.9 783.8 258.7 783.85 258.55 783.65 258.5 766.25 253.15 741.35 242.15 731.15 237.65 721.6 232.55 713.05 228.1 711.45 226.9 706.60390625 223.305859375 706.4 219.45 768.852734375 222.450390625 829.4 236.85 832.1 237.55 832.85 234.85 833.55 232.15 830.9 231.3 794.2 219.25 775.35 210.3 748.4517578125 197.5515625 730.45 180.5 764.5193359375 175.480078125 804.05 191.05 820.6 197.55 840.55 208.6 852.15 215 875 228.95 876.75 230 878.15 228.25 879.55 226.45 877.95 225.15 858.85 209.3 844.95 188.85 841.05 183.1 839.65 179.6 837.1 173.25 839.7 169.5 842.95 164.85 851.55 168.8 852.65 169.25 863.35 175.85 909.1 203.6 940.35 237.7 941.25 238.7 942.25 237.75 943.2 236.75 942.3 235.75 925.85 217.6 909.95 203.4 892.1 187.55 873.65 175.55 865.95 170.6 864.2 169.5 858.75 166.1 854.4 164 844.15 159 837.8 163.45 831.35 168.05 833.55 177.35 Z"
				/>
			</g>

			<g id="Layer7_20_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 749.75 780.2
Q 749.75 780 749.55 780 697.95 778.45 633.9 777.85 593.65 777.45 518.25 777.3
L 486.35 777.25
Q 475.05 777 469.35 776.9 459.3 776.75 452.45 777.5 449.85 777.8 449.85 780.2 449.85 782.6 452.45 782.85 458.25 783.5 466.85 783.4 471.7 783.35 481.35 783.15
L 568.1 783
Q 642.25 782.9 683.75 782.15 705.55 781.7 749.55 780.4 749.75 780.4 749.75 780.2 Z"
				/>
			</g>

			<g id="Layer7_21_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1368.2 765.8
Q 1368.2 766 1368.4 766.1 1370.2 767.35 1373.25 767.75 1374.95 767.95 1378.5 768.1 1383.1 768.5 1392.4 768.8 1398.55 769 1420.15 769.35 1429.55 769.55 1476.7 769.9
L 1560.05 770.3
Q 1564.05 770.35 1573.2 770.65 1581.25 770.6 1585.95 768.6 1587 768.15 1587 766.8 1586.95 765.4 1585.95 764.95 1581.45 762.95 1573.7 762.85 1564.95 763.1 1561.05 763.05
L 1476.7 762.7
Q 1427.3 762.7 1421.15 762.75 1402.65 762.8 1393.35 763 1384.1 763.2 1379.5 763.55 1375.65 763.65 1373.75 763.85 1370.35 764.2 1368.4 765.5 1368.2 765.6 1368.2 765.8 Z"
				/>
			</g>

			<g id="Layer7_22_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 200.8 650.15
Q 200.8 650.3 200.9 650.3 201.05 650.3 201.05 650.15 201.05 650 200.9 650 200.8 650 200.8 650.15 Z"
				/>
			</g>

			<g id="Layer7_23_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 308.6 672.45
Q 278.55 665.15 250.3 657.1 230.75 651.2 220.85 648.65 211.9 646.35 207.5 645.8 199.55 644.85 194.1 646.8 193.6 647 193.65 647.45 193.65 647.9 194.1 648.1 196.9 649.2 201.2 650.05 203.6 650.5 208.45 651.4 214.3 652.75 222.95 655.25 232.15 657.95 250.55 663.5 279.8 671.9 308.8 679.05 366.45 693.15 426.9 703 429.95 703.55 430.8 700.45 431.65 697.35 428.6 696.8 368.75 687.1 308.6 672.45 Z"
				/>
			</g>

			<g id="Layer7_24_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 429.05 700.25
Q 428.85 700.3 428.85 700.5 429.4 712.85 432.15 729.65 433.7 739.3 437.25 758.55 442.5 789 446.95 818.1 456.65 881.5 463.55 937.95 465.35 953.5 465.5 954.65 465.85 957.15 466.15 957.8 466.8 959.45 468.5 959.6 471.35 959.85 472.25 956.65 473.15 953.45 470.05 952.85 470.0125 952.8421875 469.95 952.8 469.8416015625 951.8650390625 469.75 950.75 469.55 946.65 469.35 944.85
L 467.65 931.1
Q 465.7 915.25 464.55 906.55 461.1 880.05 457.75 856.9 450.8 808.55 441.8 757.3 437.4 732.4 436.65 728.6 433.4 712.3 429.3 700.45 429.2 700.25 429.05 700.25 Z"
				/>
			</g>

			<g id="Layer7_25_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 198.05 653.9
Q 197.9 653.95 197.95 654.15 200.25 662.45 203.15 675.05 204.75 682.05 207.9 696.05
L 218.15 738.9
Q 223.35 760.85 227.7 780.85 228.7 785.55 232.05 802.45 234.7 815.8 236.65 823.95 236.95 825.3 238.25 824.9 239.55 824.55 239.35 823.2 238.05 814.25 235.5 802 232.5 787.95 231 780.95 226.05 757.75 221.5 737.95 216.25 715.5 210.9 696.25 208.95 689.2 204.6 675.15 200.3 661.15 198.3 654.05 198.25 653.85 198.05 653.9 Z"
				/>
			</g>

			<g id="Layer7_26_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 464.05 955.5
Q 459.9 952.95 453.15 949.95 445.55 946.75 441.9 945.05 435.55 942.2 418.8 934.3 396.6 923.8 374.7 912.7 332.45 891.2 287.6 865.35 271.3 855.85 263.2 851
L 251.6 844.05
Q 247.65 841.5 245.6 840.35 242.05 838.4 239.05 837.9 238.9 837.9 239 838 241.6 841.65 247.65 845.4 251 847.5 257.6 851.2 261.9 853.9 278.55 864 301.4 877.65 322.7 889.45 365.75 913.45 411.5 935.6 424.7 942 437.1 947.7 441.25 949.6 449.7 953.7 457.2 957.2 462.65 958.75 464.15 959.2 464.75 957.75 465.35 956.3 464.05 955.5 Z"
				/>
			</g>

			<g id="Layer7_27_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 496.45 948.65
Q 508.1 946.4 522.7 943.3 570.1 933.4 573 932.8 631.7 920.6 676.6 917.7 679.55 917.5 679.55 914.5 679.55 911.55 676.6 911.6 652.7 912.05 622.7 917.05 605.35 919.95 569.55 927.65
L 515.45 939.3
Q 510.45 940.4 488.4 945.4 481.8 946.5 478.4 947.45 472.35 949.15 470.15 952.75 469.65 953.6 470.4 954.35 471.15 955.05 472 954.55 476.45 952.1 483.95 950.7 488.2 949.9 496.45 948.65 Z"
				/>
			</g>

			<g id="Layer7_28_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 509.15 852.15
Q 509.05 852.55 509.3 852.75 510.65 854.1 512.75 854.95 513.3 855.15 516.8 856.25 521.65 857.85 528.95 859.9
L 551.55 866.45
Q 574.95 873.4 597.4 880.55 619.05 887.5 642.05 895.4 656.55 900.35 663.05 902.85 666.75 904.25 673.1 907.6 679.8 911.2 682.95 912.5 684.7 913.2 685.6 911.45 686.5 909.7 685.35 908.4 682.45 905.25 677.1 902.6 674.6 901.35 667.75 898.65 661.75 896.25 647.2 891.4 624.6 883.7 600.65 876.25 575.65 868.45 553.75 862.2 546.2 860 531.1 855.8 524 853.9 519.75 852.9 516.35 852 514.65 851.7 511.75 851.15 509.55 851.7 509.2 851.8 509.15 852.15 Z"
				/>
			</g>

			<g id="Layer7_29_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1375.4 677.55
Q 1351.7 640.2 1323.95 603.25 1296.95 567.3 1267.55 534.2 1251.7 516.35 1236.4 500.3 1227.9 491.4 1220.9 484.45 1216.1 479.7 1213.3 477.45 1208.9 473.85 1204.6 471.95 1195.05 467.8 1182.1 468.9 1172.75 469.7 1159.6 473.65 1133.15 481.7 1117.55 495.2 1116.45 496.15 1117.4 497.35 1118.35 498.55 1119.6 497.85 1132.2 490.85 1137.45 488.25 1147.5 483.2 1156.1 480.4 1167.95 476.55 1176.25 475.2 1187.95 473.3 1196.85 475.3 1205.35 477.2 1214.4 485.45 1219.6 490.2 1228.9 500.3 1244.45 515.95 1257.05 529.7 1312.2 590.05 1357.15 657.45 1370.35 677.3 1381.85 696.35 1389.2 708.55 1393.55 716.15 1399.75 726.75 1400.4 728.35 1404.2 737.45 1398 738.7 1395.6 739.2 1396.3 741.75 1396.95 744.3 1399.35 743.65 1408.4 741.25 1405.85 731.2 1404.65 726.35 1398.75 716.2 1387.6 696.8 1375.4 677.55 Z"
				/>
			</g>

			<g id="Layer7_30_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 859.9 528.35
Q 863.3 522 859.25 515.3 855.5 509 848.55 507.1 841 505.05 831.85 507.75 823.95 510.05 816.75 515.25 810.05 520.05 805.2 526.65 802.8400390625 529.837109375 801 533.3 800.3970703125 531.1353515625 799.95 529.55 798 522.7 795.85 518.45 794.05 514.95 791.2 513 788.05 510.95 784.35 511.25 774.5 512.15 772.15 522.5 771.1388671875 526.990625 771.55 532.5 763.8744140625 526.3765625 757.4 523.7 744.2 518.3 734.1 525.6 724.7 532.35 726.1 547 727 556.45 732.85 570.45 737.65 582 746.65 589.05 755.2 595.75 767.8 598.6 775.6 600.35 791.85 602.4 806.3 604.7 815 609.25 826.1 615.1 832.55 627.7 837.85 638.15 839.7 652.6 844.25 688.15 830.6 708.05 821.1 721.85 797.1 737.4 780.5 748.1 778.1 749.75 767.55 757.1 760.85 764 755.5 769.5 752.35 774.1 748.05 780.3 746.95 786 745.7 792.45 749.25 798.1 752.4 803 758.6 806.6 770.6 813.5 790.9 817.95 809.1 822 826.5 820.9 828.55 820.8 828.55 818.75 828.55 816.7 826.5 816.6 792.15 814.9 767.1 803.5 759 799.75 755.9 795.4 751.9 789.65 755.8 782.4 760.1 774.45 769.2 766.05 775.7 760.05 785.45 753.35 786.1 752.9 802.8 742 824.55 727.85 834.2 715.5 841.5 706.2 844.9 694.15 847.85 683.55 847.9 670.4 848 643.35 838.6 625.55 833.5 616 825.25 609.45 817.15 603.05 806.55 600 799.85 598.1 784.65 595.95 770.3 593.95 762.85 591.55 748.75 587.05 741.9 576.05 738.2 570 735.6 562.55 733.05 555.25 732.05 548.1 730.9 539.65 734.45 533.95 738.4 527.6 746.75 527.4 753.9 527.25 761.4 531.95 765.75 534.7 773.6 541.8 775.2 543.25 777.05 542.2 778.9 541.15 778.4 539 777 533 776.8 530.6 776.3 525.65 777.95 522.3 779.3 519.5 782 517.9 785 516.15 787.7 517.45 789.9 518.5 791.3 521.9 791.55 522.4 793.1 527.6
L 797.7 542.55
Q 798.3 544.6 800.35 544.55 802.4 544.5 803.1 542.55 807.45 530.35 817.8 521.7 828.75 512.6 841.25 512.15 845.35 512.05 848.55 513.3 852.3 514.8 854.1 518 856.2 521.55 854.85 524.85 853.65 527.7 850.05 530.1 845.5 533.2 837.05 538.3 827.75 543.95 824 546.4 822.15 547.6 823.25 549.5 824.35 551.4 826.35 550.35 831.7 547.45 846.1 539.1 853.75 534.65 853.8 534.65 858.1 531.7 859.9 528.35 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.218994140625, 0, 0, 0.218994140625, -32.55,-26.95) ">
			<use xlinkHref="#Layer9_0_FILL" />
		</g>

		<g transform="matrix( 0.218994140625, 0, 0, 0.218994140625, -32.55,-26.95) ">
			<use xlinkHref="#Layer8_0_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_0_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_1_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_2_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_3_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_4_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_5_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_6_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_7_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_8_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_9_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_10_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_11_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_12_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_13_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_14_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_15_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_16_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_17_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_18_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_19_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_20_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_21_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_22_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_23_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_24_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_25_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_26_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_27_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_28_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_29_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer7_30_MEMBER_100_FILL" />
		</g>
	</svg>
);
