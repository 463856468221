import React from "react";

export const skillsPage2 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="275px"
		height="480px"
		viewBox="0 0 275 480"
		id="frame-2"
	>
		<defs>
			<g id="Layer2_0_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1532.65 303.5
Q 1532.65 303.6 1532.75 303.6 1532.85 303.6 1532.85 303.5 1532.85 303.4 1532.75 303.4 1532.65 303.4 1532.65 303.5 Z"
				/>
			</g>

			<g id="Layer2_1_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 988.55 283.75
Q 985.1 281.25 979.65 278.3 973.5 275.1 970.45 273.4 964.15 269.8 960.65 266.75
L 959.45 265.7
Q 959.2 265.4 959.2 265.25 959.15 265.1 959.25 264.65 959.6 263.3 960.4 261.95 962.05 259.15 964.55 257.8 964.7 257.75 964.45 257.65 959.85 259.65 958.15 263.8 957.45 265.2 957.55 265.8 957.6 266.15 958.1 266.7 958.35 266.95 958.85 267.45 960.45 269.2 962.8 270.95 966.25 273.55 972 276.75 980.9 281.65 981.5 282 990.35 287.3 997.35 295.05 1004.3 302.75 1008.75 312.1 1011.2 317.2 1012.8 322.8
L 1014.05 328.35
Q 1014.8 331.7 1015.8 333.75 1015.95 334.05 1016.3 334 1016.7 333.95 1016.75 333.6 1017.25 331.45 1016.9 328.85 1016.7 327.3 1015.9 324.15 1014.75 319.5 1012.7 314.55 1008.95 305.35 1002.85 297.55 996.5 289.4 988.55 283.75 Z"
				/>
			</g>

			<g id="Layer2_3_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 998.05 364.25
Q 998.1 364.4 998.25 364.35 1002.55 363.15 1010.85 359.75 1016.5 357.45 1023.45 354.9 1036.55 350.25 1049.15 347.4 1076.4 341.35 1100.05 344.15 1124.35 347 1144.1 358.2 1154.5 364.05 1162.6 371.75 1171.6 380.2 1175.9 387.1 1178.7 391.45 1178.7 395.35 1178.7 400.65 1175.55 403.8 1173.05 406.3 1167.1 406.6 1160.25 406.95 1158 408.2 1156 409.3 1154.85 411.55 1154.05 413.15 1155.6 414.05 1157.15 414.95 1158.1 413.4 1159.15 411.75 1161.4 411.15 1162.75 410.75 1165.5 410.6 1169.45 410.35 1170.65 410.2 1173.5 409.8 1175.55 408.75 1180.05 406.45 1181.9 400.9 1183.65 395.65 1182.1 390.65 1180.9 386.8 1177 381.7 1173.7 377.4 1170.1 373.65 1162.05 365.05 1152.5 358.7 1132.95 345.7 1107.85 341.5 1083.3 337.35 1056.1 342.45 1043.7 344.75 1029.4 349.7 1013.3 355.2 1003.95 360.45 1000.4 362.35 998.1 364.05 998 364.15 998.05 364.25 Z"
				/>
			</g>

			<g id="Layer2_4_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1157.95 471.7
Q 1149.85 463.35 1144.1 454.45 1138.65 446 1134.7 434.55 1133.8 431.9 1133.2 429.25 1133.15 429.05 1132.95 429.15 1132.75 429.2 1132.8 429.4 1136.25 449.15 1151.7 467.6 1164.4 482.8 1185.8 497.5 1192.35 502.05 1206.75 511.3 1220.6 520.15 1227.7 525.15 1250 540.8 1261.95 556.85 1276.8 576.75 1278.4 596.8
L 1278.65 602.3
Q 1278.75 604.1 1280.5 604.1 1282.3 604.1 1282.35 602.3 1282.9 592.4 1278.95 580.5 1275.7 570.55 1269.3 560.65 1257.8 543 1237.3 527.7 1228.9 521.4 1216.35 513.55 1202 504.7 1194.85 500.2 1183.5 493.05 1175.55 487 1165.5 479.45 1157.95 471.7 Z"
				/>
			</g>

			<g id="Layer2_5_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1129.2 713.85
Q 1129.85 715 1130.95 714.3 1143.65 706.2 1159.3 698.8 1172.2 692.7 1189.15 686.25 1196.2 683.55 1219.4 675.05 1224.3 673.25 1234.1 669.65 1242.5 666.45 1248.55 663.7 1256.15 660.35 1260.4 656.15 1263 653.65 1264.3 651.05 1266 647.65 1265.15 644.85 1265.1 644.7 1264.95 644.65 1264.75 644.6 1264.7 644.75 1264.4 645.4 1263.7 647.4 1263.1 649.1 1262.6 650.05 1261.25 652.5 1259 654.7 1255.35 658.25 1248.65 661.4 1245.8 662.75 1241.85 664.3 1237.25 666.05 1234.95 666.9 1227.15 669.9 1220.05 672.45 1195.5 681.35 1190.3 683.3 1173.55 689.65 1161.05 695.35 1143.25 703.45 1129.6 712.05 1128.5 712.75 1129.2 713.85 Z"
				/>
			</g>

			<g id="Layer2_6_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1087.6 663.65
Q 1083 670.7 1080.5 678.15 1076.9 689.05 1080.65 695.95 1082.65 699.65 1087.1 702.7 1089.4 704.3 1095.3 707.35 1108.85 714.45 1116.9 718.15 1117.4 718.4 1117.65 717.9 1117.95 717.45 1117.5 717.15 1112.35 714 1099.5 707 1094.35 704.35 1091.85 702.85 1087.4 700.3 1084.95 697.6 1079.6 691.7 1082.7 681.25 1084.85 674.15 1089.4 666.85 1096.95 654.8 1110.9 643.55 1119.55 636.65 1137.15 625.5
L 1156.3 613.45
Q 1158.25 612.2 1166.3 607.3 1172.5 603.55 1176.2 601 1177.35 600.15 1176.65 598.95 1175.95 597.7 1174.7 598.4 1170.9 600.55 1165.3 604.1 1156.6 609.55 1156 609.9 1149.9 613.7 1137.7 621.1 1119.55 632.2 1109.95 639.9 1095.45 651.5 1087.6 663.65 Z"
				/>
			</g>

			<g id="Layer2_7_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1038.4 706.6
Q 1038.1 706.35 1037.85 706.6 1032.05 712.4 1028.7 721.25 1025.6 729.4 1025.2 738.3 1025 742.4 1025.55 747 1026.2 752.4 1027.4 755.3 1028.9 759.25 1031.65 761.4 1035 764.05 1038.6 762.4 1040.05 761.8 1042.6 759.55 1044.25 758.15 1047.35 755.2
L 1051.5 751.35
Q 1050.79765625 755.975390625 1051.15 760.85 1051.75 768.85 1055.1 776.35 1058.5 783.85 1064.25 789.3 1066.2 791.2 1067.4 792 1069.4 793.3 1071.25 793.35 1073.65 793.4 1074.9 791.45 1076.05 789.7 1075.75 787.25 1075.15 782.45 1074.05 775.4 1074.03125 775.2818359375 1074 775.15 1077.8521484375 781.1353515625 1082.65 786.65 1087.5 792.2 1091.65 794.75 1097.35 798.25 1103.15 797.25 1106 796.75 1108.25 795 1110.55 793.25 1111.65 790.65 1113.7 786.05 1112.15 780.15 1110.8 775.2 1106.05 769.55 1104.1 767.25 1096.75 759.65
L 1088.45 751
Q 1086.45 748.75 1085.65 748.25 1084.05 747.25 1082.25 748.05 1080.4 748.9 1080.95 750.9 1081.35 752.45 1082.9 752.85 1083.6689453125 753.0484375 1084.25 752.8 1084.32578125 752.86796875 1084.4 752.95
L 1098.6 767.85
Q 1103 772.4 1104.7 774.8 1107.8 779.05 1108.35 783.15 1109 787.6 1106.95 790.35 1104.5 793.5 1099.6 793.15 1095.5 792.9 1091.25 789.45 1088.85 787.4 1084.8 782.7 1077.4 774.05 1072 763.05 1071.7 762.35 1070.85 762.1 1070.05 761.9 1069.4 762.2 1067.85 762.85 1068.2 764.65
L 1070.95 783.1
Q 1071.45 786 1071.6 787.45 1071.8 788.3 1071.65 788.9 1071.65 789.1 1071.55 789.2 1071.4998046875 789.2333984375 1071.45 789.25
L 1071.25 789.25
Q 1070 789.1 1068.5 787.8 1068.25 787.6 1066.2 785.55 1061.5 780.75 1058.6 774.4 1055.75 768.05 1055.2 761.3 1054.5 753.25 1056.85 746.05 1057.3 744.6 1055.95 743.9 1054.6 743.15 1053.55 744.15 1041.8 755.4 1039.5 757.7 1036.15 761.1 1033.5 759.65 1031.15 758.4 1029.55 754.15 1027.2 747.85 1027.45 738.7 1027.7 730.55 1030.2 723 1032.6 716 1038.5 707.15 1038.7 706.8 1038.4 706.6 Z"
				/>
			</g>

			<g id="Layer2_8_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 969.1 456.1
Q 963.15 486.7 968.4 516.9 969.7 524.55 972.25 533.4 973.5 537.55 974.95 541.45 976.95 546.9 978.5 549.4 978.85 549.95 979.4 549.7 979.95 549.45 979.8 548.85 979.5 547.55 977.55 541.55 976.85 539.55 975.35 534.45 973.35 527.5 971.85 519.9 969 505.15 968.85 490 968.6 459.7 979.2 431.5 984.4 417.4 994.5 401.3 995.35 399.95 993.95 399.15 992.55 398.35 991.7 399.65 984.15 410.8 977.7 426.9 972 441.25 969.1 456.1 Z"
				/>
			</g>

			<g id="Layer2_9_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 791.1 383.25
Q 786.1 388.1 778 392.9 768.4 398.1 763.65 400.75 754.95 405.65 749.35 410.1 742.05 415.9 737.75 422.6 733.05 430 732.05 437.9 731 445.9 734.6 453.5 740.4 466.1 757.75 474.8 771.65 481.65 790.65 483.6 804.5 485.1 825.8 483.9 831.35 483.6 844.35 482.75 856.3 481.95 862.9 481.6 884.2 480.5 900.4 481.4 919.15 482.55 937.2 487.15 939.75 487.8 946 489.6 946.7 489.85 947.05 489.05 947.4 488.25 946.7 487.95 932.15 481.55 911.3 479.05 895.45 477.1 874.7 477.35 866.55 477.5 856.15 478 851.4 478.25 837.55 479.1 826 479.85 820.1 480.1 810.35 480.5 802.6 480.25 783.4 479.75 769.4 475.15 751.45 469.3 742.3 458.4 737.2 452.25 736.05 445.2 734.9 438 738.25 430.3 743.9 417.3 760.9 406.8 762.25 405.95 775.6 398.5 784.55 393.5 789.75 389.3 794.25 385.7 798.75 380.1 801.05 377.25 806.4 369.7
L 811.05 363.4
Q 811.5 362.85 811.25 362 811 361.25 810.4 360.9 809.05 360.1 807.9 361.55 802.6 369.05 799.9 372.75 795.15 379.35 791.1 383.25 Z"
				/>
			</g>

			<g id="Layer2_10_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 873.45 328.1
Q 873.55 328.25 873.7 328.25 874.65 328.1 875.65 327.55 876.25 327.25 877.45 326.45 878.9 325.55 881.85 323.65 886.7 320.95 890.85 321.5 895.05 322.05 899.2 325.6 901.95 327.95 905.55 332.55 912.45 341.4 917.6 351.2 920.1 355.95 922.2 360.85 924.45 366.45 924.4 369.9 924.4 372.35 923.2 374.25 922 376.15 919.75 377.05 917.7 377.8 914.9 377.95 913.2 378 909.9 377.8 908.2 377.7 908.2 379.4 908.25 381.15 909.9 381.3 916.05 381.9 919.95 380.9 925.4 379.45 927.4 374.8 929.35 370.2 927.55 363.8 926.55 360.45 923.15 353.4 917.85 342.3 910.45 332.45 906.2 326.75 903.25 323.95 898.7 319.65 893.9 318.2 887.9 316.45 882 319.55 879.5 320.9 876.95 323.2 873.85 325.9 873.4 327.85 873.35 327.95 873.45 328.1 Z"
				/>
			</g>

			<g id="Layer2_11_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 968.85 706.2
Q 966.7 703.45 966.15 699.05 965.75 696.2 965.95 691.25 966.5 676.15 974.95 662.9 979 656.5 984.6 651.45 985.6 650.6 987.7 648.9 989.55 647.4 990.55 646.15 992.95 643.1 992.5 639.3 992.1 635.95 989.65 632.1 986.55 627.85 985.1 625.75 980.85 619.4 978.85 612.15 976.65 603.85 977 595.35 977.25 588.3 981.1 578.9 981.2 578.6 980.9 578.45 980.55 578.35 980.4 578.6 977.25 583.8 975.4 592.85 973.85 600.55 974.9 608.05 975.95 615.45 979.25 622.45 980.9 625.9 983.2 629.05 986.8 634.1 987.7 635.85 989.8 639.9 988.55 642.6 987.9 644.1 986.05 645.65 983.9 647.3 982.95 648.1 977.4 652.85 972.85 659.45 963.85 672.7 962.4 688.55 961.9 693.9 962.15 697.6 962.55 702.75 964.4 706.35 965.75 708.95 969.75 712.6 973.9 716.4 975.25 718.7 976.2 720.3 977.8 719.35 979.4 718.4 978.55 716.75 977.35 714.2 974 711.25 969.9 707.55 968.85 706.2 Z"
				/>
			</g>

			<g id="Layer2_12_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 977.1 767.1
Q 976.6 767.25 976.75 767.75 979.15 777.35 983.35 791.8 990 814.75 990.3 815.8 997.25 840.35 1003.35 863.75 1009.9 888.5 1015.65 912.15 1020.95 933.75 1027.1 960.75 1032.5 984.85 1037.6 1009.6 1038.3 1013.2 1039.25 1015.5 1039.6 1016.45 1040.65 1016.35 1041.65 1016.25 1041.6 1015.15 1041.5 1012.5 1040.8 1009.05 1039.9 1005.05 1039.45 1003
L 1031.2 966.5
Q 1026.2 944.65 1019.6 917.9 1014.1 895.6 1007.2 869.55 1000.85 845.4 994 821.4 992.75 816.95 987.1 797.4 981.6 778.75 979.85 773.5
L 977.75 767.45
Q 977.6 766.95 977.1 767.1 Z"
				/>
			</g>

			<g id="Layer2_13_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1081.05 175.95
L 1087.1 175.95
Q 1088.1 175.9 1088.4 174.9 1088.65 173.95 1087.8 173.4 1085.85 172.25 1083.65 172.3 1080.1 172.3 1079.5 174.25 1079.4 174.65 1079.6 175.05 1079.83515625 175.4732421875 1080.2 175.55 1080.22578125 175.5716796875 1080.25 175.6 1080.6 176 1081.05 175.95 Z"
				/>
			</g>

			<g id="Layer2_14_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1138.15 94.15
Q 1136.75 90.8 1134.75 89.35 1132.45 87.75 1128.95 89.3 1124.55 91.15 1121.3 95.55 1116 102.75 1115.65 115 1115.55 120 1116.1 130.05 1116.1052734375 130.3623046875 1116.1 130.65 1114.3013671875 120.6 1110.55 110.8 1104.2 94.1 1093 80.25 1086.6 72.3 1079.15 66.1 1078.35 65.45 1077.4 65.65 1076.4 65.9 1076.15 66.9 1071.7 84.4 1075.7 102.8 1079.7 120.9 1090.85 135.45 1097.45 144.05 1105.1 149.6 1105.4 149.85 1105.65 149.5 1105.9 149.2 1105.6 148.95 1091.75 137.25 1084.4 121.35 1076.95 105.35 1077.05 87.35 1077.134765625 78.6580078125 1078.95 70.65 1090.4984375 81.216015625 1098.65 94.65 1107.6 109.45 1111.45 126.2 1113.6 135.45 1114.05 145.3 1114.1 146.9 1115.6 147.05 1117.1 147.25 1117.65 145.8 1120.2 138.9 1119.45 123.45 1118.75 108.6 1122 101.3 1124 96.75 1127.2 94.2 1129.4 92.45 1131.25 92 1132.55 91.65 1133.55 92.7 1134.05 93.25 1134.9 94.85 1136.8 98.8 1136.6 106 1136.55 107.5 1135.9 118.95
L 1134.1 150.15
Q 1134 151.5 1135.45 151.7 1136.95 151.9 1137.25 150.55 1137.95 147.45 1142.2 145.95 1144.45 145.2 1149.35 144.65 1158.9 143.6 1168.35 145.25 1173.6 146.25 1177.6 147.65 1180.6 148.6 1181.35 148.95 1183.6 150 1184.05 151.55 1184.55 153.2 1183.25 154.75 1182.1 156.1 1180.4 156.7 1178.55 157.35 1175.8 157.45 1172.65 157.45 1171.1 157.45
L 1148 157.55
Q 1146.4 157.6 1146.4 159.2 1146.4 160.85 1148 160.85
L 1172.05 160.8
Q 1176.05 160.8 1177.35 160.7 1180.35 160.45 1182.5 159.5 1184.55 158.6 1185.95 156.8 1187.35 154.9 1187.55 152.75 1187.75 150.4 1186.15 148.5 1184.85 146.9 1182.5 145.85 1178.5 144.05 1172.4 142.6 1161.4 140.1 1150.7 141.15 1144.2 141.75 1140.85 143 1138.9845703125 143.693359375 1137.6 144.65
L 1139.05 120.2
Q 1139.75 109.35 1139.85 106.25 1140.05 98.75 1138.15 94.15 Z"
				/>
			</g>

			<g id="Layer2_15_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1159.15 173.05
Q 1158.55 173.15 1158.45 173.75
L 1158.45 174.3
Q 1158.75 175.05 1159.4 175.05 1168.8 174.95 1175.4 176.35 1183.4 178.05 1190.55 181.6 1194.6 183.7 1197.5 185.75 1201.1 188.3 1202.35 190.8 1203.9 193.95 1201.7 196.4 1199.85 198.5 1196.4 199.15 1193.85 199.6 1187.7 198.8 1182.1 198.1 1178.95 199.1 1177.8 199.5 1178.1 200.7 1178.45 201.9 1179.65 201.55 1182.7 200.75 1188.35 201.45 1194.35 202.2 1197.1 201.7 1200.75 201.05 1203.15 198.85 1205.9 196.35 1205.65 192.9 1205.4 189.2 1201.95 185.9 1199.75 183.8 1195.25 181.15 1187.5 176.5 1178.8 174.4 1168.3 171.85 1159.15 173.05 Z"
				/>
			</g>

			<g id="Layer2_16_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1181.95 218.75
L 1184.45 220
Q 1187.55 221.6 1189.05 222.5 1194.55 225.6 1197.85 228.6 1205.62421875 235.4896484375 1207.25 244.05 1204.5044921875 244.3552734375 1201.45 243.1 1198.9 242.05 1195.45 239.5 1191.9 236.8 1190.05 235.55 1188.15 234.3 1187.35 233.85 1185.65 233 1184.3 233.05 1183.75 233.1 1183.5 233.65 1183.25 234.2 1183.7 234.6 1184.2 235 1185.05 235.45 1185.55 235.7 1186.55 236.2 1188.25 237.1 1189.55 238.05 1191.55 239.4 1195.45 242.35 1202.8 247.45 1208.95 246.2 1209.3 246.1 1209.55 245.8 1209.85 245.4 1209.8 245.05 1209.05 238.85 1205.2 233.3 1201.8 228.35 1196.25 224.3 1191.6 220.9 1184.2 217.45 1180.05 215.55 1179.4 217.5 1179.25 218.05 1179.5 218.7 1179.8 219.4 1180.25 219.6 1180.35 219.65 1180.3 219.5 1179.55 218.55 1180.2 218.55
L 1180.45 218.55
Q 1181.05 218.25 1181.95 218.75 Z"
				/>
			</g>

			<g id="Layer2_17_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1131 241.85
Q 1131.05 242 1131.15 242 1131.65 241.95 1132.45 242.15 1132.9 242.25 1133.8 242.45 1135.3 242.8 1136.5 243.25 1138.95 244.25 1141.4 246 1146.35 249.4 1149.4 252.9 1153.2 257.35 1151.4 261.55 1150.55 263.7 1148.55 265.3 1146.65 266.8 1144.3 267.5 1141.35 268.3 1137.5 268.05 1136.4 268 1134.05 267.65 1131.8 267.3 1130.55 267.25 1129.45 267.2 1129.35 268.3 1129.2 269.45 1130.25 269.75 1132.35 270.35 1137.3 270.65 1141.3 270.8 1144.3 270.1 1147.1 269.4 1149.4 267.75 1151.85 266 1153.15 263.6 1156 258.4 1152.3 252.95 1149.9 249.4 1143.95 245 1140.25 242.35 1137.8 241.55 1135.95 240.95 1134.4 240.9
L 1133 240.95
Q 1132.6 241.05 1132.05 241.3 1131.35 241.6 1131.1 241.7 1131 241.75 1131 241.85 Z"
				/>
			</g>

			<g id="Layer2_18_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1002.9 204.55
Q 1003.2 204.35 1003.1 204.05 1002.75 202.75 1001 203.4 999.95 203.8 998.65 204.8 997.2 206 995.25 208.8
L 992.1 213.25
Q 991.2 214.4 990.6 215.7 989.55 217.85 990.15 218.65 990.7 219.4 991.45 218.95 991.9 218.7 992.2 217.95 992.45 217.15 992.6 216.75 992.85 216.2 993.9 214.55
L 996.75 210.65
Q 998.35 208.2 999.5 207 1000.2 206.25 1001.1 205.7
L 1002.7 204.8
Q 1003 204.5 1002.9 204.55 Z"
				/>
			</g>

			<g id="Layer2_19_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1010.9 207.3
Q 1010.35 207.05 1009.95 207.45 1009.2 208.25 1007.7 211.25
L 1006.7 213.15
Q 1005.75 214.8 1005.75 215.55 1005.75 216.3 1006.45 216.55 1007.05 216.8 1007.65 216.3 1008 216.05 1008.3 215.4 1008.6 214.7 1008.75 214.35
L 1010.5 210.35
Q 1011.15 208.8 1011.25 208.2 1011.35 207.6 1010.9 207.3 Z"
				/>
			</g>

			<g id="Layer2_20_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1020 214
Q 1019.5 213.5 1018.85 213.85 1018.25 214.2 1017.5 215.4 1017.3 215.7 1016.55 216.9
L 1015.4 219.55
Q 1015 220.6 1015.65 221.35 1016.15 221.85 1016.9 221.65 1017.55 221.5 1017.85 220.85 1018.15 220 1018.3 219.6
L 1018.85 218 1020.15 215.15
Q 1020.45 214.45 1020 214 Z"
				/>
			</g>

			<g id="Layer2_21_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1031.7 217.75
Q 1031.68671875 217.4412109375 1031.4 217.25 1031.15 217.05 1030.8 217.15 1030.45 217.25 1030.35 217.6 1030.1 218.6 1030 221.45
L 1030.05 224.7
Q 1030.1 225.15 1030.35 225.7 1030.5 226.05 1030.95 226.25 1031.35 226.45 1031.75 226.35 1032.75 226.25 1032.9 225.35 1032.95 225.05 1032.85 223.95
L 1032.35 221.2
Q 1031.942578125 218.665234375 1031.7 217.75 Z"
				/>
			</g>

			<g id="Layer2_22_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 976.55 243
Q 977.65 242.75 979.85 242.3 982 241.7 982.7 240.7 982.8 240.5 982.65 240.3 982.5 240.15 982.3 240.2 980.4 240.8 979.4 240.95
L 976.3 241.45
Q 974.65 241.8 973.45 242.4 972.1 243.2 972 243.25 970.95 243.3 970.55 243.6 970.1 243.95 970.25 244.35 970.55 245.3 971.7 245.15 972.55 245 973.45 244.4 974.95 243.4 976.55 243 Z"
				/>
			</g>

			<g id="Layer2_23_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 994.6 247
Q 993.95 246.65 993.55 247.15 993.35 247.4 993.3 247.45
L 992.7 248.1
Q 991.95 248.95 991.8 249.1
L 990.85 249.95 990.95 249.9
Q 990.8 250 990.75 250
L 990.5 250.2 989.2 251
Q 988.55 251.65 988.95 252.5 989.35 253.2 990.15 253.1 990.5 253.05 991.1 252.65 992.5 251.7 993.25 250.8 993.8 250.25 994.3 249.5 994.75 248.8 995 247.95 995.1 247.3 994.6 247 Z"
				/>
			</g>

			<g id="Layer2_24_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1003.6 253.3
Q 1003.15 253.25 1002.8 253.5 1002.55 253.75 1001.9 255.15
L 1000.65 258.45
Q 1000.1 260.1 1000.1 260.35 1000.1 260.75 1000.35 261.1 1000.65 261.45 1001 261.55 1001.9 261.7 1002.35 260.95 1002.6 260.6 1003 259.1
L 1003.45 257.45
Q 1003.85 256.1 1003.9 255.85 1004.25 254.3 1004.15 253.85 1004.05 253.4 1003.6 253.3 Z"
				/>
			</g>

			<g id="Layer2_25_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 984.6 321.2
Q 983.65 320.4 981.25 318.65 977.65 316 973.55 314.05 966.623828125 310.6638671875 958.85 309.3 965.024609375 305.859765625 966.15 305.2 968.75 303.6 970.25 302.2 972.25 300.25 972.95 298 973.7 295.75 972.9 293.4 972.15 291.15 970.3 289.5 966.4 286.05 961.1 286.75 958.18125 287.0962890625 954.9 288.75 956.1177734375 285.2744140625 955.5 280.95 954.35 272.65 947.65 268.8 944.15 266.8 939.9 266.55 935.75 266.3 931.85 267.7 927.9 269.1 924.95 272 921.95 274.85 920.35 278.7 918.45 283.15 918.6 288.1 918.7 293.15 920.85 297.4 921.2 298.05 921.9 297.75 922.65 297.45 922.4 296.75 919.9 288.8 921.9 282.1 924.3 274.35 930.85 271.15 933.95 269.6 937.45 269.4 941.05 269.15 944.2 270.4 947.3 271.55 949.45 274.05 951.55 276.55 952.3 279.8 953.15 283.3 952.35 286.65 951.45 290.15 948.9 292.5 948 293.35 948.85 294.45 949.65 295.55 950.7 294.85 954.15 292.5 956.1 291.55 959.35 289.95 962.15 289.8 964.55 289.7 966.7 290.85 969.05 292.1 969.85 294.25 971.3 298.15 965.35 302 962.9 303.55 959.4 305.55 955.35 307.8 953.35 308.9 952.35 309.5 952.7 310.6 953.05 311.7 954.15 311.8 962.1 312.45 969.6 315.6 977.2 318.8 983.05 324.1 986.2 326.95 985 329.55 983.85 331.95 980.05 333.85 977.5 335.15 972.3 336.75 968.45 338 963.7 339.05 954.05 341.1 943.65 341.1 942.1 341.1 942.1 342.7 942.1 344.25 943.65 344.25 953.55 344.35 963.6 342.25 968.55 341.3 973.35 339.7 979.15 337.9 981.95 336.4 985.7 334.4 987.5 331.4 989.6 327.8 987.45 324.35 986.45 322.8 984.6 321.2 Z"
				/>
			</g>

			<g id="Layer2_27_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1126.4 256.95
L 1125.2 257.2
Q 1124.633984375 257.31328125 1124.4 257.6
L 1124.15 257.7
Q 1123.95 257.8 1124 257.95 1124 258.15 1124.2 258.2
L 1124.4 258.2
Q 1124.35078125 258.13125 1124.3 258.05
L 1124.55 258.2 1124.4 258.2
Q 1124.70234375 258.4703125 1125.3 258.55
L 1132.35 259.15
Q 1133.1 259.25 1133.6 259 1134 258.85 1134.2 258.4 1134.35 257.9 1134 257.6 1133.35 257.1 1132.8 257
L 1128.9 256.8
Q 1126.45 256.95 1126.4 256.95 Z"
				/>
			</g>

			<g id="Layer2_28_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1061.2 180.9
Q 1060.6 180.95 1060.6 181.55 1060.7 185.35 1064.1 188.45 1066.85 190.9 1071.05 192.4 1073.05 193.15 1075.8 193.65 1076.5 193.8 1080.65 194.45 1083.95 195 1085.8 195.45 1086.15 195.55 1088.35 196.15 1089.9 196.6 1090.95 196.7 1091.6 196.75 1091.8 196.1 1092.05 195.45 1091.6 195 1090.25 193.65 1087.6 192.95 1086.3 192.65 1082.95 192.25 1079.75 191.9 1078.1 191.75 1075.2 191.4 1073.25 190.95 1069.05 190 1066.2 187.7 1063 185.2 1061.9 181.35 1061.75 180.8 1061.2 180.9 Z"
				/>
			</g>

			<g id="Layer2_29_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1009.75 133.85
Q 1000.2 129.05 986.8 124.35 973.35 119.65 962.15 118.25 955.25 117.4 950.15 117.75 943.55 118.2 938.6 120.65 933.7 123.1 931 127.75 928.1 132.7 929.1 137.85 930 142.6 934.45 147.3 935.75 148.65 942.7 154.7 953.1 163.6 961.6 168.75 972.5 175.35 983.85 178 989.15 179.25 996.8 180.45 1001.15 181.15 1009.8 182.45
L 1012.95 182.95
Q 1013.65 183.05 1016.25 182.95 1017.1 182.9 1017.2 182.1 1017.3 181.35 1016.55 181.05 1014.55 180.2 1013.75 180.05 1013.6 180 1010.35 179.45
L 991.95 176.3
Q 978.85 173.9 969.75 169.3 960.8 164.75 951.25 157.1 948.4 154.8 942.8 150 939.6 147.15 938.95 146.55 936.8 144.55 935.4 142.8 931.85 138.25 932.35 133.85 932.85 129.35 936.75 125.8 940.5 122.35 946.55 121.25 951 120.45 957.55 120.85 968.35 121.55 981.15 125.6 992.25 129.15 1003.4 134.05 1015.65 139.6 1022.75 145.75 1031.6 153.4 1035 161.6 1035.9 163.7 1036.7 166.4 1036.95 167.35 1037.95 167.1 1038.9 166.85 1038.7 165.85 1037.75 161.1 1034.85 155.95 1031.75 150.6 1028.65 147.4 1021.45 139.75 1009.75 133.85 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.4829559326171875, 0, 0, 0.4829559326171875, -199.15,-31.25) ">
			<use xlinkHref="#Layer2_0_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_1_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_3_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_4_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_5_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_6_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_7_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_8_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_9_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_10_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_11_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_12_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_13_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_14_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_15_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_16_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_17_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_18_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_19_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_20_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_21_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_22_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_23_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_24_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_25_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_27_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_28_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer2_29_MEMBER_0_FILL" />
		</g>
	</svg>
);
