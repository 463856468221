import React from 'react';

export const IllustratorIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 2437.51">
		<defs></defs>
		<title>adobe-illustrator</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-1"
					d="M104.17,104.17H2395.84V2333.34H104.17ZM0,2437.51H2500V0H0ZM1637.5,868.75c0-8.33,3.13-12.5,12.5-12.5h163.54c8.33,0,12.5,3.12,12.5,12.5v821.88c0,8.33-2.08,12.5-12.5,12.5H1652.09c-10.42,0-13.55-5.21-13.55-13.54V868.75ZM1626,632.29C1626,565.63,1672.92,526,1732.3,526c63.54,0,106.25,42.71,106.25,106.25,0,68.75-44.79,106.25-108.34,106.25C1669.79,738.54,1626,701,1626,632.29Zm-468.75,579.17c-29.16-115.63-97.91-367.71-124-489.58h-2.08c-21.87,121.87-77.08,328.12-120.83,489.58ZM867.71,1380.21l-82.29,312.5c-2.09,8.33-5.21,10.42-15.63,10.42H616.67c-10.42,0-12.5-3.13-10.42-15.63L902.09,652.08c5.2-18.75,8.33-35.42,10.41-86.45,0-7.29,3.13-10.42,8.33-10.42h218.75c7.29,0,10.42,2.08,12.51,10.42l331.24,1124c2.09,8.32,0,13.54-8.33,13.54H1302.08c-8.33,0-13.54-2.09-15.62-9.37L1201,1380.21Z"
				/>
			</g>
		</g>
	</svg>
);

export const PhotoshopIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 2437.51">
		<defs></defs>
		<title>adobe-ps</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-1"
					d="M104.17,104.17H2395.84V2333.34H104.17ZM0,2437.51H2500V0H0ZM1685.42,1000c-82.29,0-110.41,41.67-110.41,76,0,37.5,18.74,63.54,129.16,120.84,163.54,79.16,214.59,155.2,214.59,266.66,0,166.67-127.09,256.25-299,256.25-90.63,0-168.76-18.75-213.55-44.79-7.29-3.12-8.33-8.33-8.33-16.66V1505.21c0-10.41,5.21-13.54,12.5-8.33,65.63,42.71,140.63,61.46,209.37,61.46,82.3,0,116.67-34.38,116.67-81.26,0-37.49-24-70.83-129.17-125-147.92-70.83-209.37-142.7-209.37-262.49,0-134.38,105.21-245.84,287.5-245.84,89.58,0,152.08,13.55,186.46,29.17,8.33,5.21,10.41,13.54,10.41,20.83v142.71c0,8.33-5.2,13.54-15.62,10.41-45.83-29.16-113.54-46.87-181.25-46.87ZM789.58,1124c24,2.08,42.71,2.08,84.38,2.08,121.88,0,236.46-42.7,236.46-208.33,0-132.29-82.29-199-220.83-199-41.67,0-81.25,2.08-100,3.13ZM604.17,570.83c0-7.29,14.59-12.5,22.91-12.5,66.67-3.12,165.63-5.21,268.76-5.21,288.54,0,401,158.34,401,360.42,0,264.58-191.67,378.13-427.09,378.13-39.58,0-53.12-2.09-81.25-2.09v400c0,8.33-3.12,12.49-12.5,12.49H616.67c-8.33,0-12.5-3.12-12.5-12.49V570.83Z"
				/>
			</g>
		</g>
	</svg>
);

export const XdIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 2437.5">
		<defs></defs>
		<title>adobe-xd</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-1"
					fill="#dddbdb"
					d="M2057.3,2437.5H442.7C197.9,2437.5,0,2239.6,0,1994.8V442.7C0,197.9,197.9,0,442.7,0H2057.3C2302.1,0,2500,197.9,2500,442.7V1994.8C2500,2239.6,2302.1,2437.5,2057.3,2437.5Z"
				/>
				<path
					className="cls-2"
					fill="#0c0c0c"
					d="M2017.7,2333.3H482.3c-208.3,0-378.1-169.8-378.1-378.1V482.3C104.2,274,274,104.2,482.3,104.2H2016.7c209.4,0,378.1,169.8,378.1,378.1V1954.2c1,209.3-168.8,379.1-377.1,379.1Z"
				/>
				<path
					className="cls-1"
					fill="#dddbdb"
					d="M988.5,1140.6,1280.2,1701c5.2,8.3,2.1,16.7-6.3,16.7H1092.6c-11.5,0-16.7-3.1-21.9-13.5-66.7-137.5-134.4-275-204.2-422.9h-2.1c-62.5,139.6-131.3,286.5-197.9,424-5.2,8.3-10.4,11.5-18.8,11.5H476c-10.4,0-11.5-8.3-6.2-14.6l285.4-543.8-276-550c-6.3-8.3,0-14.6,6.2-14.6H664.6c10.4,0,14.6,2.1,18.7,11.5C748.9,742.8,815.6,884.5,878.1,1023h2.1c60.4-137.5,127.1-280.2,191.7-416.7,5.2-8.3,8.3-13.5,18.8-13.5h167.7c8.3,0,11.5,6.3,6.3,14.6Zm342.8,169.8c0-242.7,161.5-432.3,417.7-432.3,21.9,0,33.3,0,54.2,2.1v-276a10.38,10.38,0,0,1,10.4-10.4h164.6c8.3,0,10.4,3.1,10.4,8.3v956.3c0,28.1,0,63.5,5.2,102.1,0,6.3-2.1,8.3-8.3,11.5a620.12,620.12,0,0,1-266.7,60.4c-226.1.9-387.6-138.6-387.5-422Zm471.8-266.6c-14.6-6.3-35.4-10.4-60.4-10.4-131.3,0-222.9,101-222.9,268.8,0,191.7,93.8,268.8,211.5,268.8,25,0,52.1-3.1,71.9-11.5V1043.8Z"
				/>
			</g>
		</g>
	</svg>
);

export const VsCodeIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2429.78 2403.79">
		<defs></defs>
		<title>vscode</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-1"
					d="M23.12,838.24S-36.06,795.58,35,738.62l165.46-148s47.35-49.81,97.4-6.42l1526.86,1156v554.33s-.74,87-112.45,77.43Z"
				/>
				<path
					className="cls-1"
					d="M416.67,1195.54,23.12,1553.33s-40.44,30.09,0,83.84l182.72,166.2s43.4,46.61,107.51-6.41l417.22-316.37Z"
				/>
				<path
					className="cls-1"
					d="M1107.6,1198.5l721.75-551.12L1824.67,96S1793.84-24.32,1691,38.32L730.57,912.46Z"
				/>
				<path
					className="cls-1"
					d="M1712.22,2372.74c41.92,42.9,92.72,28.85,92.72,28.85l562.46-277.17c72-49.07,61.89-110,61.89-110V359.37c0-72.74-74.47-97.9-74.47-97.9l-487.49-235C1760.8-39.36,1691,38.32,1691,38.32s89.75-64.61,133.65,57.7V2284.46a99.49,99.49,0,0,1-9.62,43.15c-12.82,25.89-40.69,50.06-107.51,39.95Z"
				/>
			</g>
		</g>
	</svg>
);

export const SublimeTextIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2499.56 2499">
		<defs></defs>
		<title>sublime-text</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-1"
					fill="#dddbdb"
					d="M2402.78,2499H96.78C43.64,2499,0,2455.36,0,2402.22V96.78C0,43.64,43.64,0,96.78,0h2306c53.14,0,96.78,43.64,96.78,96.78v2306c-.56,53.14-43.64,96.22-96.78,96.22Z"
				/>
				<path
					className="cls-2"
					fill="#0c0c0c"
					d="M576.22,754.68l1308-415.1s92.87-48.67,70.49,41.4L1958,781s16.22,58.74-64.34,73.29l-531.46,166.15Z"
				/>
				<path
					className="cls-2"
					fill="#0c0c0c"
					d="M576.22,754.68S524.75,767,539.86,854.82l-2.8,385.45s-4.48,48.67,87.83,68.25L1921.1,1725.86s43.64,17.34,38.6-36.36l.56-429.65s12.31-44.2-68.25-73.29l-529.78-166.15Z"
				/>
				<path
					className="cls-2"
					fill="#0c0c0c"
					d="M1147.4,1476.35,595.8,1644.74s-66.57,2.24-58.74,127-.56,375.94-.56,375.94,5.59,46.44,69.37,19.58l1308-417.33s46.43-11.75,7.27-24.62C1881.94,1713,1147.4,1476.35,1147.4,1476.35Z"
				/>
			</g>
		</g>
	</svg>
);
