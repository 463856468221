import React from "react";

export const contactPage2 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="264px"
		height="153px"
		viewBox="0 0 264 153"
		id="frame-2"
	>
		<defs>
			<g id="Layer2_0_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 586.1 -1622.2
Q 583.85 -1617.7 588.45 -1615.55 595.2 -1612.35 604.45 -1609.3 606.65 -1608.55 620.9 -1604.15
L 857.5 -1531.5
Q 878.9 -1524.95 1131.9 -1447.65 1157 -1440 1207.1 -1424.65 1250.6 -1411.45 1282.45 -1402 1285.7 -1401 1288.2 -1402.65 1290.45 -1404.15 1291.25 -1407 1292.05 -1409.9 1290.85 -1412.45 1289.6 -1415.25 1286.35 -1416.25 1231.5 -1433.4 1151.5 -1457.75 1061.45 -1485 1016.45 -1498.75 834.65 -1554.05 743.75 -1581.6
L 630.75 -1615.75
Q 618.25 -1619.65 611.95 -1621.5 601 -1624.7 592.8 -1625.95 588.3 -1626.6 586.1 -1622.2 Z"
				/>
			</g>

			<g id="Layer2_1_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 581.4 -1624.85
Q 578.45 -1628 574.9 -1627.55 457.95 -1612.45 338.15 -1593.9 267.8 -1583 127.3 -1559.6 -18.85 -1535.1 -82.15 -1525.05 -133.95 -1516.8 -200.4 -1507 -231.8 -1502.35 -318.8 -1489.8 -361.7 -1483.6 -447.65 -1471.55 -523.7 -1460.75 -576.3 -1452.3 -596.6 -1449 -607.35 -1446.3 -610.15 -1445.6 -611.5 -1443.2 -612.8 -1441 -612.45 -1438.3 -612.1 -1435.6 -610.3 -1433.85 -608.35 -1431.9 -605.4 -1432.05 -591.65 -1432.6 -573.25 -1435 -551.95 -1438.1 -541.3 -1439.65
L -348.95 -1467.35
Q -257.65 -1480.55 -228.05 -1484.9 -160 -1494.9 -107.25 -1503.2 -28.2 -1515.6 103 -1537.5 236.75 -1559.85 312.25 -1571.7 435.7 -1590.95 543.7 -1605.05
L 574.9 -1609.1
Q 578.85 -1609.6 581.4 -1611.9 584.15 -1614.45 584.15 -1618.3 584.15 -1621.85 581.4 -1624.85 Z"
				/>
			</g>

			<g id="Layer2_2_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1288.9 -1388.2
Q 1285.95 -1390.35 1282.15 -1389.55 1031.5 -1339.2 748.25 -1286.9 479.35 -1237.25 212.9 -1192.3 129.5 -1178.3 62.75 -1167.3 12.65 -1159.2 -12.3 -1155.05 -37.3 -1151 -49.8 -1148.9 -71.55 -1145.2 -87.1 -1141.65 -89.9 -1141 -91.3 -1138.55 -92.55 -1136.3 -92.2 -1133.5 -91.85 -1130.7 -90.05 -1128.95 -88.1 -1127.05 -85.15 -1127.3 -57.05 -1129.95 -18.1 -1136.25 4.15 -1139.85 48.6 -1147.45 115.65 -1158.4 182.7 -1169.65 316.7 -1192.05 450.6 -1215.55 591.9 -1240.35 719.5 -1263.8 848.3 -1287.45 986.6 -1314.1 1126.45 -1341.05 1253.35 -1366.4
L 1286.7 -1373.05
Q 1290.5 -1373.85 1292.05 -1376.85 1293.4 -1379.6 1292.5 -1382.95 1291.55 -1386.3 1288.9 -1388.2 Z"
				/>
			</g>

			<g id="Layer2_3_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 388.6 -2390.4
Q 386.2 -2393.1 382.1 -2393 118.25 -2384.75 -88.6 -2376.85 -328.15 -2367.55 -556.8 -2356.65 -667.2 -2351.45 -688.85 -2350.35 -763.7 -2346.5 -820.8 -2342.45 -824.25 -2342.2 -826.3 -2339.7 -828.15 -2337.45 -828.2 -2334.4 -828.2 -2331.3 -826.35 -2329.25 -824.3 -2327 -820.8 -2327.1 -772.2 -2328.7 -703.2 -2331.95 -624.8 -2335.75 -585.6 -2337.6 -474.05 -2342.85 -350.35 -2348.15 -96.45 -2358.95 117.9 -2366.45 270.05 -2371.8 382.1 -2375.05 386.2 -2375.15 388.6 -2378.05 390.75 -2380.65 390.75 -2384.3 390.75 -2387.95 388.6 -2390.4 Z"
				/>
			</g>

			<g id="Layer2_4_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 653.6 -1620.55
Q 655.75 -1618.35 658.45 -1618.55 689.45 -1620.85 727.75 -1624.7 752.2 -1627.2 796.95 -1632.2 874.15 -1640.7 934.25 -1648.2 960.8048828125 -1651.457421875 988.9 -1655.2 981.2966796875 -1646.7810546875 970 -1634.3 965.8 -1629.65 968.85 -1624.75 971.9 -1619.95 977.85 -1620.85 1024.35 -1627.75 1073.3 -1621.95 1119.7 -1616.4 1166.15 -1599.7 1169.1 -1598.65 1172.2 -1600.45 1175.15 -1602.25 1176 -1605.3 1176.9 -1608.6 1175.2 -1611.35 1173.6 -1613.95 1170.4 -1615.2 1124.9 -1633.3 1073.4 -1638.5 1035.3177734375 -1642.3431640625 996.2 -1638.95 1010.230859375 -1654.11640625 1016 -1660.5 1020.35 -1665.3 1017.2 -1670.25 1014.1 -1675.15 1007.95 -1674.3 859.7 -1653.5 735.65 -1640.5 674.1 -1634.05 658.45 -1632.25 655.55 -1631.9 653.65 -1630.15 651.65 -1628.3 651.6 -1625.4 651.55 -1622.7 653.6 -1620.55 Z"
				/>
			</g>

			<g id="Layer2_5_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1214.7 -1617.15
Q 1212.5 -1621.3 1208.2 -1619.85 1199.65 -1617 1188.65 -1610.05 1176.25 -1601.75 1170.15 -1597.9
L 1132.7 -1573.95
Q 1128.65 -1571.35 1129.05 -1566.05 1129.5 -1560.65 1134.55 -1559.7 1170.4 -1553.05 1204.05 -1543.65 1221.85 -1538.7 1237.7 -1533.5 1242.55 -1531.95 1254.75 -1527.55 1265.55 -1523.65 1271.85 -1521.75 1274.8 -1520.85 1277.4 -1522.4 1279.8 -1523.8 1281 -1526.55 1282.25 -1529.35 1281.55 -1531.9 1280.75 -1534.7 1277.85 -1536 1263.4 -1542.45 1243.95 -1548.55 1233 -1552 1209.15 -1558.6 1183.654296875 -1565.6615234375 1157.1 -1571.15
L 1205.25 -1602.35
Q 1209.25 -1605 1213.95 -1610 1216.85 -1613.1 1214.7 -1617.15 Z"
				/>
			</g>

			<g id="Layer2_6_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1346.6 -1563.85
Q 1345.85 -1563.15 1344.05 -1561.8 1342.3 -1560.5 1341.5 -1559.75
L 1336.8 -1555.2
Q 1332.7 -1551.25 1328.3 -1548.1 1324.95 -1545.65 1317.75 -1541.95 1310.45 -1538.15 1307.2 -1535.85 1302.05 -1532.2 1304.35 -1526.85 1306.6 -1521.5 1312.85 -1522.5 1318.7 -1523.45 1325.85 -1527.6 1330.5 -1530.3 1337.5 -1535.6 1343.65 -1540.25 1347.75 -1544.25 1350.75 -1547.05 1352.8 -1549.6 1353.8 -1550.8 1354.85 -1552.8 1356.2 -1555.4 1356.65 -1556.1 1359.65 -1560.7 1355.1 -1564.15 1350.5 -1567.6 1346.6 -1563.85 Z"
				/>
			</g>

			<g id="Layer2_7_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -525.45 -1589.7
Q -528.45 -1589 -529.95 -1586.35 -531.3 -1583.9 -530.9 -1580.9 -530.55 -1577.9 -528.65 -1576.05 -526.5 -1573.95 -523.35 -1574.25 -498.65 -1576.55 -466.7 -1581.3 -455.45 -1582.95 -410.3 -1590.15
L -299 -1608
Q -271.7 -1612.4 -235.2 -1619.05 -231.4 -1619.75 -229.35 -1623.65 -227.35 -1627.35 -228.35 -1631.1 -229.45 -1635.1 -233 -1636.9 -236.15 -1638.5 -240.35 -1637.9 -289.15 -1630.9 -351.75 -1620.65 -379.3 -1616.1 -462.95 -1601.95 -501.05 -1595.4 -525.45 -1589.7 Z"
				/>
			</g>

			<g id="Layer2_8_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -418.7 -2242.45
Q -425.3 -2241.9 -438.45 -2240.65
L -476.9 -2237.6
Q -510.95 -2234.8 -515.95 -2234.35 -538.1 -2232.35 -554.9 -2230.1 -558.5 -2229.6 -560.75 -2227.55 -563.25 -2225.25 -563.25 -2221.75 -563.25 -2218.4 -560.75 -2215.85 -558.25 -2213.25 -554.9 -2213.35 -537.9 -2213.75 -515.4 -2215.2 -513.65 -2215.35 -475.95 -2218.05
L -437.45 -2221
Q -423.75 -2221.85 -416.9 -2222.4 -404.85 -2223.25 -396.45 -2224.7 -392.6 -2225.35 -390.85 -2228.35 -389.2 -2231.1 -389.75 -2234.65 -390.3 -2238.2 -392.65 -2240.65 -395.25 -2243.3 -398.95 -2243.35 -407.1 -2243.45 -418.7 -2242.45 Z"
				/>
			</g>

			<g id="Layer2_9_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -622.85 -2087.25
Q -620.6 -2085 -617.3 -2085.3 -538.65 -2093.25 -498.95 -2097.95 -469.05 -2101.45 -380.55 -2112.2 -376.3 -2112.7 -373.6 -2115.2 -370.6 -2117.95 -370.6 -2122.15 -370.6 -2126 -373.55 -2129.15 -376.7 -2132.5 -380.55 -2132.05 -460.15 -2122.55 -499.9 -2117.6 -545.05 -2112 -619.5 -2101.45 -622.7 -2101 -624.3 -2098.25 -625.7 -2095.8 -625.3 -2092.55 -624.85 -2089.35 -622.85 -2087.25 Z"
				/>
			</g>

			<g id="Layer2_10_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -487.35 -2023
Q -488.7 -2020.65 -488.3 -2017.65 -487.9 -2014.6 -486 -2012.65 -483.85 -2010.5 -480.75 -2010.7 -449.35 -2012.7 -417.6 -2016.25
L -370.25 -2021.6
Q -364.8 -2022 -362 -2022.3 -357.05 -2022.8 -354 -2024 -350.35 -2025.35 -348.65 -2028.25 -347.05 -2030.9 -347.55 -2034 -348 -2037.05 -350.25 -2039.3 -352.7 -2041.65 -356.45 -2042.15 -359.6 -2042.55 -364.35 -2041.95 -367 -2041.6 -372.25 -2040.8
L -419.6 -2035.2
Q -449.15 -2031.7 -482.8 -2026.1 -485.85 -2025.55 -487.35 -2023 Z"
				/>
			</g>

			<g id="Layer2_11_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -466.8 -1892.2
Q -460.5 -1892.2 -457.4 -1892.5 -448.15 -1893.3 -438.15 -1894.85 -434.4 -1895.45 -432.6 -1898.6 -430.95 -1901.45 -431.45 -1905.15 -431.95 -1908.85 -434.3 -1911.25 -436.9 -1913.9 -440.7 -1913.6 -454.4 -1912.6 -476.1 -1908.05 -483.15 -1906.6 -494.25 -1905.55 -511.55 -1903.85 -512.55 -1903.75
L -584.4 -1895.3
Q -587.7 -1894.9 -589.85 -1892.9 -592.15 -1890.75 -592.15 -1887.5 -592.15 -1884.5 -589.85 -1882.05 -587.4 -1879.4 -584.4 -1879.75
L -511.55 -1887.9
Q -499.75 -1889.3 -493.85 -1889.95 -483.35 -1891.2 -476.1 -1891.9 -473 -1892.15 -466.8 -1892.2 Z"
				/>
			</g>

			<g id="Layer2_12_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -568.3 -1787.15
Q -571.2 -1786.4 -572.65 -1783.9 -573.95 -1781.65 -573.6 -1778.85 -573.25 -1776.05 -571.4 -1774.2 -569.4 -1772.2 -566.3 -1772.25 -552.65 -1772.65 -534.45 -1774.9 -513.3 -1777.85 -502.8 -1779.3 -475.5 -1783 -440.8 -1787.95
L -316 -1805.85
Q -312.3 -1806.4 -310.25 -1810.3 -308.3 -1814.05 -309.3 -1817.65 -310.4 -1821.6 -313.85 -1823.35 -316.95 -1824.95 -321.1 -1824.35
L -445 -1806.9
Q -475.5 -1802.55 -505.9 -1798.05 -516.3 -1796.5 -537.25 -1793.45 -555.2 -1790.6 -568.3 -1787.15 Z"
				/>
			</g>

			<g id="Layer2_13_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -547.55 -1685.15
Q -545.5 -1683.15 -542.45 -1683.3 -529.25 -1683.95 -512.1 -1686 -507.2 -1686.6 -481.9 -1690 -455.2 -1693.55 -421.25 -1698.35
L -231.5 -1725.55
Q -227.8 -1726.1 -225.75 -1730 -223.8 -1733.75 -224.8 -1737.35 -225.9 -1741.25 -229.35 -1743 -232.45 -1744.6 -236.6 -1744.05 -276.65 -1738.4 -356.75 -1727.1 -425.65 -1717.35 -476.8 -1709.8 -504.8 -1705.7 -510.75 -1704.75 -530 -1701.65 -544.45 -1698.2 -547.4 -1697.5 -548.85 -1695 -550.15 -1692.7 -549.75 -1689.85 -549.4 -1687 -547.55 -1685.15 Z"
				/>
			</g>

			<g id="Layer2_14_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 368.45 -2392.6
Q 367 -2390.35 367.9 -2387.35 375.85 -2361.1 390.85 -2313.55 407 -2262.25 413.9 -2239.75 435.85 -2167.75 457.6 -2092.45 500.65 -1943.3 538.2 -1795.9 548.15 -1756.9 559.1 -1712.35 559.55 -1710.55 569.15 -1669.95 575.15 -1644.4 579.75 -1627.7 580.75 -1624.05 583.7 -1622.6 586.35 -1621.3 589.5 -1622.15 592.6 -1623.05 594.4 -1625.6 596.35 -1628.45 595.6 -1632.1 592.45 -1647.95 587.05 -1669.8 583.95 -1682.3 577.6 -1707.3 569 -1742.3 558.75 -1782.3 539.05 -1859.25 519.5 -1930.85 478.95 -2079.5 433.85 -2225.95 415.7 -2284.95 408.15 -2308.7 393.5 -2354.95 381 -2390.95 380 -2393.85 377.4 -2395 375.05 -2396.05 372.45 -2395.35 369.8 -2394.65 368.45 -2392.6 Z"
				/>
			</g>

			<g id="Layer2_15_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1503.95 -1620
Q 1501.75 -1619.85 1500.1 -1618.2 1498.4 -1616.5 1498.25 -1614.3 1498.05 -1611.85 1499.9 -1609.8 1529.8 -1577.05 1533.25 -1573.15 1552.05 -1552.2 1565.45 -1535.5 1583.85 -1512.55 1595.65 -1496.35 1611.65 -1474.4 1622.9 -1455.1 1629.15 -1444.4 1631.6 -1435.65 1634.75 -1424.35 1632.25 -1414.6 1629.6 -1404.3 1621.7 -1399.1 1614.45 -1394.35 1603.3 -1394.25 1592.25 -1394.15 1578 -1395.55 1570.75 -1396.25 1552.85 -1398.5 1528.05 -1401.6 1502.55 -1406.8 1473.8 -1412.6 1446.75 -1420.55 1434.85 -1424.05 1418.95 -1429.55 1400.6 -1436 1391.4 -1439.25 1388.8 -1440.2 1386.1 -1438.6 1383.55 -1437.05 1382.8 -1434.4 1382 -1431.55 1383.5 -1429.1 1384.9 -1426.9 1387.65 -1425.75 1411.5 -1416.05 1439.85 -1408 1464.1 -1401.15 1493.45 -1395 1519.15 -1389.6 1547.25 -1385.85 1563.5 -1383.7 1573.95 -1382.65 1588.7 -1381.2 1600.75 -1380.95 1613.65 -1380.75 1623.2 -1384.75 1634.25 -1389.4 1640.2 -1399.4 1645.6 -1408.45 1646.4 -1419.8 1647.15 -1430.45 1643.8 -1441.1 1640.45 -1451.85 1632.9 -1464.35 1629.75 -1469.55 1618.6 -1485.8 1603.85 -1507.3 1587.55 -1528.45 1566.9 -1555.3 1549.8 -1575 1528.6 -1599.45 1508.4 -1618.3 1506.4 -1620.2 1503.95 -1620 Z"
				/>
			</g>

			<g id="Layer2_16_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -183.25 -1197.75
Q -231.85 -1224.7 -290.8 -1255.7 -338.35 -1280.75 -399.9 -1312.3 -427.2 -1326.35 -509.35 -1368.2 -522.95 -1375.15 -570.35 -1399.5 -608.05 -1418.95 -631.4 -1430.65 -634.15 -1432.05 -636.85 -1431.05 -639.3 -1430.15 -640.7 -1427.8 -642.1 -1425.45 -641.6 -1423.05 -641.05 -1420.35 -638.3 -1418.85 -593.45 -1394 -530.5 -1361.65 -457.95 -1324.7 -421.8 -1306.15 -357.05 -1272.95 -312.65 -1249.65 -253.5 -1218.6 -204.35 -1191.6 -182.15 -1179.4 -144.65 -1157.8 -101.3 -1132.85 -85.15 -1123.8 -82.1 -1122.1 -79.2 -1123.1 -76.55 -1124 -75 -1126.6 -73.45 -1129.2 -73.95 -1132 -74.5 -1135.05 -77.45 -1136.9 -99.9 -1151.1 -129.9 -1168.1 -141.6 -1174.75 -183.25 -1197.75 Z"
				/>
			</g>

			<g id="Layer2_17_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -621.65 -1456.9
Q -620.1 -1459.2 -620.95 -1462.25 -643 -1541.15 -663.15 -1631.3 -680.3 -1708.1 -698 -1802 -729.95 -1971.15 -730.35 -1973.25 -749.45 -2070.95 -767 -2143.6 -779.85 -2196.7 -791.6 -2237.7 -806 -2287.9 -821.65 -2330.15 -822.65 -2332.8 -825.1 -2333.9 -827.3 -2334.85 -829.7 -2334.25 -832.15 -2333.6 -833.35 -2331.7 -834.7 -2329.6 -833.8 -2326.8 -804.25 -2233.45 -785.8 -2159.75 -767.2 -2085.2 -748.25 -1989.8 -740.8 -1952.4 -715.7 -1818.5 -695.8 -1712.5 -681.8 -1647.4 -670.15 -1593.3 -660.05 -1552.5 -647.45 -1501.65 -634.15 -1458.6 -633.25 -1455.6 -630.65 -1454.4 -628.35 -1453.35 -625.7 -1454.05 -623.05 -1454.8 -621.65 -1456.9 Z"
				/>
			</g>

			<g id="Layer2_18_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1300.85 -1707.45
Q 1310.8 -1714 1321.55 -1716.7 1332.95 -1719.6 1344.15 -1717.9 1368.4 -1714.3 1387.05 -1694.6 1402.75 -1678.05 1412.75 -1652.25 1413.9 -1649.25 1416.7 -1648 1419.2 -1646.9 1421.95 -1647.6 1424.7 -1648.35 1426.05 -1650.5 1427.55 -1652.9 1426.55 -1656.05 1419.25 -1678.8 1405.7 -1696.45 1390.75 -1715.9 1370.9 -1725.35 1350 -1735.25 1327.95 -1732.85 1306 -1730.45 1287.65 -1716.3 1277.75 -1708.65 1270.35 -1699.55 1261.95 -1689.2 1258.35 -1678.75 1256.5 -1673.4 1261.65 -1671.6 1266.8 -1669.85 1269.9 -1673.9 1270.8 -1675.05 1276.85 -1683.55 1281 -1689.45 1284.2 -1692.95 1292.2 -1701.75 1300.85 -1707.45 Z"
				/>
			</g>

			<g id="Layer2_19_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1307.5 -1604.1
Q 1317.55 -1599.45 1329.85 -1595.45 1339.45 -1592.3 1352.9 -1588.85 1367.6 -1585.1 1377.4 -1584.05 1391.1 -1582.65 1402.2 -1585.45 1413.8 -1588.4 1423.6 -1595.8 1433.7 -1603.45 1439.1 -1613.8 1440.7 -1616.9 1439.75 -1619.8 1438.85 -1622.45 1436.3 -1623.95 1433.7 -1625.45 1430.95 -1624.9 1427.9 -1624.35 1426 -1621.45 1419.3 -1611.25 1409.8 -1605.4 1399.8 -1599.25 1388.05 -1598.75 1381.3 -1598.45 1373.2 -1599.6 1367.55 -1600.4 1358.6 -1602.4 1342.6 -1605.95 1331.7 -1609.2 1303.05 -1617.6 1283.65 -1633.75 1274.55 -1641.4 1264.3 -1652.45 1259 -1658.2 1246.5 -1672.7 1244.85 -1674.65 1242.7 -1674.55 1240.75 -1674.45 1239.2 -1672.85 1237.65 -1671.25 1237.4 -1669.15 1237.15 -1666.8 1238.7 -1664.85 1256.3 -1642.8 1269.6 -1630.35 1287.9 -1613.2 1307.5 -1604.1 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_0_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0987396240234375, 0, 0, 0.0959930419921875, 96.65,248.85) ">
			<use xlinkHref="#Layer2_1_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_2_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_3_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.1066436767578125, 0, 0, 0.0959930419921875, 84.4,249.45) ">
			<use xlinkHref="#Layer2_4_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_5_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_6_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_7_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_8_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_9_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_10_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_11_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_12_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_13_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.095947265625, 0.002471923828125, -0.002471923828125, 0.095947265625, 92.2,248.4) ">
			<use xlinkHref="#Layer2_14_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_15_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959625244140625, 0.0015716552734375, -0.0015716552734375, 0.0959625244140625, 95,248.6) ">
			<use xlinkHref="#Layer2_16_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0980224609375, 96.8,253.1) ">
			<use xlinkHref="#Layer2_17_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_18_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer2_19_MEMBER_20_FILL" />
		</g>
	</svg>
);
