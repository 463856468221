import React from "react";

export const skillsPage3 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="275px"
		height="480px"
		viewBox="0 0 275 480"
		id="frame-3"
	>
		<defs>
			<g id="Layer3_0_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 982.4 318.75
Q 979.7 316.95 975.05 314.55 968.1353515625 310.829296875 962.1 309.35 964.2755859375 307.90546875 966.1 305.85 970.05 301.35 970.85 295.7 971.3 292.45 970.3 289.85 969.2 286.9 966.45 285.6 964.05 284.45 961.25 284.6 958.55 284.7 956.2 286 953.95625 287.223828125 951.95 289.5 952.6671875 282.5716796875 951.95 275.65 951.3 269.75 949.2 266.5 947 263.25 943.35 261.45 939.65 259.65 935.55 259.7 931.45 259.75 927.8 261.6 924 263.55 921.15 267.2 918.45 270.65 917.15 274.9 915.5 280.35 916.3 286.35 917.05 292.25 920.05 297.2 920.4 297.8 921 297.5 921.6 297.25 921.5 296.55 920.3 290.4 920 287.4 919.5 282.25 920.35 278.15 921.1 274.5 923 271.35 925 268.05 927.85 266.05 930.7 264.1 933.9 263.6 937.2 263.1 940.4 264.2 943.85 265.35 945.75 267.9 947.8 270.5 948.35 275.2 949.75 285.65 947.1 296.95 946.75 298.4 948.15 299 949.55 299.65 950.35 298.3 953.3 293.2 955.55 291.05 959.3 287.45 963.55 288.5 968.05 289.55 967.35 295 966.75 299.4 963.95 302.95 960.6 307.1 955.65 308.45 954.15 308.85 954.4 310.3 954.65 311.75 956.1 311.9 963 312.7 970.95 316.7 974.5 318.45 977.85 320.45 979.8 321.55 980.6 322.1 982.15 323.15 982.95 324.25 984.85 326.9 982.05 329.95 980.05 332.15 976.7 333.7 973.85 335.05 969.5 336.4 964.55 337.8 962.1 338.55 955.85 340.4 952.7 341.35 947.05 343.05 943.3 344.3 941.7 344.85 942.15 346.5 942.55 348.2 944.25 347.75 948.2 346.7 953.7 345.05 956.85 344.1 963.1 342.15 965.85 341.35 971.45 339.7 976.25 338.2 979.45 336.5 983.3 334.5 985.5 331.6 988.25 327.95 987.1 324.3 986.2 321.4 982.4 318.75 Z"
				/>
			</g>

			<g id="Layer3_1_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 873.15 326.35
Q 873.45 326.4 873.65 326.15 874.45 325.2 876.35 324.75
L 881.45 323.4
Q 887.15 322.05 891.3 322.25 896 322.5 899.45 324.55 902.8 326.55 905.25 331.2 908.5 337.25 912.95 349.45 916.45 358.75 919.1 368.7 920.25 372.65 920.5 374.05 920.6 374.65 920.5 374.9 920.35 375.1 919.8 375.25 919 375.5 917.05 375.8
L 911.2 377
Q 909.15 377.35 908.1 377.6 906.3 377.95 905.15 378.6 904.3 379.05 904.6 379.9 904.9 380.75 905.8 380.85 907 381 908.7 380.65 909.65 380.45 911.55 380
L 917.85 378.75
Q 918.6 378.6 920.3 378.3 921.75 377.95 922.5 377.25 924 375.95 923.3 372.9 921.05 363.2 916.45 350.4 913.95 343.55 912.45 339.95 910.1 334.15 907.75 329.75 905.1 324.6 901.25 322.15 897.35 319.65 891.9 319.25 886.95 318.85 879.25 320.9 875.75 321.75 874.45 322.35 871.3 323.85 872.65 326 872.8 326.3 873.15 326.35 Z"
				/>
			</g>

			<g id="Layer3_2_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 787.45 385.15
Q 782.1 389 773.05 394.3 761.35 401.15 758.4 403 750.85 407.75 745.85 412.65 739.8 418.6 736.65 425.4 733.3 432.7 733.35 439.85 733.4 447.75 737.65 453.95 745.15 464.9 763.85 472.2 777 477.3 796.05 479.05 803.6 479.7 812.9 479.9 819 480 829.8 479.9 835.9 479.85 848.1 479.65 858.95 479.45 866.4 479.5 886.55 479.6 902.3 481.1 919.95 482.8 937.65 487.45
L 946.05 489.65
Q 947.1 489.9 947.55 488.85 948.05 487.75 947.05 487.25 940.55 484.15 930.25 481.55 922.75 479.6 913.3 478.2 897.4 475.9 877.95 475.4 866.85 475.1 842.95 475.55 820.05 475.9 807.9 475.5 789.3 474.9 775.85 471.5 758.35 467.15 747.45 458.4 740.55 452.95 738.4 446.7 736.1 440.25 737.8 433.05 739.45 425.9 744.2 419.45 748.5 413.65 754.95 408.85 759.95 405.15 768.9 399.9 779.55 393.7 783.1 391.35 794.75 383.7 810.95 369.55
L 817.5 363.75
Q 818.5 362.95 817.55 362 816.65 361.1 815.65 361.9 806.5 370 801.85 373.9 794.1 380.4 787.45 385.15 Z"
				/>
			</g>

			<g id="Layer3_3_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 958.35 260.5
Q 958.35 260.9 958.7 260.9 959.1 260.9 959.1 260.5 959.1 260.1 958.7 260.1 958.35 260.1 958.35 260.5 Z"
				/>
			</g>

			<g id="Layer3_4_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 978.75 280.15
Q 976.95 279.25 974.1 278.1 969.65 276.3 969.35 276.15 962.5 273.3 961.1 269.15 960.35 266.8 960.8 264.6 961.35 262.15 963.35 260.95 963.85 260.7 963.6 260.1 963.35 259.55 962.85 259.75 958.95 261.25 958.75 265.95 958.5 270.3 961.2 273.45 962.85 275.4 965.5 276.9 967.3 278 970.45 279.3 977.05 282.2 979.6 283.6 988.95 288.85 997.05 296.6 1005.05 304.3 1010.8 313.5 1014.25 319 1016.25 324.1 1017.05 326.2 1017.95 329.4
L 1019.4 334.75
Q 1019.7 335.65 1020.65 335.55 1021.55 335.45 1021.55 334.5 1021.6 330.75 1019.55 325.25 1018.15 321.1 1015.55 316.2 1010.85 307.5 1003.25 299.1 995.6 290.85 987.2 285.15 983 282.3 978.75 280.15 Z"
				/>
			</g>

			<g id="Layer3_5_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 995.7 172.95
Q 980.6 169.1 972.1 165.75 961.85 161.65 951.85 155.15 946.35 151.6 943.55 149.1 939.1 145.2 937.35 141 935.55 136.8 937.25 132.6 938.85 128.65 942.85 126.15 947.15 123.45 953.3 123.25 957.6 123.05 964.3 124.2 984.85 127.7 1003.25 137.4 1012.3 142.15 1020.7 148.35 1025 151.55 1028.65 154.85 1029.95 156 1032.6 158.2 1034.95 160.15 1036.4 161.65
L 1040.05 165.4
Q 1040.75 166.1 1041.4 165.45 1042.1 164.75 1041.45 164 1033.6 154.65 1026.1 148.6 1017.45 141.55 1008.2 136.2 988.9 125.1 967.55 120.75 960.25 119.3 955.6 119.15 948.85 118.9 943.8 121 938.85 123.05 935.7 127 932.25 131.25 932.2 136.35 932.1 145.35 942.65 153.75 951.5 160.75 963.1 166.15 973.65 171.1 987 174.5 1000.2 178 1012.65 180.6 1016.35 181.4 1019.05 181.5 1019.8 181.55 1020 180.75 1020.25 179.95 1019.55 179.65 1017.2 178.55 1013.75 177.55 1009.75 176.55 1007.75 176
L 995.7 172.95 Z"
				/>
			</g>

			<g id="Layer3_6_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1060.55 180.55
Q 1060 180.8 1060.2 181.3 1061.7 185.05 1065 188.1 1068 190.85 1072 192.7 1076.3 194.7 1081.45 195.5 1084.1 195.9 1086.25 196.05
L 1089.35 196.1
Q 1089.37109375 196.225 1089.4 196.35 1089.7 197.2 1090.45 197.35 1091.55 197.65 1092.45 196.9 1093.3 196.1 1093.1 194.95 1092.65 192.8 1089.4 193.15 1082.7 193.75 1077.65 192.7 1072.35 191.65 1068.15 188.6 1063.9 185.5 1061.35 180.8 1061.15 180.3 1060.55 180.55 Z"
				/>
			</g>

			<g id="Layer3_7_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1084.9 170.85
Q 1082.8 172.25 1082 173.2 1081.8064453125 173.3935546875 1081.75 173.6 1081.4453125 173.74453125 1081.15 173.85
L 1081.1 173.9 1079.85 174.3
Q 1079.4 174.4 1079.15 174.85 1078.9 175.25 1078.95 175.75 1079.1 176.7 1080.2 176.85
L 1086.95 177.7
Q 1087.3 177.75 1087.7 177.5 1088.05 177.25 1088.15 176.9 1088.5 176.15 1087.9 175.45 1087.710546875 175.24140625 1087.5 175.05 1088.2447265625 174.471484375 1088.1 173.7 1088 172.75 1087.15 172.6 1086.9630859375 172.54765625 1086.75 172.5 1087.1404296875 171.7861328125 1086.5 171.1 1085.75 170.35 1084.9 170.85 Z"
				/>
			</g>

			<g id="Layer3_8_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1128.95 242.2
Q 1128.95 242.1 1128.85 242.05
L 1128.5 241.8
Q 1134.31171875 241.81875 1138.2 243.3 1143.25 245.15 1147.15 249.3 1151.55 253.95 1152.3 259 1152.8 262.25 1149.7 264.1 1147.2 265.6 1143.55 266.65 1139.95 267.7 1136.2 267.85 1134.6 268 1132.3 267.8 1131.05 267.65 1130.4 267.6 1129.35 267.55 1128.55 267.65 1127.85 267.75 1127.75 268.55 1127.65 269.3 1128.25 269.65 1129.35 270.25 1131.85 270.45 1134 270.6 1135.75 270.55 1139.7 270.5 1143.45 269.5 1147.45 268.45 1150.55 266.75 1154.2 264.85 1154.95 261.8 1155.65 258.95 1154.25 255.25 1153.15 252.35 1150.95 249.45 1146.95 244.2 1141.2 241.6 1133.65 238.05 1127.25 239.7 1126.8 239.8 1126.55 240.35 1126.35 240.85 1126.6 241.3 1127.35 242.4 1128.8 242.3 1128.9 242.3 1128.95 242.2 Z"
				/>
			</g>

			<g id="Layer3_9_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1129.2 253.6
Q 1127.4 253.7 1125.85 254.1 1124.2 254.6 1122.85 255.35 1121.1 256.45 1120.65 257.75 1120.55 258.15 1120.95 258.15
L 1121.35 258.1 1121.45 258.05 1122.15 257.9 1123.55 257.45
Q 1125.3 256.85 1126.35 256.7 1127.6 256.4 1129 256.5
L 1130.3 256.6
Q 1131.8 256.85 1132.2 256.7 1132.95 256.5 1133.2 255.7 1133.4 254.85 1132.8 254.35 1131.85 253.6 1129.2 253.6 Z"
				/>
			</g>

			<g id="Layer3_10_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1138.35 92.8
Q 1136.75 90.6 1134.6 89.45 1132.25 88.15 1129.7 88.4 1127.05 88.7 1124.65 90.35 1122.5 91.85 1120.9 94.25 1118.25 98.2 1117.55 104.4 1117.2 107.1 1117.15 115.2 1117.15 118.7 1117.15 130.9 1117.17265625 135.31328125 1117.15 138.95 1111.73515625 125.2154296875 1105.65 110.2 1101.85 101 1098.75 94.8 1094.7 86.6 1090.35 80.25 1084.6 71.8 1077.95 65.7 1077.15 64.95 1076.25 65.25 1075.35 65.55 1074.95 66.5 1068.55 81.9 1072.55 100.1 1076.15 116.45 1086.95 131.05 1092.95 139.1 1099.6 145.65 1100.1 146.15 1100.6 145.65 1101.05 145.15 1100.65 144.6 1094.6 137.15 1090.7 131.5 1085.55 124.15 1082.2 117.4 1074.35 101.45 1074.7 85.55 1074.9109375 77.450390625 1077.4 70.1 1087.300390625 79.8232421875 1095.3 95.05 1100.15 104.35 1108.75 125.9
L 1117.15 146.9
Q 1117.6 148 1118.85 147.95 1120.05 147.85 1120.05 146.55 1120.05 146.5 1120.35 121.15 1120.35 113.3 1120.45 109.4 1120.65 103.6 1121.7 100.05 1123.3 94.95 1126.8 92.75 1128.95 91.4 1131.15 91.65 1133.4 91.95 1135.1 93.8 1138.15 97 1137.75 103.45 1137.6 106 1137 109.65 1136.65 111.7 1135.95 115.8
L 1131.4 144.15
Q 1131.3 144.95 1131.8 145.55 1132.35 146.2 1133.15 145.9 1143.65 142.45 1154.9 142.9 1166.05 143.35 1176.35 147.55 1180.444140625 149.1876953125 1185.25 152.05 1177.107421875 156.2775390625 1168.2 158.35 1157.75 160.8 1147.65 160 1146.65 159.95 1146.65 161 1146.65 162.1 1147.65 162.1 1170.25 162.55 1188.9 153.4 1189.65 153.05 1189.6 152.2 1189.55 151.4 1188.9 150.9 1179.75 144.45 1168.05 141.65 1156.6 139 1145.2 140.35 1139.9099609375 140.9748046875 1134.55 142.5
L 1138.75 117.35
Q 1139.55 112.9 1139.95 110.65 1140.6 106.75 1140.8 103.95 1141.45 97 1138.35 92.8 Z"
				/>
			</g>

			<g id="Layer3_11_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1159.4 174.2
Q 1159.05 174.95 1159.55 175.1 1160.25 175.25 1161.75 174.75 1162.8 174.4 1165.05 174.6 1167.2 174.75 1172.9 176.1 1182.15 178.4 1188.85 181.35 1197.6 185.25 1200.6 190.2 1202.9 194 1201.05 196.6 1199.75 198.4 1195.9 199.25 1194.15 199.65 1191.7 199.9 1188.85 200.2 1187.4 200.35 1184.05 200.7 1182.4 200.85 1179.35 201.2 1177.35 201.6 1176.35 201.85 1176.5 202.95 1176.65 204.05 1177.7 204.05 1179.85 204 1183.05 203.6 1184.85 203.4 1188.4 202.9 1189.85 202.75 1192.7 202.5 1195.2 202.2 1196.95 201.8 1201.15 200.85 1203.05 198.5 1204.3 197 1204.55 194.95 1204.8 193.05 1204.1 191.1 1201.8 184.55 1191.75 179.65 1183.25 175.6 1173.6 173.5 1170.95 172.9 1168.1 172.45 1164.7 172 1163.65 172.05 1161.1 172.25 1160 173.25 1159.7 173.55 1159.4 174.2 Z"
				/>
			</g>

			<g id="Layer3_12_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1179.55 218.9
Q 1179.35 219.55 1179.95 219.8 1181.95 220.75 1186.45 221.85 1190.2 222.95 1192.95 224.2 1199.35 227.05 1203.5 231.55 1207.95 236.4 1208.8 242.2 1209.15 244.35 1208.15 245 1207.25 245.55 1205.2 245.45 1202.85 245.4 1199.8 244.05 1195.85 242 1193.8 240.95 1190.15 239.15 1187.45 239 1186.55 238.95 1186.45 239.85 1186.35 240.8 1187.2 241.1 1191.1 242.5 1193.45 243.7 1198.7 246.35 1199.5 246.65 1202.8 248 1205.75 248.05 1209.85 248.15 1211.05 245.4 1211.65 244.1 1211.4 242.1 1211.25 240.85 1210.6 238.75 1209.5 235.45 1207.6 232.7 1203.75 227.15 1197.15 223.5 1193.2 221.4 1189.2 220.15 1185.95 219.15 1184.8 218.9 1182.25 218.25 1180.35 218.3 1179.75 218.3 1179.55 218.9 Z"
				/>
			</g>

			<g id="Layer3_13_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1001.15 204.6
Q 1000.65 203.9 1000 204.4 998.3 205.65 993.15 210.1
L 988.05 214.4
Q 987.45 214.9 987.25 215.25 986.95 215.7 987.05 216.35 987.15 216.8 987.7 217 988.25 217.15 988.8 216.9 989.05 216.75 989.65 216.2
L 994.6 211.75
Q 999.75 207.2 1001.1 205.8 1001.65 205.25 1001.15 204.6 Z"
				/>
			</g>

			<g id="Layer3_14_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1009.85 207.55
Q 1009.15 207.25 1008.75 207.9 1007.55 209.65 1006.45 211.55 1005.8 212.8 1004.45 215.3 1004.2 215.8 1004.3 216.25 1004.45 216.75 1004.9 217 1005.35 217.25 1005.8 217.1 1006.3 217 1006.55 216.55 1007 215.7 1008.55 212.65 1009.35 211.05 1010.35 208.6 1010.6 207.85 1009.85 207.55 Z"
				/>
			</g>

			<g id="Layer3_15_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1018.05 213.3
L 1018 213.3
Q 1018.113671875 213.282421875 1018.2 213.25
L 1017.9 213.55 1018 213.3
Q 1017.7244140625 213.51875 1017.45 213.95 1017.15 214.65 1017 214.95 1016.7 215.6 1016.25 216.95 1015.2 220.05 1014.95 220.9 1014.8 221.35 1015.05 221.75 1015.3 222.2 1015.7 222.3 1016.75 222.6 1017.1 221.5 1017.75 219.65 1018.3 217.5 1018.7 216.2 1018.8 215.55
L 1019 213.9 1018.9 213.5
Q 1018.9 213.15 1018.6 213.1 1018.3 213.05 1018.05 213.3 Z"
				/>
			</g>

			<g id="Layer3_16_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1029.1 215.75
Q 1028.75 215.75 1028.65 216.05 1028.2 217.9 1028.1 221.2
L 1028 223.7
Q 1027.9 225.95 1027.9 226.4 1028.05 227.55 1029.1 227.55 1030.15 227.55 1030.25 226.4 1030.3 225.8 1030.2 223.8
L 1030.1 221.2
Q 1029.9 217.65 1029.5 216.05 1029.45 215.75 1029.1 215.75 Z"
				/>
			</g>

			<g id="Layer3_17_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 982.65 238.3
Q 982.1 237.75 981.45 238.25 978.85 240.4 976.65 241.55 975.35 242.25 974.15 242.7 971.95 243.5 971.25 243.8 970.75 244.1 970.9 244.7 971.1 245.35 971.65 245.3 972.9 245.3 974.75 244.6 976.5 243.85 977.65 243.2 980.75 241.55 982.65 239.45 983.2 238.85 982.65 238.3 Z"
				/>
			</g>

			<g id="Layer3_18_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 994.85 247.15
Q 994.6 246.2 993.65 246.4 990.5 247.15 987.9 248.25 986.75 248.65 985.1 249.5 983.05 250.55 982.45 251.25 982.05 251.8 982.55 252.35 983 252.85 983.6 252.75 983.8 252.7 985 252.05
L 986.3 251.35
Q 987.45 250.7 988.8 250.15 991.45 249.05 994.2 248.35 995.15 248.15 994.85 247.15 Z"
				/>
			</g>

			<g id="Layer3_19_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1003.4 256.1
Q 1003.55 254.5 1003.15 253.1 1003 252.4 1002.25 252.5 1001.5 252.6 1001.5 253.3
L 1001.4 255.7 1001 257.35
Q 1000.8 257.95 1000.8 258.25 1000.9 259.25 1001.8 259.25 1002.65 259.25 1003.15 257.7
L 1003.4 256.1 Z"
				/>
			</g>

			<g id="Layer3_20_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1000.4 364.55
Q 1000.65 365.5 1001.6 365.3 1011.3 363.05 1026.45 358.4 1046.2 352.35 1051.1 351 1064.75 347.15 1076.05 345.35 1089.6 343.1 1101.6 343.4 1114.55 343.65 1126.35 347 1137.15 350.1 1148.35 356.1 1160.95 362.75 1168.3 368.5 1178.7 376.6 1180.55 386.35 1181.5 391.5 1179.5 396.2 1177.5 400.8 1173.2 403.9 1168.9 407 1162.85 408.4 1158.25 409.5 1151.6 409.75 1147.45 409.85 1145.4 410.1 1144 410.25 1144 411.55 1144 412.85 1145.4 412.95 1150.65 413.5 1158.3 412.6 1165.1 411.65 1169.95 409.5 1175 407.25 1178.55 403.5 1182.4 399.4 1183.55 394.45 1184.85 389 1183.1 383.15 1181.5 377.85 1177.75 373.15 1174.4 368.9 1169.05 364.9 1165.45 362.2 1159 358.25 1134.55 343.3 1111.95 340.55 1089.1 337.85 1059 345.25 1048.15 347.95 1033.1 352.8 1016.05 358.5 1007.45 361.25
L 1001.05 363.35
Q 1000.15 363.65 1000.4 364.55 Z"
				/>
			</g>

			<g id="Layer3_21_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1134.5 433.45
L 1134.45 433.5
Q 1135.1 435.85 1136.5 438.55 1137.2 439.9 1139.25 443.3 1142.5 448.6 1145.85 453.3 1152.8 463.15 1160.05 471.2 1167.7 479.8 1174.35 485.35 1180.75 490.65 1190.9 497.05 1212 510.3 1227.85 524.7 1236.05 532.1 1242.7 539.7 1248.2 546.1 1254.85 555.95 1262.8 567.75 1266.4 574.35 1272.15 585 1274.25 594.55 1274.9068359375 597.505859375 1275.35 599.25 1275.34609375 599.4681640625 1275.45 599.75
L 1275.55 599.95
Q 1275.85 600.75 1276.7 600.65 1277.55 600.55 1277.6 599.65 1277.7 595.65 1275.65 588.9 1274.4 584.6 1271.85 579 1268.25 571.1 1260.95 560.1 1253.5 548.75 1248.85 542.95 1243.05 535.6 1234.85 527.65 1219.7 513.05 1199.05 499.55 1197.35 498.45 1189.65 493.65 1183.9 490.1 1180.4 487.6 1172.55 481.9 1165.6 474.8 1149.35 458 1137.55 437.75 1136.4 435.75 1134.5 433.45 Z"
				/>
			</g>

			<g id="Layer3_22_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1133.45 709.75
Q 1133.2 710.6 1133.5 711.4 1133.8 712.2 1134.6 712.65 1136 713.4 1137.5 712.4 1138.781640625 711.5455078125 1138.2 710.3 1139.2263671875 709.996875 1140.85 709.6 1144.15 708.6 1147.6 706.95 1152 704.85 1160.2 699.55 1168.5 694.25 1172.95 692.15 1178.95 689.3 1188.45 686.7 1189.85 686.3 1196.45 684.5 1201.35 683.2 1204.4 682.25 1211.5 680.05 1220.6 676.45 1227 673.9 1236.3 669.8 1245.4 665.85 1249.45 663.8 1256.7 660.1 1261.2 656 1266.85 650.8 1268.9 644.45 1269.05 643.9 1268.5 643.75 1267.95 643.55 1267.75 644.1 1265.6 650.4 1259.6 655.35 1255.05 659.15 1247.55 662.7 1243.7 664.55 1232.8 668.95 1222.8 673.1 1215.45 675.65 1206.15 679 1197.85 681.25 1192.2 682.7 1189.35 683.45 1184.3 684.75 1180.9 685.85 1172.7 688.45 1165.6 692.55 1163.3 693.9 1158.8 696.7 1154.3 699.55 1152 700.85 1147.45 703.5 1144.3 704.8 1142.8 705.45 1140.35 706.25 1137.6 706.85 1136.3 707.3 1133.9 708.05 1133.45 709.75 Z"
				/>
			</g>

			<g id="Layer3_23_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1083.6 675.85
Q 1081.55 680 1080.95 683.75 1079.85 691.05 1084 698.05 1088.3 705.3 1097.2 710.1 1103.45 713.5 1114.15 716.55 1115.1 716.85 1115.4 715.85 1115.65 714.9 1114.7 714.55 1103.45 710.45 1100 708.75 1092 704.75 1087.8 699 1082.9 692.3 1083.45 685.4 1083.75 681.75 1085.7 677.7 1087.15 674.75 1089.9 670.8 1098.25 658.75 1111.2 646.5 1120 638.2 1135.9 625.45
L 1151.75 612.8
Q 1154.45 610.65 1159.95 606.4 1164.8 602.6 1167.9 599.8 1168.9 598.95 1168 597.75 1167.15 596.6 1166.05 597.35 1162.9 599.6 1158.5 603.3 1153.6 607.4 1151.1 609.4
L 1136.25 621.5
Q 1119.5 635.1 1110.4 643.55 1096.8 656.2 1088 668.7 1085.15 672.85 1083.6 675.85 Z"
				/>
			</g>

			<g id="Layer3_24_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1038.4 709
Q 1034.65 717.5 1031 727.85 1026.8 739.8 1025.3 746.5 1023 756.95 1025.7 763.75 1026.7 766.25 1027.8 767.5 1029.35 769.3 1031.5 769.35 1034.3 769.45 1039.4 766.9 1044.63671875 764.1388671875 1048.25 759.7
L 1047.65 765.5
Q 1047.45 767.45 1047 771.3 1046.65 774.7 1046.65 777.1 1046.6 782 1048.35 785.8 1050.25 790.05 1054.15 792.4 1057.5 794.4 1061.5 794.8 1065.55 795.2 1069.1 793.8 1072.8 792.35 1075.3 789.15 1077.75 786 1078.4 782 1079.06328125 778.1447265625 1078.15 774.45 1079.217578125 775.2923828125 1080.35 776.2 1086.3 780.95 1090.65 784.15 1095.95 788.1 1100.4 790 1106.15 792.5 1111.45 792.15 1118.1 791.65 1120.3 787.55 1122.4 783.55 1119.6 777.45 1118.55 775.2 1116.65 772.5 1115.75 771.2 1113.2 767.85 1110 763.55 1105.65 758.8 1101.15 753.9 1095.85 749.4 1094.35 748.2 1093.85 747.9 1092.65 747.2 1091.45 747.3 1090.3 747.45 1089.6 748.2 1088.8 749 1089.25 750.1 1089.7 751.2 1090.8 750.9 1091.475 750.7466796875 1091.65 750.2 1091.8130859375 750.292578125 1092.1 750.35 1092.5 750.5 1093.7 751.6
L 1097.55 755.15
Q 1101.65 759.2 1104.5 762.4 1108 766.3 1110.9 770.2 1114.85 775.5 1116.3 778.25 1118.95 783.45 1117.2 786.15 1115.4 788.9 1109.6 788.7 1105.2 788.55 1100.35 786.1 1096.8 784.3 1092.15 780.8 1088.1 777.8 1082.35 773.25 1076.16640625 768.22578125 1072.9 765.65
L 1072.7 765.5
Q 1072.1 765 1071.45 765 1070.7 764.95 1070.15 765.5 1069.65 766 1069.65 766.8 1069.6 767.6 1070.15 768.05
L 1070.3 768.2
Q 1075.4431640625 772.4259765625 1075.05 779.75 1074.7 787.35 1068.4 790.25 1065.65 791.5 1062.35 791.3 1059.2 791.1 1056.45 789.6 1053.55 788 1052.05 785.35 1050.25 782.25 1050.35 776.65 1050.4 774.35 1051.1 765.5
L 1052.05 753.25
Q 1052.15 752.1 1050.9 751.75 1049.65 751.4 1049.15 752.5 1046 759.2 1039.6 763.1 1036.35 765.1 1033.05 765.95 1032 766.2 1031.4 766.1 1030.65 765.95 1030.15 765.15 1029.1 763.4 1028.5 761.5 1026.7 755.7 1028.65 745.9 1029.85 739.85 1033.1 728.95 1035.95 719.3 1039.55 709.5 1039.75 709 1039.2 708.75 1038.65 708.5 1038.4 709 Z"
				/>
			</g>

			<g id="Layer3_25_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 971.25 453.3
Q 968.95 469.75 969.1 483.6 969.25 499.75 972.65 513.7 976.9 531.05 985.15 543.55 986 544.85 987.35 544.05 988.7 543.25 987.9 541.95 980.4 529.7 976.65 516.4 973.05 503.7 972.25 488.7 971.55 474.5 973.55 457.6 974.95 445.3 978.55 426.85 980.3 417.65 982 412.75 984.35 406 989.15 400.35 989.6 399.85 989.15 399.35 988.65 398.9 988.1 399.3 983.6 402.85 980.7 409.7 978.85 414.15 977 422.1 973.45 438.05 971.25 453.3 Z"
				/>
			</g>

			<g id="Layer3_26_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 980.3 724.8
Q 980.25 724.75 980.15 724.8
L 979.7 725.25 979.7 725.35 979.85 725.35 980.3 724.9 980.3 724.8 Z"
				/>
			</g>

			<g id="Layer3_27_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 964.25 678.3
Q 965.3 675.75 967.95 673.1 969.5 671.45 973.2 668.25 976.75 665.2 978.4 663.35 983.7 657.55 986.6 649.45 989.65 641 987.7 633.3 986.85 629.85 984.5 625.85 983.9 624.8 980.1 619 976.95 614.2 975.85 610.75 974.3 606.1 974.55 600.55 974.8 595.75 976.5 589.95 977.35 587 980 579.75 980.2 579.15 979.6 579 979 578.85 978.75 579.4 974.7 590.2 973.65 597.15 972.1 607.4 975.45 615.2 976.6 617.8 979.4 622.45 982.3 627.25 983.4 629.65 986.95 637.35 984.7 645.9 982.55 654.2 976.8 660.8 975 662.9 971.45 665.8 967.25 669.3 965.95 670.6 963.15 673.3 961.8 675.9 960.55 678.25 959.7 682.7 958.25 690.95 959.75 698.15 960.5 701.85 962.75 705.7 964.25 708.35 967.35 712.45 973.85 720.9 979.15 725.8 980.45 727.05 981.75 725.75 983.05 724.45 981.8 723.15 974.7 715.75 971.5 711.8 968.45 708.05 967.15 706.2 964.85 702.85 963.85 699.95 961.65 693.4 962.75 684.95 963.2 681.1 964.25 678.3 Z"
				/>
			</g>

			<g id="Layer3_28_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 976.2 764.3
Q 975.7 764.45 975.75 765 976.35 768.7 978.7 777.15
L 1001.75 862.35
Q 1007.35 883.15 1008.1 886.1 1011.65 899.65 1014.05 910 1016.9 922.75 1018.95 934.1 1020.2 941.35 1022.55 955.85 1024.7 968.4 1027.1 977.25 1030.25 988.7 1034.75 997.65 1035.75 999.6 1037.35 1002.4 1038.05 1003.6 1039.3 1002.85 1040.55 1002.15 1039.85 1000.9 1034.75 992.15 1031.2 981 1028.3 972 1026 959.9 1025.4 956.7 1022.1 937.3 1019.75 923.6 1017.8 914.8 1013.6 895.9 1005.6 867.15
L 992.25 818.95
Q 987.85 802.85 985.65 794.75 981.7 780.4 978.85 770.65 977.75 766.95 976.9 764.7 976.7 764.2 976.2 764.3 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_0_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_1_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_2_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_3_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_4_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_5_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_6_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_7_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_8_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_9_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_10_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_11_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_12_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_13_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_14_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_15_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_16_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_17_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_18_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_19_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_20_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_21_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_22_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_23_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_24_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_25_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_26_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_27_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer3_28_MEMBER_0_FILL" />
		</g>
	</svg>
);
