import React from "react";

export const skillsPage1 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="275px"
		height="480px"
		viewBox="0 0 275 480"
		id="frame-1"
	>
		<defs>
			<g id="Layer1_0_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 890.45 320.3
Q 884.75 319.35 879.95 321.05 877.5 321.85 875.5 323.5 874.65 324.2 873.85 325.25 873.15 326.15 873 327.45 872.95 327.7 873.2 327.85 873.45 328.05 873.65 327.95 874.6 327.65 875.15 327.2 875.7 326.7 876.8 326 878.4 324.9 880.3 324.2 884.05 322.85 888.5 323.35 896.8 324.15 903.75 332.45 906.8 336.05 909.7 341.2 911.5 344.45 914.45 350.7 917.15 356.4 919.2 361.65 919.85 363.2 921.4 367.4 922.15 369.55 922.25 369.85 922.6 371.3 922.25 372.35 921.6 374.3 919.2 375.5 917.3 376.4 914.85 376.7 910.85 377 908.85 377.35 905.4 377.9 903.35 379.55 902.55 380.25 903.15 381.3 903.8 382.35 904.75 381.95 907.9 380.65 910.2 380.4 912.15 380.15 916.1 379.8 921.75 378.9 924.35 375.3 925.95 373.1 925.5 370.05 925.3 368.4 923.9 364.75 921.65 358.55 918.95 352.45 912.9 338.85 908.3 332.65 900.5 322 890.45 320.3 Z"
				/>
			</g>

			<g id="Layer1_1_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 809.95 361.6
Q 809.5 361.25 809.1 361.65 806.95 363.85 803.2 368 799.15 372.45 797.35 374.35 791 380.95 784.9 386.4 774.2 396.05 757.65 407.6 748.5 414 744.35 418.15 739.15 423.35 736 430.6 732.9 437.9 733.05 445.35 733.2 453.15 736.95 459.75 744.6 473.35 762.8 479 774.85 482.75 795.75 483.6 810.05 484.15 831.75 483.25 865.7 481.85 867.7 481.8 888 481.35 903.15 482.85 911.85 483.7 920.4 485.4 926.1 486.55 937.6 490 938.8 490.35 940.35 490.5
L 941.7 490.65 942.1 490.65
Q 941.55 490.6 941.9 490.6 944.1 490.9 944.6 489.05 944.85 488.25 944.4 487.4 943.9 486.45 943.05 486.35 941.2 486.1 938.85 485.3 932.15 483.25 930.55 482.8 922.6 480.6 913.45 479.25 897.8 476.95 878.55 476.95 866.4 476.95 843.35 478.1 819.5 479.35 808.2 479.4 797.95 479.45 790.8 479.1 781.3 478.6 773.45 477.3 754.85 474.2 745.05 464.2 739.7 458.85 737.65 452.3 735.55 445.5 737.15 437.8 740.1 423.65 754.1 412.8 756.6 410.9 761 407.85 766.05 404.35 767.95 402.95 774.5 398.15 781.05 392.6 794.25 381.4 805.05 368.9 808.15 365.3 810.1 362.45 810.4 361.95 809.95 361.6 Z"
				/>
			</g>

			<g id="Layer1_2_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1134.6 432.4
Q 1134.05 432.75 1134.3 433.3 1142.3 450.7 1157.95 467.75 1169.45 480.3 1189.5 496.65 1193.75 500.1 1206.4 510.15 1216.95 518.6 1223.2 523.75 1242.5 539.9 1253.9 553.3 1260.85 561.45 1266.1 570.1 1269.3 575.5 1271.2 579.45 1274.8 587.1 1274.9 592.4 1274.95 594.7 1274.15 599 1273.9 600.5 1275.2 601.05 1276.5 601.6 1277.35 600.35 1280.65 595.4 1278.5 586.7 1277.2 581.4 1273.45 574.3 1268.95 565.65 1261.9 556.3 1250.25 541.25 1231.8 525.7 1224.85 519.8 1214.6 511.95 1202.9 503.1 1197.05 498.6 1176.2 482.75 1163.85 470.8 1147.05 454.4 1137.9 437.4
L 1135.45 432.65
Q 1135.2 432.1 1134.6 432.4 Z"
				/>
			</g>

			<g id="Layer1_3_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1130.15 712.1
Q 1130.45 713.1 1131.4 712.75
L 1135.35 711.2
Q 1136.45 710.75 1139.9 709.9 1141.6 709.5 1143.9 708.6 1145.25 708.1 1147.85 707.05
L 1164.35 700.65
Q 1175.15 696.45 1196.75 688.1 1201.8 686.1 1211.95 682.15 1220.9 678.6 1227.1 676 1234.45 672.95 1239.35 670.5 1245.9 667.25 1251.05 663.85 1257.4 659.6 1262.25 654.55 1264.75 652.05 1266.95 648.9 1267.9 647.6 1268.95 645.8 1269.75 644.4 1269.75 644.35 1269.8 644.2 1269.95 642.7 1270 642.5 1269.8 642.35 1269.6 642.25 1269.4 642.35 1268 643.4 1267.4 644.25 1267 644.8 1265.25 646.9 1262.75 649.8 1260.85 651.6 1257 655.3 1251 659.35 1242.3 665.1 1227.75 671.2 1222 673.6 1213.2 677.05 1199.5 682.4 1198.6 682.75 1195.55 683.95 1166.75 695.5
L 1148.75 702.75
Q 1140.85 706 1140 706.35 1137.05 708.15 1135.45 708.8
L 1130.8 710.65
Q 1129.85 711.05 1130.15 712.1 Z"
				/>
			</g>

			<g id="Layer1_4_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1002.1 362.45
Q 1002.15 362.9 1002.55 362.85 1009.05 362 1015.8 359.9 1019.2 358.85 1028.9 355.15 1042.15 350.2 1052.05 347.8 1074.1 342.55 1096.5 344.2 1119.4 345.85 1138.6 354.8 1148.75 359.45 1157.45 366 1168.2 374.2 1173.5 381.8 1176.9 386.7 1177.85 391.55 1178.95 397.15 1176.45 401.85 1173.95 406.5 1169.7 407.55 1167.95 408 1164.45 408 1160.85 408 1159.15 408.4 1156.45 409.1 1154.65 411.1 1153.5 412.45 1154.75 413.7 1156 415 1157.3 413.75 1158.85 412.3 1162 412.1 1163.75 411.95 1167.15 412.05 1173.15 411.75 1176.95 408.05 1180.95 404.15 1182 398.3 1183 392.75 1181.05 387.3 1179.15 381.95 1174.85 376.7 1171.6 372.65 1166.2 367.85 1157.6 360.2 1147.7 354.75 1128.4 344.05 1104.4 340.85 1081.7 337.8 1058.3 342.45 1046.8 344.7 1036.05 348.75 1031.85 350.35 1022.65 354.1 1013.95 357.65 1009.2 359.4 1004.7 361.1 1002.45 361.85 1002.05 362 1002.1 362.45 Z"
				/>
			</g>

			<g id="Layer1_5_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 975.4 296.85
Q 975.9 294.35 974.85 291.9 973.85 289.6 971.75 287.95 967.35 284.45 961.35 285.4 958.25 285.95 954.8 287.85 954.0212890625 288.2748046875 953.05 288.9 954.471484375 281.1123046875 952.8 275.3 951.75 271.55 949.4 268.55 946.9 265.3 943.6 263.7 940.05 262.05 936.2 262.35 932.4 262.7 929.05 264.9 925.75 267.1 923.5 270.5 921.25 273.8 920.3 277.75 919.15 282.35 919.4 287.95 919.6 292.15 920.7 298.1 920.85 298.95 921.8 298.85 922.75 298.7 922.7 297.85 922.3 286.7 922.85 282.8 924 274.25 929.25 269.65 931.65 267.5 934.5 266.75 937.55 265.85 940.45 266.8 943.2 267.65 945.4 270.05 947.45 272.25 948.5 275.15 949.95 279.1 949.65 284.05 949.4 287.8 948 292.95 947.7 294.15 948.75 295.05 949.85 296 950.95 295.2 954.65 292.5 956.4 291.45 959.7 289.55 962.6 289.15 965.1 288.85 967.45 289.9 970 291 971.1 293.15 972.35 295.55 970.65 298.15 969.6 299.7 966.85 301.9 961.1 306.5 954.75 310.1 953.6 310.75 953.95 312.2 954.35 313.65 955.7 313.55 965.4 312.85 974.65 315.9 978.25 317.15 981 319.1 984.25 321.4 985.65 324.4 987.15 327.6 985.5 330.9 984.05 333.8 980.8 335.6 977.95 337.2 972.25 338.15
L 951.1 341.2
Q 944.1 342.2 939.5 343 937.95 343.3 938.2 345.1 938.45 346.95 940.05 346.75 944.8 346.2 951.45 345.2 955.2 344.65 962.8 343.45 973.25 341.9 973.7 341.8 979.7 340.7 983.05 338.7 987 336.4 988.85 332.65 990.9 328.6 989.65 324.45 988.55 320.8 985.3 317.8 982.5 315.25 978.55 313.5 971.44453125 310.3134765625 962.5 309.8 965.3328125 307.9884765625 967.95 305.95 971.2 303.45 972.5 302.05 974.85 299.55 975.4 296.85 Z"
				/>
			</g>

			<g id="Layer1_6_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 967.3 455.85
Q 962.15 487.1 968.15 517.9 969.7 526.1 972.4 534.85 973.25 537.6 975.15 543 977.45 549.2 978.75 551.4 979.55 552.7 981 552.1 982.4 551.5 982 550.05 981.55 548.45 980.7 546.35 979.75 543.95 979.25 542.75
L 976.75 535.55
Q 974.4 528.45 972.7 520.7 969.35 505.65 968.75 490.7 967.45 459.85 976.85 430.4 979.4 422.35 982.95 414.2 985.35 409 986.65 406.4
L 988.55 402.75
Q 989.2 401.6 989.35 401 989.45 400.65 989.2 399.25 989.2 399.15 989.05 399.15 987.55 399.75 986.85 400.8 986.1 401.95 984.8 404.35 982.65 408.3 981.25 411.3 978.05 418.05 975.2 425.65 969.85 440.45 967.3 455.85 Z"
				/>
			</g>

			<g id="Layer1_7_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 965.4 704.65
Q 963 701.15 962.15 697.35 959 683 973.5 665.3 974 664.7 979.05 658.95 982.5 655.1 984.4 652.35 989.9 644.35 988.9 636.6 988.45 632.85 986.6 628.75 985.25 625.75 982.65 621.5
L 978 614.15
Q 976.7 612.1 976.25 611.25 975.35 609.55 975.05 608.05 974.45 604.75 975.15 600.3 975.35 598.95 976.75 592.7 977.9 588.05 978.3 585.65 979.1 581.4 978.6 578.45 978.55 578.35 978.45 578.35 978.35 578.4 978.35 578.5 977.95 582.3 975.3 592 972.95 600.5 972.95 605.6 972.95 609 974 611.35 974.7 612.9 975.9 614.95 976.6 616.1 978.05 618.4 981.3 623.8 982.35 625.75 984.6 630 985.6 633.6 987.95 641.9 983.05 649.35 981.1 652.35 977.5 656.25 973.45 660.5 971.5 662.7 956.85 679.55 957.95 694.65 958.65 703.9 965.7 711.35 967.8 713.55 972.25 717.7 975.2 720.6 977.85 724.6 978.85 726.1 980.3 725.2 981.75 724.3 981.05 722.7 979.7 719.6 976.45 716.25 972.55 712.5 970.65 710.7 967.3 707.45 965.4 704.65 Z"
				/>
			</g>

			<g id="Layer1_8_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1086.3 681.8
Q 1088.15 678.7 1091 674.7 1091.25 674.35 1096.1 667.85 1118.85 637.05 1141.7 620.1 1154.85 610.35 1173.65 601.9 1174.5 601.55 1174.1 600.6 1173.7 599.7 1172.8 599.95 1158.55 604.2 1142.6 615.35 1128.9 624.9 1116.45 637.65 1105.3 649.25 1094 664.25 1087.4 673 1083.85 678.4 1080.7 683.25 1080.5 687.1 1080.35 691.15 1083.45 695.2 1085.9 698.45 1090.65 702.45 1094.25 705.65 1098.75 708.85 1107.4 715 1117 719.35 1118.25 719.9 1118.95 718.7 1119.65 717.45 1118.4 716.9 1103.2 709.8 1089.6 696.2 1087.6 694.25 1086.7 693.1 1085.2 691.25 1084.65 689.4 1083.75 686.25 1086.3 681.8 Z"
				/>
			</g>

			<g id="Layer1_9_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1039.1 707.1
Q 1038.1 706.35 1037.35 707.15 1031.2 713.6 1027.45 722.35 1023.9 730.75 1023.1 740 1022.2 750.25 1024.4 757.6 1025.7 762.05 1028.6 764.65 1032.1 767.75 1036.2 766.25 1038.4 765.45 1040.85 763.25 1042.3 761.95 1044.9 759.3
L 1049 755.35
Q 1048.528515625 760.5779296875 1049.2 765.95 1050.3 774.95 1054.5 783.05 1056.5 787 1059.35 790.6 1061.25 793 1062.3 794.15 1064.1 796.1 1065.8 797.05 1067.95 798.25 1070.2 797.8 1072.4 797.35 1073.95 795.45 1075.3 793.8 1076.2 791.2 1076.75 789.45 1077.4 786.45 1078.729296875 780.2466796875 1078.3 774.15 1081.873828125 778.765625 1085.65 782.95 1090.05 787.85 1093.55 791 1098.6 795.4 1102.9 796.35 1107.95 797.55 1112.3 795.3 1116.95 792.85 1117.75 787.8 1118.5 783.25 1116.2 777.95 1114.6 774.3 1110.95 769.2 1107.25 764 1103.15 759.9 1098.05 754.65 1093.7 752.05 1092.3 751.25 1091.25 752.6 1090.2 753.95 1091.4 755.05 1092.35 755.95 1095 758.3 1097.25 760.3 1098.55 761.55 1102 764.95 1105 768.75 1107.8 772.35 1110.1 776.25 1112.7 780.65 1113.15 784.2 1113.6 788.6 1110.95 790.6 1108.45 792.55 1104.7 791.85 1101.05 791.25 1096.95 787.6 1094.4 785.4 1089.8 780.4 1081.9 771.7 1075.85 762.4 1074.8 760.75 1072.8 761.5 1070.8 762.25 1071.45 764.25 1074.35 773.05 1073.3 782.05 1072.65 787.2 1071.25 790.6 1070.6 792.2 1069.85 792.8 1069 793.5 1067.55 792.5 1065.35 790.8 1062.65 787.1 1060.35 783.95 1058.6 780.6 1055.25 774.1 1054.05 766.3 1052.7 757.85 1054.85 749.3 1055.25 747.55 1053.7 746.65 1052.15 745.75 1050.85 747
L 1043.3 754.2
Q 1038.9 758.7 1036.55 760.7 1033.7 763.1 1031.6 761.3 1029.7 759.8 1028.65 756.1 1026.65 749.4 1027.25 740.4 1027.7 732.65 1030.55 724.6 1033.5 716.6 1039.4 708.75 1040.1 707.85 1039.1 707.1 Z"
				/>
			</g>

			<g id="Layer1_10_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1004.05 177.85
Q 996.65 176.75 990.9 175.5 976.95 172.55 966.6 167.95 955.9 163.2 947.7 156.55 938.65 149.15 933.25 139.9 929.75 133.9 932.4 129.7 934.85 125.65 940.85 123.35 945.9 121.45 952.3 121.2 957.2 121 963.95 121.85 985.8 124.55 1004.15 135.1 1022.75 145.7 1034.7 163.45
L 1037.65 167.75
Q 1038.1 168.35 1038.85 168.1 1039.6 167.8 1039.35 167.05 1036.15 158.2 1027.2 148.6 1019.7 140.6 1010 134.35 991.1 122.25 967.8 118.55 960.75 117.45 955.65 117.3 948.95 117.1 943.5 118.4 937.15 119.95 932.7 123.5 927.3 127.8 927.05 133.6 926.95 136.5 928.4 139.75 929.4 142.15 931.55 145.3 934.6 150 939 154.55 946.85 162.75 957.9 168.8 968.8 174.8 982.95 178.6 994.05 181.6 1009.45 183.7 1014.9 184.4 1016.6 184.45 1018.6 184.55 1018.9 182.35 1019.25 180.2 1017.25 179.8 1014.65 179.25 1010.65 178.75
L 1004.05 177.85 Z"
				/>
			</g>

			<g id="Layer1_11_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1160.35 176.6
Q 1160.6 176.65 1160.7 176.45 1161 175.9 1162 176 1162.2 176.05 1163.45 176.4
L 1175.5 179.75
Q 1184.75 182.35 1191.1 185.2 1194.75 186.85 1196.95 188.25 1200.05 190.25 1201.75 192.65 1203.75 195.45 1203.6 197.95 1203.5 199.5 1202.55 200.35 1201.7 201.1 1200.05 201.4 1195.95 202.25 1190.85 202.6 1188.9 202.7 1186.1 202.65 1182.95 202.55 1181.35 202.5 1180.05 202.5 1179.85 203.95 1179.6 205.35 1180.9 205.55 1189 206.8 1200.7 204.6 1205.25 203.75 1206.45 200.25 1207.65 197.1 1205.9 193.2 1203.15 187.3 1194.5 183.3 1186.8 179.8 1176.2 177.1 1169.55 175.35 1165.65 174.65 1163.4 174.2 1162.3 174.3 1160.15 174.5 1160.1 176.3 1160.05 176.55 1160.35 176.6 Z"
				/>
			</g>

			<g id="Layer1_12_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1180.3 219.55
Q 1180.25 219.7 1180.45 219.75 1182.25 220.45 1185.85 221.75 1189 222.9 1191.2 224 1196.5 226.7 1200.65 230.45 1206.25 235.55 1208.2 239.5 1208.55 240.1 1208.7 240.95 1208.75 241.35 1208.75 241.4 1208.7 241.4 1208.3 241.35 1207.85 241.3 1206.8 241.25 1205.8 241.25 1205.25 241.15 1202.95 240.75 1199.4 239.35 1194.25 237.3 1192.95 236.9 1190.65 236.1 1189.6 236 1187.55 235.75 1186.35 236.8 1185.55 237.5 1186.15 238.4 1186.75 239.25 1187.7 239.1 1189.15 238.85 1191.2 239.45 1191.8 239.6 1194.5 240.6 1196.6 241.35 1200.75 242.9 1204.55 244.1 1207.15 244.2 1211.5 244.35 1211.45 241.2 1211.45 239.7 1210.5 237.95 1209.95 236.95 1208.5 235.1 1206.55 232.45 1204.3 230.35 1199.55 225.75 1194 223.05 1186.55 219.3 1180.5 219.35 1180.3 219.35 1180.3 219.55 Z"
				/>
			</g>

			<g id="Layer1_13_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1089.5 176.35
Q 1089.6 175.85 1089.35 175.35 1089.1 174.8 1088.65 174.6
L 1088.4 174.5
Q 1088.2833984375 174.25234375 1088.05 174.05 1086.7 173.05 1085.3 173.05 1084.61328125 173.1048828125 1084.15 173.3 1084.0259765625 173.3861328125 1083.9 173.45 1083.7255859375 173.3990234375 1083.5 173.35 1082.040625 173.1939453125 1080.45 173.7 1079.4 174.05 1079.25 175.1 1079.15 176.35 1080.05 176.75 1084.05 178.6 1088.25 177.85 1089.3 177.6 1089.5 176.35
M 1083.75 173.55
L 1083.7 173.55
Q 1083.680859375 173.56953125 1083.6 173.55
L 1083.75 173.55 Z"
				/>
			</g>

			<g id="Layer1_14_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1062.65 181.25
L 1062.65 181.15
Q 1062.6 181 1062.4 181 1062.25 181 1062.1 181.1 1062 181.2 1061.6 181.6 1061.35 181.85 1061.25 182.25 1061.1 183.1 1061.3 184.15 1061.6 186.05 1062.7 187.8 1064.9 191.45 1069.4 193.4 1073.4 195.15 1079.25 195.4 1079.3 195.4 1084.45 195.55 1086.1 195.65 1089.75 196.45 1091.3 196.85 1092.05 195.25 1092.8 193.65 1091.25 192.9 1089.35 192 1086.7 191.75 1085.1 191.6 1081.95 191.65 1075.9 191.75 1072.95 191.15 1068.65 190.25 1065.9 187.65 1064.5 186.35 1063.6 184.75 1062.697265625 183.0798828125 1062.65 181.55 1062.6498046875 181.486328125 1062.65 181.4 1062.64765625 181.3248046875 1062.65 181.25 Z"
				/>
			</g>

			<g id="Layer1_15_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1124.55 258.4
Q 1124.6080078125 258.3751953125 1124.65 258.35
L 1124.5 258.55 1124.55 258.4
Q 1124.4 258.5419921875 1124.4 258.75 1124.4 258.934375 1124.5 259.05 1124.56015625 259.1201171875 1124.6 259.2
L 1124.9 259.5 1125.5 259.75
Q 1126.05 259.9 1126.65 259.9 1128.15 260.1 1128.85 260.1
L 1130.9 260.25
Q 1132 260.35 1132.55 260 1133.4 259.55 1133.4 258.75 1133.4 257.95 1132.55 257.5 1132 257.15 1130.9 257.25
L 1126.65 257.6
Q 1126.05 257.6 1125.5 257.75
L 1124.9 258
Q 1124.65 258.15 1124.6 258.35
L 1124.55 258.4 Z"
				/>
			</g>

			<g id="Layer1_16_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1003.25 203.6
Q 1003.15 203.05 1002.65 203 1001.05 202.7 998.8 204.85 997.65 205.95 995.45 208.35 994.4 209.55 992.4 212.05
L 990.85 214.05
Q 990.3 214.8 990.05 215.15 989.6 215.8 989.45 216.35 989.2 217.15 989.95 217.8 990.75 218.45 991.5 217.9 991.9 217.6 993.1 215.95
L 1001.5 205.7
Q 1001.95 205.25 1003.05 204.45 1003.35 204.1 1003.25 203.6 Z"
				/>
			</g>

			<g id="Layer1_17_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1009.4 208.25
Q 1008.9 208.25 1008.8 208.8 1008.25 213.35 1005.2 216.25
L 1004.35 217.05
Q 1004.1 217.4 1004.2 217.8 1004.25 218.25 1004.6 218.45 1005.15 218.8 1005.85 218.4 1006.15 218.2 1006.85 217.5 1008.4 215.9 1009.25 213.7 1010.3 211.25 1010 208.8 1009.95 208.25 1009.4 208.25 Z"
				/>
			</g>

			<g id="Layer1_18_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1019.45 212.85
Q 1019.3 212.8 1019.25 212.9
L 1018.9 213.15
Q 1018.75 213.25 1018.75 213.35
L 1017.6 216.65
Q 1017.4 217.2 1016.85 218.25 1016.1 219.25 1015.8 219.8 1015.6 220.1 1015.7 220.5 1015.75 220.85 1016.05 221.05 1016.6 221.55 1017.35 221 1017.9 220.55 1018.55 219.25 1019.05 218.2 1019.25 217.25 1019.55 216.15 1019.7 215.2
L 1019.85 213.6 1019.8 213.3
Q 1019.55 213.05 1019.55 213 1019.55 212.9 1019.45 212.85 Z"
				/>
			</g>

			<g id="Layer1_19_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1029.15 216.7
Q 1028.8 216.8 1028.9 217.15 1029.7 219.85 1029.85 221.25
L 1030.05 223.3
Q 1030.05 223.7 1030 224.5 1030.05 225.2 1030.25 225.65 1030.5 226.1 1031.1 226.1 1031.65 226.1 1031.9 225.65 1032.25 225.05 1032 223.2 1031.85 222 1031.55 220.9 1030.8 218.4 1029.65 216.85 1029.45 216.55 1029.15 216.7 Z"
				/>
			</g>

			<g id="Layer1_20_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 983.6 239.5
Q 983.4 239 982.85 239.1 982.55 239.15 981.35 239.75
L 971.85 244.2
Q 970.55 244.85 970.35 245.05 969.95 245.35 970.25 245.75 970.5 246.15 970.9 246.1 971.3 246.05 972.45 245.45
L 981.8 241.05
Q 983.2 240.4 983.35 240.3 983.75 239.95 983.6 239.5 Z"
				/>
			</g>

			<g id="Layer1_22_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1004.7 252.2
Q 1004.3 252.05 1004.1 252.4 1002.95 254.7 1002.7 255.05
L 1002.1 255.95 1001.95 256.05 1001.95 256.15 1000.9 257.25
Q 1000.4 257.75 1000.3 257.95 999.8 258.75 1000.35 259.3 1001.2 260.15 1002.5 258.85 1003.65 257.6 1004.3 256.25 1005.25 254.2 1005.1 252.7 1005.1 252.3 1004.7 252.2 Z"
				/>
			</g>

			<g id="Layer1_23_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 977.3 279.8
Q 975.6 279.05 972.35 277.85 968.9 276.55 967.4 275.9 960.8 272.95 959.6 268.55 958.95 266.2 959.55 264.1 960.25 261.7 962.3 260.65 962.95 260.3 962.65 259.6 962.35 258.9 961.65 259.1 957.45 260.5 957 265.4 956.55 269.95 959.25 273.35 962 276.75 968.45 279.4 976.35 282.6 978.45 283.65 988 288.45 996.05 295.9 1004 303.25 1009.6 312.45 1012.85 317.75 1015.1 323.25 1015.95 325.45 1017.05 329 1018.4 333.55 1018.8 334.8 1019.2 335.95 1020.55 335.85 1021.9 335.7 1021.75 334.4 1021.25 330.7 1018.9 324.3 1017.05 319.3 1014.65 314.85 1009.85 305.75 1002.6 297.9 995.4 290.2 986.55 284.7 982.05 281.9 977.3 279.8 Z"
				/>
			</g>

			<g id="Layer1_24_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 978.25 765.25
Q 977.75 765.4 977.85 765.9 978.75 770.95 980.45 777.85 981.4 781.8 983.4 789.7
L 989.1 813.4
Q 992.85 829.05 1000.55 860.3 1015.75 922.9 1023.2 954.2
L 1029.45 980.6
Q 1033.15 996.45 1035.8 1007 1036.05 1008.05 1037.1 1007.75 1038.15 1007.45 1037.9 1006.45 1035.7 996.5 1032.4 982.65 1028.55 966.85 1026.65 958.95
L 1015.4 912
Q 1003.85 864.15 992.45 818.15 988.05 800.8 985.85 792.1 983.7 783.25 982.55 778.8 980.6 771 978.9 765.6 978.7 765.1 978.25 765.25 Z"
				/>
			</g>

			<g id="Layer1_25_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1186.1 226.5
Q 1186 226.7 1186.1 226.7 1186.2 226.7 1186.2 226.6 1186.2 226.5 1186.1 226.5 Z"
				/>
			</g>

			<g id="Layer1_26_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1138.95 97.9
Q 1138.25 92.15 1134.85 90.65 1132.8 89.75 1130.75 90.65 1128.9 91.4 1127.5 93.35 1126.2 95.2 1125.25 98 1124.9 99.05 1123.9 103 1122.4 108.7 1121.05 115.15 1118.996875 125.0673828125 1117.9 134.7 1114.3826171875 120.2689453125 1109.7 108.7 1102.45 91 1091.8 78.25 1085.05 70.3 1077.4 64.8 1077 64.55 1076.45 64.7 1075.9 64.85 1075.7 65.3 1070.35 76.45 1068.7 82.2 1065.85 92.1 1067.95 100.2 1068.95 104.05 1071.25 108.45 1072.75 111.3 1075.8 116.15 1080.7 123.8 1086 130.65 1093 139.7 1099.8 146.1 1100 146.3 1100.15 146.1 1100.35 145.9 1100.15 145.7 1086.3 130 1078.65 118.15 1075.35 113.05 1074 110.65 1071.6 106.35 1070.35 102.7 1067.6 94.8 1069.85 85.5 1070.95 81 1073.25 75.8 1074.6654296875 72.615234375 1077.15 67.8 1089.8796875 78.2099609375 1098.5 92.45 1106.7 105.95 1112.35 124.45 1115.2 133.8 1117.15 143.45 1117.4 144.6 1118.55 144.35 1119.65 144.15 1119.75 143.1 1120.85 132.05 1123 120.4 1125.6 106.95 1128.3 98.55 1129.95 93.5 1132.5 93.2 1135.45 92.9 1136.05 98.75 1137.15 109.15 1135.8 120.45 1134.35 132.8 1130 144.3 1129.7 145.1 1130.35 145.75 1131 146.35 1131.8 146.15 1143.55 142.95 1150.05 141.85 1160.35 140.1 1168.65 140.95 1173.3 141.4 1177.55 142.9 1179.4 143.55 1181.4 144.5
L 1183.45 145.5
Q 1184.7 146.15 1185.25 146.85 1185.9 147.6 1185.35 148.8 1185 149.45 1184.05 150.6 1182.6 152.4 1180.75 153.7 1177.45 156.2 1173.2 157.45 1168.3 158.95 1161.6 158.95 1161.15 158.95 1149.85 158.35 1148.65 158.3 1148.45 159.6 1148.25 160.95 1149.45 161.1 1156.15 162.1 1161.1 162.2 1167.55 162.3 1172.75 160.95 1177.7 159.7 1181.6 157.05 1186.55 153.8 1188.3 150 1189.6 147.2 1188.05 145.15 1187.15 143.85 1184.15 142.25 1179.65 139.85 1174.6 138.7 1165.65 136.6 1154.35 138.05 1146.2611328125 139.089453125 1133.75 142.55 1137.272265625 132.266796875 1138.65 121.6 1140.3 108.6 1138.95 97.9 Z"
				/>
			</g>

			<g id="Layer1_28_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1129.75 243.1
Q 1130.05 244.05 1131 243.9 1136.1 243.05 1142.5 247 1148.5 250.75 1150.8 254.1 1152.2 256.15 1152.6 258.4 1153 260.85 1152.1 263 1151.1 265.35 1148.5 266.25 1146.7 266.85 1143.5 266.85
L 1132.4 266.65
Q 1131.15 266.6 1130.5 266.6 1129.35 266.6 1128.65 266.9 1127.75 267.2 1127.8 268.05 1127.8 268.85 1128.65 269.2 1129.4 269.5 1130.5 269.55 1131.15 269.6 1132.4 269.55
L 1144.1 269.85
Q 1152.05 269.85 1154.6 264.85 1155.9 262.4 1155.8 259.45 1155.7 256.65 1154.4 254.1 1152 249.45 1145.05 245.2 1136.95 240.35 1130.45 241.9 1129.5 242.1 1129.75 243.1 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_0_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_1_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_2_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_3_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_4_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_5_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_6_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_7_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_8_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_9_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_10_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_11_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_12_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_13_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_14_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_15_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_16_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_17_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_18_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_19_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_20_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_22_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_23_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_24_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_25_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_26_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer1_28_MEMBER_0_FILL" />
		</g>
	</svg>
);
