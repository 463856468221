import React from "react";

export const aboutPage2 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="329px"
		height="191px"
		viewBox="0 0 329 191"
		id="frame-2"
	>
		<defs>
			<linearGradient
				id="Gradient_2"
				gradientUnits="userSpaceOnUse"
				x1="172.44999999999993"
				y1="785.2"
				x2="1465.65"
				y2="231.59999999999997"
				spreadMethod="pad"
			>
				<stop offset="4.705882352941177%" stopColor="#2DFCD8" />

				<stop offset="5.882352941176471%" stopColor="#2BF0CE" />

				<stop offset="11.764705882352942%" stopColor="#21BBA1" />

				<stop offset="18.03921568627451%" stopColor="#198C79" />

				<stop offset="24.313725490196077%" stopColor="#136558" />

				<stop offset="30.58823529411765%" stopColor="#0F453C" />

				<stop offset="37.254901960784316%" stopColor="#0D2C27" />

				<stop offset="43.92156862745098%" stopColor="#0C1A18" />

				<stop offset="51.372549019607845%" stopColor="#0C0F0F" />

				<stop offset="59.6078431372549%" stopColor="#0C0C0C" />
			</linearGradient>

			<g id="Layer6_0_FILL">
				<path
					fill="url(#Gradient_2)"
					stroke="none"
					d="
M 1147.65 788.45
L 894.9 73.05 184.75 682.3 285.45 997.85 1147.65 788.45 Z"
				/>
			</g>

			<g id="Layer5_0_FILL">
				<path
					fill="#0C0C0C"
					stroke="none"
					d="
M 426.2 703.75
L 207.6 651.85 -17.35 777.05 -130.75 1054.65 118.55 1261.6 474.7 988.6 426.2 703.75 Z"
				/>
			</g>

			<g id="Layer4_0_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1072.15 239.05
Q 1072.1 237.5 1070.9 236.9 1061.25 231.95 1050 230 1039.2 228.05 1028 229.05 1017.35 229.95 1007.25 233.65 1000.7 236.05 997.85 237.5 992.15 240.4 990.25 243.8 989.95 244.4 990.2 245 990.4 245.55 991.05 245.8 991.5 245.95 991.8 245.8 991.84921875 245.775390625 991.95 245.65
L 992.6 245.4
Q 993.45 245.25 994.75 244.6 995.45 244.25 996.8 243.55 1003.8 240.25 1007.1 239.05 1016.8 235.55 1027.2 234.55 1045.5357421875 232.7341796875 1063.7 239.35 1051.446484375 245.6859375 1038.65 251.35 1036.85 252.15 1031.5 254.05 1026.95 255.7 1024.5 257 1022.6 258 1021.95 259.4 1021.2 261 1022.3 262.7 1022.95 263.75 1024.15 263.6 1025.4 263.5 1025.7 262.3 1026.05 260.95 1030.75 259.3 1033.3 258.4 1037.15 257.2 1042.2 255.05 1047.75 252.45 1050.25 251.25 1059.3 246.75 1066.45 243.2 1070.9 241.2 1072.15 240.65 1072.15 239.05 Z"
				/>
			</g>

			<g id="Layer4_1_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 865.05 327.45
Q 865 327.5 865.05 327.55 876.2 334.7 893.3 339.5 897.45 340.65 923.4 346.5 926.15 347.1 926.9 344.3 927.7 341.45 924.95 340.9 908.3 337.35 893.05 334.8 883.5 333.45 878.7 332.45 870.3 330.7 865.15 327.45 865.1 327.4 865.05 327.45 Z"
				/>
			</g>

			<g id="Layer4_2_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 890.4 313.1
Q 890 314.25 890.65 315.25 894.65 321.05 901.75 321.6 905.25 321.85 908.25 320.65 911.8 319.3 913 316.4 913.7 314.8 912.6 313.65 912.019140625 313.0427734375 911.25 312.95 910.8744140625 309.277734375 906.55 307.95 904.934375 307.4451171875 903.35 307.6 902.121875 307.2421875 900.7 307.1 899.4671875 306.9548828125 898.6 307.8 900.2580078125 307.5681640625 901.8 307.95 901.139453125 308.161328125 900.55 308.45 899.233984375 308.5505859375 898 308.65
L 898.05 308.6
Q 898.3109375 308.1328125 898.6 307.8 897.7521484375 307.939453125 896.85 308.25 896.1236328125 308.5244140625 895.3 308.95 895.289453125 308.944921875 895.25 308.95 894.154296875 309.114453125 893.75 309.85 892.7693359375 310.4888671875 891.7 311.3 890.75 312.05 890.4 313.1 Z"
				/>
			</g>

			<g id="Layer4_3_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1021.9 332.4
Q 1016.1 333.6 1015.95 333.65 1012.4 334.75 1011.45 337.05
L 1011.45 337.2
Q 1012.75 338.2 1014.35 338.2 1014.95 338.2 1017.55 337.8 1026.35 336.4 1031.8 335.95 1040.1 335.3 1043.85 335.75 1050.95 336.55 1054.25 340.65 1057.75 344.95 1056.4 351.25 1055.15 356.85 1051 361.05 1047.4 364.65 1041.2 367.35 1033.9 370.15 1030.3 371.65 1028.45 372.45 1028.9 374.3 1029.35 376.2 1031.4 375.7 1045.4 372.55 1054.6 363.9 1066.1 353.2 1060.25 340.6 1055.45 330.3 1038.9 330.6 1030.2 330.85 1021.9 332.4 Z"
				/>
			</g>

			<g id="Layer4_4_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1035.95 354.1
Q 1038.8 356.3 1039.95 356.85 1041.2 357.45 1042.3 356.65 1043.4 355.8 1043.05 354.45 1042.35 351.75 1039.2 349.6 1036.7 347.9 1033.4 347.05 1030.25 346.3 1027.6 346.5 1023.85 346.7 1022.75 348.9
L 1022.75 348.9
Q 1022.7669921875 348.9587890625 1022.75 349 1022.35 349.9 1023.15 350.4 1024.45 351.2 1027.25 351.25 1030.6 351.35 1031.65 351.65 1033.55 352.25 1035.95 354.1 Z"
				/>
			</g>

			<g id="Layer4_5_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 938.55 410.25
Q 939.55 411.5 940.85 410.8 944.85 408.6 949.35 410.25 953.7 411.9 955.85 416 957.75 419.65 958.05 424.95 958.2 428 958.1 434.2 958.2 435.65 959.7 435.9 961.2 436.15 961.55 434.65 963.25 428.2 962.25 421.75 961.15 414.4 956.9 410.15 953.05 406.25 948.1 405.55 942.8 404.75 938.7 408.05 937.5 409 938.55 410.25 Z"
				/>
			</g>

			<g id="Layer4_6_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 810.55 419.75
Q 811.5373046875 415.4451171875 810.95 414.9
L 811.05 414.85 811.15 414.2
Q 811.25 413.9 811.05 413.6 810.85 413.3 810.55 413.35 807.95 413.5 806.45 417.35 805.6 419.45 804.75 423.35 803.8 426.55 801.3 433.65 799.35 440 799.5 444.15 799.55 445.55 800.9 445.9 802.25 446.25 803.05 445.1 804.95 442.2 806.3 437.05 807.65 431.15 808.35 428.45 810.35 420.65 810.55 419.75 Z"
				/>
			</g>

			<g id="Layer4_7_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 841.3 411.9
Q 841.25 411.8 841.15 411.8 836.5 411.9 834.6 418.65 834.3 419.75 833.75 422.2 833.3 424.55 832.9 425.75 832.4 427.4 831.3 428.85 829.9 430.45 829.05 431.45 828.4 432.25 828.75 433.15 829.15 434 830.2 434.1 833.45 434.5 835.1 431.65 836.25 429.6 837 424.8 838.05 418.05 838.3 417.1 839.25 413.15 841.25 412.05 841.35 412 841.3 411.9 Z"
				/>
			</g>

			<g id="Layer4_8_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 867.65 417.45
Q 866.75 416.9 866.05 417.65 865 418.85 863.9 420.65 863.8 420.8 862.05 423.85 860.65 425.85 860 426.9 858.85 428.75 858.95 430.3 859.05 431.45 860.05 432.1 861.1 432.75 862.15 432.1 863.5 431.25 864.35 429.35 865.15 427.1 865.65 425.95 866.7 423.7 867.15 422.55 868.05 420.5 868.35 419 868.55 418.05 867.65 417.45 Z"
				/>
			</g>

			<g id="Layer4_9_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 898.3 420.05
Q 898.3 419.85 898.1 419.85
L 896.9 419.95
Q 896.75 419.55 896.2 419.55 893.25 419.4 890.5 422.1 887.9 424.6 887.45 427.6 887.25 429.45 889.1 429.95 891 430.5 891.25 428.65 891.75 424.9 893.95 422.85 894.3 422.55 895.35 421.95 896.25 421.45 896.6 421.05 896.7 420.95 897 420.55 896.9 420.05 897.2 420.45
L 898.15 420.25
Q 898.35 420.25 898.3 420.05 Z"
				/>
			</g>

			<g id="Layer4_10_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 878.1 465.9
Q 876.4 465.15 875.55 466.7 871.4 474.55 871.8 482 871.9 484.35 874.15 484.35 876.4 484.35 876.6 482 877.4 473.85 879.25 468.25 879.8 466.6 878.1 465.9 Z"
				/>
			</g>

			<g id="Layer4_11_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 838.45 468.75
L 838.05 468.6
Q 837.6 468.45 837.05 468.65 836.45 468.85 836.25 469.3 834.95 472 834.55 474.5 833.95 478.05 835.55 479.45 836.25 480.1 837.15 480 838.1 479.9 838.6 479.1 839.15 478.25 839.05 477.1 838.85 475.75 838.8 475 838.7 473.3 839.15 470.55 839.45 469.3 838.45 468.75 Z"
				/>
			</g>

			<g id="Layer4_12_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 816.3 471.15
Q 816.05 470.9 815.7 471 814.4 471.45 813.55 473 813.5 473.15 812.4 475.9 811.9 477.2 810.55 479.8 809.65 482.15 810.35 483.95 810.9 485.45 812.3 485.8 813.65 486.2 814.85 485.1 816.3 483.85 816.6 481.55 816.7 478.8 816.85 477.4 817.2 475.2 817.25 474.15 817.3 472.25 816.3 471.15 Z"
				/>
			</g>

			<g id="Layer4_13_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 949.65 520.5
Q 948.65 524.2 952.2 525.15 972.05 530.65 990.35 515 997.1 509.2 1004.8 499.5 1005.75 498.3 1017.2 483.05 1033.35 461.5 1050.15 450.4 1072.2 435.9 1094.05 440.25 1104.85 442.35 1113.8 449.35 1119 453.4 1122.15 457.85 1122.5 458.35 1124.75 462.15 1126.25 464.75 1127.75 466.1 1128.15 466.4 1128.35 465.95 1129.1 462.6 1126.85 458.15 1125.45 455.4 1122.3 451.25 1116.7 444.05 1108.3 439.55 1099.4 434.75 1088.55 433.9 1078.45 433.1 1068 435.75 1047.4 441.05 1029.2 459.1 1022.25 466 1014.05 476.3 1013.15 477.4 1000 494.55 990.2 507.4 980.25 513.55 967.1 521.65 954.2 517.85 950.65 516.8 949.65 520.5 Z"
				/>
			</g>

			<g id="Layer4_14_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1313.8 586.2
Q 1306.6 575.5 1300.1 568.4 1296.2 564.15 1284.1 552.5 1273.9 542.75 1268.5 536.15 1251.4 513.2 1242.05 502.35 1225.75 483.45 1208.7 476.05 1186.7 466.55 1159.45 473.6 1136.25 479.6 1114.55 495.85 1114.1 496.2 1114.3 496.75 1114.45 497.3 1115.05 497.1 1120.85 495.15 1135.8 489.2 1149.05 483.9 1156.8 481.6 1181.4 474.2 1200.5 480.15 1217.45 485.45 1233.3 502.3 1239.1 508.45 1246.35 517.85 1250.45 523.2 1258.4 533.75 1264.45 541.55 1272.35 549.85 1278.25 556.1 1287.45 564.85 1300.8 577.55 1313.65 597.7 1326.75 618.3 1338.15 632.6 1342.6 638.2 1352.1 649.6 1361.35 660.7 1366 666.6 1382.5 687.4 1391.4 705.25 1396.7 715.95 1399.75 726.65 1401.35 732.25 1402.35 738.25 1403.2 743.7 1403.25 744.7 1403.45 748.6 1401.95 750.85 1400.25 753.4 1403 755 1405.7 756.55 1407.4 754.05 1409.6 750.7 1409.5 745.55 1409.5 743.15 1408.45 736.6 1406.9 726.65 1403 716.1 1396.2 697.6 1382.75 678.35 1374.65 666.65 1354.15 641.95 1335 618.85 1326 605.1 1318 592.45 1313.8 586.2 Z"
				/>
			</g>

			<g id="Layer4_15_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1036.7 767.75
Q 1043.05 761.7 1051.35 755.35 1084.9 729.75 1121.7 725.55 1138.45 723.65 1161.3 724.6 1174.5 725.2 1200.85 727.1 1224.55 728.55 1240.65 727.5 1253.25 726.65 1257.1 726.05 1266.7 724.55 1272.55 720.75 1279.3 716.4 1279.25 709.7 1279.15 703.2 1272.85 698.25 1264.75 692 1254.6 686.6 1245 681.55 1234.8 677.9 1234.5 677.8 1234.35 678.1 1234.25 678.4 1234.5 678.6 1237.7 680.85 1252.45 690 1263.65 696.95 1269.75 702.3 1273.95 705.95 1273.65 709.9 1273.4 713.85 1268.65 716.55 1264.95 718.6 1259.35 719.65 1256.8 720.1 1249.6 720.85 1232.2 722.65 1208 721.5 1180.25 719.65 1166.4 718.9 1143.25 717.65 1126.05 719.1 1104.25 720.95 1086.65 727.35 1069.95 733.55 1054.4 743.85 1044.6 750.35 1039.25 754.6 1030.8 761.35 1026.15 767.7 1020.6 775.4 1023.35 783.85 1026.05 792.15 1034.85 795.9 1043.45 799.55 1053.25 795.6 1058.52890625 793.4884765625 1063.1 789.65 1063.284765625 791.1970703125 1063.45 792.95 1064.15 799.1 1065.35 802.95 1066.25 805.95 1068.55 807.65 1070.95 809.45 1073.95 809.05 1076.85 808.75 1079.4 806.65 1081.8 804.7 1083.15 801.95 1084.55 799.1 1084.65 793.2 1084.660546875 792.7693359375 1084.65 792.35 1084.787109375 792.599609375 1084.9 792.85 1088.05 799.85 1089.65 802.55 1096.2 813.55 1106.1 810.85 1113.4 808.85 1120.15 797.3 1125.2 788.6 1126.4 777.55 1126.75 774.2 1123.25 774.2 1119.75 774.2 1119.4 777.55 1118.6 785.65 1115 792.45 1109.45 803.05 1104.3 804.4 1098.05 806 1092.95 795.95 1088.15 784.05 1085.95 781.1 1084.65 779.4 1082.75 779.65 1080.9 779.9 1080.05 781.9 1079.2 784 1078.85 792.1 1078.6 798.85 1076.05 801.35 1072.85 804.5 1071.55 800.85 1071.1 799.5 1070.65 794.45
L 1069.55 781.8
Q 1069.35 779.4 1067.25 778.5 1065.1 777.55 1063.3 779.2 1055.4 786.5 1047.8 788.8 1037.5 791.9 1031.9 783.7 1029.1 779.65 1031.1 775.05 1032.5 771.8 1036.7 767.75 Z"
				/>
			</g>

			<g id="Layer4_16_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 832.95 613.55
Q 832.85 613.75 832.95 613.75 833.05 613.75 833.05 613.65 833.05 613.55 832.95 613.55 Z"
				/>
			</g>

			<g id="Layer4_17_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 865.95 581.5
Q 859.45 586.75 854.55 591.25 849.8 595.65 844.5 601.8 842.15 604.5 835.15 613.05 835.05 613.2 835.15 613.35 835.3 613.45 835.45 613.35 840.2 609.2 846.9 604.05 847.2 603.8 858.7 595.2 863.9 591.25 870.1 586.9 871.9 585.7 875.75 583.35 879.1 581.2 881 579.3 883.2 577.05 881.3 574.8 879.4 572.6 876.7 573.75 874.2 574.8 871.1 577.25 867.6 580.2 865.95 581.5 Z"
				/>
			</g>

			<g id="Layer4_18_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 878 574.7
Q 877.8 574.95 878 575.2 897 595.55 908.75 612.3 915.9 622.5 920.45 632.3 924 639.85 925.2 644.55 926 647.6 925.8 651.35 925.7 653.6 925.2 658.15 925.1 659.75 926.8 660.2 928.55 660.7 929.2 659.25 931.4 654.55 929.55 647.3 928.7 643.8 925.3 635.65 920.5 624.35 913.05 613.65 906.75 604.5 897.3 593.75 893.8 589.8 888 584.1 879.8 576.1 878.45 574.7 878.2 574.45 878 574.7 Z"
				/>
			</g>

			<g id="Layer4_19_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 423.75 699.45
Q 422.7 699.55 422.7 700.6 422.75 705.6 424 712.6 424.75 716.6 426.35 724.5 427.95 733.25 431.35 750.75 439.95 796.9 440.95 802.3 450.35 853.55 459.25 905.6
L 464.15 933.9
Q 465 938.9 466.5 949.05 468 958 469.75 964 470.1 965.2 471.4 965.05 472.75 964.9 472.65 963.65 472.35 958.6 471.2 951.6 469.75 943.65 469.05 939.7
L 464.55 913.35
Q 463.05 904.75 455.3 861.75 443 792.85 436.75 758.45
L 431.55 730.2
Q 429.95 720.15 429 715.1 427.35 706.15 425.1 700.3 424.75 699.35 423.75 699.45 Z"
				/>
			</g>

			<g id="Layer4_20_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 313.7 673.75
Q 276.95 665.25 258.55 661.05 226.3 653.7 203.25 649.25 201.65 648.95 201.25 650.5 200.8 652.05 202.35 652.5 225.25 658.9 257.55 666.8 276 671.3 312.95 680.15 344.95 687.9 367.95 693.3
L 395 699.65
Q 399.6 700.75 408.9 703.1 417.15 705.1 422.9 705.85 425.55 706.2 425.85 703.45 426.1 700.65 423.75 699.85 418.3 697.95 409.85 696.05 400.4 694.05 395.8 692.95
L 367.8 686.3
Q 349.8 682.05 313.7 673.75 Z"
				/>
			</g>

			<g id="Layer4_21_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 198.7 656.4
Q 198.55 656.45 198.6 656.55 206.05 698.8 215.9 739.6 221.3 762.05 226.7 780.55 228.75 787.6 233 800.8 237.45 814.6 239.35 821.05 239.55 821.65 240.2 821.45 240.9 821.3 240.7 820.65 234.75 803.5 228.3 780.15 227.45 777.05 217.5 739.15 206.85 698.5 198.85 656.5 198.8 656.35 198.7 656.4 Z"
				/>
			</g>

			<g id="Layer4_22_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 463.95 953.05
Q 464.7 951.3 463.15 950.5 455.05 946.5 441.65 940.85 424.45 933.55 420 931.55 398.65 921.9 378.25 911.7 336.2 890.7 296.35 866.95 294.05 865.6 273.6 852.8 259.8 844.2 250.4 839.35 249.8 839.05 249.3 839.65 248.8 840.2 249.35 840.7 257.05 847.05 267.8 854.1 272.75 857.3 287.15 866.15 307.55 878.7 328.6 890.55 369.1 913.3 412.55 933.4 435.2 943.85 436.75 944.55 450.7 950.75 461.55 954.3 463.2 954.85 463.95 953.05 Z"
				/>
			</g>

			<g id="Layer4_23_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 571.15 927.2
Q 540.35 933.2 516.9 938.7 488 945.5 463.4 953.15 463.25 953.2 463.3 953.35 463.35 953.5 463.5 953.45 486.9 948.35 517.45 942.45 528.95 940.25 571.6 932.35
L 666 915.05
Q 668.15 914.65 672.65 913.95 676.55 913.2 679 911.8 680.15 911.1 680.05 909.55 679.9 907.9 678.45 907.7 676 907.35 672.9 907.8 669.25 908.5 667.35 908.85
L 571.15 927.2 Z"
				/>
			</g>

			<g id="Layer4_24_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 631.15 888.85
Q 611.55 880.65 592.05 873.75 572.7 866.95 552.05 861.1 540.15 857.75 532.2 855.9 525.6 854.15 522.1 853.4 515.85 852 511.85 852.5 511.7 852.55 511.65 852.65 511.6 852.8 511.75 852.85 514.9 855.05 520.75 856.75 524 857.75 530.3 859.4 536.8 861.5 549.9 865.5 568.95 871.4 589.65 878.9 610.4 886.4 627.9 893.7 637.95 897.85 647.85 902.1 651.05 903.5 657.45 906.6 663.25 909.3 667.3 910.4 669.05 910.9 669.7 909.25 670.3 907.6 668.9 906.6 665.4 904.05 659.6 901.35 652.95 898.5 649.75 897 639.25 892.2 631.15 888.85 Z"
				/>
			</g>

			<g id="Layer4_25_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 654.9 782.9
Q 657.7 782.95 684.6 783.85 712.1 784.85 718.7 784.9 732.748828125 785.0083984375 744.25 784.1 744.9826171875 784.4162109375 746 784.25
L 753.5 783.15
Q 756 782.8 755.6 780.05 755.2 777.3 752.75 777.35 731.6 778 722.6 778.1 705.75 778.4 692.4 777.95 672.55 777.25 662.65 776.95 645.15 776.35 632.95 776.35 609.1 776.35 572.75 780.25 543.1 783.5 513.75 782.3 511.25 782.2 497.35 781.5 487.35 780.95 480.95 780.85 466.35 780.55 446.2 783.25 445.95 783.25 445.95 783.55 445.95 783.8 446.2 783.8 458.3 783.85 476.15 785.3 486.1 786.1 506 787.8 537.85 790.15 565 787.45 575.05 786.45 595 784.45 612.2 782.9 625.15 782.65 637.95 782.45 654.9 782.9 Z"
				/>
			</g>

			<g id="Layer4_26_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1364.55 769.45
Q 1364.6 769.6 1364.75 769.7 1369.15 771.15 1376.4 770.75 1380.5 770.5 1388.3 769.65
L 1413.65 768.1
Q 1431.65 767.05 1439 766.7 1453.2 766 1464.4 765.9 1486.1 765.65 1515.75 766.75 1532.9 767.4 1567.1 768.95
L 1624.9 771.25
Q 1627.8 771.35 1627.8 768.35 1627.8 765.35 1624.9 765.25 1556.45 761.5 1522.2 760.35 1462.75 758.35 1419.5 762.35
L 1390.25 765.2
Q 1377.9 766.4 1376.55 766.6 1372.5 767.05 1370.5 767.4 1367 767.95 1364.7 769.2 1364.5 769.25 1364.55 769.45 Z"
				/>
			</g>

			<g id="Layer4_27_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 857.4 531.35
Q 861.5 525.8 858.7 520.15 856.15 515 849.75 512.55 843.25 510.1 834.95 511.3 827.95 512.25 820.55 515.6 813 519 806.9 524.65 802.691015625 528.5111328125 799.4 533.2 798.9439453125 531.8447265625 798.55 530.2 797.4 524.55 796.75 521.8 794.25 511.7 786.65 510.5 782.4 509.8 778.35 512.4 774.55 514.75 772.55 518.85 770.1 523.7 771.1 530.4 771.370703125 532.148828125 771.95 534.4 770.3759765625 533.2796875 768.6 531.9 759.4 524.85 758.4 524.15 745.85 515.5 735.45 519.8 727 523.25 724.4 533.35 722.2 541.85 724.6 551.55 727.15 561.95 734.1 571.75 740.55 580.9 749.6 587.85 758.5 594.7 769 598.45 773.85 600.2 782.5 601.95 792.5 604 796.15 605.05 819.1 611.75 832.65 634.7 845.55 656.6 843.45 681.1 842.15 696.25 833.1 706 829.1 710.35 822.85 715.35 815.5 720.95 811.85 723.75
L 787 743
Q 779.35 748.75 775.55 751.7 768.85 756.8 764.5 760.95 754.9 770.05 752.4 781.9 749.5 795.7 759.65 803.7 764.3 807.35 772.45 809.2 772.85 809.3 786.35 811.55 798 813.45 803.75 814.25 813.45 815.55 821.3 815.95 821.65 815.95 821.7 815.6 821.7 815.2 821.4 815.1 809.6 810.95 793.75 807.2 784.85 805.45 780.3 804.5 772.1 802.8 767.25 800.55 761.75 798 759.75 792.35 757.85 787.1 759.55 781 761 775.7 765.3 770.6 768.4 766.9 773.95 762.3 782.95 754.85 796.55 744.5 804.15 738.7 819.4 727.15 832.35 717.1 837.05 712.25 845.85 703.1 848.8 692.7 854.4 672.95 847.55 650.5 840.9 628.85 825.7 614.25 817.8 606.65 807.4 602.05 798.55 598.05 786.25 595.65 773.5 593.2 764.2 588.8 753.45 583.8 745.45 575.5 736.6 566.35 732.25 555.9 726.95 543.15 730.75 532.95 733.35 526.1 740.05 524.95 746.05 523.9 752.7 527.55 756.3 529.55 764.05 535.8 771 541.4 775.7 543.5 777.4 544.25 779.05 543.2 780.75 542.1 780.05 540.2 777.65 533.55 777.1 530.95 776 525.3 777.85 521.5 779.8 517.6 783.55 516.65 787.6 515.65 789.85 519.5 791.55 522.45 793 529.95 794.35 537 796.75 540.35 797.7 541.65 799.2 541.7 800.75 541.75 801.6 540.35 808.15 529.3 819.8 522.55 832.5 515.2 844.4 517.4 847.4 517.95 849.65 519.15 852.55 520.65 853.45 522.9 854.6 525.8 851.65 529.05 851 529.75 846.25 533.6
L 821.5 553.8
Q 819.5 555.45 821.4 557.35 823.3 559.25 825.35 557.65 836.95 548.35 842.8 543.75 845.2 541.85 850.5 538 855.15 534.45 857.4 531.35 Z"
				/>
			</g>

			<g id="Layer4_28_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 938.65 428.25
Q 936.9 431.4 936.8 431.55 934.4 435.4 931.05 438.9 925 445.15 917.05 448.75 908.3 452.8 898.25 452.6 890.2 452.4 879.15 449.25 869 446.35 860.65 445.35 850.85 444.2 841.7 445.45 831.85 446.8 823.45 450.35 818.3 452.5 816.8 453.4 812.65 455.75 811.95 458.35 811.85 458.55 812.05 458.55 813.75 458.95 815.65 458.15 816.75 457.7 819.15 456.5 823.85 454.6 828.5 453.1 839.25 449.7 850.05 449.4 858.6 449.15 871.2 452.35 887.75 456.55 892 457.15 900.2 458.3 908.5 456.55 916.6 454.85 923.9 450.55 931.1 446.2 936.35 440.15 939.7 436.3 941.25 433.8 943.8 429.8 944 426.45 944.1 425.25 942.85 424.75 941.65 424.25 940.8 425.1 939.75 426.25 938.65 428.25 Z"
				/>
			</g>

			<g id="Layer4_29_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 836.2 171.7
Q 840.3 181.6 846.3 190.4 854.23671875 202.2134765625 864.7 211.55 851.5859375 206.5236328125 843.55 203.45 821.35 194.95 805.85 189.6 785.5 182.5 768.9 179.35 748.65 175.45 730.7 176.6 729.4 176.7 728.7 177.6 728 178.5 728.3 179.75 731.1 192.55 745.85 203 751.15 206.75 759.2 211.05 763.75 213.45 772.25 217.75 775.1060546875 219.2537109375 777.85 220.6 775.17109375 220.26328125 772.65 219.95 735.25 215.25 704.65 217.7 703.05 217.8 702.4 219.3 701.7 220.75 702.9 221.9 718.6 236.85 737.8 245.95 757.2 255.15 778.5 257.55 779.65 257.65 779.85 256.35 780 255.05 778.85 254.8 758.35 251.15 739.9 241.95 723.74921875 233.89609375 710.5 222.25 738.2181640625 220.875390625 771.9 225.3 781.963671875 226.638671875 797.55 229.35 801.1173828125 230.72421875 804.45 231.8 822.55 237.55 839.05 237.1 841.3 237.05 841.7 234.65 842.1 232.25 839.75 231.75 815.2296875 226.6888671875 796.5 223.5 787.138671875 219.80234375 776.35 214.2 759.1 205.25 750.5 199.65 743.6 195.15 739.6 190.7 735.673046875 186.349609375 733.95 181.4 750.4841796875 180.8177734375 769.1 184.55 785.3 187.8 806.25 195.1 821.4 200.45 843.1 208.75 855.35 213.45 879.75 222.85 881.8 223.65 882.65 221.45 883.5 219.3 881.7 218.15 861.7 205.25 849.45 185.8 836.4 165.05 842.05 162.9 843.8 162.3 846.75 163.3 848.4 163.85 851.15 165.25 852.95 166.25 861.95 171.8 901.5 196.25 943.2 233.2 945.15 234.9 947.05 233 948.9 231.15 947.05 229.35 914.05 198.15 870.9 170.7 864.75 166.65 861.6 164.7 856.1 161.2 851.9 159.3 848.45 157.7 845.25 157.3 841.35 156.8 838.55 158.35 835.05 160.35 834.75 164.4 834.5 167.6 836.2 171.7 Z"
				/>
			</g>

			<g id="Layer4_30_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 685.55 316.9
Q 674.15 322.85 667.7 330 655.05 344.05 658.05 364.25 660.9 383.25 675.25 396.1 691.15 410.3 715.65 414.1 734.2 417 760.55 414.1 776.1 412.4 784.8 410.8 798.1 408.3 808.25 404.15 819.2 399.6 827.65 393.1 831.1 390.45 837.8 385.05 843.8 380.85 849.4 379.55 850.8 379.2 850.6 377.6 850.4 375.95 848.95 376.15 841.7 377.1 833.15 383.35 823.95 390.85 818.95 393.8 810.9 398.55 800.3 401.7 791.85 404.2 780.45 406.05 753.9 410.35 737.1 410.35 712.45 410.3 694.8 402.05 685.7 397.8 678.9 391.65 671.35 384.85 667.45 376.5 663.4 367.85 663.3 358.25 663.25 348.2 667.65 340.15 672.15 331.95 682.5 325.55 688.3 322 701.55 316.25 721.85 307.45 742.65 303.85 767.95 299.55 791.45 304 801.05 305.85 813.25 310.5 820.3 313.25 834.3 319.1 835.15 319.45 835.6 318.55 836.05 317.7 835.45 317.1 828.35 310.2 817.65 305.55 809 301.75 797.2 299.1 775.55 294.2 752.65 296.5 729.2 298.85 706 308 692.25 313.4 685.55 316.9 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.218994140625, 0, 0, 0.218994140625, -32.55,-26.95) ">
			<use xlinkHref="#Layer6_0_FILL" />
		</g>

		<g transform="matrix( 0.218994140625, 0, 0, 0.218994140625, -32.55,-26.95) ">
			<use xlinkHref="#Layer5_0_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_0_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_1_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_2_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_3_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_4_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_5_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_6_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_7_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_8_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_9_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_10_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_11_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_12_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_13_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_14_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_15_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_16_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_17_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_18_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_19_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_20_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_21_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_22_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_23_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_24_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_25_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_26_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_27_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_28_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_29_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer4_30_MEMBER_100_FILL" />
		</g>
	</svg>
);
