import React from "react";

export const homePage2 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="500px"
		height="356px"
		viewBox="0 0 500 356"
		id="frame-2"
	>
		<defs>
			<linearGradient
				id="Gradient_1"
				gradientUnits="userSpaceOnUse"
				x1="942.95"
				y1="558.95"
				x2="1200.95"
				y2="558.95"
				spreadMethod="pad"
			>
				<stop offset="15.686274509803921%" stopColor="#0C0C0C" />

				<stop offset="31.372549019607842%" stopColor="#0C0F0E" />

				<stop offset="42.745098039215684%" stopColor="#0C1816" />

				<stop offset="52.94117647058823%" stopColor="#0D2824" />

				<stop offset="61.96078431372549%" stopColor="#0E3F37" />

				<stop offset="70.98039215686275%" stopColor="#125C50" />

				<stop offset="79.2156862745098%" stopColor="#17806E" />

				<stop offset="87.45098039215686%" stopColor="#1EAA92" />

				<stop offset="95.29411764705883%" stopColor="#27DABB" />

				<stop offset="100%" stopColor="#2DFCD8" />
			</linearGradient>

			<g id="Layer1_0_FILL">
				<path
					fill="url(#Gradient_1)"
					stroke="none"
					d="
M 942.95 441.2
L 942.95 676.75 1201 604.95 1201 513 942.95 441.2 Z"
				/>
			</g>

			<g id="Layer2_0_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 953.3 406.6
Q 958.6 404.15 964.55 403.15 970.25 402.25 976 402.85 978.25 403.1 981.7 403.9 985.85 405 987.3 406.15 988.8 407.35 988.65 408.7 988.55 409.85 986.65 410.65 985.3 411.25 982.85 412.15 980.15 413.2 979.05 413.65 978 414.15 978.5 415.35 979 416.55 980.1 416.15 983.95 414.8 987.85 413.1 991.4 411.55 991.4 408.55 991.35 405.25 987.85 403.3 985.75 402.1 981.45 401.1 974.9 399.55 968 400.1 961.15 400.7 954.85 403.25 951.05 404.8 948.1 406.75 946.6 407.75 945.25 408.85 944.05 409.85 943.9 410 943.2 410.75 942.95 411.5 942.9 411.8 943.1 412 943.3 412.2 943.6 412.1 944.4 412 945.9 410.9 946.85 410.2 948.3 409.35 950.85 407.75 953.3 406.6 Z"
				/>
			</g>

			<g id="Layer2_1_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 957.05 419.15
Q 957.3 419.75 957.9 419.65 962.55 418.45 965 418 968.9 417.25 972.2 417.15 979.35 416.95 986.5 418.85 990.2 419.85 993.4 421.3 997.7 423.15 999.45 424.85 1000.55 425.9 1001.3 427.6 1001.8 428.7 1002.3 430.75 1002.7 432.3 1002.7 432.6 1002.8 433.6 1002.2 434.3 1001.2 435.35 999.35 436.1 995.9 437.5 991.75 436.65 989.45 436.15 987.6 435.4 986.2 434.8 985.5 434.5 984.25 433.95 983.35 433.8 982.65 433.7 982.25 434.3 981.85 434.95 982.4 435.5 983.4 436.45 986.1 437.55 988.6 438.55 990.4 438.95 994.7 439.95 998.35 439.05 1000.25 438.65 1001.9 437.7 1003.75 436.65 1004.7 435.2 1005.55 433.95 1005.05 431.45 1004.45 428.85 1004 427.6 1002.6 423.75 999.4 421.6 996.7 419.8 992 418.05 984.25 415.1 975.55 415 964.5 414.8 957.35 418.3 956.8 418.55 957.05 419.15 Z"
				/>
			</g>

			<g id="Layer2_2_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 954.55 391.15
Q 950.3 387.75 942.1 389.05 936.15 390.1 928.55 393.25 925.75 394.45 922.35 396.55 917.4 399.7 918.85 400.9 919.05 401 919.05 400.85 919 400.5 920.15 400.05 920.7 399.8 921.5 399.55
L 924.35 398.25
Q 926.75 397.1 929.75 395.95 935.45 393.6 941 392.35 947.8 390.8 951.7 392.9 953.3 393.75 953.5 394.55 953.65 395.1 953.1 395.75 952.45 396.45 952.2 396.7 950.1 398.9 947.95 400.6 947 401.4 945.4 402.5 943.25 403.95 942.8 404.3 939.4 406.65 939.55 408.75 939.6 409.4 940.15 409.75 940.75 410.15 941.3 409.75 942.1 409.2 943.25 407.75 943.7 407.3 944.45 406.75 944.9 406.5 945.7 405.9 948.6 404.05 951.4 401.6 952.2 400.9 953.95 399.3 955.4 397.9 956.05 396.65 956.8 395.2 956.35 393.65 955.9 392.2 954.55 391.15 Z"
				/>
			</g>

			<g id="Layer2_3_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 887.35 383.35
Q 887.7 381.7 889.05 381.7 890.2 381.7 891.35 382.85 894.55 386.05 897.8 391.65 899.15 394 900.6 397.2
L 902.3 401.5
Q 902.65 402.2 903.2 402.45 903.95 402.8 904.35 402.15 904.7 401.65 904.6 400.9 904.55 400.6 904.25 399.6 904.05 398.8 903.1 396.35 902.1 393.85 900.25 390.5 898.35 387.3 896.7 385.15 894.25 381.9 892.4 380.45 891.2 379.5 889.8 379.25 888.25 378.95 887 379.75 885.05 380.95 884.95 384.3 884.95 389.55 888.9 396.15 889.95 397.85 893.2 402.5 895.95 406.4 897.3 408.95 897.9 410 898.95 409.35 900 408.75 899.45 407.65 898.2 405.15 893.2 398.35 889 392.7 887.8 388.55 886.95 385.35 887.35 383.35 Z"
				/>
			</g>

			<g id="Layer2_4_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 870.8 458.45
L 871.8 460.05
Q 872.55 461.35 873.1 461.7 873.45 462 873.8 461.65 874.15 461.4 874.05 460.95 874 460.55 873.7 460.05 873.35 459.5 873.2 459.2
L 872.15 457.5
Q 870.4 454.8 869.9 454.2 869.75 454 869.45 454 869.2 454 869 454.15 868.6 454.5 868.85 455 869.3 456 870.8 458.45 Z"
				/>
			</g>

			<g id="Layer2_5_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 897.3 442.85
Q 897.15 442.35 896.7 442.7 895.9 443.35 895.15 444.9 894.8 445.55 894.05 447.5 892.7 451.2 894.05 452.85 894.55 453.5 895.5 453.15 896.4 452.8 896.3 451.95 896.05 451.05 896 450.75
L 896 449.75 896.5 447.75
Q 897.1 445.9 897.2 445.35 897.55 443.95 897.3 442.85 Z"
				/>
			</g>

			<g id="Layer2_6_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 911.8 455.6
Q 911.5 456.5 911.65 457.45 911.75 458.25 912.5 459.05 912.93359375 459.48359375 913.45 459.7 913.78046875 461.007421875 915.2 461.35 916.55 461.55 917.7 460.6 919.25 459.35 919.3 457.2 919.4 455 917.85 453.8 916.05 452.55 914.3 453.1 912.45 453.65 911.8 455.6 Z"
				/>
			</g>

			<g id="Layer2_7_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 924.1 466.85
Q 923.5 466.95 923.35 467.45 922.95 468.85 922 470.2 921.05 471.5 920.1 472.25 918.05 473.65 914.6 473.3 911.6 473 909.1 472.05 906.15 470.85 904.5 468.95 903.6 467.9 902.6 468.9 901.6 469.9 902.45 470.95 904.4 473.2 908.25 474.65 912.65 476.25 915.95 476.2 919.9 476.1 922.3 473.85 923.35 472.85 924.5 470.65 925.55 468.55 924.95 467.25 924.7 466.75 924.1 466.85 Z"
				/>
			</g>

			<g id="Layer2_8_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 963.05 507
Q 962.4 507 962.1 507.4 961.6 508 961.55 508.6
L 961.45 517.6
Q 961.45 518.392578125 961.4 519.75 961.34765625 519.909375 961.3 520.1 961.05 521.45 961.9 522.25 962.65 523 963.65 522.6 964.6 522.15 964.65 521.1
L 964.55 508.6
Q 964.5 508 964 507.4 963.65 507 963.05 507 Z"
				/>
			</g>

			<g id="Layer2_9_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 953.45 513.5
Q 953.05 513.1 952.4 513.1 951.8 513.15 951.4 513.55 950.95 513.95 950.95 514.55 951.0322265625 515.7013671875 950.95 516.65
L 950.85 517 950.75 517.7 950.8 517.3
Q 950.7 517.8 950.75 518.1
L 951 518.8
Q 951.25 519.4 952.1 519.5 952.85 519.6 953.35 519.1 953.8 518.55 953.85 518.15
L 953.95 517.35 953.9 516.9 953.95 516.4
Q 953.95 515.15 953.85 514.55 953.85 513.9 953.45 513.5 Z"
				/>
			</g>

			<g id="Layer2_10_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 941.05 512.7
Q 940.7 512.85 940.45 513.1 940.25 513.25 940.05 513.75 940 513.85 939.6 515.05
L 939.25 516.2
Q 939.065625 516.8146484375 938.95 517.25 938.9779296875 517.232421875 939 517.2
L 938.95 517.25 938.95 517.3 938.95 517.35 938.9 517.45 938.95 517.3 938.95 517.25
Q 938.75 517.60625 938.75 518 938.75 518.75 939.35 519.2 939.95 519.65 940.7 519.45 941.7 519.2 941.95 518.1 942.05 517.7 942.05 516.8
L 942.15 514.95
Q 942.25 513.4 941.65 512.85 941.3 512.65 941.05 512.7 Z"
				/>
			</g>

			<g id="Layer2_11_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 928.4 515.45
L 928.45 515.1 928.4 515.45 928.4 515.45
M 930.6 514.25
Q 930.2 514 929.65 514.05 929.2 514.05 928.85 514.4 928.45 514.8 928.45 515.4
L 928.4 515.45 927.95 518.3
Q 927.75 519.1 927.85 519.5 928 520.05 928.5 520.5 929.2 521.05 930.05 520.65 930.9 520.3 931.1 519.4 931.3 518.35 931.4 517.2 931.4 515.75 931.2 515.05 931.05 514.55 930.6 514.25 Z"
				/>
			</g>

			<g id="Layer2_12_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 962.65 538.25
L 962.65 538.2
Q 962.65 537.85 962.8 538.6
L 962.65 538.25 962.65 543.95
Q 962.65 546.1 962.7 546.35 962.95 547.4 964.15 547.4 965.3 547.35 965.55 546.35 965.65 545.95 965.65 543.8
L 965.65 539.15
Q 965.65 538.05 965.6 537.7 965.4 536.85 964.75 536.5 963.85 536.05 963.15 536.65 962.35 537.2 962.6 538.15
L 962.65 538.25 Z"
				/>
			</g>

			<g id="Layer2_13_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 954.7 541.45
Q 953.845703125 541.578125 953.65 542.4
L 953.45 543.2
Q 953.3412109375 544.34453125 952.75 545.8 952.45 546.5240234375 952.45 546.85 952.55 547.7 953.15 548.05 953.9 548.55 954.55 548.05 954.9 547.85 955.2 547.45
L 955.65 546.35
Q 955.85 545.85 955.95 545.2 956.15 544.3 956.15 543.75 956.25 542.95 956.05 542.35 955.75 541.35 954.7 541.45 Z"
				/>
			</g>

			<g id="Layer2_14_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 946.75 538.75
Q 946.2 538.7 945.7 539.1 945.35 539.4 945.2 539.95 945.1 540.3 945 540.95
L 944.25 544.05
Q 944.15 544.4 943.95 545.1 943.75 545.7 943.9 546.15 944.1 546.9 944.9 547.15 945.6 547.35 946.3 546.8 946.6 546.55 946.85 545.9 947.05 545.2 947.15 544.85
L 947.8 541.7
Q 947.95 540.55 947.95 540.5 948 539.75 947.7 539.35 947.35 538.85 946.75 538.75 Z"
				/>
			</g>

			<g id="Layer2_15_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 915.55 551.45
Q 917.3 552.95 920.75 555.95 926.25 560.2 932.6 563.65 939.6 567.4 945.45 569.25 953.75 571.85 958.75 568.75 961.75 566.9 962.95 563.9 963.6 562.3 963.45 560.75 963.2 558.8 961.45 558.85 960.5 558.9 960.2 559.85 959.940234375 560.5919921875 960.4 561
L 960.35 561.45 960.05 562.6
Q 959.7 563.55 958.8 564.45 957.25 566.15 954.65 566.75 952 567.4 948.5 566.55 946.55 566.05 942.6 564.5 931.4 560 921.75 552.15 918.35 549.15 916.55 547.75 913.5 545.35 910.75 544.3 909.1 543.7 908.65 545.5 908.15 547.25 909.8 547.85 912.4 548.85 915.55 551.45 Z"
				/>
			</g>

			<g id="Layer2_16_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 927.1 558.8
L 926.95 558.8
Q 926.65 558.75 926.35 559 926.1 559.2 926.05 559.45
L 926 559.65
Q 926 560.1 925.35 560.65 924.6 561.25 924.3 561.45
L 921.5 563.5
Q 918.65 565.55 915.65 567.25 912.1 569.35 909.65 569.7 906.6 570.05 906.55 570.05 904.85 570.35 903.6 571 903 571.35 902.9 572.1 902.8 572.85 903.3 573.35 906.1 575.85 907.95 578.35
L 908.85 579.65
Q 908.8978515625 579.6978515625 909.2 580.35 909.0546875 580.35 908.95 580.35 907.75 580.35 907.1 580.7 906.5 581 906.5 581.7 906.5 582.4 907.1 582.7 907.9 583.25 909.35 583.2 910.85 583.2 911.65 582.65 913.25 581.45 911.75 578.85 909.7150390625 575.4205078125 907.15 572.85 907.6994140625 572.7275390625 908.35 572.65 911 572.3 911.75 572.1 914.2 571.4 918.45 568.65 921.25 566.85 924.5 564.35 925.25 563.8 927.2 562.25 928.65 560.75 928.05 559.35 927.85 558.9 927.1 558.8 Z"
				/>
			</g>

			<g id="Layer2_17_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 870.65 570.75
Q 878.95 573 886.25 576.5 890.75 578.75 900.4 584.85 900.9 585.2 901.25 584.7 901.6 584.2 901.2 583.8 896.45 579.05 888.85 575 882.3 571.55 874.65 569.1 860.25 564.55 842.35 563.9 827.6 563.4 807.3 565.4 797.55 566.4 791.05 568.1 782.4 570.4 776.05 574.75 768.2 580.2 764.35 587.25 759.7 595.75 762.8 603.75 763.3 605.05 764.75 604.7 766.15 604.3 765.65 602.95 762.95 596.1 766.8 588.95 769.95 583 776.65 578.35 783.1 573.8 790.25 571.6 796.4 569.75 805.9 568.75 825.55 566.6 838.45 566.7 856.3 566.8 870.65 570.75 Z"
				/>
			</g>

			<g id="Layer2_18_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 828.8 576.7
Q 837.8 575.3 846.9 574.75 856.6 574.2 863.05 576.85 863.55 577.05 863.8 576.5 864 575.95 863.55 575.65 860.65 573.75 856.6 573.05 853.5 572.5 849.05 572.65 841.8 572.8 832.5 574.05 814.25 576.45 799.15 581.95 792.45 584.35 784.85 589.45 782.2 591.25 778.2 593.75 773.75 596.5 771.5 597.9 763.8 602.65 760.85 607.8 759.1 610.8 758.6 614 758.05 617.5 759.2 620.5 761.35 625.95 769.35 629.6 772.85 631.15 777.9 633.1 780.75 634.25 786.5 636.4 787.5 636.8 787.8 635.7 788.1 634.6 787.1 634.2 777.15 630.5 772.2 628.5 768.8 627.15 766.7 625.85 763.75 624.05 762.25 621.7 760.45 619.05 760.6 615.65 760.7 612.6 762.3 609.55 765 604.5 772.4 599.85 776.45 597.3 784.6 592.2 792.45 587 798.25 584.7 811.1 579.6 828.8 576.7 Z"
				/>
			</g>

			<g id="Layer2_19_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 817.2 590.15
Q 817.25 590.85 817.95 590.8 819.65 590.7 847.3 588.65 865 587.35 876.75 587.25 908.45 586.95 935.1 592.4 944.75 594.4 964 598.8 973.7 600.9 993.3 604.5 1007.15 607 1007.9 607.1 1016.2 608.5 1022.6 608.95 1033.4 609.7 1050.5 607.2 1062.4 605.4 1064.35 605.15 1072.2 604.1 1078.25 604.05 1094.6 603.95 1103.15 609.8 1105.55 611.45 1107.4 613.85 1108.25 614.95 1109.55 613.95 1110.85 612.95 1110 611.8 1103.65 603.4 1088.45 601.35 1082.1 600.45 1074.5 600.85 1068.8 601.15 1060.55 602.3 1051.2 603.75 1046.5 604.45 1038.3 605.6 1032.35 605.85 1025.5 606.1 1017.3 605.2 1011.45 604.5 1002.35 602.85 982.55 599.35 972.7 597.4 967.55 596.4 943.4 591.05 927.15 587.45 913.95 586 899.6 584.4 884.35 584.4 859.45 584.35 824.7 588.2 820.05 588.95 817.75 589.3 817.1 589.4 817.2 590.15 Z"
				/>
			</g>

			<g id="Layer2_20_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1116.6 618.1
Q 1115.55 617.65 1115 617.4 1113.95 617.05 1113.3 617.1 1113.1 617.1 1112.75 617.2 1112.35 617.4 1112.4 617.6 1112.5 618.3 1113.5 618.85 1114.5 619.35 1116.6 620.35 1118.956640625 621.5283203125 1121.1 623.1 1119.292578125 626.91328125 1115.85 631.25 1115.3 632 1108.95 639.45 1104.7 644.45 1102.45 647.85 1100.4822265625 650.75625 1099.15 653.6 1098.3880859375 651.9560546875 1097.4 649.8 1096.45 647.4 1095.95 646.2 1095 644.05 1094 642.85 1093.45 642.15 1092.5 642.55 1091.55 642.95 1091.6 643.85 1091.75 645.5 1092.65 647.7 1093.2 648.95 1094.35 651.3 1096.2 655.25 1097.95 658.6 1098.45 659.55 1099.55 659.4 1100.7 659.2 1101 658.2 1102.3 653.7 1105.45 648.9 1107.6 645.7 1111.75 640.7 1116.1 635.5 1118.25 632.45 1121.55 627.75 1123.45 623.3 1123.85 622.45 1123.05 621.8 1119.75 619.4 1116.6 618.1 Z"
				/>
			</g>

			<g id="Layer2_21_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1118.2 629.15
Q 1118.05 629.65 1118.55 629.85 1133.7 634.85 1144.05 647.5 1148.95 653.4 1152.65 660.9 1154.2 664.05 1154.5 664.7 1155.4 667.05 1155.1 668.75 1154.75 670.6 1152.95 671.15 1151.5 671.6 1149.6 671.1 1146.2 670.2 1142.35 666.5 1140.65 664.85 1139.2 662.9 1137.6 660.15 1136.65 658.9 1136 658.1 1135.1 658.7 1134.2 659.3 1134.4 660.2 1135.25 663.7 1139.7 668.05 1143.4 671.6 1147.25 673.1 1151.8 674.85 1154.8 673.1 1156.7 672.05 1157.3 669.85 1157.85 667.95 1157.3 665.7 1156.8 663.8 1155.65 661.45 1155.1 660.3 1153.5 657.35 1151.2 653.25 1148.85 650 1143.35 642.25 1136.65 637.05 1127.95 630.3 1118.85 628.75 1118.3 628.7 1118.2 629.15 Z"
				/>
			</g>

			<g id="Layer2_22_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1131.25 667.05
Q 1131.25 666 1130.4 665.6 1129.65 665.2 1129.1 665.4 1127.95 665.85 1127.8 667.05 1127.5 670.1 1128.35 675 1129.05 679.6 1130.7 681.75 1131.8 683.15 1133.4 683.75 1135.15 684.45 1136.75 683.95 1139.85 683 1141.1 679.15 1141.7 677.05 1141.55 674.9 1141.45 671.95 1140.8 670.7 1140.6 670.3 1140.1 670.4 1139.6 670.45 1139.5 670.9 1139.25 672.15 1139.15 674.75 1138.7 677.35 1138.35 678.4 1137.85 679.85 1136.8 680.7 1135.55 681.65 1134.25 680.85 1133 680.1 1132.4 678.25 1132.15 677.45 1131.7 675 1131.45 673.35 1131.35 671.05 1131.3 668.4 1131.25 667.05 Z"
				/>
			</g>

			<g id="Layer2_23_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1113.15 668.1
Q 1112.35 667.5 1111.35 667.95 1110.9 668.15 1110.35 668.65
L 1107.85 671.05
Q 1105.95 673 1105.15 674.85 1104.3 676.95 1104.95 678.95 1105.6 681.05 1107.5 682.15 1110.9 684.15 1115.5 682.1 1116.75 681.55 1119.45 679.8 1122.45 677.75 1123.55 676.75 1124.15 676.2 1123.65 675.35 1123.2 674.5 1122.4 674.8 1120.9 675.4 1118.75 676.7 1115.9 678.45 1115.2 678.8 1111.85 680.55 1109.7 679.55 1108.45 679 1108.25 677.85 1108.05 676.75 1108.75 675.5 1109.35 674.45 1111 673 1111.5 672.55 1112.6 671.55 1113.55 670.6 1113.75 669.8 1114 668.7 1113.15 668.1 Z"
				/>
			</g>

			<g id="Layer2_24_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 964.3 596.85
Q 964.25 597.15 964.55 597.25 967.45 598.45 973.65 599.25 977.7 599.85 982.85 600.1 992.1 600.6 1001.65 599.85 1019.25 598.5 1038.45 592.6 1049.8 589.15 1056.95 588.8 1065.6 588.45 1075.75 590.6 1093.5 594.4 1108.15 602.3 1116.9 607.05 1123.8 612.85 1126.55 615.2 1127.1 615.75 1128.9 617.6 1129.5 619.35 1130.15 621.2 1129.05 622.45 1128.6 623 1127.95 623.3 1127.15 623.65 1126.65 623.25 1125.5 622.35 1124.4 623.45 1123.3 624.5 1124.45 625.4 1125.9 626.55 1127.65 626.45 1129.35 626.35 1130.8 625.1 1132.2 623.85 1132.65 622.05 1133.05 620.35 1132.5 618.55 1131.95 616.75 1130.45 614.9 1129.4 613.65 1127.45 611.9 1124.2 609.05 1120.4 606.3 1106.8 596.45 1088.15 590.65 1077.55 587.4 1068.8 586.3 1058.15 585 1049.05 586.65 1045.15 587.4 1039.25 589.2 1031 591.7 1029.5 592.1 1020 594.65 1009.7 596.05 999.15 597.55 987.1 597.45 980.7 597.35 976.05 597.05 972.25 596.75 970.35 596.6 967 596.35 964.65 596.55 964.3 596.6 964.3 596.85 Z"
				/>
			</g>

			<g id="Layer2_25_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1132.1 622.95
Q 1131.95 623.2 1132.15 623.45 1133.8 625.5 1136.65 628.2 1138.25 629.7 1141.5 632.65 1147 637.9 1150.2 641.8 1154.55 647 1156.95 652.6 1158.5 656.2 1159.1 659.85 1159.45 661.6 1159.35 662 1159.2 662.7 1158.65 662.75 1158.35 662.8 1157.5 662.6 1155.75 662.2 1154.65 661.1 1154.15 660.65 1153.7 661.15 1153.2 661.6 1153.65 662.05 1155.6 664 1157.9 663.85 1159.05 663.7 1159.55 663.4 1160.2 662.95 1160.05 661.9 1159.4 656.5 1156.5 650.05 1152.25 640.3 1142.9 631.25 1136.65 625.2 1132.6 622.85 1132.3 622.7 1132.1 622.95 Z"
				/>
			</g>

			<g id="Layer2_26_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 962.35 456
Q 962.15 456.75 962.9 456.95 968.15 458.7 978.6 462.15 987.5 465.4 993.55 469.3 999.35 473.05 1006.45 479.3 1014.1 486.05 1017.4 490.6 1022.6 497.75 1023.05 504.8 1023.3 508.85 1022.05 512.1 1020.55 515.8 1017.4 517.65 1014.05 519.6 1009.7 520.05 1005.65 520.5 1001.65 519.55 998.1 518.75 993.75 516.7 992.75 516.25 986.2 512.85 975.4 507.25 970.75 504.25 962.4 498.9 957.45 492.75 956.65 491.7 955.45 492.65 954.25 493.6 954.95 494.7 958.6 500.3 966.7 505.55 972.15 509.2 981.95 514.4 988.4 517.8 990.1 518.6 994.8 520.9 998.6 522.05 1002.85 523.4 1007.25 523.35 1011.85 523.3 1015.9 521.8 1023.95 518.85 1025.7 510.65 1027.35 502.95 1023.3 494.65 1020.3 488.3 1013.05 481.4 1006.1 474.8 999.9 470.45 985.85 460.4 963.3 455.45 962.55 455.25 962.35 456 Z"
				/>
			</g>

			<g id="Layer2_27_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 795.8 616.65
Q 795.75 616.15 795.3 616.05 794.85 615.9 794.6 616.3 793.6 617.95 793.4 621.45 793.25 624.15 793.65 627.15 794.35 632.25 796.8 638.05 800.65 647.05 808.75 657.05 822.25 673.65 841.35 684 853.4 690.45 863.6 692.9 865.45 693.35 865.95 691.55 866.45 689.7 864.65 689.15 852.55 685.45 845.7 682 836.9 677.7 828.55 671.45 820.15 665.25 813.2 657.6 805.95 649.65 801.05 640.9 797.65 634.75 796.35 629.15 795.6 625.8 795.55 622.95 795.55 622.05 795.75 619.8 795.9 617.75 795.8 616.65 Z"
				/>
			</g>

			<g id="Layer2_28_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1166.55 683.25
Q 1166.118359375 682.9302734375 1165.55 683.25 1164.75 683.7 1165.15 684.6 1165.6 685.55 1167.55 688.15 1168.5 689.35 1170.55 691.7
L 1174.9 696.55
Q 1175.2 696.9 1175.85 697.6 1176.45 698.15 1177.05 698.25 1177.9 698.35 1178.6 697.95 1179.35 697.45 1179.3 696.55 1179.25 695.55 1178.65 694.9
L 1173.25 689.2
Q 1171.25 687.1 1170.15 686.15 1167.6 683.8 1166.55 683.2
L 1166.55 683.25 Z"
				/>
			</g>

			<g id="Layer2_29_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1104.4 687.15
Q 1104.15 686.45 1103.45 686.45 1102.3 686.35 1101 687.9 1100.4 688.6 1099.25 690.45 1097.6 693.05 1096.35 696.15 1093.75 702.4 1093.4 708.4 1093.35 709.1 1093.85 709.75 1094.35 710.3 1095.05 710.55 1095.55 710.75 1096.25 710.55 1097.7 710.25 1097.9 708.4 1098.55 702 1100.3 697.4 1100.95 695.5 1102.5 692.35
L 1103.85 689.75
Q 1104.35 688.75 1104.45 688.5 1104.65 687.75 1104.4 687.15 Z"
				/>
			</g>

			<g id="Layer2_30_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1110.75 710.05
Q 1121.55 711.4 1126.35 711.4 1131.15 711.45 1142 710.4
L 1157.8 708.8
Q 1160.8 708.5 1166.85 707.9 1172.05 707.35 1175.8 706.5 1176.3 706.4 1176.7 706 1177.15 705.6 1177.2 705.1 1177.35 704.35 1177.25 704.1
L 1177.2 704.05
Q 1176.75 702.6 1175.3 702.6 1170.75 702.45 1159.5 703.65
L 1143.45 705.25
Q 1131.75 706.4 1127.85 706.45 1121.95 706.55 1112.45 705.35 1105.45 704.5 1103.85 704.35 1097.85 703.75 1094.8 704.25 1093.95 704.45 1093.45 705.25 1093 706.05 1093.1 706.9 1093.4 708.7 1095.45 708.95 1096.8 709.1 1099.3 709.1 1101.9 709.15 1103.1 709.25 1105.55 709.45 1110.75 710.05 Z"
				/>
			</g>

			<g id="Layer2_31_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1096.6 667.6
Q 1097.7 668.1 1098 668.15 1098.4 668.3 1098.7 668.3 1099 668.35 1099.25 668.3
L 1099.55 668.2
Q 1099.65 668.2 1099.7 668.05 1099.8 667.95 1099.7 667.85
L 1099.55 667.65
Q 1099.5 667.4 1099.4 667.3
L 1098.75 666.9
Q 1098 666.5 1097.65 666.3
L 1095.35 665.1
Q 1094.95 664.8 1094.85 664.8 1094.3 664.5 1093.8 664.9 1093.4 665.25 1093.55 665.75 1093.7 666.2 1094.3 666.45
L 1096.6 667.6 Z"
				/>
			</g>

			<g id="Layer2_32_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1092.35 674.15
L 1091.9 673.65
Q 1091.5 673.25 1090.7 672.8
L 1089.65 672.1
Q 1088.55 671.45 1088 671.45 1087.35 671.45 1087 672.05 1086.6 672.65 1087 673.25 1087.45 673.75 1087.85 673.95
L 1089.85 674.75
Q 1090.65 675.05 1091.05 675.1
L 1091.75 675.2 1092.4 675.05
Q 1092.55 675.05 1092.6 674.9 1092.65 674.75 1092.55 674.65
L 1092.45 674.45 1092.35 674.15 Z"
				/>
			</g>

			<g id="Layer2_33_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1173.25 666.8
Q 1173.5 667.3 1174.15 667.3 1174.6 667.215625 1174.8 667.15 1174.9205078125 667.1048828125 1174.95 667.05
L 1175.15 666.95
Q 1175.3 666.9 1175.55 666.65
L 1175.65 666.6 1176.3 666.1 1179 663.3
Q 1180.1 662.3 1180.2 662.1 1180.85 661.4 1180.8 660.6 1180.75 659.95 1180.35 659.4 1179.95 658.8 1179.3 658.65 1178.35 658.4 1177.55 658.9
L 1177.15 659.2
Q 1176.75 659.45 1176.05 660.15 1175.45 660.8 1174.45 662.1 1173.95 662.8 1173.35 664.05 1173.2 664.4 1173.1 665.2
L 1173 665.7 1173.05 665.8
Q 1173.15 666.45 1173.05 666.05
L 1173.05 666.15
Q 1173.05 666.45 1173.25 666.8 Z"
				/>
			</g>

			<g id="Layer2_34_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1194.95 509.35
Q 1195.4 508.3 1195.55 507.15 1195.6 506.7 1195.15 506.45 1194.7 506.2 1194.3 506.45 1193.25 507.2 1191.55 509.7 1189.95 512.3 1189.15 514 1186.9 518.9 1185.55 523 1182.75 531.6 1181.75 542.4 1179.9 562.5 1182.85 582.85 1183.65 588.2 1184.9 594.05
L 1186.9 602.1
Q 1187.4 603.8 1188.4 604.9 1189.1 605.7 1190.3 605.4 1191.5 605.1 1191.65 604.05 1191.85 602.4 1191.65 601.55
L 1191 599.25
Q 1189.95 594.85 1189.8 594.2 1188.75 589.6 1187.95 584.65 1186.5 575.65 1186 565.2 1185.5 554.05 1185.95 545.8 1186.5 535.2 1188.6 526.6 1189.8 521.65 1191.75 516.8 1192.85 513.95 1193.85 511.8
L 1194.95 509.35 Z"
				/>
			</g>

			<g id="Layer2_35_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1201.7 497.4
Q 1201.55 497.6 1201.7 497.8 1202.45 499 1204.3 500.3 1205.35 501 1207.35 502.3 1209.5 503.85 1213.7 507.15 1217.8 510.35 1219.95 511.85 1221.25 512.85 1223.15 514.05 1224.7 515 1225 515.1 1226.1 515.6 1227.05 515.4 1227.65 515.25 1228 514.65 1228.35 514 1228.15 513.4 1227.8 512.55 1226.95 511.9 1226.8 511.8 1225.25 510.9
L 1221.95 508.8
Q 1221.05 508.15 1215.45 504.15 1212.15 501.65 1209.05 499.75 1206.5 498.3 1205.7 497.95 1203.6 497 1202.05 497.15 1201.8 497.2 1201.7 497.4 Z"
				/>
			</g>

			<g id="Layer2_36_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1220.9 513.25
Q 1220.6 513.25 1220.55 513.55 1220.05 515.2 1220.05 518.6
L 1220.1 524.05
Q 1220.1 531.7 1219.9 534.45 1219.5 541.05 1220.2 555.5 1221.15 574.65 1223.95 597.6 1224.15 599.2 1224.85 603.5 1225.45 607.15 1225.65 609.35 1225.85 611.45 1226.05 615.25 1226.25 619.35 1226.4 621.15 1226.5 622.05 1227.45 622.25 1228.45 622.45 1228.65 621.45 1229.25 617.9 1228.75 610.85 1228.1 604 1227.8 600.55 1227.6 598.35 1226.55 590 1225.75 583.5 1225.5 579.45 1224.6 565.3 1224.25 558.2 1224.05 551.2 1223.9 547.7 1223.7 541.45 1223.25 537.15 1222.35 528.45 1222.25 525.3
L 1222 519.35
Q 1221.9 515.25 1221.3 513.55 1221.2 513.3 1220.9 513.25 Z"
				/>
			</g>

			<g id="Layer2_37_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1225.1 623.65
Q 1221.95 623.15 1217.55 624.55 1212.55 626.4 1210.25 627.2 1205.3 628.95 1202.8 629.75
L 1199.1 631.05
Q 1196.25 632.05 1195.4 632.7 1194.65 633.25 1194.9 634.1 1195.1 634.95 1196.05 635.05 1197 635.15 1199.85 634.6 1201.55 634.35 1203.65 633.75 1207.75 632.75 1211 631.75 1213.35 631.05 1218.05 629.55 1219.85 629 1221.55 628.65 1222.7 628.4 1225.1 628.15 1226.75 627.9 1226.75 625.9 1226.75 623.95 1225.1 623.65 Z"
				/>
			</g>

			<g id="Layer2_38_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1226.1 547.95
Q 1226.8 550.75 1228.9 556.2
L 1231.45 564.15
Q 1233.75 571.9 1235.1 579.05 1237.8 592.7 1237.65 606.6 1237.5 618.6 1234.85 632.1 1232.65 643.15 1228.4 657 1225.95 664.85 1223.7 671.25 1222.85 673.7 1221.05 678.6 1219.45 682.95 1218.65 685.95 1218.15 687.75 1219.9 688.2 1221.6 688.65 1222.3 687 1223.4 684.35 1224.7 680.4 1226.1 675.9 1226.8 673.7 1229.6 665.1 1230.9 660.8 1235.35 646.35 1237.35 635.3 1239.9 621.45 1240.15 609.55 1240.45 595.95 1237.9 581.9 1236.5 574.1 1233.95 565.3 1232.55 560.6 1231.1 556.6 1229.7 552.6 1229.4 551.9 1228.4 549.25 1227.35 547.4 1227.05 546.9 1226.5 547.15 1226 547.4 1226.1 547.95 Z"
				/>
			</g>

			<g id="Layer2_39_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1197.6 497.5
Q 1197.4 498.7 1197.5 499.65
L 1197.95 502.6
Q 1198.35 505.35 1198.35 508.8 1198.4 513.1 1198.35 521.75 1198.65 539.75 1198.9 548.85
L 1199.6 576.2
Q 1200.05 592.45 1199.35 602.85 1198.5 614.7 1195.7 632.6 1195.45 634.5 1197 634.95 1197.7 635.15 1198.45 634.75 1199.25 634.35 1199.35 633.65 1200.85 624.7 1201.55 620.25 1202.75 612.55 1203.3 606.75 1204.45 595.25 1204.1 579.6 1203.7 555 1202.7 524.25 1202.4 514.25 1201.9 509.25 1201.55 505.25 1200.75 502.55 1200.25 500.65 1199.55 499.4 1198.9 498.2 1197.65 497.45
L 1197.6 497.5 Z"
				/>
			</g>

			<g id="Layer2_40_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 866.2 444.2
Q 865.05 444.45 864.25 445.4 863.75 446.05 863.05 447.45 861.55 450.3 861.25 452.7 860.45 458 862.9 463.45 864.9 468 870 473.95 870.8 474.9 871.7 474 872.6 473.1 871.8 472.15 867.05 466.7 865.1 462.2 862.35 456.05 864.2 450.6 864.6 449.4 865.55 447.6 865.95 446.9 866.4 446.6 866.85 446.3 867.65 446.3 870.85 446.3 873.1 448.25 875.6 450.35 877.5 452.25
L 879.75 454.55
Q 880.05 454.85 880.65 455.4 881.2 455.9 881.7 455.95 882.05 456.05 882.05 455.75 882.1 454.85 881.1 453.55 879.85 452.2 879.4 451.65 877.3 449.3 875.25 447.4 872.75 445.1 871.1 444.6 868.2 443.8 866.2 444.2 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_0_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_0_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_1_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_2_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_3_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_4_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_5_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_6_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_7_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_8_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_9_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_10_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_11_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_12_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_13_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_14_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_15_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_16_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_17_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_18_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_19_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_20_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_21_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_22_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_23_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_24_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_25_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_26_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_27_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_28_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_29_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_30_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_31_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_32_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_33_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_34_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 0.8308258056640625, 0, 0, 0.890716552734375, -547.7,-319.2) ">
			<use xlinkHref="#Layer2_35_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_36_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_37_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_38_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_39_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer2_40_MEMBER_1_FILL" />
		</g>
	</svg>
);
