import React from "react";

export const aboutPage4 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="329px"
		height="191px"
		viewBox="0 0 329 191"
		id="frame-4"
	>
		<defs>
			<linearGradient
				id="Gradient_2"
				gradientUnits="userSpaceOnUse"
				x1="172.44999999999993"
				y1="785.2"
				x2="1465.65"
				y2="231.59999999999997"
				spreadMethod="pad"
			>
				<stop offset="4.705882352941177%" stopColor="#2DFCD8" />

				<stop offset="5.882352941176471%" stopColor="#2BF0CE" />

				<stop offset="11.764705882352942%" stopColor="#21BBA1" />

				<stop offset="18.03921568627451%" stopColor="#198C79" />

				<stop offset="24.313725490196077%" stopColor="#136558" />

				<stop offset="30.58823529411765%" stopColor="#0F453C" />

				<stop offset="37.254901960784316%" stopColor="#0D2C27" />

				<stop offset="43.92156862745098%" stopColor="#0C1A18" />

				<stop offset="51.372549019607845%" stopColor="#0C0F0F" />

				<stop offset="59.6078431372549%" stopColor="#0C0C0C" />
			</linearGradient>

			<g id="Layer12_0_FILL">
				<path
					fill="url(#Gradient_2)"
					stroke="none"
					d="
M 1147.65 788.45
L 894.9 73.05 184.75 682.3 285.45 997.85 1147.65 788.45 Z"
				/>
			</g>

			<g id="Layer11_0_FILL">
				<path
					fill="#0C0C0C"
					stroke="none"
					d="
M 426.2 703.75
L 207.6 651.85 -17.35 777.05 -130.75 1054.65 118.55 1261.6 474.7 988.6 426.2 703.75 Z"
				/>
			</g>

			<g id="Layer10_0_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 708.7 316
Q 715.65 314.35 728.35 311.8 753.55 306.25 754.35 306.1 770.15 303 781.85 302.6 813.55 301.45 831.15 319.45 831.55 319.85 831.95 319.45 832.35 319.05 832 318.6 817.55 300.3 790.55 298.3 772.75 296.95 742.9 303.3 726.45 306.65 718.2 308.4 703.65 311.45 693.8 314.5 670.15 321.85 661.7 337 652.55 353.35 660.05 373.3 667 391.75 683.2 402.3 700.05 413.3 727.4 416.5 736.5 417.6 751.55 418.05 760.25 418.25 764.9 418.05 772.3 417.75 778.1 416.4 787.5 414.2 797.95 407.65 802.75 404.6 815.6 394.9 826.75 386.45 833.75 382.8 838.3 380.45 843.95 379.05 848.15 378 854.6 377.1 856.75 376.8 856.75 374.3 856.7 371.8 854.6 371.4 840.35 368.9 822.85 380.75 817.95 384.05 808.6 391.55 799.65 398.75 795.3 401.6 786.65 407.3 779.5 409.5 773.95 411.25 766.95 411.8 762.05 412.15 754.15 411.95 740.95 411.75 732.05 410.95 720 409.85 710.25 407.35 686.45 401.25 674.45 386.55 668.3 379.15 665.25 370.25 662 360.8 663 351.75 665.25 332.2 688.15 322.4 696.35 318.9 708.7 316 Z"
				/>
			</g>

			<g id="Layer10_1_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 863.25 326.2
Q 864.65 328.25 868.05 329.5 870 330.2 873.9 331.3 877.35 332.6 882 334.65 884.7 335.8 890 338.2 898.05 341.85 902.95 343 906.75 343.9 910.05 343.8 913.15 343.65 917 342.5 918.6 342 918.4 340.3 918.15 338.55 916.45 338.6 909.75 338.75 901.9 336.55 896.45 335.1 887.95 331.65 880.85 328.7 874.25 326.6 870.7 325.35 868.85 324.95 865.65 324.25 863.45 325.3 863.25 325.4 863.15 325.7 863.1 326 863.25 326.2 Z"
				/>
			</g>

			<g id="Layer10_2_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 769.1 255.15
Q 768.95 255.4 769.1 255.4
L 770.4 255.35
Q 770.5 255.35 770.5 255.3 770.5 255.2 770.4 255.2
L 769.1 255.15 Z"
				/>
			</g>

			<g id="Layer10_3_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 849.7 160.1
Q 848.5 160.65 847.95 162 841.5 177.95 850.8 195.6 854.787890625 203.1869140625 862.8 212.5 825.474609375 195.8705078125 803.15 188.5 761.45 174.8 725.8 174.75 723.7 174.75 723 176.65 722.3 178.55 723.6 180.05 741.5 200.9 771.7 216.15 780.16640625 220.416796875 792.4 225.35 745.6896484375 217.7556640625 701.6 215.75 699.65 215.65 698.75 217.7 697.9 219.75 699.4 221.05 716.2 235.4 735.7 244.45 756.25 254 777.1 256.35 777.9 256.45 778 255.65 778.05 254.8 777.35 254.6 755.95 248.95 738.25 240.05 723.4603515625 232.577734375 710.9 222.65 773.4056640625 227.56875 828.05 238.6 831.15 239.25 831.95 236.3 832.8 233.35 829.8 232.3 793.1 219.8 774.85 210.7 749.37421875 197.9837890625 732.85 181.05 764.7328125 182.2556640625 801.6 194.35 826.45 202.5 873.25 223.55 875.45 224.55 877.05 222.7 878.6 220.85 876.75 219 861.95 204.2 856.45 194.15 848.7697265625 180.1859375 852.3 167.3 881.4662109375 186.5390625 900.25 202.85 923.75 223.3 944.6 246.45 946.25 248.25 948.05 246.45 949.8 244.65 948.2 242.85 904.8 194.1 852.25 160.25 851 159.45 849.7 160.1 Z"
				/>
			</g>

			<g id="Layer10_4_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1068.15 237.05
Q 1068.2 235.3 1066.7 234.5 1056.3 228.6 1040.3 228.25 1030.15 228 1012.85 230.35 1004.7 231.4 1001.35 232.2 994.9 233.8 990.85 236.9 986.65 240.15 986.25 243.6 986.05 245.45 986.75 246.3 987.2 246.85 989.1 247.9 989.2 247.95 989.3 247.85 989.4 247.75 989.35 247.65 988.75 246.4 990.9 244.05 992.45 242.25 993.9 241.35 997.6 239 1002.95 237.9 1007.2 236.95 1012.9 236.3 1015.6 236 1022.95 235.4 1034.2 234.5 1043.25 235.15 1048.1 235.5 1054 237 1054.701953125 237.1619140625 1057.65 238 1050.1580078125 242.217578125 1041 246.55 1036 248.85 1017.55 257.05 1015.4 258 1016.45 260.35 1017.45 262.75 1019.65 262.05 1031.05 258.6 1043.85 252.3 1054.15 247.25 1066.7 239.65 1068.15 238.8 1068.15 237.05 Z"
				/>
			</g>

			<g id="Layer10_5_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1012.9 340.2
Q 1014.2 340.5 1015.9 339.45 1016.85 338.85 1018.6 337.55 1024 334.25 1030.05 333.9 1035.8 333.6 1041.45 336.1 1046.8 338.5 1050.95 342.85 1054.95 347.05 1056.4 352.2 1058.15 358.5 1054.2 362 1051.5 364.4 1044.3 365.45 1037.2 366.5 1034.45 369 1033.35 370.05 1034.15 371.3 1034.95 372.6 1036.35 372.25 1036.85 372.15 1044.1 370.7 1048.85 369.7 1051.75 368.8 1058.6 366.7 1061 361.1 1063.5 355.3 1061.2 348.4 1059.15 342.2 1054.25 337.45 1048.6 332 1041.35 329.5 1033.65 326.9 1026.4 328.45 1023 329.15 1020.05 330.7 1016.75 332.4 1014.65 334.8 1013.6 336.1 1012.9 337.5 1011.65 339.9 1012.9 340.2 Z"
				/>
			</g>

			<g id="Layer10_6_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1024.55 348.05
Q 1024.65 349 1025.5 349.15 1026.75 349.35 1029.2 349.65 1031.4 349.9 1032.8 350.4 1034 350.8 1036.2 352.05 1038.4 353.3 1039.6 353.7 1040.9 354.15 1041.8 352.95 1042.65 351.8 1041.95 350.65 1039.75 347.05 1034.3 345.9 1029.05 344.8 1025.2 346.75 1024.45 347.1 1024.55 348.05 Z"
				/>
			</g>

			<g id="Layer10_7_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 964.9 419.4
Q 962.75 411.45 957.95 406.95 953.45 402.7 947.45 401.8 940.85 400.8 936.55 404.75 936 405.3 936.45 406.05 936.85 406.85 937.65 406.6 943.15 404.65 948.1 405.85 953.35 407.1 956.85 411.6 960.15 415.8 961.25 422.25 961.5 423.75 961.6 427.75 961.65 431.25 962.25 433.2 962.45 433.95 963.35 434.1 964.2 434.25 964.55 433.5 967.15 427.8 964.9 419.4 Z"
				/>
			</g>

			<g id="Layer10_8_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 810.55 414.5
Q 810.65 414.6 810.75 414.45
L 810.95 414.1
Q 811.25 413.75 810.85 413.35
L 810.55 413.05
Q 809.95 412.6 809.35 412.75 806.7 413.6 805.3 418.25 804.55 420.85 803.85 425.45 803.15 428.8 801.4 436.1 800.1 442.55 800.45 446.8 800.6 448.45 802.3 449.05 804.05 449.65 804.85 448 806.4 444.75 807.3 439.6 808.1 433.7 808.55 430.9 809.55 424.55 809.7 423.25 810.45 414.5 810.55 414.5 Z"
				/>
			</g>

			<g id="Layer10_9_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 873.35 418.1
Q 873.45 418.05 873.3 417.9 872.9 418 870.35 418.35 868.75 418.6 867.5 419.35 865.55 420.55 863.6 422.9 861.75 425.35 860.95 427.7 859.95 430.75 860.95 433 861.15 433.6 861.85 433.55 862.5 433.5 862.8 433 863.5 431.8 864.5 428.8 865.45 425.95 866.45 424.55 867.7 422.8 869.75 420.95 870.35 420.45 871.45 419.35 872.45 418.4 873.35 418.1 Z"
				/>
			</g>

			<g id="Layer10_10_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 898.6 425.3
Q 898.25 424.85 897.75 424.95 896.8 424.75 896.3 424.85 895.95 424.95 894.75 425.75 893.2 427 892.4 428.8 891.45 430.85 891.6 432.65 891.75 434.7 893.25 436.1 893.95 436.75 894.8 436.4 895.65 436.05 895.7 435.1 895.75 433.45 895.8 432.6 895.9 431.1 896.2 430.15 896.6 428.95 897.3 428.05
L 897.85 427.05
Q 898.25 426.35 898.5 426.15 898.95 425.8 898.6 425.3 Z"
				/>
			</g>

			<g id="Layer10_11_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 814.3 473.1
Q 813.7 472.1 812.85 472.25 811 472.4 809.4 473.5 807.8 474.6 806.95 476.25 805.9 478.3 806.05 480.5 806.25 483.15 808.4 483.7 810.05 484.15 810.85 482.6 811.25 481.9 811.2 480.85 811.15 479.45 811.2 479.1 811.35 477.55 812.45 476.4 812.55 476.3 813.7 475.4 814 475.2 814.3 474.7 814.7 474.1 814.9 473.9 814.996875 473.803125 814.9 473.65 814.975 473.775 814.3 473.1 Z"
				/>
			</g>

			<g id="Layer10_12_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 842.35 468.6
Q 842.65 468.4404296875 842.65 468.15
L 842.45 467
Q 842.4 466.4 841.5 466.05
L 841.25 465.9
Q 840.6 465.5 839.7 465.6 838.75 465.7 838.3 466.3 836.55 468.6 836.55 472.5 836.55 475.8 837.75 478.95 838.7 481.35 841.2 480.7 843.75 480.05 843 477.5 842 474.05 842 471.65 842 471.4 842.3 469.3 842.361328125 468.94453125 842.35 468.6 Z"
				/>
			</g>

			<g id="Layer10_13_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 880.45 469.35
Q 880.7 469.2 880.6 469 879.75 466.75 877.65 467.25 875.65 467.7 875.05 469.95 874.75 471.1 874.9 473.8 875.05 476.25 874.4 477.65 873.75 479.05 871.95 480.7 869.9 482.6 869.3 483.55 868.85 484.25 869.2 485.05 869.55 485.8 870.3 486.05 872.9 486.75 875.4 484.8 877.45 483.2 878.75 480.4 880 477.55 879.8 474.85 879.75 474.3 879.55 473.4 879.3 472.3 879.25 471.95 879.15 471 879.25 470 879.4 468.5 880.05 469.3 880.2 469.5 880.45 469.35 Z"
				/>
			</g>

			<g id="Layer10_14_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 851.8 537.25
Q 856.9 530.6 854.6 523.85 852.35 517.3 844.55 514.3 830.6 508.95 816.6 517.7 807.96953125 523.105859375 803.75 530.95 798.10625 510.207421875 782.7 509.55 772.75 509.15 768.35 518.45 766 523.4392578125 765.4 530.6 744.3197265625 514.2884765625 730.95 526.8 722.75 534.5 723.1 546.4 723.45 557.1 730.25 566.75 743 584.9 771.25 596.65 776.9 599.05 795.55 605.8 810.75 611.3 819.3 615.45 831.25 621.3 838.5 630.2 846.2 639.75 848.1 652.25 849.8 663.45 847.2 675.55 844.75 686.95 838.9 697.45 833.1 707.95 823.15 718.4 815.55 726.35 803.65 736.15 800.3 738.9 782.3 753 774.1 759.4 771.95 761.25 766.25 766.1 762.65 770.55 752.9 782.6 755.45 793.4 757.9 803.25 771.7 811.4 783.65 818.4 798.35 819.85 812.4 821.25 826.25 817.35 826.4 817.3 826.35 817.15 826.35 817 826.15 817 813.45 819.6 800.55 817.15 788.1 814.8 776.5 808.05 769.7 804.05 766.2 800.6 760.95 795.4 760.7 789.6 760.5 783.8 764.65 777.65 767.6 773.3 773.3 768.2 785.05 757.75 810.15 738.6 831.8 720.9 842.25 704.3 853.25 686.85 855 666.95 856.95 644.9 846.1 629.15 840.25 620.7 830.25 614.4 822.5 609.5 810.55 605.05 795.35 599.7 787.75 596.95 774.35 592.1 765.45 587.6 751 580.3 742.05 571.95 730 560.65 728.7 548.1 728.05 541.8 730.95 536 734.1 529.8 739.8 527.7 746.3 525.35 754.05 529.45 758.6 531.85 766.05 538.4 767.4 539.65 769.1 539 770.85 538.35 770.85 536.45 770.75 527.05 773 521.7 776.35 513.65 784.6 515.5 792.5 517.25 796.4 526.15 798.5 531.05 800.35 542.4 800.7 544.4 803.05 544.4 805.45 544.4 805.75 542.4 807.4 531.4 816.95 524.25 826.5 517.15 837.55 518.6 844.9 519.6 847.95 523.35 852.65 529.25 843.85 537.15 833.9 546.2 819 551.8 816.4 552.8 817.15 555.6 817.9 558.4 820.5 557.4 842.75 549.1 851.8 537.25 Z"
				/>
			</g>

			<g id="Layer10_15_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 881.85 581.75
Q 880.1 578.85 877.2 580.2 863 586.9 850.4 595.5 843.85 600.05 839.3 603.85 835.9 606.45 834.25 607.95 831.35 610.65 830.85 613.15 830.75 613.85 831.35 613.85 833.75 613.8 836.75 611.75 838.4 610.6 841.5 608.2 848.35 603.45 854.05 599.95 866.15 592.45 880.6 586 883.55 584.7 881.85 581.75 Z"
				/>
			</g>

			<g id="Layer10_16_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 884.05 580.1
Q 883.1 581.1 884.05 582.2 889.1 587.8 899.25 598.75 907.9 608.45 912.8 616.6 924.7 636.25 925.4 660.65 925.5 662.75 927.7 662.75 929.9 662.75 929.8 660.65 929.15 637.55 916.45 616.05 905.85 597.95 886.15 580.1 885.05 579.15 884.05 580.1 Z"
				/>
			</g>

			<g id="Layer10_17_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1033.25 774.4
Q 1036.3 768.3 1044.25 759.1 1055.6 746.1 1070.5 736.95 1085.4 727.85 1102.15 723.65 1119.1 719.35 1143.55 720.05 1157.55 720.4 1185.3 722.25 1210.85 723.2 1229.05 723.4
L 1250.95 723.45
Q 1257.7 723.4 1258.6 723.35 1263 723.1 1266.15 722.05 1274 719.55 1275.7 713.8 1276.7 710.45 1275.15 706.85 1274.05 704.3 1271.25 700.8 1265.1 693.1 1257.4 687.85 1249.05 682.1 1240.2 680.2 1238.3 679.8 1237.4 681.7 1236.55 683.65 1238.4 684.55 1248.95 689.65 1255.05 694.05 1264 700.4 1268.1 707.9 1272.15 715.25 1257.2 716.65 1252.4 717.1 1245.55 716.9 1239.15 716.65 1238 716.65 1217.8 716.6 1192 715.8 1179.9 715.4 1146 714 1125 713.15 1108.2 716.1 1088.8 719.5 1072.55 728.3 1057 736.75 1044.9 748.9 1037.45 756.35 1033.15 762.2 1027.1 770.4 1025.15 777.85 1022.95 786.15 1026.45 793.55 1030.25 801.6 1038.6 802.8 1049.6916015625 804.333984375 1060.6 791.6 1061.001953125 796.110546875 1062.25 799.6 1065.7 809.3 1075.2 809.45 1082.6 809.6 1087.1 800.85 1089.2 796.75 1091.9 790.4 1092.2021484375 789.6677734375 1092.5 788.95 1093.1099609375 794.7447265625 1093.65 796.95 1096.1 807.5 1105.95 807.6 1123.5 807.85 1126.35 779.15 1126.65 776.1 1123.5 776.1 1120.35 776.1 1120.05 779.15 1117.45 804.05 1104.55 801.2 1099.4 800.05 1098.25 791 1097.8 780.6 1096.65 777.4 1096.05 775.65 1094 775.2 1091.85 774.75 1091.05 776.65
L 1085.3 790.1
Q 1084.5 791.95 1082.8 796.35 1081.1 800.2 1079.1 801.95 1075.95 804.7 1072.05 802.5 1068.6 800.55 1067.25 796.7 1066.15 793.6 1066.2 789.45 1066.25 787.05 1066.95 782.1 1067.3 779.7 1064.75 779.2 1062.3 778.7 1061.05 780.5 1055.1 789.3 1048.3 793.85 1038.4 800.45 1032.9 791.95 1028.2 784.75 1033.25 774.4 Z"
				/>
			</g>

			<g id="Layer10_18_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 424.8 703.8
Q 424.8 703.75 424.7 703.8 424.3 710.25 425.3 718.75 425.45 720.15 427.45 733.6 429.35 747 432.2 764.9 437.55 798.15 442.6 827.3 448.35 860.15 453.9 889.6
L 459.9 920.65
Q 460.9 925.7 462.6 936.3 464.35 945.65 466.75 951.5 467.1 952.4 468.05 952.3 469 952.25 469 951.2 468.9 945.2 467 936.35 464.65 926.45 463.7 921.65 460.65 906.15 457.7 890.6 451.15 855.8 446.4 828.3 440.55 794.6 436 765.9
L 431.1 734.65
Q 429.6 724.25 428.7 719.05 427.1 710.1 424.8 703.8 Z"
				/>
			</g>

			<g id="Layer10_19_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 313 669.85
Q 283.5 663.05 255.55 657.45 240.15 654.3 226.7 651.8 216.25 649.85 213.15 649.35 209.2 648.6 207.2 648.35 203.65 647.85 201.2 648.5 201.1 648.55 201.1 648.65 201.05 648.7 201.15 648.75 203.4 650.4 207.45 651.4 209.75 651.95 214.25 652.8 218.7 653.8 227.65 655.6 236.2 657.4 256.4 661.8 282.4 667.55 312.7 674.65 371.8 688.65 425.65 703.65 427.95 704.3 428.6 701.9 429.25 699.55 426.95 698.9 368.35 682.6 313 669.85 Z"
				/>
			</g>

			<g id="Layer10_20_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 195.95 657.45
Q 195.3 658.2 196.05 658.9 197.55 660.35 198.5 664.2 199.05 666.3 199.75 670
L 221.1 749.75
Q 234.75 800.4 246.5 840.75 246.75 841.6 247.55 841.35 248.4 841.15 248.15 840.3 238.35 800.55 223.25 744
L 210.35 696
Q 206.15 680.65 204.05 672.95 203 667.3 202.15 664.2 200.55 658.55 197.5 657.1 196.6 656.7 195.95 657.45 Z"
				/>
			</g>

			<g id="Layer10_21_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 470.15 950.4
Q 462.5 946.8 458.75 945
L 436 934.1
Q 415.15 924.05 390.7 911.95 347.5 890.5 299.9 865.7 298.3 864.85 274.65 852.1 259.45 843.85 249.1 839.25 248 838.8 247.45 839.75 246.85 840.75 247.85 841.4 256.7 847.2 270 854.35 277.6 858.45 292.75 866.4 312 876.8 337.3 890.05 382.45 913.55 428.25 935.9 449.15 946.1 454.4 948.6 458.5 950.55 466.9 954.65 474.25 958 479.8 959.6 481.2 959.95 481.7 958.65 482.2 957.35 481.1 956.6 476.8 953.65 470.15 950.4 Z"
				/>
			</g>

			<g id="Layer10_22_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 478.5 948.25
Q 478.2 948.2 478.15 948.45 477.05 953.95 476.9 955.05 476.85 955.2 477.05 955.25 477.2 955.25 477.25 955.1 477.55 954.1 478.7 948.65 478.75 948.35 478.5 948.25 Z"
				/>
			</g>

			<g id="Layer10_23_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 487.5 949.35
Q 491.5 948.2 498.95 945.6 512.85 941.35 523.6 938.3 545.95 931.9 571.55 926 594.75 920.65 620.1 916.3 626.5 915.2 645.6 912.8 661.55 910.75 670.9 908.8 673.5 908.25 673.1 905.45 672.7 902.6 670.1 902.65 659.5 902.9 645.05 905.15 628.35 908.05 620.2 909.45 594.45 913.95 569.8 919.7 544.85 925.55 520.15 933 509.5 936.15 496.6 940.55 486 944.15 485.4 944.4 478.75 947.05 475.4 950.3 475.2 950.5 475.35 950.7 475.45 950.95 475.7 950.95 480.35 951.4 487.5 949.35 Z"
				/>
			</g>

			<g id="Layer10_24_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 625.3 884.7
Q 594.4 874.95 579.05 869.95 564 865.05 556.45 862.65 543.4 858.5 533.75 855.75
L 522.3 852.5
Q 519.9 851.7 518.65 851.3 516.35 850.65 514.85 850.85 513 851.05 512.05 852.15 510.95 853.4 512.1 854.8 512.45 855.25 512.9 854.95 513.35 854.65 513.25 854.15 513.15 853.4 517.85 854.75 520.8 855.6 523.25 856.45
L 533.55 860.15
Q 537.5 861.6 543.4 863.55 546.7 864.65 553.25 866.8
L 591.8 879.6
Q 604.8 883.95 630.7 892.95 653.95 900.9 669.8 905.5 672.1 906.15 672.7 903.9 673.25 901.65 671.1 900.8 652.45 893.5 625.3 884.7 Z"
				/>
			</g>

			<g id="Layer10_25_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 761.75 781.15
Q 761.75 777.9 758.65 777.95
L 636.15 780.1
Q 595.7 780.95 575.45 781.35 539.5 782.05 514.7 781.95 495.35 781.85 481.6 781.3 477.8 781.15 464.1 780.35 458.85 779.75 456.2 779.6 451.55 779.35 448.45 780.6 448.05 780.75 448 781.1 447.95 781.5 448.3 781.7 450.45 783.25 453.55 783.85 454.25 784 459.25 784.65 466.6 785.55 474.8 786.2 490.35 787.45 504.95 787.8 518.25 788.05 535.25 787.95 543.6 787.9 565.5 787.55 606.65 786.95 688.95 785.55
L 758.65 784.4
Q 761.75 784.35 761.75 781.15 Z"
				/>
			</g>

			<g id="Layer10_26_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1368.3 770.35
Q 1368.3 771.95 1369.85 772 1395.2 773 1430.15 772.9 1450.25 772.85 1490.4 772.45 1510.5 772.4 1550.7 772.3 1585.85 772.2 1610.95 771.65 1613.55 771.6 1613.55 768.95 1613.55 766.3 1610.95 766.25 1586.05 766 1550.7 766.3 1510.5 766.7 1490.4 766.85 1450.25 766.95 1430.15 767.15 1395.1 767.45 1369.85 768.7 1368.3 768.8 1368.3 770.35 Z"
				/>
			</g>

			<g id="Layer10_27_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 910.5 309.15
Q 909.6142578125 306.9845703125 907.5 305.95 904.75 304.6 902.45 305.85 902.0830078125 306.0421875 901.75 306.25 900.2759765625 306.46484375 898.75 307.6 898.0748046875 308.1171875 897.7 308.7 896.1255859375 308.926171875 894.55 309.7 893.7 310.15 893.25 311.25 892.8 312.3 893.15 313.25 894.8 318.15 899.65 319.55 903.7072265625 320.743359375 906.4 318 907.4767578125 317.872265625 908.55 317.4 911.2 316.2 911.9 313.75 912.6796875 311.1357421875 910.5 309.15 Z"
				/>
			</g>

			<g id="Layer10_28_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 941.9 425.35
Q 939.35 427.2 935.8 430.95 931.2 435.85 929.95 436.95 922.2 444 915.25 447.55 906.45 452.1 897.75 451.55 893.55 451.3 887.85 449.6 881.45 447.5 878.2 446.55 861.05 441.7 843.6 444.2 822 447.2 811.6 456.15 810.5 457.1 811.35 458.45 812.15 459.85 813.5 459.4 816.1 458.55 822.35 455.95 827.95 453.6 831.3 452.65 840.4 450 850.2 449.45 860.6 448.85 870.15 450.65 873.75 451.35 879.35 453.05 886.55 455.25 888.4 455.75 904.7 460.1 919.55 451.85 925.65 448.45 932.8 442.05 938.1 437.35 939.6 435.9 943.45 432.1 945.35 428.85 946.3 427.25 944.9 425.75 943.5 424.25 941.9 425.35 Z"
				/>
			</g>

			<g id="Layer10_29_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 841.65 411.45
Q 839.9 410.7 838.95 412.3 833.65 421.6 831.75 431.15 831.35 433.15 833.35 434.1 835.4 435.05 836.25 433.05 839.85 424.8 842.95 414 843.45 412.25 841.65 411.45 Z"
				/>
			</g>

			<g id="Layer10_30_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1390.95 690.3
Q 1385.25 681.45 1377.4 672.45 1371 665.1 1361.9 656.25 1343.6 638.4 1331.15 620.45 1328.55 616.7 1318.25 601 1310.4 589 1305.05 581.8 1292.7 565.1 1273.7 545.05 1266.65 537.6 1256.35 527.1 1244.65 515.2 1238.8 509.25
L 1220.7 490.8
Q 1215.9 485.9 1213.2 483.5 1208.85 479.6 1204.85 477.2 1195.6 471.55 1182.5 471.3 1172.6 471.05 1159.2 474.05 1134.8 479.55 1115.7 495.35 1114.5 496.3 1115.55 497.65 1116.55 499 1117.85 498.15 1136.25 486.8 1156 481.55 1167.45 478.5 1176.15 477.9 1187.75 477.1 1196.3 480.35 1204.5 483.5 1213.75 492.55 1219 497.65 1228.6 508.2
L 1260.1 540.15
Q 1279.7 560.3 1290.3 573.15 1300.75 585.8 1315.35 607.9 1322.75 619.1 1326.6 624.6 1333 633.75 1338.7 640.6 1343.95 646.9 1352.6 655.5 1357.45 660.25 1367.1 669.8 1382.1 685.2 1394.4 707.7 1398.7 715.5 1405.25 728.95 1406.2 730.85 1408.1 734.7 1409.7 738.05 1410.3 740.7 1411.15 744.65 1409.7 747.8 1408 751.45 1403.95 751.15 1401.25 750.95 1401.25 753.75 1401.25 756.6 1403.95 756.8 1407.7 757.1 1410.85 754.95 1413.8 752.9 1415.35 749.3 1417.05 745.35 1416.05 740.3 1415.35 736.85 1412.95 731.65 1401.1 706.1 1390.95 690.3 Z"
				/>
			</g>

			<g id="Layer10_31_MEMBER_100_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 944.3 526.5
Q 944.3 526.8 944.65 526.8 957.05 527.1 964.8 526.25 975.85 525.05 984.3 521.3 993.7 517.1 1001.7 508.85 1006.65 503.7 1015.15 492 1028.85 473.2 1047.3 459.85 1067.35 445.3 1088.4 440.9 1101.95 438 1113.4 443.35 1126.4 449.45 1126.8 463.05 1126.9 466.3 1130.15 466.3 1133.45 466.3 1133.55 463.05 1133.85 453.4 1127.05 445.3 1120.7 437.75 1111.05 434.65 1100.5 431.25 1087.4 433.95 1077.35 436 1064.75 441.95 1043.85 451.7 1026.8 468.05 1018.3 476.2 1011.25 485.8 1006.2 493.05 1003.65 496.6 999.1 503 995.25 506.75 986.3 515.5 971.65 520.6 959.55 524.8 944.65 526.15 944.3 526.2 944.3 526.5 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.218994140625, 0, 0, 0.218994140625, -32.55,-26.95) ">
			<use xlinkHref="#Layer12_0_FILL" />
		</g>

		<g transform="matrix( 0.218994140625, 0, 0, 0.218994140625, -32.55,-26.95) ">
			<use xlinkHref="#Layer11_0_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_0_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_1_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_2_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_3_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_4_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_5_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_6_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_7_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_8_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_9_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_10_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_11_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_12_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_13_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_14_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_15_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_16_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_17_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_18_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_19_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_20_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_21_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_22_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_23_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_24_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_25_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_26_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_27_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_28_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_29_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_30_MEMBER_100_FILL" />
		</g>

		<g transform="matrix( 0.2189788818359375, 0, 0, 0.2189788818359375, -32.55,-26.95) ">
			<use xlinkHref="#Layer10_31_MEMBER_100_FILL" />
		</g>
	</svg>
);
