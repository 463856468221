import React from "react";

export const contactPage1 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="264px"
		height="153px"
		viewBox="0 0 264 153"
		id="frame-1"
	>
		<defs>
			<g id="Layer1_0_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 315 -2397.85
Q 275.65 -2394.6 256 -2393.25 200.9 -2389.4 137.95 -2385.4 18.65 -2377.9 -95.9 -2372.1 -330.8 -2360.2 -564.1 -2354.3 -629.95 -2352.65 -695.55 -2351.45
L -760.1 -2350.2
Q -789.85 -2349.65 -793.55 -2349.55 -808.9 -2349.15 -810.3 -2349.05 -814.05 -2348.85 -818.8 -2347.85 -820.25 -2347.55 -827.15 -2345.8 -830.35 -2345.05 -832.2 -2342.35 -833.9 -2339.85 -833.9 -2336.65 -833.95 -2333.4 -832.25 -2331 -830.4 -2328.3 -827.15 -2327.6 -822.35 -2326.45 -819.95 -2325.9 -815.7 -2324.9 -812.7 -2324.7 -809.9 -2324.5 -805.5 -2324.55 -798.95 -2324.6 -798.35 -2324.6 -788 -2324.6 -767.3 -2324.8 -733.15 -2325.05 -708.7 -2325.5 -653.65 -2326.4 -590.4 -2327.85 -469.2 -2330.7 -356.25 -2334.85 -125.95 -2343.1 111.7 -2357.75 181.95 -2362.15 242.9 -2366.35 264.75 -2367.85 308.55 -2370.55 346.95 -2373.2 374.05 -2376.4 379.4 -2377.05 382.8 -2380.2 386.55 -2383.65 386.55 -2388.9 386.55 -2393.9 382.85 -2397.65 379.05 -2401.55 374.05 -2401.35 350.25 -2400.55 315 -2397.85 Z"
				/>
			</g>

			<g id="Layer1_1_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -629.15 -1492.75
Q -632.45 -1507.2 -639.3 -1536.05
L -696.9 -1791.5
Q -709.4 -1847 -735 -1961.2 -760.1 -2073.35 -773.1 -2130.9
L -794.65 -2226.45
Q -798.2 -2242.35 -805.35 -2274.25 -811.65 -2301.7 -817.15 -2321.75 -818.35 -2326.05 -821.75 -2327.75 -824.85 -2329.25 -828.45 -2328.2 -832.1 -2327.15 -834.15 -2324.2 -836.45 -2320.9 -835.7 -2316.65 -832.6 -2298.5 -827.15 -2273.45 -820.75 -2244.75 -817.65 -2230.45 -817.05 -2227.65 -799.05 -2146.55 -780.2 -2061.65 -761.25 -1976.75 -736.05 -1863.55 -685.85 -1637.15
L -654.3 -1494.65
Q -650.8 -1478.35 -648.95 -1470.2 -645.75 -1456.15 -642.6 -1446.1 -641.35 -1442.1 -638 -1439.9 -634.55 -1437.55 -630.45 -1438.2 -626.55 -1438.75 -623.6 -1441.8 -620.35 -1445.15 -620.9 -1449.05 -623.35 -1467.3 -629.15 -1492.75 Z"
				/>
			</g>

			<g id="Layer1_2_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 585.4 -1613.45
Q 587.15 -1609.65 591.5 -1608.25 647.6 -1589.9 723.05 -1566.5 767 -1552.85 855 -1525.85
L 1120.95 -1443.9
Q 1145.45 -1436.35 1194.45 -1420.85 1237.9 -1407.3 1268.35 -1399.1 1273.05 -1397.8 1277.45 -1400.3 1281.85 -1402.75 1283.1 -1407.45 1284.4 -1412.25 1281.9 -1416.5 1279.45 -1420.6 1274.75 -1422.2 1248.1 -1431.35 1209.5 -1443 1165.7 -1455.95 1143.9 -1462.6
L 744.55 -1583.9
Q 659.6 -1609.75 596.85 -1627.65 592.4 -1628.9 589 -1626.7 585.95 -1624.65 584.9 -1620.8 583.8 -1616.9 585.4 -1613.45 Z"
				/>
			</g>

			<g id="Layer1_3_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 194.35 -1163.7
Q 416.25 -1205.25 455.55 -1212.5
L 975.65 -1308.35
Q 1024.65 -1317.4 1122.8 -1335.15 1209.45 -1351 1269.65 -1363.35 1273.75 -1364.2 1276 -1368.3 1278.15 -1372.3 1277.05 -1376.4 1275.85 -1380.7 1272.05 -1382.7 1268.5 -1384.5 1264 -1383.8 1209.35 -1374.85 1132.45 -1360.65 1044.9 -1344.3 1001.1 -1336.25
L 741 -1288.5
Q 339.7 -1214.9 220.75 -1193.2 208.9 -1191.05 73.55 -1166.55 -15.35 -1150.45 -73.55 -1139.45 -78.3 -1138.55 -80.95 -1133.65 -83.5 -1128.95 -82.2 -1124.25 -80.8 -1119.25 -76.3 -1116.9 -72.1 -1114.7 -66.95 -1115.65 35.15 -1133.9 194.35 -1163.7 Z"
				/>
			</g>

			<g id="Layer1_4_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -275.45 -1214.95
Q -263.75 -1208.45 -257.9 -1205.2 -247.7 -1199.65 -240.1 -1195.95 -233.75 -1192.85 -223.55 -1188.2 -210.15 -1182.15 -206.9 -1180.65 -167.35 -1162.4 -98.8 -1129.15 -86.55 -1123.25 -75.55 -1118.5 -71.4 -1116.75 -67.6 -1117.6 -63.45 -1118.5 -61.25 -1122.25 -59.1 -1125.9 -60.2 -1130.2 -61.3 -1134.6 -65 -1136.6 -80.95 -1145.25 -103.2 -1155.95 -116.05 -1162.15 -141.95 -1174.3 -162.55 -1184.1 -188.2 -1196.05 -193.8 -1198.65 -207.35 -1204.75 -219.65 -1210.3 -226.5 -1213.5 -233.2 -1216.7 -242.1 -1221.45 -247.25 -1224.2 -257.5 -1229.8
L -422.05 -1320
Q -423.55 -1320.85 -515 -1371.4 -570.85 -1402.25 -608.3 -1422.15 -621.55 -1429.15 -632.25 -1434.5 -636.2 -1436.5 -640 -1435.15 -643.4 -1433.95 -645.4 -1430.65 -647.35 -1427.35 -646.65 -1423.9 -645.9 -1420.05 -642.05 -1417.75 -604.2 -1395.05 -549.3 -1365.05 -486.65 -1331.2 -455.5 -1314.15 -419.7 -1294.55 -365.45 -1264.6 -280.8 -1217.9 -275.45 -1214.95 Z"
				/>
			</g>

			<g id="Layer1_5_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1296.15 -1698.55
Q 1307.85 -1708.65 1325 -1712 1340.05 -1714.95 1356.4 -1708.95 1363.8 -1706.3 1371.05 -1701.35 1377.85 -1696.7 1383.85 -1690.5 1396.45 -1677.55 1404.25 -1656.9 1405.9 -1652.5 1409.55 -1650.25 1413.45 -1647.85 1417.95 -1649.1 1422.35 -1650.35 1424.65 -1654.4 1426.9 -1658.45 1425.75 -1662.85 1420.2 -1684 1404.75 -1701.9 1389.35 -1719.75 1369.25 -1728.45 1348.2 -1737.55 1325.75 -1734.95 1303.05 -1732.3 1284.95 -1718.4 1274.9 -1710.7 1268.2 -1700.8 1265.05 -1696.15 1263.05 -1689.95 1261.9 -1686.3 1260.15 -1678.3 1259.25 -1674.25 1261.35 -1671.2 1263.25 -1668.4 1266.7 -1667.4 1270.15 -1666.45 1273.15 -1667.9 1276.45 -1669.5 1277.65 -1673.5 1278.6 -1676.8 1281.2 -1680.75 1282.65 -1683.05 1285.9 -1687.45 1289.75 -1692.95 1296.15 -1698.55 Z"
				/>
			</g>

			<g id="Layer1_6_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1363.55 -1598.95
Q 1342.2 -1601.1 1321.65 -1609.55 1301.15 -1618 1284.35 -1631.45 1274.25 -1639.5 1265.8 -1649 1262.55 -1652.65 1258.2 -1658.55 1251.45 -1667.65 1250.9 -1668.35 1248.25 -1671.8 1244.35 -1672.55 1240.85 -1673.2 1237.7 -1671.4 1234.55 -1669.65 1233.5 -1666.35 1232.3 -1662.7 1234.2 -1658.6 1243.9 -1638 1269.95 -1617.05 1292.15 -1599.3 1318.8 -1589.15 1344.45 -1579.4 1372.05 -1578.2 1405.3 -1576.65 1427.35 -1586.15 1435.7 -1589.75 1440.85 -1595.9 1446.6 -1602.8 1446.7 -1611.15 1446.8 -1620.1 1441.4 -1626.7 1435.65 -1633.6 1426.9 -1633.8 1423.05 -1633.9 1420.35 -1631.1 1417.9 -1628.6 1417.35 -1624.85 1416.75 -1621.05 1418.45 -1618.2 1420.35 -1615 1424.3 -1614.35 1424.75 -1614.3 1425.85 -1613.85
L 1425.85 -1613.85
Q 1426.0583984375 -1613.503125 1426.2 -1613.05 1426.7 -1611.6 1426.55 -1610.55 1426.35 -1609.5 1425.4 -1608.35 1422.6 -1605.15 1416.75 -1603.25 1414.4 -1602.5 1406.85 -1600.9 1386.6 -1596.65 1363.55 -1598.95 Z"
				/>
			</g>

			<g id="Layer1_7_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1208.4 -1623.35
Q 1192.15 -1612.4 1169.2 -1596.4 1143.25 -1578.15 1130.2 -1569.1 1124.65 -1565.25 1125.4 -1558.75 1126.15 -1552.2 1132.7 -1550.15
L 1233.8 -1518.7
Q 1239.6 -1516.9 1251.2 -1513 1261.55 -1509.6 1268.8 -1508.05 1273 -1507.15 1276.65 -1509.1 1280.4 -1511.2 1281.55 -1515.3 1282.7 -1519.55 1280.5 -1523.05 1278.45 -1526.35 1274.3 -1528 1267.65 -1530.7 1257.45 -1533.7 1245.95 -1536.95 1240.3 -1538.7
L 1158.75 -1563.85
Q 1167.8841796875 -1570.22890625 1179.7 -1578.5 1202.15 -1594.25 1218.5 -1606.1 1221.9 -1608.6 1223 -1612.25 1224.2 -1616.2 1222.05 -1619.75 1220.05 -1623.15 1215.95 -1624.35 1211.7 -1625.55 1208.4 -1623.35 Z"
				/>
			</g>

			<g id="Layer1_8_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1345.1 -1553.45
Q 1342.5 -1556.05 1339 -1556.3 1335.1 -1556.6 1331.85 -1553.7 1321.7 -1544.65 1310.65 -1533.2 1307.45 -1529.9 1307.75 -1525.8 1308 -1522.05 1310.85 -1519.2 1313.7 -1516.35 1317.45 -1516.1 1321.55 -1515.8 1324.85 -1519 1336.95 -1530.75 1345.3 -1540.2 1348.2 -1543.45 1347.95 -1547.35 1347.7 -1550.8 1345.1 -1553.45 Z"
				/>
			</g>

			<g id="Layer1_9_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1505.45 -1624.65
Q 1501.8 -1624.35 1499 -1621.6 1496.25 -1618.9 1496 -1615.3 1495.8 -1611.35 1498.95 -1608.15 1517.55 -1589.2 1531.05 -1574.25 1547.85 -1555.6 1561 -1538.5 1589.75 -1500.95 1612.35 -1459.5 1624.7 -1436.95 1633.8 -1417.05 1638.5 -1406.9 1640.25 -1399.2 1640.207421875 -1399.41328125 1640.1 -1398.55
L 1638.5 -1398.1
Q 1634.75 -1397.1 1625.25 -1395.6 1580.95 -1388.75 1524.15 -1400.95 1498.95 -1406.35 1467 -1416.75 1458.55 -1419.5 1410.85 -1436 1407.05 -1437.3 1403.2 -1435 1399.45 -1432.8 1398.35 -1428.9 1397.25 -1424.8 1399.4 -1421.25 1401.4 -1417.95 1405.45 -1416.4 1470.3 -1392.05 1512.7 -1382.55 1574 -1368.8 1624.25 -1375.35 1637.9 -1377.1 1638.05 -1377.1 1646.2 -1378.55 1651.05 -1381.55 1656.8 -1385.1 1659.05 -1391.6 1661.1 -1397.5 1659.95 -1404.6 1659.15 -1409.5 1656.55 -1415.8 1655.05 -1419.45 1651.75 -1426.6 1646.55 -1438.55 1641.05 -1449.75 1619.75 -1493.45 1588.7 -1536.1 1550.8 -1588.2 1512.8 -1622 1509.5 -1625 1505.45 -1624.65
M 1640.75 -1399.3
Q 1641.25 -1398.95 1640.65 -1398.7
L 1640.15 -1398.55
Q 1640.4513671875 -1399.51328125 1640.75 -1399.3 Z"
				/>
			</g>

			<g id="Layer1_10_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 665.25 -1639.9
Q 663.05 -1637.35 663.05 -1633.7 663.05 -1630 665.3 -1627.4 667.8 -1624.45 672 -1624.25 787.65 -1618.35 927.15 -1640.25 943.1 -1642.75 962.6 -1646.5 968.4458984375 -1647.6146484375 976.25 -1649.2 974.8408203125 -1647.426171875 973.25 -1645.75 965.1 -1637.2 954.15 -1631.95 949.4 -1629.65 949.35 -1623.5 949.3 -1617.3 954.15 -1615.05 972.4 -1606.65 997.85 -1609.35 1006.2 -1610.25 1019.95 -1612.9 1036.9 -1616.15 1042.05 -1616.95 1063.5 -1620.25 1085.05 -1619.1 1107.65 -1617.95 1127.45 -1612.05 1154 -1604.2 1172.85 -1594.6 1176.85 -1592.55 1180.75 -1594 1184.3 -1595.3 1186.35 -1598.7 1188.35 -1602.15 1187.65 -1605.65 1186.85 -1609.55 1182.85 -1611.7 1145 -1632 1098.45 -1637.3 1076.35 -1639.8 1054.15 -1637.9 1044.55 -1637.05 1031.95 -1634.85 1031.65 -1634.8 1009.95 -1630.65 995.7 -1627.9 986.75 -1627.75 984.7490234375 -1627.7119140625 982.8 -1627.8 985.94140625 -1630.62890625 988.8 -1633.85 999.1 -1645.5 1004.8 -1660.5 1006.85 -1665.9 1002.3 -1669.9 998 -1673.7 992.7 -1672.6 875.65 -1649 743.1 -1644.25 727.75 -1643.7 707.55 -1643.35 703.6 -1643.25 672 -1642.8 667.75 -1642.75 665.25 -1639.9 Z"
				/>
			</g>

			<g id="Layer1_11_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 456.65 -1614.15
Q 395.2 -1602.8 338.7 -1593.05 223.4 -1573 103.6 -1554.85 0.3 -1539.15 -129.55 -1521.45 -182.35 -1514.3 -247.05 -1506.05 -290.75 -1500.5 -364.6 -1491.3 -390.45 -1488.1 -484.1 -1477.15 -557.95 -1468.55 -603.45 -1462.4 -628.95 -1458.95 -634.45 -1458.15 -638.15 -1457.65 -640 -1454.5 -641.6 -1451.7 -641.1 -1448 -640.6 -1444.3 -638.3 -1441.9 -635.7 -1439.25 -631.95 -1439.55 -580.1 -1443.45 -511.5 -1450.9 -504.25 -1451.65 -391.3 -1464.5 -279.5 -1477.25 -154.9 -1493.55 -37.1 -1508.9 80.45 -1526.55 200.65 -1544.55 315.3 -1564.15 435.85 -1584.75 551.7 -1607.3 568.4 -1610.5 582.05 -1613.55 586.7 -1614.6 589.2 -1619.15 591.65 -1623.65 590.4 -1628.25 589.05 -1633.1 584.75 -1635.35 580.75 -1637.45 575.7 -1636.6 552.8 -1632.65 516.15 -1625.55 468.8 -1616.4 456.65 -1614.15 Z"
				/>
			</g>

			<g id="Layer1_12_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 373.9 -2381.75
Q 370.95 -2380.95 369.1 -2378.8 367.1 -2376.4 367.2 -2373.15 367.55 -2363.75 370.25 -2353.9
L 449.1 -2074.8
Q 494.9 -1913.2 532.65 -1779.5
L 555.85 -1697.35
Q 563.5 -1669.25 567.4 -1655.25 574.3 -1630.25 579.95 -1613.45 581.7 -1608.25 586.15 -1606.15 590.2 -1604.2 594.75 -1605.45 599.3 -1606.7 601.75 -1610.4 604.45 -1614.45 603.2 -1619.85 599.6 -1635.25 593.2 -1657.2 589.6 -1669.6 582.25 -1694.35
L 410.65 -2293.7
Q 402.15 -2323.6 398.55 -2335.85 396.5 -2342.9 392.55 -2357.3 388.75 -2369.65 384.1 -2377.75 382.5 -2380.5 379.55 -2381.55 376.85 -2382.5 373.9 -2381.75 Z"
				/>
			</g>

			<g id="Layer1_13_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -365.85 -2235.35
Q -368.8 -2238.45 -372.7 -2238.25 -422.15 -2235.7 -485.7 -2231.1 -513.9 -2229.05 -598.6 -2222.45 -634.25 -2219.75 -663.4 -2215.75 -666.55 -2215.3 -668.05 -2212.8 -669.4 -2210.55 -668.95 -2207.55 -668.5 -2204.55 -666.55 -2202.5 -664.4 -2200.25 -661.3 -2200.25 -636.65 -2200.3 -605 -2202.1 -590.75 -2202.9 -548.85 -2205.8
L -436.4 -2213.55
Q -410.25 -2215.35 -372.7 -2218.7 -368.6 -2219.1 -365.85 -2221.65 -362.95 -2224.4 -362.95 -2228.5 -362.95 -2232.35 -365.85 -2235.35 Z"
				/>
			</g>

			<g id="Layer1_14_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -529.05 -2119.35
Q -529.05 -2116 -526.6 -2113.45 -524.1 -2110.9 -520.7 -2111 -503.7 -2111.4 -481.2 -2112.85 -479.25 -2113 -441.75 -2115.7
L -403.3 -2118.65
Q -389.6 -2119.5 -382.7 -2120 -370.7 -2120.9 -362.25 -2122.35 -358.45 -2123 -356.65 -2126 -355.05 -2128.75 -355.6 -2132.3 -356.15 -2135.85 -358.5 -2138.25 -361.1 -2140.9 -364.8 -2140.95 -372.9 -2141.05 -384.55 -2140.1 -391.15 -2139.55 -404.25 -2138.3
L -442.75 -2135.25
Q -455.75 -2134.15 -481.8 -2131.95 -504.1 -2129.95 -520.7 -2127.75 -524.3 -2127.25 -526.55 -2125.2 -529.05 -2122.9 -529.05 -2119.35 Z"
				/>
			</g>

			<g id="Layer1_15_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -588.65 -1984.9
Q -586.4 -1982.6 -583.15 -1982.95 -510.5 -1990.2 -464.75 -1995.55 -438.45 -1998.65 -346.4 -2009.85 -342.15 -2010.35 -339.4 -2012.85 -336.45 -2015.6 -336.45 -2019.75 -336.45 -2023.6 -339.4 -2026.8 -342.5 -2030.15 -346.4 -2029.7 -455.7 -2016.5 -465.75 -2015.25 -505.25 -2010.35 -585.35 -1999.1 -588.55 -1998.65 -590.1 -1995.9 -591.5 -1993.4 -591.1 -1990.2 -590.65 -1986.95 -588.65 -1984.9 Z"
				/>
			</g>

			<g id="Layer1_16_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -330.15 -1939.55
Q -332.85 -1939.2 -338.05 -1938.4
L -385.4 -1932.8
Q -416.3 -1929.15 -448.65 -1923.7 -451.7 -1923.2 -453.2 -1920.6 -454.55 -1918.3 -454.1 -1915.25 -453.7 -1912.25 -451.8 -1910.3 -449.65 -1908.15 -446.55 -1908.35 -413.5 -1910.45 -383.45 -1913.9
L -336.1 -1919.25
Q -330.65 -1919.65 -327.85 -1919.95 -322.9 -1920.45 -319.8 -1921.6 -316.15 -1923 -314.45 -1925.9 -312.9 -1928.55 -313.35 -1931.65 -313.85 -1934.7 -316.1 -1936.95 -318.55 -1939.3 -322.3 -1939.8 -325.4 -1940.2 -330.15 -1939.55 Z"
				/>
			</g>

			<g id="Layer1_17_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -460.05 -1803.15
Q -466.2 -1802.55 -478.35 -1801.4
L -550.2 -1792.9
Q -553.5 -1792.5 -555.65 -1790.55 -557.95 -1788.4 -557.95 -1785.15 -557.95 -1782.1 -555.65 -1779.65 -553.25 -1777.05 -550.2 -1777.4
L -477.35 -1785.55
Q -453.75 -1788.4 -441.95 -1789.5 -438.8 -1789.8 -432.65 -1789.8 -426.3 -1789.85 -423.25 -1790.1 -414.55 -1790.85 -403.95 -1792.5 -400.2 -1793.1 -398.4 -1796.25 -396.75 -1799.1 -397.25 -1802.8 -397.75 -1806.5 -400.1 -1808.9 -402.7 -1811.55 -406.5 -1811.25 -420.65 -1810.15 -441.95 -1805.7 -449.05 -1804.2 -460.05 -1803.15 Z"
				/>
			</g>

			<g id="Layer1_18_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -503.1 -1691.1
Q -521.1 -1688.2 -534.1 -1684.8 -537 -1684.05 -538.45 -1681.55 -539.75 -1679.25 -539.4 -1676.45 -539.05 -1673.65 -537.2 -1671.8 -535.2 -1669.8 -532.1 -1669.9 -518.55 -1670.25 -500.25 -1672.55 -479.1 -1675.45 -468.6 -1676.9
L -281.85 -1703.5
Q -278.15 -1704.05 -276.1 -1707.95 -274.15 -1711.7 -275.15 -1715.3 -276.2 -1719.25 -279.7 -1721 -282.8 -1722.6 -286.95 -1722
L -471.75 -1695.7
Q -482.15 -1694.15 -503.1 -1691.1 Z"
				/>
			</g>

			<g id="Layer1_19_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -514.7 -1592.65
Q -516 -1590.35 -515.6 -1587.5 -515.25 -1584.65 -513.4 -1582.8 -511.3 -1580.8 -508.25 -1580.95 -494.95 -1581.6 -477.9 -1583.65 -470.5 -1584.55 -447.7 -1587.65 -411.6 -1592.45 -387.05 -1596
L -197.35 -1623.15
Q -193.65 -1623.7 -191.6 -1627.65 -189.65 -1631.4 -190.65 -1635 -191.7 -1638.9 -195.2 -1640.65 -198.3 -1642.25 -202.45 -1641.7 -381.35 -1616.45 -442.6 -1607.45 -470.8 -1603.35 -476.6 -1602.4 -495.85 -1599.3 -510.3 -1595.85 -513.25 -1595.15 -514.7 -1592.65 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.095977783203125, -0.0011749267578125, 0.0011749267578125, 0.095977783203125, 99.4,249.55) ">
			<use xlinkHref="#Layer1_0_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_1_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_2_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_3_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.095916748046875, 0.003387451171875, -0.003387451171875, 0.095916748046875, 92.45,249) ">
			<use xlinkHref="#Layer1_4_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_5_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_6_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_7_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_8_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_9_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.1081085205078125, -0.003265380859375, 0.002899169921875, 0.095947265625, 86.9,252.6) ">
			<use xlinkHref="#Layer1_10_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_11_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.095947265625, 0.002899169921875, -0.002777099609375, 0.09539794921875, 90.5,244.85) ">
			<use xlinkHref="#Layer1_12_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_13_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_14_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_15_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_16_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_17_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_18_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer1_19_MEMBER_20_FILL" />
		</g>
	</svg>
);
