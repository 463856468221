import React from "react";

export const homePage4 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="500px"
		height="356px"
		viewBox="0 0 500 356"
		id="frame-4"
	>
		<defs>
			<linearGradient
				id="Gradient_1"
				gradientUnits="userSpaceOnUse"
				x1="942.95"
				y1="558.95"
				x2="1200.95"
				y2="558.95"
				spreadMethod="pad"
			>
				<stop offset="15.686274509803921%" stopColor="#0C0C0C" />

				<stop offset="31.372549019607842%" stopColor="#0C0F0E" />

				<stop offset="42.745098039215684%" stopColor="#0C1816" />

				<stop offset="52.94117647058823%" stopColor="#0D2824" />

				<stop offset="61.96078431372549%" stopColor="#0E3F37" />

				<stop offset="70.98039215686275%" stopColor="#125C50" />

				<stop offset="79.2156862745098%" stopColor="#17806E" />

				<stop offset="87.45098039215686%" stopColor="#1EAA92" />

				<stop offset="95.29411764705883%" stopColor="#27DABB" />

				<stop offset="100%" stopColor="#2DFCD8" />
			</linearGradient>

			<g id="Layer1_0_FILL">
				<path
					fill="url(#Gradient_1)"
					stroke="none"
					d="
M 942.95 441.2
L 942.95 676.75 1201 604.95 1201 513 942.95 441.2 Z"
				/>
			</g>

			<g id="Layer4_0_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 880.5 456.35
Q 880.75 456.5 880.75 456.2 879.6 451.9 875.3 447.35 870.2 441.9 866.5 443.3 864.9 443.95 863.75 446.4 862.25 449.95 861.85 452.3 861.35 455 862.25 458.05 862.8 460.05 864.5 463.4 867.6 469.7 871.95 472.75 872.9 473.4 873.45 472.4 874.05 471.4 873.1 470.75 869.3 468.05 866.3 461.9 864.45 458.2 864.05 456.25 863.5 453.7 865 449.2 865.7 447.05 865.95 446.55 866.75 445.05 868.05 445 870.05 444.9 873.25 447.75 874.7 449.05 876.9 452.05 879.15 455.1 880.5 456.35 Z"
				/>
			</g>

			<g id="Layer4_1_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 872.5 460.5
Q 873.8 462.05 874.05 462.25 874.65 462.65 875.15 462.1 875.7 461.6 875.35 461
L 870.45 455.35
Q 870.15 454.9 869.7 454.65 869.55 454.6 869.4 454.65 869.25 454.75 869.25 454.9 869.3 455.6 870.05 456.9
L 872.5 460.5 Z"
				/>
			</g>

			<g id="Layer4_2_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 897.25 443.1
Q 897.45 442.95 897.25 442.75 896.35 442.35 895.7 443.45 895.25 444.1 894.9 446.3
L 894.25 451.55
Q 894.15 452.95 894.3 453.45 894.55 454.25 895.35 454.25 896.05 454.25 896.4 453.45 896.45 453.3 896.6 451.85
L 896.8 444.65
Q 896.8 443.55 897.25 443.1 Z"
				/>
			</g>

			<g id="Layer4_3_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 923.7 467.95
Q 922.85 468.3 923.1 469.1 923.35 470.2 922.75 471.45 922.4 472.2 921.4 473.45 919.55 475.9 917.45 475.65 915.75 475.4 913.1 473.45 911 471.95 909.25 469.7 908.9 469.25 908.25 469.3 907.65 469.3 907.25 469.7 906.35 470.6 907.25 471.7 909.6 474.6 912.25 476.3 915.6 478.45 918.6 478.05 920.15 477.85 921.45 476.75 922.4 475.95 923.55 474.35 926.15 470.8 924.9 468.35 924.5 467.6 923.7 467.95 Z"
				/>
			</g>

			<g id="Layer4_4_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 963.2 508.35
Q 962.7 508.3 962.5 508.7 962.2 509.3 962.2 510.25 962.15 510.75 962.2 511.8
L 962.25 514.75
Q 962.15 516.55 962 517.45
L 961.65 519.1
Q 961.45 520.1 961.55 520.75 961.7 521.6 962.6 521.7 963.35 521.75 963.9 521.05 964.2 520.75 964.4 519.5
L 964.65 517.8
Q 964.85 515.9 964.85 514.85 964.85 513.8 964.65 511.8 964.4 509.45 963.9 508.7 963.6 508.4 963.2 508.35 Z"
				/>
			</g>

			<g id="Layer4_5_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 952.2 518.3
Q 953.6 518.2 953.7 516.8
L 953.3 513.8 953.15 513.4
Q 952.85 512.55 951.95 512.7 951.45 512.8 951.1 513.9
L 950.9 515
Q 950.7 516.2 950.7 516.8 950.7 517.5 951.15 517.85 951.55 518.3 952.2 518.3 Z"
				/>
			</g>

			<g id="Layer4_6_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 938.65 518.5
L 938.55 519.05
Q 938.55 519.2 938.8 519.95 939.05 520.45 939.75 520.65 940.4 520.8 940.9 520.45 941.6 520.05 941.8 519.15
L 942.25 516.8
Q 942.5 515.65 942.55 515.05
L 942.65 514.3
Q 942.3109375 514.636328125 941.75 514.55 942.8427734375 513.9125 942.65 514.2
L 942.65 514.3
Q 942.701953125 514.26171875 942.75 514.2 943.1 513.75 942.85 513.15 942.5 512.5 941.8 512.6 941 512.6 940.6 513.35
L 940.2 514.15 938.65 518.5 Z"
				/>
			</g>

			<g id="Layer4_7_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 930.3 515.65
Q 929.9 515.5 929.6 515.8
L 929.25 516.25 928.9 517.4 928.9 517.45 928.75 517.65 928.75 517.7
Q 928.45 518.1 928.3 518.5 928.25 518.9 928.3 519.25
L 928.45 519.6
Q 928.75 520 928.95 520.1 929.75 520.55 930.45 520.05 930.85 519.85 931.05 519.45
L 931.3 518.9 931.4 518.4 931.45 517.6
Q 931.45 517.05 931.35 516.85
L 931.1 516.2
Q 930.8 515.7 930.3 515.65 Z"
				/>
			</g>

			<g id="Layer4_8_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 961.2 539.85
Q 961.15 540.8 961.05 542.75 960.94765625 544.522265625 960.65 546.45 960.3896484375 546.597265625 960.25 546.9 959.55 548.6 960.7 549.75 961.3 550.35 962.15 550.1 963.05 549.9 963.2 549.1 963.85 545.75 964 543.1 964.1 541.15 964.05 540.2
L 963.9 538.1 963.8 537.5 963.75 537.35
Q 963.55 536.3 962.7 536.15 961.65 536.05 961.3 537.05 961.15 537.6 961.15 538.45 961.15 538.9 961.2 539.85 Z"
				/>
			</g>

			<g id="Layer4_9_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 954.15 540.85
Q 953.6 540.85 953.35 541.45 953.2 541.85 953.1 542.7
L 952.8 546.85
Q 952.75 547.4 953.2 547.8 953.65 548.25 954.15 548.2 954.7 548.2 955.1 547.8 955.55 547.4 955.5 546.85
L 955.2 542.7
Q 955.1 541.85 954.95 541.45 954.75 540.85 954.15 540.85 Z"
				/>
			</g>

			<g id="Layer4_10_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 946.8 540.8
Q 946.2 540.7 945.85 541.1 945.5 541.55 945.35 542
L 945.2 542.7
Q 945 543.45 944.9 544.3
L 944.75 545.7
Q 944.65 546.85 944.65 547.1 944.85 548.4 945.95 548.55 947.15 548.7 947.6 547.5 947.65 547.25 947.8 545.8
L 947.95 543.05
Q 947.85 541.75 947.6 541.35 947.35 540.9 946.8 540.8 Z"
				/>
			</g>

			<g id="Layer4_11_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 914.3 549.7
Q 914.85 550.25 916.65 551.55 918.65 553 921.55 554.65 923.35 555.65 926.9 557.5 930.05 559.2 932.1 560.6 940.45 566.1 943.1 567.3 949.85 570.35 955.5 568.4 958.4 567.45 960.5 565.3 961.5 564.2 962.15 563
L 962.8 561.55
Q 963 560.55 963.15 560.5 963.95 560.15 963.65 559.2 963.4 558.25 962.55 558.3 961.45 558.35 960.85 559.35 960.25 560.65 959.9 561.3 958.85 563.35 956.45 564.7 951.9 567.25 945.65 564.9 943.1 563.95 940.05 562.05 939.05 561.4 934.9 558.45 931.5 556.15 924.85 552.55 921.45 550.7 919.4 549.25 918.3 548.5 917.1 547.45 916 546.4 915.9 546.3 915.2 545.75 914.45 545.45 913.65 545.15 913.05 545.8 912.4 546.45 912.65 547.25 912.95 548.5 914.3 549.7 Z"
				/>
			</g>

			<g id="Layer4_12_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 796 619.05
Q 795 618.75 794.75 619.75 791.85 629.25 793.85 640.05 795.65 649.9 801.2 659.15 811.1 675.7 832.6 687.65 837.6 690.5 843.5 693.1 845.65 694.15 849.35 695.6 850.3 696 852.25 696.85 853.95 697.5 855.3 697.4 856.2 697.35 856.6 696.5 857 695.6 856.3 694.95 855.5 694.1 854.15 693.5 852.55 692.9 851.7 692.55 848.85 691.5 846.9 690.6 841.9 688.5 837.35 686.25 827 681.05 819.7 675.2 811.45 668.6 805.85 660.5 799.85 651.9 797.3 642.3 794.4 631.15 796.75 620.3 796.95 619.3 796 619.05 Z"
				/>
			</g>

			<g id="Layer4_13_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 814.6 591
Q 814.7 591.95 815.6 591.85 821.4 591.3 831 589.9 842.55 588.15 846.35 587.7 862.95 585.7 877.35 584.85 911.2 582.95 938.65 587.7 954.25 590.4 968.25 595.3 973.5 597.1 982.55 600.95 992.5 605.2 996.75 606.75 1011.4 612.05 1025.65 611.55 1031.85 611.35 1040 610.15 1041.65 609.9 1054.2 607.85 1063 606.4 1068.25 605.85 1076.05 605 1082.4 605.3 1089.5 605.7 1095.8 607.65 1103.35 610.05 1106.95 613.95 1109.1 616.3 1109.6 619.3 1109.85 620.7 1111.3 620.35 1112.75 619.95 1112.5 618.5 1111.45 612.5 1105.2 608.45 1100.4 605.4 1093.2 603.7 1081.55 600.95 1065.05 603.05 1045.7 606.35 1035.95 607.55 1018.9 609.6 1006.75 606.55 1000.4 605 991.85 601.45 982.2 597.3 977.3 595.35 963.6 589.75 947.75 586.3 919.65 580.15 885.65 581.3 870.1 581.8 854.05 583.65 833.65 586.1 822.85 588.2 817.95 589.15 815.3 589.85 814.45 590.05 814.6 591 Z"
				/>
			</g>

			<g id="Layer4_14_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1111.65 618.8
Q 1111.55 619 1111.7 619.2 1112.55 620.15 1115 621.1 1116.7 621.85 1118.65 622.5 1121.82109375 623.5828125 1124.9 624.2 1124.0939453125 625.1833984375 1122.9 626.15 1120.4 628.05 1119.2 629 1115.95 631.65 1112.4 636.85 1108.5 642.65 1107 645.9 1106.15 647.8 1105.45 650.65 1105.205859375 651.8705078125 1104.9 652.8 1104.4359375 651.84765625 1104 651.15 1102.4 648.6 1100.5 647.6 1099.75 647.2 1099.2 647.75 1098.65 648.35 1099 649.1 1099.65 650.35 1101.45 652.6 1102.8 654.65 1103.5 656.45 1103.8 657.15 1104.55 657.45 1105.35 657.75 1105.85 657.1 1107.1 655.55 1107.85 652.7 1108.95 648.55 1109.15 648 1110 645.9 1111.65 643.2 1112.6 641.65 1114.55 638.6 1118 633.4 1121.35 630.65 1122.6 629.65 1125.05 627.65 1127.1 625.85 1128.1 624 1128.4 623.5 1128.2 622.95 1127.95 622.3 1127.4 622.2 1123.15 621.65 1119.45 620.65
L 1115.65 619.5
Q 1114.5 619.05 1113.85 618.85 1112.8 618.5 1112 618.55 1111.75 618.55 1111.65 618.8 Z"
				/>
			</g>

			<g id="Layer4_15_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1123.8 631.95
Q 1124.25 632.3 1124.75 632.1 1125.1 631.95 1126.05 632.85 1126.55 633.35 1127.2 634.05 1128.9 635.75 1129.95 637.05 1132.45 639.9 1134.45 643.05 1138.5 649.4 1141.05 657.6 1143.65 665.8 1144.9 674.95 1145.45 678.9 1145.5 679.1 1145.65 681.65 1144.7 683.1 1143.95 684.25 1142.4 684.7 1141.05 685.1 1139.55 684.85 1136 684.2 1133.9 680.3 1133.1 678.8 1131.35 673.1 1131.05 672.2 1130.2 672.45 1129.35 672.7 1129.5 673.6 1131.2 684.55 1137.65 686.2 1140.9 686.95 1143.55 685.55 1146.45 683.95 1146.3 680.05 1146.25 678.05 1145.7 675.15 1145.1 671.85 1144.8 670.2 1143.85 664.25 1142.9 660 1140.4 649.55 1136.15 642.05 1134 638.1 1130.5 634.05 1125.45 628.15 1123.65 631 1123.35 631.55 1123.8 631.95 Z"
				/>
			</g>

			<g id="Layer4_16_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1141.95 654.9
Q 1141.25 655.3 1141.5 655.95 1142.7 658.7 1145.75 663.85 1148.65 669.45 1149.95 672.3
L 1151.8 676.45
Q 1153.05 679.15 1153.1 680.6 1153.2 681.6 1152.65 682.25 1152.1 682.95 1151.1 683 1150.45 683.05 1149.6 682.55 1148.55 682 1148.15 681.9 1147.65 681.75 1147.35 682.1 1147 682.45 1147.1 682.95 1147.5 684.7 1149.95 685 1152.25 685.25 1153.6 684.15 1155.35 682.75 1155.05 680.05 1154.9 678.65 1153.75 675.65 1152.05 670.9 1149.15 665.05 1147.35 661.5 1146.45 659.85 1144.75 656.9 1143.1 655 1142.65 654.5 1141.95 654.9 Z"
				/>
			</g>

			<g id="Layer4_17_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1138 647.45
Q 1137.65 647.65 1137.75 648.05 1138 649.05 1138.9 650.35 1139.45 651.1 1140.45 652.5
L 1155.85 674.55
Q 1155.95 674.7 1157.4 676.6 1158.35 677.9 1158.75 678.8 1159.4 680.3 1157.8 680.9 1155.75 681.3 1155.05 681.45 1154.45 681.6 1154.4 682.3 1154.4 683.05 1155.05 683.15 1157.5 683.6 1159.5 682.15 1161.75 680.55 1160.65 678.1 1160.15 677.05 1159.1 675.55 1157.95 673.9 1157.4 673.1
L 1153.75 667.65
Q 1150.15 662.35 1146.4 657.15 1143.35 652.95 1142.8 652.25
L 1140.7 649.55
Q 1139.25 647.75 1138.65 647.4 1138.3 647.2 1138 647.45 Z"
				/>
			</g>

			<g id="Layer4_18_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 971.7 593.5
Q 971.25 593.3 971.05 593.8 970.85 594.3 971.25 594.5 976.55 597.2 980 598.35 984.4 599.8 989.3 600.65 998.55 602.35 1008.35 601.65 1017.55 600.95 1027.35 597.85 1033.5 595.9 1045.55 591.4 1056.25 587.65 1064.25 586.75 1073.8 585.7 1083.45 587.3 1101.2 590.2 1118.35 601.45 1126.4 606.7 1134.65 614.25
L 1138.4 617.85
Q 1138.95 618.4 1140.25 619.55 1141.35 620.65 1141.7 621.55 1142.1 622.55 1140.75 622.9 1139.1 623.15 1138.6 623.2
L 1132.7 624.15
Q 1131.45 624.4 1131.65 625.8 1131.85 627.2 1133.1 627.1 1135.8 626.85 1138.25 626.5 1139.05 626.4 1140.7 626.2 1142.2 625.95 1143.05 625.45 1145.15 624.3 1145 622.05 1144.9 620.1 1143.35 618.25 1141.4 615.95 1136.3 611.4 1133.05 608.4 1128.9 605.1 1112 591.85 1094.6 586.55 1084.7 583.6 1074.7 583.25 1064.35 582.9 1054.6 585.35 1049.15 586.75 1035.45 591.85 1023.45 596.3 1016.15 597.7 1005.5 599.75 993.85 598.55 989 598.1 982.8 596.65 975.3 594.85 971.7 593.5 Z"
				/>
			</g>

			<g id="Layer4_19_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1146.4 630.8
Q 1145.7 628.3 1144.95 626.7 1144.2 624.95 1142.7 625.4 1141.9 625.6 1141.9 626.5 1141.9 627.2560546875 1142.6 627.5 1142.6412109375 627.5763671875 1142.6 627.7 1142.6 627.95 1142.85 628.6
L 1144.3 634.05
Q 1145.25 638.15 1145.75 641.7 1146.7634765625 648.4720703125 1146.5 655.8 1145.6958984375 656.08828125 1145.65 657.1 1145.6 658.2 1146.65 658.4 1147.35 658.55 1147.95 658.55 1148.55 658.55 1149 658.1 1149.4 657.65 1149.45 657.05 1149.9 648.3 1148.35 639.3 1147.8 635.65 1146.4 630.8 Z"
				/>
			</g>

			<g id="Layer4_20_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1146.8 637.45
Q 1146.8 637.4 1146.7 637.5 1147.25 640 1148 645.15
L 1150.95 660.35
Q 1151.55 663.4 1151.6 664.25 1151.65 665.3 1151.25 667.15 1151.2 667.9 1151.9 668.15 1152.6 668.35 1153.1 667.9 1154.15 667.1 1153.9 664.95 1153.35 662.55 1153.2 661.7 1152.15 655.9 1151.6 653.4 1150.4 648.1 1149.6 645.45 1149.15 643.95 1148.25 641.45 1147.15 638.45 1146.8 637.45 Z"
				/>
			</g>

			<g id="Layer4_21_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1165.9 683.5
Q 1165.2 683.4 1164.9 684 1164.55 684.55 1164.8 685.15 1164.9 685.45 1165.45 686.2
L 1177.05 701.25
Q 1177.4 701.7 1178.1 701.7 1178.75 701.7 1179.2 701.25 1180.15 700.3 1179.2 699.1
L 1172.2 690.4
Q 1170 687.6 1168.8 686.2 1167 684.1 1166.95 684.05 1166.5 683.6 1165.9 683.5 Z"
				/>
			</g>

			<g id="Layer4_22_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1104.55 688.3
Q 1104.55 687.95 1104.3 687.8 1103.55 687.3 1102.7 687.6 1102 687.9 1101.4 688.6 1100.8 689.35 1100.1 690.7 1099.4 692.2 1099 692.9
L 1096.7 697.5
Q 1094.75 701.25 1094.35 702.3 1093.9 703.5 1095.2 704 1096.45 704.5 1097.05 703.4 1097.5 702.65 1098.05 701.45 1098.6 700.1 1098.9 699.45
L 1102.55 691.25
Q 1102.9 690.3 1103.15 689.8 1103.6 688.85 1104.2 688.7 1104.5 688.65 1104.55 688.3 Z"
				/>
			</g>

			<g id="Layer4_23_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1109.05 707.7
Q 1114.65 707.45 1125.85 707
L 1169.15 705.05
Q 1170.8 705 1174.05 704.85 1176.85 704.7 1178.9 704.4 1179.7 704.3 1179.55 703.45 1179.4 702.6 1178.65 702.5 1177.2 702.45 1170.15 702.7
L 1101.25 705.45
Q 1094.35 705.8 1091.9 706.35 1090.85 706.6 1091 707.8 1091.15 709 1092.25 708.9 1099.3 708.15 1109.05 707.7 Z"
				/>
			</g>

			<g id="Layer4_24_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1100.8 711.8
L 1127.05 710.75
Q 1141.2 710.05 1156.8 708.7 1159.55 708.45 1165.05 707.95 1169.75 707.45 1173.15 706.6 1173.4 706.55 1173.35 706.3 1173.35 706.05 1173.1 706.05 1170 705.85 1165.7 706 1160.75 706.3 1158.25 706.4
L 1143.65 707.15
Q 1126.6 708 1114.2 708.4
L 1101.8 708.8
Q 1100.35 708.8 1099.6 708.85 1098.35 708.9 1097.5 709.2 1096.6 709.5 1096.6 710.4 1096.6 711.3 1097.5 711.55 1098.2 711.8 1099.15 711.8 1099.7 711.85 1100.8 711.8 Z"
				/>
			</g>

			<g id="Layer4_25_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1200 498.3
Q 1199.9 498.8 1200.4 499.15 1201.9 500.25 1204.75 502.8 1206.9 504.5 1209.85 506.35 1212.15 507.85 1215.05 509.45
L 1217.7 510.85
Q 1218.1 511.05 1219 511.4 1219.7181640625 511.758984375 1220.05 512.05 1220.4279296875 512.5080078125 1221.1 512.55 1221.85 512.65 1222.3 511.9
L 1222.4 511.75
Q 1222.95 510.8 1222.15 509.9 1221.75 509.45 1220.95 509.05 1220 508.65 1219.55 508.4
L 1216.7 506.95
Q 1213.2 505 1211 503.65
L 1203.05 498.3
Q 1201.55 497.25 1200.6 497.55 1200.05 497.75 1200 498.3 Z"
				/>
			</g>

			<g id="Layer4_26_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1222.35 509.95
Q 1221.95 509.9 1221.9 510.25 1221.4 512.9 1220.95 520.75
L 1220.45 531.65
Q 1220.2 538.85 1220.3 553.4 1220.6 574.45 1222.45 596.85 1223.6 611.2 1224.9 621.45 1225.1 622.85 1226.55 622.85 1228 622.85 1227.85 621.45 1225.15 596.35 1224.15 578.1 1223.25 560.4 1223.05 534.85
L 1222.95 522.75
Q 1223 518.65 1223 516.55 1223 512.95 1222.7 510.35 1222.7 510.05 1222.35 509.95 Z"
				/>
			</g>

			<g id="Layer4_27_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1226.85 624.45
Q 1226.5 623 1225.1 623.45 1212.5 627.45 1209.95 628.2 1208.55 628.6 1206.6 628.9 1204.35 629.25 1203.2 629.4 1198.9 630 1198.95 632.1 1199.05 632.7 1199.5 632.75 1200 632.9 1200.7 632.7 1201.1 632.55 1201.9 632.3 1202.85 632.1 1204.85 632 1207.8 631.95 1211.4 630.85 1216.25 629.4 1225.9 626.35 1227.25 625.9 1226.85 624.45 Z"
				/>
			</g>

			<g id="Layer4_28_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1224.95 546.75
Q 1224.35 547.25 1224.7 547.85 1227.4 552.65 1228.25 554.25 1230.2 557.9 1231.4 560.9 1234.1 567.75 1235.4 575.45 1237.35 587.95 1237.1 605.45 1236.65 635.4 1227.7 661.55 1222.45 677.1 1214.15 690.8 1213.35 692.1 1214.75 692.95 1216.15 693.75 1216.95 692.4 1231.55 667.9 1236.95 637.75 1239.4 623.8 1239.8 608.5 1240.3 592.3 1238.4 578.4 1237.1 568.95 1234.05 561.3 1230.55 552.35 1225.95 546.85 1225.5 546.3 1224.95 546.75 Z"
				/>
			</g>

			<g id="Layer4_29_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1198.7 509.3
Q 1198.5 509 1198.2 509.2 1189.45 515.2 1185.25 524.25 1181.55 532.3 1180.95 543.2 1180.05 562.9 1185.8 585.9 1188.8 597.65 1193.95 609.35 1194.55 610.65 1195.85 609.9 1197.15 609.15 1196.55 607.85 1187.35 587.5 1184.5 567.65 1182.95 557.1 1182.95 547.05 1182.95 535.2 1185.7 527.6 1187.6 522.35 1191.05 518 1193.35 515.05 1198.7 509.8 1198.9 509.55 1198.7 509.3 Z"
				/>
			</g>

			<g id="Layer4_30_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1165.5 667.9
Q 1165.05 668.2 1165.15 668.45 1165.4 669.05 1166.35 669.2 1166.75 669.25 1167.9 669.25 1171.5 669.4 1171.55 669.4 1172.8 669.4 1175.2 669.45 1177.35 669.45 1178.85 669.2 1179.45 669.1 1179.45 668.4 1179.45 667.65 1178.85 667.55 1177.25 667.3 1174.95 667.3 1172.35 667.35 1171.05 667.35 1167.8 667.45 1167.1 667.55 1165.85 667.7 1165.5 667.9 Z"
				/>
			</g>

			<g id="Layer4_31_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1168.75 652.65
Q 1168.35 652.85 1167.7 653.5
L 1164.65 657.25
Q 1164.3 657.8 1164.15 658.3
L 1164.05 658.9 1164.1 659.3
Q 1164.15 659.35 1164.3 659.45 1164.85 659.45 1165.2 659.35 1165.45 659.3 1166.3 658.8 1166.75 658.5 1168.15 657.2
L 1169.75 655.65 1170.65 654.55
Q 1170.75 654.35 1171.1 653.3 1171.2 652.8 1170.8 652.4 1170.4 651.95 1169.9 652.15 1169.2 652.3 1168.75 652.65 Z"
				/>
			</g>

			<g id="Layer4_32_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1092.35 658
Q 1092.1 658.3 1092.35 658.55 1095.05 661.6 1096 662.5 1098.5 665 1100 666 1100.15 666.1 1100.3 665.95 1100.4 665.8 1100.3 665.65 1099.25 664.05 1096.85 661.65
L 1092.9 658
Q 1092.6 657.75 1092.35 658 Z"
				/>
			</g>

			<g id="Layer4_33_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1199.3 498.25
Q 1198.3 498.25 1198.3 499.25 1198.2 505.45 1198.65 525.15
L 1199 551.05
Q 1199.1 559.6 1199.25 576.7 1199.35 591.5 1198.85 602.35 1198.5 610.3 1197.85 616.65 1197.6 619.3 1196.85 623.75 1196.05 628.9 1195.8 630.85 1195.6 632.55 1197.45 632.8 1199.3 633 1199.75 631.4 1200.8 627.5 1201.75 618.85 1202.5 612.5 1202.8 606 1203.3 596.7 1203.2 580.7 1203 554.7 1202 528.65 1201.25 508.25 1200.4 499.25 1200.3 498.25 1199.3 498.25 Z"
				/>
			</g>

			<g id="Layer4_34_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 928.15 557.5
Q 927.35 557.5 927.05 558.3 926.65 560.25 926.1 561.15 925.7 561.85 923.85 563.55 921 566.05 918.65 567.75 913.6 571.5 907.3 574.55 906.8 574.8 906.65 575.45 906.45 576.1 906.75 576.5 907.3 577.2 909.05 578.85 909.65 579.45 909.8 580 909.95 580.5 909.75 581.3 909.45 582.35 910.4 582.85 911.35 583.4 912.1 582.7 913 581.9 913 580.6 913 579.45 912.35 578.3 911.9 577.5 910.75 576.5 910.558203125 576.3224609375 910.35 576.15 916.2646484375 573.1408203125 921.3 569.2 924.45 566.8 926.75 564.5 930.2 561.05 929.2 558.3 928.9 557.5 928.15 557.5 Z"
				/>
			</g>

			<g id="Layer4_35_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 912.9 456.65
Q 913.0853515625 457.267578125 913.35 457.75 913.680078125 458.2837890625 914.1 458.65
L 914.1 458.7
Q 914.1072265625 458.7466796875 914.1 458.8 914.314453125 460.0197265625 914.7 460.55 915.35 461.7 916.55 461.75 917.7189453125 461.7986328125 918.5 460.7 918.995703125 460.4072265625 919.3 459.9 920.009375 458.8546875 919.6 457.55 919.7255859375 457.279296875 919.8 457 920.1 456 919.75 455.05 919.4 454 918.5 453.55 917.44921875 452.980859375 916.25 453.5 915.63671875 453.177734375 914.95 453.25 913.65 453.4 913.1 454.5 912.55 455.65 912.9 456.65 Z"
				/>
			</g>

			<g id="Layer4_36_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 954.1 406.9
Q 961.1 404.5 966.75 403.85 973.55 403.05 979.35 404.1 982.55 404.65 985.45 405.65
L 989.65 407.5 989.7 407.55
Q 989.37109375 407.7556640625 988.4 408.6
L 983.8 411.6
Q 981.6 412.95 977 415.25 975.75 415.9 976.5 417.2 977.2 418.45 978.5 417.85 984.6 415 991.5 409.85 992.95 408.7 993.05 407.75 993.2 406.3 991.6 405.35 989.35 403.9 985.2 402.55 978.4 400.35 970.75 400.65 963.45 400.9 956.35 403.35 952.4 404.7 948.55 406.5 947.1 407.25 945 408.45 943.7 409.2 943.55 409.35 942.75 409.95 942.55 410.7
L 942.65 410.85
Q 943.5 411.15 944.75 410.6
L 954.1 406.9 Z"
				/>
			</g>

			<g id="Layer4_37_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 957.9 418.8
Q 958.15 419.25 958.65 419.15 959.75 418.95 962 418.75 964.15 418.35 965.4 418.25 968.65 418.05 972.25 418.35 978.05 418.8 985.8 421.35 990.2 422.8 992.1 423.6 995.65 425.1 997.9 426.95 1000.55 429.1 1001.4 431.9
L 1001.65 433.25
Q 1001.65 433.75 1001.55 433.95 1001.45 434.15 1001 434.25 999.9 434.55 997.5 434.75 989.75 435.35 982.85 432.1 981.55 431.45 980.75 432.75 980 434.05 981.3 434.7 988.9 438.25 996.65 437.75 998.25 437.7 1000.25 437.35 1001.7 437.05 1002.2 436.9 1003.3 436.5 1003.85 435.75 1005.55 433.5 1003.45 429.1 1001.95 425.9 998.4 423.55 995.95 421.95 991.5 420.25 982.8 416.8 975.55 415.8 970.7 415.15 966.7 415.55 964.25 415.8 962.45 416.25 960.75 416.6 958.15 418.05 957.65 418.3 957.9 418.8 Z"
				/>
			</g>

			<g id="Layer4_38_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 946.65 390.1
Q 943.05 389.55 939.95 389.75 933.3 390.2 927.3 393.25 923.8 395 921.25 397.4 920.1 398.35 919.05 399.7 917.5 401.75 918.75 402.15 919.25 402.3 919.75 402 919.95 401.9 920.55 401.4
L 922.85 399.5
Q 924.95 397.85 927.5 396.45 932.7 393.55 938.1 393.05 940.75 392.7 943.85 392.95 947.3 393.15 949.05 394 950.25 394.6 950.35 395.2 950.4 395.7 949.9 396.45 949.25 397.25 949 397.5 947.25 399.45 944.9 401.4 940.6 405.05 939.95 405.65 937.25 408.2 935.8 410.7 935.1 411.9 936.35 412.65 937.65 413.35 938.35 412.15 939.8 409.7 942.7 407.1 944.4 405.6 947.9 402.75 951.2 399.9 952.4 398.05 954.55 394.65 952.15 392.35 950.55 390.8 946.65 390.1 Z"
				/>
			</g>

			<g id="Layer4_39_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 885.15 389.2
Q 885.9 391.65 887.45 394.7 888.75 397.4 890.8 400.7 893.15 404.65 894.8 406.05 895.2 406.35 895.6 406.15 896.05 405.9 895.9 405.4 895.6 404.15 894.7 402.55 893.65 400.7 893.15 399.8 891.3 396.65 890.1 394.1 888.6 390.75 887.8 388.05 887.2 385.95 887.15 385.3 887.1 384.65 887.3 384.45 887.45 384.25 888.1 384.3 892.95 384.6 896.6 391.5 897.75 393.75 899.35 397.75 901.2 402.35 902 404 902.25 404.55 902.9 404.3 903.55 404.05 903.35 403.4 901.25 396.65 899.1 391.3 896.6 385.1 892.6 382.7 890.2 381.25 887.7 381.35 884.65 381.45 884.25 384.25 884 385.85 885.15 389.2 Z"
				/>
			</g>

			<g id="Layer4_40_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 828.8 567.25
Q 847.3 567.1 861.65 570.2 880.75 574.4 894.95 583.75 896.15 584.55 896.9 583.3 897.65 582 896.45 581.2 871.1 564.35 831.05 564.25 811.75 564.15 795.15 567.55 786.15 569.4 777.95 572.4 772.4 574.45 770.6 575.6 768.1 577.2 766.05 579.75 761.45 585.5 761.2 592.9 760.95 600.5 765.5 606.15 766.45 607.25 767.5 606.25 768.55 605.2 767.65 604.05 763.95 599.55 764.15 593.2 764.3 587.2 767.85 582.3 769.6 579.9 771.9 578.35 773.7 577.15 778.75 575.3 786.25 572.5 794.85 570.7 810.1 567.4 828.8 567.25 Z"
				/>
			</g>

			<g id="Layer4_42_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 866.5 576.65
Q 866.7 576.35 866.5 576.35 847.45 573.55 831.8 575.15 815.75 576.8 801.6 582.25 787.55 587.6 775.05 596.45 770.4 599.75 768.65 601.15 765.15 604 763.15 606.85 760.65 610.4 760.85 614.65 761.1 618.65 763.65 622.4 767.95 628.65 777.1 632.9 780.95 634.7 785.55 636.25 790.75 637.95 793.5 638 794.8 638.05 794.95 636.75 795.05 635.45 793.9 635 792.7 634.55 790.75 634 788 633.2 787.6 633.1 783.3 631.75 780.65 630.55 772.8 627.25 768.75 623.15 765.7 619.9 764.7 616.65 763.5 612.75 765.6 609.3 767.25 606.6 770.55 603.85 771.8 602.85 776.55 599.4 788.7 590.75 801.4 585.6 814.5 580.35 829.5 578.1 846.85 575.6 866.5 576.65 Z"
				/>
			</g>

			<g id="Layer4_43_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 966.75 455.9
Q 966.6 456.25 966.9 456.4 970.6 458.3 983 463.7 988.4 465.95 990.35 467.05 993.35 468.65 997.05 471.4 1011.6 481.9 1017.9 493.55 1021.75 500.65 1022.6 507.7 1022.9 510.2 1022.9 511.35 1022.85 513.45 1021.95 514.8 1020.3 517.4 1016.5 519.1 1010.2 521.9 1001.6 519.8 996.25 518.5 987.3 514.2 979.4 510.35 971.9 505.4 967.6 502.45 964.85 500.35 962.15 498.25 961.45 497.6 959.55 495.95 958.45 494.4 957.45 492.9 955.8 493.85 954.15 494.8 955.2 496.35 957 499 961.1 502.25 964.65 505.1 967.9 507.35 975.1 512.3 982.6 516.15 991.65 520.8 998.4 522.85 1007.65 525.65 1014.85 523.65 1018.85 522.55 1021.85 520.2 1025.45 517.45 1026.2 513.9 1026.8 511.1 1025.85 505.75 1025.2 501.95 1023.7 498 1018.3 483.95 1003.45 472.35 999.15 469.05 996 467 992.4 464.75 987.2 462.7 974.9 457.9 967.25 455.7 966.9 455.6 966.75 455.9 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_0_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_0_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_1_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_2_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_3_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_4_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_5_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_6_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_7_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_8_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_9_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_10_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_11_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_12_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_13_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_14_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_15_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_16_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_17_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_18_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_19_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_20_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_21_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_22_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_23_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_24_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_25_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_26_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_27_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_28_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_29_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_30_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_31_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_32_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_33_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_34_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_35_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_36_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_37_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_38_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_39_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_40_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_42_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer4_43_MEMBER_1_FILL" />
		</g>
	</svg>
);
