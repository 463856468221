import React from "react";

export const contactPage3 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="264px"
		height="153px"
		viewBox="0 0 264 153"
		id="frame-3"
	>
		<defs>
			<g id="Layer3_0_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 361.9 -2395.3
Q 360 -2392.4 361 -2388.65 369.25 -2357.25 381.7 -2313.5 396.05 -2263.55 403.15 -2238.6 431.5 -2139.35 445.65 -2089.7 493.8 -1921.4 530.65 -1791.9 575.1 -1636.25 578.65 -1623.8 579.9 -1619.4 583.65 -1617.65 587.05 -1616.1 590.95 -1617.15 594.9 -1618.25 596.95 -1621.3 599.2 -1624.7 597.95 -1629.1 581.65 -1685.9 511.95 -1927.7 500.1 -1968.8 469.2 -2076.5 442.15 -2170.7 426.35 -2225.3 418.3 -2253.3 402.25 -2309.35 388.15 -2358.4 377.35 -2393.15 376.2 -2396.85 373.05 -2398.3 370.2 -2399.65 366.95 -2398.75 363.65 -2397.9 361.9 -2395.3 Z"
				/>
			</g>

			<g id="Layer3_1_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 739.95 -1590.45
Q 690.45 -1604.45 665.6 -1611.3 622.6 -1623.1 590.75 -1630.35 586.7 -1631.25 583.6 -1629.2 580.75 -1627.3 579.8 -1623.85 578.85 -1620.4 580.3 -1617.4 581.9 -1614.05 585.9 -1612.8 612.35 -1604.55 651.95 -1593.8 674.1 -1587.8 718.3 -1575.85 791.1 -1555.6 856.55 -1536.3 1014.65 -1489.7 1134.9 -1446.75 1213.05 -1418.85 1278.85 -1391.15 1282.5 -1389.6 1286.6 -1392.1 1290.4 -1394.45 1291.45 -1398.25 1292.65 -1402.45 1290.4 -1405.95 1288.4 -1409.1 1284.3 -1410.85 1223.35 -1436.5 1153.4 -1461.7 1093.2 -1483.4 1020.4 -1506.9 900.1 -1545.8 739.95 -1590.45 Z"
				/>
			</g>

			<g id="Layer3_2_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 623.35 -1628
Q 620.2 -1631.4 616.2 -1631 503 -1619.95 371 -1602.85 266.7 -1589.35 126.55 -1568.75 16.55 -1552.55 -117.6 -1531.55 -209.7 -1517.15 -361.6 -1492.75
L -499.75 -1470.55
Q -522.7 -1466.85 -568.85 -1459.8 -609.25 -1453.35 -637.55 -1447.05 -640.75 -1446.35 -642.3 -1443.55 -643.75 -1441 -643.35 -1437.8 -642.95 -1434.6 -640.9 -1432.65 -638.7 -1430.45 -635.35 -1430.75 -609.2 -1433.3 -573.45 -1438.85 -532.3 -1445.55 -511.8 -1448.8
L -388.3 -1468.2
Q -226.1 -1493.9 -144.95 -1506.7 -4.95 -1528.8 97.5 -1544.05 228.6 -1563.55 340.45 -1578.25 462.8 -1594.3 616.2 -1610.65 620.5 -1611.1 623.3 -1613.75 626.35 -1616.55 626.35 -1620.8 626.35 -1624.8 623.35 -1628 Z"
				/>
			</g>

			<g id="Layer3_3_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -628.95 -1460.05
Q -627.05 -1462.8 -627.75 -1466.3 -641.6 -1533.65 -660.55 -1628.65 -671.35 -1682.8 -692.95 -1791.1 -725.85 -1955.55 -760.75 -2118.15 -769.1 -2157.25 -782.25 -2217
L -793.9 -2269.2
Q -795.9 -2278.15 -799.75 -2296.3 -803.4 -2312.05 -807.6 -2322.85 -808.65 -2325.65 -810.8 -2327.15 -813.1 -2328.8 -816 -2328.35 -818.75 -2327.95 -820.75 -2325.85 -822.9 -2323.6 -822.65 -2320.85 -821.8 -2310.35 -818.9 -2296.3 -815.2 -2280.1 -813.45 -2272 -807 -2242.6 -803.35 -2225.55 -789.95 -2162.8 -783.25 -2131.45 -768.75 -2063.75 -750.65 -1975.2 -727.85 -1863.25 -716.4 -1807.3 -696.1 -1707.85 -681.6 -1639.5 -660.7 -1540.85 -642.95 -1462.1 -642.15 -1458.6 -639.35 -1457.2 -636.85 -1455.9 -633.75 -1456.75 -630.7 -1457.65 -628.95 -1460.05 Z"
				/>
			</g>

			<g id="Layer3_4_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 242.75 -2396.45
Q 134.65 -2391.15 128.15 -2390.85 53.5 -2387.5 -101 -2380.3 -330.85 -2369.55 -557.1 -2358.85 -642.9 -2354.75 -685.75 -2352.65
L -748.9 -2349.6
Q -770.5 -2348.5 -781.65 -2347.85 -787.9 -2347.5 -798.05 -2346.35 -810.6 -2344.95 -814.4 -2344.6 -818.15 -2344.3 -820.35 -2341.6 -822.35 -2339.2 -822.4 -2335.9 -822.45 -2332.65 -820.45 -2330.4 -818.25 -2327.95 -814.4 -2328 -810.35 -2328.05 -800.35 -2327.75 -791.45 -2327.5 -786.35 -2327.65 -761.1 -2328.65 -755.95 -2328.9
L -355.95 -2347.5
Q -103.35 -2359.4 100.1 -2369.35 103.8 -2369.55 228.75 -2375.2 306.15 -2378.75 357.4 -2381.9 361.8 -2382.15 364.4 -2385.3 366.7 -2388.15 366.75 -2392.05 366.75 -2395.95 364.45 -2398.55 361.85 -2401.45 357.4 -2401.3 311.85 -2399.8 242.75 -2396.45 Z"
				/>
			</g>

			<g id="Layer3_5_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 692.1 -1619.05
Q 717.35 -1621.95 767.85 -1627.95 824.35 -1634.45 925.1 -1644.75 944.45 -1646.7 973.35 -1649.8 980.1490234375 -1650.51796875 989.8 -1651.55 975.842578125 -1644.0345703125 960 -1641.75 954.1 -1640.85 953.45 -1634.2 952.85 -1627.7 957.8 -1624.9 978.25 -1613.45 1009.5 -1613.25 1018.9 -1613.2 1036.45 -1614.25 1055 -1615.4 1063.25 -1615.45 1091.6 -1615.6 1119.65 -1609.15 1147.5 -1602.75 1173.15 -1590.25 1176.65 -1588.55 1180.1 -1589.85 1183.2 -1591 1184.95 -1594 1186.7 -1597.05 1186.1 -1600.05 1185.4 -1603.35 1181.85 -1605.1 1155.75 -1617.85 1127.6 -1624.85 1099.45 -1631.85 1070.5 -1632.8 1061.05 -1633.1 1044.5 -1632.1 1024.55 -1630.9 1017.85 -1630.8 1004.3884765625 -1630.5873046875 993.2 -1632.4 996.383203125 -1633.8009765625 999.5 -1635.45 1016 -1644.2 1028.35 -1657.75 1032.35 -1662.1 1030.2 -1668.3 1027.95 -1674.7 1021.6 -1674.1 851.05 -1657.35 699.3 -1639.7 643.85 -1633.6 616.2 -1630.15 612.2 -1629.65 609.65 -1627.3 606.85 -1624.75 606.85 -1620.8 606.85 -1617.15 609.6 -1614.25 612.5 -1611.2 616.2 -1611.5 647.75 -1614 692.1 -1619.05 Z"
				/>
			</g>

			<g id="Layer3_6_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1214.85 -1611.85
Q 1211.9 -1613.55 1208.4 -1612.35 1198.5 -1608.85 1186.9 -1602.65 1178.75 -1598.3 1166.5 -1590.7 1149 -1579.85 1128.5 -1562.9 1124.8 -1559.85 1126.25 -1554.5 1127.7 -1549.15 1132.35 -1548.25 1153.95 -1544.15 1197.15 -1535.9 1235.45 -1528.65 1261.9 -1523.7 1265.45 -1523.05 1268.4 -1524.6 1271.55 -1526.25 1272.5 -1529.75 1273.45 -1533.05 1271.7 -1536.35 1269.85 -1539.75 1266.5 -1540.4 1228.0787109375 -1547.8349609375 1155.45 -1561.65 1165.0884765625 -1568.5283203125 1176.45 -1575.45 1183.8 -1579.95 1195.75 -1585.95 1212.05 -1594.1 1215.35 -1595.85 1218.5 -1597.55 1219.4 -1600.9 1220.2 -1603.95 1218.9 -1607.15 1217.55 -1610.35 1214.85 -1611.85 Z"
				/>
			</g>

			<g id="Layer3_7_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1342.65 -1559.25
Q 1339.35 -1560.15 1336.55 -1558 1325.6 -1549.6 1319.3 -1543.15 1310.05 -1533.75 1306.35 -1524.75 1303.85 -1518.7 1310 -1515.55 1312.55 -1514.25 1315.4 -1514.45 1318.45 -1514.65 1320.45 -1516.55 1322.95 -1518.9 1326.75 -1523.8 1331 -1529.35 1332.85 -1531.3 1339.2 -1538 1347.2 -1544.2 1349.95 -1546.35 1349.95 -1549.75 1349.95 -1552.85 1347.8 -1555.65 1345.65 -1558.45 1342.65 -1559.25 Z"
				/>
			</g>

			<g id="Layer3_8_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1361.2 -1600.85
Q 1341.5 -1604.8 1323.2 -1613.3 1304.65 -1621.85 1289.15 -1634.35 1270.8 -1649.05 1257.8 -1667.2 1255.65 -1670.2 1252.4 -1671.15 1248.95 -1672.2 1245.8 -1670.35 1242.85 -1668.6 1241.8 -1664.95 1240.65 -1661.2 1242.7 -1658.35 1256.3 -1639.2 1274 -1624 1291.9 -1608.7 1312.8 -1598.5 1334.8 -1587.8 1355.6 -1583.2 1380.05 -1577.8 1400.7 -1581.4 1413.3 -1583.6 1422.5 -1590.15 1432.45 -1597.2 1436.9 -1608.35 1438.25 -1611.75 1436.5 -1614.8 1434.9 -1617.6 1431.55 -1619 1428.15 -1620.45 1425.05 -1619.65 1421.65 -1618.75 1420.15 -1615.4 1413.8 -1601.15 1394.15 -1598.6 1380.65 -1596.9 1361.2 -1600.85 Z"
				/>
			</g>

			<g id="Layer3_9_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1343.8 -1732.95
Q 1330.95 -1734.95 1317.8 -1731.65 1305.6 -1728.6 1293.8 -1721.2 1284.65 -1715.5 1272.75 -1705.45 1271.05 -1704.1 1270.15 -1703.4 1268.6 -1702.2 1267.7 -1701.25 1266.2 -1699.6 1265.6 -1697.45 1265.3 -1696.25 1265 -1693.1 1264.15 -1684.9 1264.15 -1677.25 1264.15 -1674.15 1266.35 -1672 1268.35 -1670 1271.3 -1669.55 1274.3 -1669.1 1276.6 -1670.45 1279.2 -1671.95 1279.8 -1675.15 1280.9400390625 -1681.0130859375 1282.05 -1690.55 1299.9068359375 -1704.4509765625 1312.35 -1709.75 1332.05 -1718.05 1349.5 -1711.8 1367 -1705.5 1381.35 -1686.9 1386.4 -1680.3 1392.85 -1669.8 1399.95 -1658 1403.55 -1652.35 1405.85 -1648.8 1409.45 -1648.05 1412.7 -1647.45 1415.75 -1649.25 1418.85 -1651.05 1419.95 -1654.3 1421.2 -1657.9 1419.4 -1661.65 1406.05 -1689.35 1389.7 -1706.75 1368.6 -1729.1 1343.8 -1732.95 Z"
				/>
			</g>

			<g id="Layer3_10_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -538.6 -1688.45
Q -538.25 -1685.45 -536.35 -1683.6 -534.2 -1681.5 -531.05 -1681.8 -506.4 -1684.1 -474.4 -1688.8 -465 -1690.2 -418 -1697.65
L -306.7 -1715.55
Q -279.4 -1719.95 -242.9 -1726.6 -239.1 -1727.3 -237.05 -1731.15 -235.05 -1734.9 -236.05 -1738.6 -237.15 -1742.6 -240.7 -1744.4 -243.85 -1746.05 -248.05 -1745.45 -297.05 -1738.45 -359.45 -1728.2 -390 -1723.15 -470.65 -1709.45 -507.15 -1703.25 -533.15 -1697.25 -536.15 -1696.55 -537.65 -1693.9 -539 -1691.45 -538.6 -1688.45 Z"
				/>
			</g>

			<g id="Layer3_11_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -401.5 -1600.3
Q -399.25 -1598.1 -395.9 -1598.4 -378.85 -1600 -356.35 -1603.1 -354 -1603.45 -316.95 -1608.8
L -278.75 -1614.45
Q -265.35 -1616.25 -258.65 -1617.2 -246.75 -1618.9 -238.7 -1620.9 -235.05 -1621.8 -233.3 -1624.95 -231.65 -1627.8 -232.1 -1631.35 -232.55 -1634.9 -234.85 -1637.2 -237.4 -1639.7 -241.25 -1639.5 -249.5 -1639.05 -261.2 -1637.2 -267.85 -1636.15 -281.05 -1633.95 -294.1 -1631.95 -320.2 -1628 -356.8 -1622.3 -359.25 -1621.9 -381.45 -1618.3 -398.1 -1614.85 -401.35 -1614.15 -402.9 -1611.35 -404.35 -1608.8 -403.95 -1605.55 -403.55 -1602.35 -401.5 -1600.3 Z"
				/>
			</g>

			<g id="Layer3_12_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -373.4 -2244.8
Q -376.5 -2248.15 -380.4 -2247.75 -474.05 -2237.65 -498.95 -2234.9 -541.5 -2230.15 -617.5 -2220.45 -621 -2220 -623.25 -2217.95 -625.7 -2215.7 -625.7 -2212.25 -625.7 -2209 -623.25 -2206.45 -620.75 -2203.75 -617.5 -2204 -538.75 -2210.95 -498.95 -2215.1 -475.15 -2217.6 -439.65 -2221.45 -400.15 -2225.7 -380.4 -2227.85 -376.15 -2228.3 -373.4 -2230.85 -370.45 -2233.65 -370.45 -2237.8 -370.45 -2241.65 -373.4 -2244.8 Z"
				/>
			</g>

			<g id="Layer3_13_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -509.8 -2099.05
Q -507.65 -2096.9 -504.55 -2097.1 -471.5 -2099.2 -441.45 -2102.65
L -394.1 -2108
Q -388.65 -2108.4 -385.85 -2108.7 -380.9 -2109.2 -377.8 -2110.35 -374.2 -2111.75 -372.45 -2114.65 -370.9 -2117.3 -371.4 -2120.4 -371.85 -2123.45 -374.1 -2125.7 -376.55 -2128.05 -380.3 -2128.55 -383.4 -2128.95 -388.15 -2128.3 -390.85 -2127.95 -396.05 -2127.15
L -443.4 -2121.55
Q -474.3 -2117.9 -506.65 -2112.45 -509.7 -2111.95 -511.2 -2109.35 -512.55 -2107.05 -512.15 -2104 -511.75 -2101 -509.8 -2099.05 Z"
				/>
			</g>

			<g id="Layer3_14_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -600.75 -2001.05
Q -598.35 -1998.5 -595.3 -1998.8
L -522.35 -2005.8
Q -498.7 -2008.3 -486.85 -2009.2 -483.8 -2009.4 -477.55 -2009.35 -471.25 -2009.25 -468.1 -2009.5 -459.55 -2010.1 -448.85 -2011.6 -445.05 -2012.1 -443.25 -2015.25 -441.6 -2018.05 -442.1 -2021.75 -442.6 -2025.45 -444.95 -2027.9 -447.55 -2030.55 -451.35 -2030.35 -459.5 -2029.85 -469.1 -2028.65 -477.25 -2027.65 -478.3 -2027.45 -482.34140625 -2026.7572265625 -485.35 -2025.55 -507.07421875 -2022.72734375 -539.05 -2019.7 -594.65 -2014.35 -595.3 -2014.3 -598.6 -2014 -600.75 -2011.95 -603.1 -2009.8 -603.1 -2006.55 -603.1 -2003.5 -600.75 -2001.05 Z"
				/>
			</g>

			<g id="Layer3_15_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -333.45 -1920.95
Q -336.45 -1924.15 -340.2 -1923.75
L -465.55 -1910.3
Q -495.5 -1907.05 -527.75 -1903.4 -538.25 -1902.2 -559.5 -1899.8 -577.65 -1897.45 -590.95 -1894.45 -593.95 -1893.75 -595.4 -1891.35 -596.7 -1889.1 -596.3 -1886.3 -595.9 -1883.5 -594 -1881.65 -591.95 -1879.55 -588.95 -1879.55 -575.5 -1879.45 -557.3 -1881.1 -536.2 -1883.3 -525.75 -1884.4 -491.75 -1887.9 -464.55 -1890.9
L -340.2 -1904.55
Q -336.15 -1905 -333.45 -1907.45 -330.6 -1910.1 -330.6 -1914.15 -330.6 -1917.9 -333.45 -1920.95 Z"
				/>
			</g>

			<g id="Layer3_16_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -555.2 -1788.2
Q -558.1 -1787.5 -559.55 -1785 -560.85 -1782.7 -560.5 -1779.85 -560.1 -1777 -558.25 -1775.15 -556.2 -1773.15 -553.15 -1773.3 -540 -1773.95 -522.8 -1776 -517.75 -1776.6 -492.6 -1780 -457.7 -1784.65 -431.95 -1788.35
L -242.2 -1815.55
Q -238.5 -1816.05 -236.45 -1820 -234.5 -1823.75 -235.5 -1827.35 -236.6 -1831.25 -240.05 -1833 -243.2 -1834.6 -247.35 -1834.05 -426.25 -1808.8 -487.5 -1799.8 -515.7 -1795.7 -521.5 -1794.75 -540.75 -1791.65 -555.2 -1788.2 Z"
				/>
			</g>

			<g id="Layer3_17_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -322.5 -1244.75
Q -288.6 -1227.1 -214.6 -1189.1
L -149.2 -1155.45
Q -126.9 -1143.75 -115.75 -1137.9 -95.8 -1127.5 -81.85 -1121.25 -77.9 -1119.5 -74.15 -1120.35 -70.15 -1121.25 -68 -1124.9 -65.95 -1128.45 -67 -1132.55 -68.1 -1136.7 -71.65 -1138.75 -83.4 -1145.55 -101 -1154.4 -110.9 -1159.4 -130.8 -1169.3
L -305.45 -1258.35
Q -388.55 -1301.05 -509.2 -1366.75
L -568.25 -1398.9
Q -578.95 -1404.7 -600.4 -1416.5 -619.2 -1426.75 -632.9 -1433.4 -636.35 -1435.05 -639.65 -1433.9 -642.6 -1432.9 -644.3 -1430.05 -646 -1427.2 -645.4 -1424.2 -644.7 -1420.9 -641.4 -1418.85 -619.3 -1405.25 -586.75 -1387.35 -549.75 -1367.2 -531.4 -1357.05 -488.3 -1333.15 -429.8 -1301.55 -382.55 -1276 -322.5 -1244.75 Z"
				/>
			</g>

			<g id="Layer3_18_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1132.05 -1363.2
Q 1045.05 -1346.75 1001.55 -1338.65
L 743.2 -1290.3
Q 664.45 -1275.55 226.6 -1193.3 102.75 -1170.05 80.5 -1165.8 31.8 -1157.05 7.4 -1152.5 -35.4 -1144.45 -65.05 -1136.4 -68.4 -1135.5 -70.1 -1132.4 -71.65 -1129.6 -71.25 -1126.25 -70.85 -1122.8 -68.7 -1120.75 -66.3 -1118.4 -62.65 -1118.8 -35.15 -1121.8 2.85 -1128.8 24.65 -1132.8 68.05 -1141.25 107.45 -1148.6 198.5 -1165.75
L 456.8 -1214.3
Q 644.75 -1249.65 975.9 -1312.15 1024.6 -1321.3 1122 -1339.45 1206.9 -1355.5 1267.75 -1368.5 1271.9 -1369.4 1273.55 -1372.6 1275 -1375.5 1274 -1379.05 1272.95 -1382.55 1270.1 -1384.65 1266.9 -1386.95 1262.8 -1386.3 1207.9 -1377.35 1132.05 -1363.2 Z"
				/>
			</g>

			<g id="Layer3_19_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1505.75 -1622.25
Q 1502.55 -1622 1500.2 -1619.7 1497.85 -1617.4 1497.7 -1614.4 1497.5 -1611.05 1500.25 -1608.25 1523.2 -1584.9 1533.65 -1573.6 1551.85 -1554 1564.95 -1537.05 1594 -1499.55 1616.6 -1456.8 1627.9 -1435.55 1637.4 -1413.15 1640.35 -1406.2 1641.35 -1403.4 1641.5427734375 -1402.869921875 1642.45 -1400 1642.4080078125 -1399.933984375 1642.3 -1399.85 1641.65 -1399.35 1641.55 -1399.35 1637.45 -1397.7 1630.95 -1396.95 1623.5 -1396.3 1620.05 -1395.95 1568.7 -1390.6 1519.2 -1398.7 1491 -1403.4 1464.05 -1411.9 1450.5 -1416.2 1437.35 -1421.45 1431 -1424 1424.95 -1426.75 1422.0537109375 -1427.8470703125 1420 -1428.7 1419.95703125 -1429.1861328125 1419.85 -1429.7 1419.2 -1433 1416.4 -1435.45 1413.65 -1437.85 1410.05 -1438.35 1406.2 -1438.9 1402.9 -1437 1399.65 -1435.15 1398.25 -1431.5 1396.85 -1427.85 1397.85 -1424.2 1399.6 -1417.8 1408.05 -1413.6 1431.9 -1401.6 1464.05 -1391.9 1522.3 -1374.35 1582.9 -1374.9 1600.15 -1375.05 1612.95 -1376 1629.4 -1377.25 1642.7 -1380.05 1649.85 -1381.55 1654.6 -1385.5 1659.95 -1390 1661 -1396.65 1661.95 -1402.85 1658.7 -1411.45 1656.85 -1416.35 1652.45 -1425.4 1629.9 -1477.5 1597.25 -1524.3 1577.25 -1553 1557.95 -1575.2 1535.25 -1601.3 1512.15 -1620.2 1509.25 -1622.6 1505.75 -1622.25 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_0_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_1_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0955047607421875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_2_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.098724365234375, 96.8,254.45) ">
			<use xlinkHref="#Layer3_3_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_4_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_5_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_6_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_7_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_8_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_9_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_10_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_11_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_12_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_13_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_14_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_15_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_16_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0979766845703125, 0.002777099609375, -0.00274658203125, 0.093505859375, 94.5,245.95) ">
			<use xlinkHref="#Layer3_17_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_18_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer3_19_MEMBER_20_FILL" />
		</g>
	</svg>
);
