import React from "react";

export const homePage1 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="500px"
		height="356px"
		viewBox="0 0 500 356"
		id="frame-1"
	>
		<defs>
			<linearGradient
				id="Gradient_1"
				gradientUnits="userSpaceOnUse"
				x1="942.95"
				y1="558.95"
				x2="1200.95"
				y2="558.95"
				spreadMethod="pad"
			>
				<stop offset="15.686274509803921%" stopColor="#0C0C0C" />

				<stop offset="31.372549019607842%" stopColor="#0C0F0E" />

				<stop offset="42.745098039215684%" stopColor="#0C1816" />

				<stop offset="52.94117647058823%" stopColor="#0D2824" />

				<stop offset="61.96078431372549%" stopColor="#0E3F37" />

				<stop offset="70.98039215686275%" stopColor="#125C50" />

				<stop offset="79.2156862745098%" stopColor="#17806E" />

				<stop offset="87.45098039215686%" stopColor="#1EAA92" />

				<stop offset="95.29411764705883%" stopColor="#27DABB" />

				<stop offset="100%" stopColor="#2DFCD8" />
			</linearGradient>

			<g id="Layer1_0_FILL">
				<path
					fill="url(#Gradient_1)"
					stroke="none"
					d="
M 942.95 441.2
L 942.95 676.75 1201 604.95 1201 513 942.95 441.2 Z"
				/>
			</g>

			<g id="Layer1_0_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 925.45 560.15
Q 924.6 561 922.8 562.5 920.5 564.5 916.95 566.65 911 570.45 904.15 572.5 903.1 572.8 902.8 573.9 902.5 574.95 903.3 575.75
L 907.9 580.15
Q 907.276953125 580.4076171875 907 580.5
L 904.55 581.7
Q 902.95 582.35 902.15 582.75 900.7 583.45 900.3 584.35 899.95 585.1 900.2 585.8 900.45 586.55 901.25 586.75 902.05 587 902.8 586.75 903.15 586.65 904.25 586.15
L 907.2 584.8
Q 908.05 584.45 909.95 583.65 911.55 582.8 912.05 581.7 913.05 579.65 910.5 577.2
L 908.25 575.1
Q 913.9056640625 572.9294921875 918.75 569.7 921.95 567.5 924.6 565.15
L 927.3 562.6
Q 927.7 562.2 928.65 561.3 929.4 560.4 929.55 559.65 929.7 558.95 929.25 558.45 928.75 557.9 928.05 558.1 927.35 558.3 926.6 558.95 925.85 559.8 925.45 560.15 Z"
				/>
			</g>

			<g id="Layer1_1_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 796.5 615.95
Q 796.3 615.85 796.15 616.05 794 619.7 793.55 621.25 792.85 623.75 792.65 627.2 792.4 633.15 793.95 639.05 796.85 650.3 805.65 660.65 812.15 668.35 824.4 677.75 830.7 682.65 834.7 685.45 840.5 689.5 845.65 692.15 852.6 695.7 858.85 697.2 861.2 697.75 862.45 697.6 864.6 697.4 864.6 695.3 864.6 693.55 862.7 693.25 861.690625 693.086328125 861.1 693.5
L 859.95 693.25
Q 858.5 692.95 857.05 692.5 854.75 691.85 851.85 690.65 847.65 688.85 842.25 685.65 835.35 681.4 824.2 672.8 814.1 665.1 808.1 658.25 800.3 649.25 797.3 639.65 795.45 633.7 795.45 628.2 795.45 624.9 795.85 622.3 796.2 618.25 796.65 616.25 796.75 616.05 796.5 615.95 Z"
				/>
			</g>

			<g id="Layer1_2_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 819.6 591.2
Q 818.55 591.6 818.7 592.85 818.8 594.15 820 594 823.7 593.5 835.25 591.6 844.75 590 850.55 589.4 866.2 587.65 881.55 587.65 912.85 587.8 943 595.15 956.75 598.5 972.8 604.3 981.75 607.55 987.5 609.15 995.65 611.35 1002.75 611.85 1014.8 612.75 1032.1 610.1 1041.85 608.6 1061.25 605.3 1078.55 603.05 1090.5 604.85 1098.2 606.05 1102.45 608.3 1108.9 611.8 1108.4 618.1 1108.2 620.75 1106.15 622.45 1105.2 623.3 1106.1 624.4 1106.95 625.5 1107.95 624.75 1110.3 623.05 1111.3 620.25 1112.25 617.6 1111.75 614.6 1111.25 611.55 1109.2 608.95 1107.35 606.55 1104.45 604.85 1099.75 602.1 1091.15 600.75 1084.1 599.7 1076.1 599.85 1065.3 600.1 1045.8 603.45 1024.95 607 1015.55 607.55 999.65 608.45 985.15 603.95 980.65 602.55 969.8 598.65 960 595.15 954.35 593.5 923.6 584.65 891.05 583.45 875.15 582.85 858.95 584.15 840.15 585.75 827.2 589 822.7 590.15 819.6 591.2 Z"
				/>
			</g>

			<g id="Layer1_3_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 947.25 592.4
Q 947.2 592.6 947.4 592.7 962.25 598.6 985 600.8 994.65 601.75 1007.1 600.35 1012.2 599.8 1028.95 597.05 1055.25 592.85 1069.3 592.45 1091.9 591.8 1109.2 598.1 1118.85 601.6 1126.6 607.3 1129.25 609.2 1134.05 613.6 1135.4 614.85 1135.7 615.25 1136.65 616.4 1136.6 617.35 1136.55 617.9 1136.1 618.4 1135.6 618.95 1135.1 618.7 1133.9 618.05 1132.95 619.25 1131.95 620.4 1133.1 621.3 1134.9 622.75 1137.1 622.05 1139.2 621.4 1140.15 619.25 1141.9 615.4 1137.4 610.25 1132.2 604.1 1122.4 598.6 1106.7 589.8 1085.7 587.75 1075.3 586.75 1063 587.55 1053.4 588.2 1040.45 590.15 1024.8 592.65 1016.95 593.9 1009.2 595.1 1005.2 595.55 998.65 596.35 993.4 596.45 983.4 596.65 970.2 595.35 956.1 593.85 947.55 592.25 947.35 592.2 947.25 592.4 Z"
				/>
			</g>

			<g id="Layer1_4_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1137.9 619.3
Q 1137.4 620 1137.95 620.7 1139.45 622.5 1142.2 625.1 1145.65 628.4 1146.55 629.3 1151 633.95 1154.45 638.5 1158.9 644.3 1161 648.2 1164.2 654.1 1164.45 659.25
L 1164.45 660.75
Q 1164.45 661.044140625 1164.4 661.6 1162.5380859375 660.1466796875 1161.5 659.85 1159.75 659.35 1158.5 661 1158.2 661.45 1158.3 662.15 1158.4 662.75 1158.8 663.15 1159.4 663.8 1160.8 664.5 1162.8 666.05 1163.35 666.25 1165.05 666.9 1166.6 666.3 1168.25 665.65 1168.7 663.9 1170.15 658.45 1167 650.9 1164.85 645.6 1160.05 639.3 1155.5 633.35 1151.05 628.85 1147.55 625.25 1145.55 623.5 1142.25 620.6 1139.25 619.05 1138.45 618.6 1137.9 619.3 Z"
				/>
			</g>

			<g id="Layer1_5_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1114.25 616.8
Q 1114.5 616.95 1115.9 617.55 1117.75 618.4 1119.4 619.4 1122.15625 620.935546875 1124.45 622.8 1115.9041015625 636.5775390625 1105.6 648.25 1104.3138671875 647.08671875 1103.6 646.6 1102.5 645.85 1101.4 646.95 1100.3 648 1101.1 649.1 1101.65 649.9 1104.3 652.55 1104.85 653.1 1105.75 653.15 1106.6 653.15 1107.15 652.55 1119.75 638.7 1128.85 623.45 1129.2 622.9 1129.1 622.15 1129.05 621.4 1128.55 621 1124.75 617.75 1121.55 616.1 1119.65 615.15 1117.85 614.5 1116.3 613.95 1115.75 613.8 1114.5 613.55 1113.65 613.85 1113.1 614.05 1112.85 614.6 1112.6 615.15 1112.9 615.65 1113.4 616.35 1114.25 616.8 Z"
				/>
			</g>

			<g id="Layer1_6_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1192.8 516.1
Q 1191.95 515.25 1191.15 515.85 1187.65 518.25 1185.2 522.95 1183.55 526.15 1181.8 531.7 1179.05 540.45 1178.2 550.35 1176.45 569.1 1182.05 587.35 1185.25 597.8 1190.6 607 1191.5 608.6 1193.15 607.65 1194.75 606.7 1193.85 605.1 1185 589.45 1182.75 571.35 1180.5 553.45 1184.95 535.85 1186.4 529.95 1188 526.4 1189.5 522.9 1193.05 517.75 1193.6 516.9 1192.8 516.1 Z"
				/>
			</g>

			<g id="Layer1_7_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1198.3 497.3
Q 1196.9 497.55 1196.8 498.95 1196.6 501.5 1196.95 505.35 1197.15 507.45 1197.55 511.7 1198 517.95 1198.3 524.15 1198.9 536.85 1198.9 549.45 1199 575.3 1196.85 599.95 1195.5 614.65 1193.65 628.1 1193.55 629.1 1193.95 629.9 1194.4 630.75 1195.35 631.05 1196.25 631.3 1197.15 630.8 1198.15 630.3 1198.25 629.35 1201.9 602.95 1203 578.4 1204.2 553.35 1202.8 527.35 1202.2 517.55 1201.8 512.9 1201.55 508.1 1201.3 505.65 1200.95 501.4 1200.15 498.5 1199.75 497.1 1198.3 497.3 Z"
				/>
			</g>

			<g id="Layer1_8_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1212.25 502.35
Q 1208.65 500.45 1206.85 499.45
L 1204.15 498.05
Q 1203.25 497.6 1202.8 497.4 1201.95 497.05 1201.3 497.1 1200.85 497.1 1200.5 497.5 1200.15 497.95 1200.35 498.35 1200.75 499.3 1202.3 500.5 1204.5 502.2 1204.9 502.45 1208.7 505 1210 505.8
L 1218.15 510.45
Q 1219.1 510.95 1221 512.05 1221.9 512.45 1222.75 512.25 1223.7 512.05 1224.15 511.2 1224.65 510.4 1224.4 509.45 1224.15 508.5 1223.35 508.05 1221.35 507.15 1220.35 506.65
L 1217.85 505.35
Q 1215.2 504.05 1212.25 502.35 Z"
				/>
			</g>

			<g id="Layer1_9_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1221.95 507.6
Q 1221.6 507.6 1221.5 507.95 1221.1 510.15 1221.05 513.25 1221.05 515 1221.1 518.55
L 1221.35 550.4
Q 1221.65 571.8 1222.4 593.2 1222.5 597.2 1222.55 605.15 1222.65 607.65 1223 611.4
L 1223.6 617.6
Q 1223.7 618.4 1224.5 618.4 1225.3 618.4 1225.25 617.6 1225.45 610.3 1225.3 606.65 1225.15 603.3 1224.65 596.2 1224.3 591.75 1224.1 585.5 1223.95 578.35 1223.85 574.8 1223.35 557.05 1223 532
L 1222.85 520.05
Q 1222.95 516.05 1222.95 514 1222.9 510.4 1222.4 507.95 1222.35 507.6 1221.95 507.6 Z"
				/>
			</g>

			<g id="Layer1_10_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1222.35 621.1
Q 1220.7 621.35 1216.35 622.95 1212.65 624.25 1210.85 625 1207.35 626.45 1205.55 627.55 1204.6 628.1 1203 629.45 1201.35 630.75 1200.45 631.3 1200.3 631.4 1200.4 631.6 1200.45 631.85 1200.65 631.75 1201.65 631.35 1203.45 631 1205.6 630.6 1206.3 630.4 1207.25 630.15 1212 628.5 1214.9 627.5 1217.6 626.75 1218.1 626.6 1220.55 626 1222.35 625.55 1223.45 625.15 1224.95 624.6 1225 623.1 1225.05 622.6 1224.75 622.05 1223.95 620.85 1222.35 621.1 Z"
				/>
			</g>

			<g id="Layer1_11_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1225.9 547.15
Q 1224.85 547.6 1225.15 548.6 1226.35 553.05 1229.4 561.7 1231.45 568.3 1232.85 575.05 1235.7 588.25 1236.4 602.35 1237.75 630.25 1230.25 656.95 1226.2 671.55 1218.9 685.6 1217.95 687.45 1219.8 688.55 1221.7 689.65 1222.6 687.75 1234.95 661.8 1238.6 633.7 1242.25 605.6 1236.8 577.95 1235.35 570.4 1233 562.5 1231.45 557.3 1230.6 554.95 1229.1 550.7 1227.45 547.65 1226.95 546.7 1225.9 547.15 Z"
				/>
			</g>

			<g id="Layer1_12_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1124.4 628.7
Q 1124.25 629.05 1124.45 629.3 1125.35 630.65 1127.4 631.95 1128.5 632.7 1130.8 634.1 1134.15 636.35 1136.95 638.8 1142.7 644 1147.45 651.1 1151.1 656.55 1155.4 665.65 1156.85 668.7 1157.15 669.6 1157.95 672.05 1157.25 673.75 1156.35 675.8 1154.5 675.25 1153.2 674.8 1151.6 673.15 1144.9 666.15 1142.35 655.7 1141.9 653.95 1140.15 654.45 1138.4 654.95 1138.75 656.7 1140.65 666.45 1148 674.7 1150.15 677.05 1151.8 678.05 1154.3 679.55 1156.65 678.9 1158.95 678.25 1160.3 676 1161.55 673.9 1161.45 671.4 1161.35 669.2 1160.35 666.4 1160.05 665.6 1158.4 661.7 1156.75 657.85 1153.95 653.15 1148.8 644.4 1142.45 638.1 1138.35 634.2 1134.4 631.65 1132.45 630.3 1129.7 629.1 1126.7 627.9 1124.85 628.25 1124.55 628.35 1124.4 628.7 Z"
				/>
			</g>

			<g id="Layer1_13_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1128.8 675.2
Q 1128.9 679.5 1131.1 682.75 1132.6 685 1134.1 685.9 1135.05 686.5 1136.25 686.6 1137.4 686.7 1138.45 686.3 1142.35 685 1143.6 679.5 1144.1 676.7 1143.85 674.5 1143.5 670.85 1142.25 669.35 1141.65 668.7 1140.85 668.95
L 1140.55 669.1 1140.5 669.1
Q 1140.15 669.25 1139.95 669.65 1139.75 670 1139.8 670.35 1139.85 671.05 1140.15 672.2 1140.55 673.5 1140.65 674 1140.9 675.8 1140.75 677.35 1140.4 681.8 1138.2 682.85 1136.15 683.85 1134.35 680.7 1132.95 678.3 1132.85 675.55 1132.8 674.4 1133.15 672.5 1134.1 670.1 1134 668.9 1133.9 667.95 1133.2 667.55 1132.45 667.15 1131.6 667.55 1130.25 668.2 1129.3 671.2 1128.7 672.85 1128.8 675.2 Z"
				/>
			</g>

			<g id="Layer1_14_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1124.7 668.2
Q 1124.2 667.45 1123.45 667.7 1120.7 668.65 1118.45 670.85 1115.7 673.4 1115.05 676.05 1114.5 678.2 1115.4 679.65 1116.55 681.4 1118.95 681.4 1119.7 681.45 1122.05 681.2 1124.15 680.95 1125.95 680.2 1127.85 679.35 1128.7 678.15 1129.25 677.55 1129.65 676.65
L 1129.95 675.85
Q 1130.05 675.4 1129.85 674.6 1129.65 673.85 1128.9 673.65 1128.2 673.5 1127.6 674 1127.55 674 1127.5 674.05
L 1127.2 674.25 1126.9 674.75 1126.85 674.85 1126.2 675.5
Q 1126.5 675.15 1125.9 675.7
L 1125.7 675.85 1125.75 675.8 1125.55 675.95 1124.85 676.35
Q 1124.8 676.35 1124.7 676.45
L 1122.4 677 1122.35 677 1119.3 677.15 1119.8 675.65 1120.05 675.1
Q 1120.75 673.7 1121.2 673.05
L 1121.75 672.2
Q 1121.7 672.3 1121.8 672.2
L 1122.6 671.3
Q 1123.5 670.35 1124.55 669.5 1125.15 669 1124.7 668.2 Z"
				/>
			</g>

			<g id="Layer1_15_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 965.6 455.75
Q 965.6 455.85 965.7 455.9 983.4 462.05 994.25 469.35 1007.6 478.35 1016.7 491 1022.75 499.5 1023.3 506.35 1023.6 510.05 1022.55 513.4 1021.4 517 1018.85 519.35 1013.85 523.95 1004.7 522.25 998.55 521.15 989.35 516.75 972.8 508.8 958.7 494.85 957.65 493.85 956.6 494.9 955.55 495.95 956.55 496.95 968.8 509.7 985.55 518.25 995.3 523.25 1001.95 524.8 1011.4 527 1017.65 523.8 1021.15 522.05 1023.4 518.55 1025.55 515.3 1026.15 511.2 1027.25 503.35 1022.7 494.95 1019 488.05 1012.2 480.9 1006.7 474.95 999.85 469.95 982.7 457.6 965.75 455.6 965.6 455.6 965.6 455.75 Z"
				/>
			</g>

			<g id="Layer1_16_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 915.4 552.5
Q 917.6 554.75 920.5 557.1 926.05 561.5 932.15 564.5 938.7 567.6 945.35 569.15 949.65 570.1 951.8 570.35 955.6 570.7 958.25 569.75 961.95 568.4 962.95 564.75 963.4 562.95 963.2 561.15 963 558.9 961.8 558 961.55 557.8 961.2 557.9 960.9 558 960.75 558.25 960.3 558.85 960.25 559.75 960.25 560.25 960.3 561.35 960.3 563.5 959.8 564.6 958.7 567.15 954.8 567.25 949.6 567.4 941.3 564.65 927.7 560.05 917.8 549.8
L 915.2 546.85
Q 914.4 545.8 913.95 545.25 913.1 544.4 912.25 544.25 909.7 543.9 910 546.45 910.1849609375 548.3001953125 911.5 548.2 911.659765625 548.359765625 911.85 548.55
L 915.4 552.5 Z"
				/>
			</g>

			<g id="Layer1_17_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 912.65 455.55
Q 912.4 456.55 912.8 457.45 913.01015625 457.954296875 913.3 458.3 913.4154296875 459.1857421875 913.8 459.85 914.1421875 460.412109375 914.65 460.75 914.7107421875 460.866015625 914.75 460.95 914.95 461.35 915.4 461.5 915.9 461.7 916.3 461.55 916.496875 461.4904296875 916.65 461.4 917.014453125 461.40703125 917.25 461.25 917.3044921875 461.2158203125 917.35 461.15 919.703515625 460.2138671875 920.2 458.6 920.65 457.4 920.1 456.2 919.6431640625 455.16171875 918.7 454.75 918.2201171875 453.745703125 916.95 453.25 915.25 452.65 913.65 453.95 912.9 454.55 912.65 455.55
M 915.3 456.3
L 915.3 456.15 915.35 456.05 915.35 456 915.4 456 915.4 456 915.45 455.95 915.9 455.85 916.05 455.85 916.1 455.85 916.05 455.85 916.1 455.9 916.15 455.9 916.1 455.9 916.1 455.85 916.15 455.9 916.15 455.95 916.15 455.9
Q 916.1734375 455.9734375 916.2 456
L 916.2 456 916.2 456.05 916.2 456.1
Q 916.2365234375 456.1232421875 916.25 456.15
L 916.25 456.15
Q 916.19765625 456.2001953125 916.15 456.2 915.8044921875 456.4134765625 915.5 456.65
L 915.4 456.55 915.4 456.5 915.35 456.4 915.35 456.4 915.3 456.3
M 916.15 455.95
L 916.2 456.1
Q 916.2091796875 456.0595703125 916.2 456
L 916.15 455.95
M 915.4 456
L 915.35 456.05 915.35 456.1 915.4 456 915.4 456 Z"
				/>
			</g>

			<g id="Layer1_18_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 925.25 466.05
Q 924.35 465.95 924 466.85 923.95 467 922.9 470 922.3 471.95 921.5 473 920.6 474.25 919.35 474.85 918 475.45 916.6 475.05 914.4 474.45 910.45 472.05 908.55 470.85 907.6 470.15 906.6 469.4 906.05 469.05 905.15 468.45 904.35 468.3 903.45 468.15 902.95 468.8 902.45 469.45 902.85 470.25 903.25 471.1 904.25 471.95 904.35 472 906.05 473.2 907.85 474.4 909.8 475.45 912.3 476.85 913.55 477.4 915.75 478.35 917.55 478.4 921.55 478.45 924.05 474.65 926.3 471.25 926.2 467.15 926.2 466.15 925.25 466.05 Z"
				/>
			</g>

			<g id="Layer1_19_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 897.15 443.35
Q 896.75 443.3 896.5 443.5 894.7 444.75 893.65 447.4 893.15 448.65 893.05 449.45 892.6 451.3 892.85 452.15 893.15 453.1 894.15 453.1 895.15 453.1 895.45 452.15 895.5 452 895.65 450.9
L 895.7 450.4 895.7 450.2 896.2 448.25 896.5 447.55 897.9 444.95
Q 898.3 444.2 897.75 443.65 897.5 443.4 897.15 443.35 Z"
				/>
			</g>

			<g id="Layer1_20_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 869.65 443.95
Q 866.8 443.45 864.5 444.85 861 447 860.8 452.9 860.6 458.1 862.1 464 863 467.55 864.8 470.1 867.4 473.85 869.6 474.4 870.5 474.65 871.15 473.85 871.8 473 871.25 472.2 870.9 471.65 870.2 471 869.4 470.3 869 469.9 868 468.85 867.3 467.7 865.8 465.35 865.15 462 863.5 455 864.25 451 864.75 448.4 866.2 447.4 867.3 446.6 868.9 446.75 870.25 446.85 871.6 447.6 873.4 448.6 876.2 451.6
L 878.6 454
Q 879 454.4 879.85 455.3 880.65 456 881.3 456.3 881.75 456.5 882.2 456.25 882.65 455.95 882.45 455.4 882.05 454.25 880.85 452.8 879.4 451.2 878.75 450.45 876.1 447.4 874.5 446.2 872.2 444.4 869.65 443.95 Z"
				/>
			</g>

			<g id="Layer1_21_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 872.75 455.55
Q 872.2 454.75 871.25 455 870.3 455.25 870.3 456.2 870.25 456.8 870.7 458.15 870.95 458.95 871.4 459.95
L 872.15 461.4 873.1 462.75
Q 873.65 463.2 874.3 463.2 874.8 463.2 875.2 462.9 875.6 462.55 875.7 462.1 875.95 461.35 875.3 460.45
L 874.9 459.8
Q 874.4 459.1 874.05 458.45
L 873.35 457.05 873.4 457.1 873.3 456.9 873.2 456.6 872.75 455.55 Z"
				/>
			</g>

			<g id="Layer1_22_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 883.65 383.65
Q 883.2 384.85 883.3 386.3 883.55 390.65 887.55 396.1
L 891.4 400.9
Q 892.15 401.8 893.2 403.55 894.35 405.4 894.9 406.15 895.1 406.4 895.5 406.25 895.85 406.1 895.85 405.75 895.75 404.35 894.25 401.55 893 399.35 891.7 397.6 889.4 394.5 888.95 393.8 887.5 391.6 886.8 389.7 885.95 387.2 886.2 385.35 886.35 384.3 886.85 384.05 887.3 383.9 888.25 384.3
L 889.55 384.95
Q 893.85 387.2 896.85 392.1 898.5 394.8 899.8 398.35
L 900.9 401.5
Q 901.1 402.05 901.45 403.25 901.85 404.3 902.45 404.75 903 405.15 903.6 405 904.25 404.9 904.45 404.2 904.7 403.45 904.45 402.5 904.4 402.3 903.95 400.9
L 902.7 397.3
Q 901.25 393.55 899.7 390.9 896.1 384.85 891.05 382.35 887.2 380.3 885.2 381.55 884.1 382.2 883.65 383.65 Z"
				/>
			</g>

			<g id="Layer1_23_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 946 389.15
Q 943.25 388.9 939.85 389.3 933.3 390.1 927.85 393.1 924.85 394.7 922.15 397.1 920.5 398.6 919.6 399.7 918.85 400.7 918.65 401.05 918.2 401.85 918.3 402.6 918.4 403.4 919 403.3 920 403.25 921.15 402.3 921.8 401.75 922.95 400.6 924.9 398.85 927.25 397.45 931.75 394.65 936.95 393.45 943.4 392.05 947.35 393.1 949.75 393.7 949.1 396.2 948.8 397.3 947.85 398.45 947.35 399.05 945.95 400.3
L 940.3 405.45
Q 938.35 407.2 937.4 408.1 935.75 409.65 934.85 411.05 934.25 411.95 935.1 412.7 935.9 413.45 936.8 413 938.25 412.25 940.05 410.6 941.1 409.7 943.05 407.85
L 948.8 402.65
Q 951.5 400.2 952.5 397.6 953.8 394.25 951.85 391.65 950.15 389.45 946 389.15 Z"
				/>
			</g>

			<g id="Layer1_24_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 955.6 406.9
Q 962.45 404.6 969.3 404.15 976.45 403.6 983.2 404.95
L 986.4 405.7
Q 988.3 406.1 988.75 406.65 989.3 407.35 987.75 408.9 987.2 409.45 985.5 410.85 982.65 413.15 978.9 414.35 975.9 415.35 971.55 415.85 970.2 416.05 970.05 417.15 969.95 417.7 970.3 418.2 970.6 418.75 971.15 418.8 974.9 419.4 979.05 418.2 982.9 417.1 986.3 414.8 990.7 411.85 992.15 409.2 993.1 407.35 992.4 405.55 991.75 403.8 989.85 402.85 987.75 401.85 982.4 400.95 978.15 400.3 974.25 400.25 965.9 400.2 957.8 402.45 953.55 403.65 949.25 405.65 946.9 406.75 945.25 407.8 943.7 408.7 943.25 409.05 942.2 409.9 942.05 410.95 942 411.3 942.3 411.65 942.6 412 942.95 412.1 943.65 412.35 944.45 412 944.95 411.8 945.85 411.25
L 949.15 409.5
Q 952.1 408.1 955.6 406.9 Z"
				/>
			</g>

			<g id="Layer1_25_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 957.35 420
L 957.55 419.9
Q 957.95 419.25 959.7 419.1
L 963.3 418.85
Q 967.8 418.55 970.15 418.5 978.05 418.35 983.7 420 989.9 421.9 995.3 427.05 997.55 429.25 1000.15 432.25
L 1001.3 433.55
Q 1001.6 433.85 1001.6 433.8 1001.45 434.2 1000.6 434.45
L 999.4 434.7
Q 995.65 435.4 991.75 434.75 990.1 434.5 987.8 433.75 984.35 432.55 984 432.45 982.75 432.1 982.3 433.3 981.9 434.4 982.85 435.15 985.6 437.25 991.3 438.05 995.6 438.75 999.9 438.05 1001.9 437.8 1003.2 436.95 1004.9 435.9 1005 434.15 1005.1 432.7 1003.45 430.75
L 1000.3 427.25
Q 993.55 419.95 986.6 417.4 979.7 414.85 969.5 415.4 963.2 415.7 959.75 416.35 957.35 416.8 956.75 418.1 956.45 418.65 956.6 419.2 956.75 419.8 957.35 420 Z"
				/>
			</g>

			<g id="Layer1_26_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 960.85 508.95
Q 960.55 511.6 960.1 519.55 960.05 520.55 960.85 521.3 961.65 522.1 962.6 522.05 963.65 522 964.35 521.3 965.05 520.6 965.1 519.55 965.5 512.45 965.45 508.95 965.45 508.05 964.75 507.3 964.05 506.6 963.15 506.65 961.1 506.75 960.85 508.95 Z"
				/>
			</g>

			<g id="Layer1_27_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 953.15 512.35
Q 952.7 511.75 951.75 511.95 951.181640625 512.1248046875 950.85 512.75 950.6208984375 513.053515625 950.55 513.55 950.45 513.9 950.4 514.65
L 950.5 514.15 950.25 517.65
Q 950.15 518.65 950.9 519.3 951.4 519.75 952.05 519.85 952.75 519.95 953.35 519.6 953.95 519.25 954.25 518.55 954.45 518.15 954.45 517.55
L 954.4 515.75
Q 954.3 514.6 954.15 514.1
L 953.7 512.95
Q 953.56875 512.73125 953.35 512.55
L 953.15 512.35 Z"
				/>
			</g>

			<g id="Layer1_28_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 941.2 511.9
Q 940.5 512 940.15 512.4
L 939.8 512.85
Q 939.4 513.15 939.25 513.75
L 939.2 513.85 938.05 517.1 938 517.4
Q 937.75 518.2 938.25 519.15 938.7 520 939.55 520.15 940.45 520.4 941.2 519.95 942.05 519.5 942.4 518.3
L 942.55 517.7
Q 942.8 516.95 942.85 516.55
L 943.1 515.4
Q 943.35 514.55 943.3 514.3
L 943.25 513.7
Q 943.15 513.05 943 512.75 942.7 512.25 942.15 512 941.6 511.85 941.2 511.9 Z"
				/>
			</g>

			<g id="Layer1_29_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 931.95 515.7
Q 931.9 515.4 931.55 515.05 931.45 514.9 930.95 514.65
L 930.2 514.5 929.65 514.55
Q 929.15 514.75 928.8 515.05
L 928.5 515.45
Q 928.25 515.85 928.25 516.45
L 928.05 517.7
Q 928.05 518.15 928.25 518.5 928.3 518.85 928.65 519.1 928.85 519.35 929.3 519.55 929.6 519.7 930.05 519.7
L 930.6 519.65
Q 931.05 519.5 931.45 519.1
L 931.75 518.7
Q 932.05 518.25 932.05 517.7
L 932.15 516.45
Q 932.15 515.95 931.95 515.7 Z"
				/>
			</g>

			<g id="Layer1_30_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 960.75 541.55
L 960.35 543.9
Q 960.3 544.45 960.05 545.65 959.95 546.7 960.35 547.3 960.85 548.05 961.7 548.15 962.45 548.25 963.15 547.7 963.65 547.3 963.85 546.35 964.05 545.25 964.1 544.75
L 964.4 541.95
Q 964.55 539.55 964.55 539.5 964.6 539.1 964.5 538.2
L 964.4 537.4 964.05 536.65
Q 963.75 536 962.95 536.05 962.2 536.05 961.85 536.65 961.2 537.7 961 539.5
L 960.8 540.95 960.85 540.85 960.8 541 960.75 541.55 Z"
				/>
			</g>

			<g id="Layer1_31_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 954.05 539.8
Q 953.25 539.8 952.9 540.45
L 952.7 540.8
Q 952.55 541.2 952.45 541.85
L 952 545.45
Q 951.95 545.8 951.8 546.55 951.85 547.1 952.2 547.85 952.6 548.55 953.45 548.65 954.25 548.75 954.85 548.2
L 955.35 547.6
Q 955.55 547.2 955.65 546.55
L 955.75 545.7 955.8 542.55
Q 955.65 541.45 955.65 541.4 955.5 540.9 955.2 540.45 954.7 539.8 954.05 539.8 Z"
				/>
			</g>

			<g id="Layer1_32_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 946.85 538.9
Q 946.5 538.75 946.2 539.05 945.7 539.6 945.6 539.7
L 944.6 542
Q 943.8 544.35 943.65 545 943.5 545.85 943.85 546.5 944.3 547.2 945 547.35 945.75 547.55 946.45 547.2 947.2 546.8 947.35 546.05 947.55 544.9 947.65 542.55 947.7 541.8 947.65 541
L 947.6 540.25 947.35 539.35
Q 947.2 539 946.85 538.9 Z"
				/>
			</g>

			<g id="Layer1_33_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 835.55 565.9
Q 854.45 566.8 867.6 569.85 885.8 574.1 898.15 584.3 898.7 584.75 899.2 584.25 899.7 583.75 899.2 583.25 889.4 573.55 871.7 568.55 858.85 564.95 839.2 563.6 818.35 562.1 801.3 564.85 792 566.4 785.2 569 776.7 572.2 770.9 577.35 764.35 583.15 761.7 591.3 758.8 600 761.8 607.8 762.4 609.35 764.1 608.9 765.75 608.4 765.15 606.85 762.6 600.4 764.7 593.05 766.6 586.25 771.75 581 780.65 571.9 799.2 568.15 815.05 564.95 835.55 565.9 Z"
				/>
			</g>

			<g id="Layer1_34_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 789.05 634.3
Q 781.4 632.75 773.8 627.7 764.95 621.8 764.1 615.35 763.6 611.1 767.1 607.1 768.05 606 767 604.9 765.95 603.85 764.95 604.95 761.35 609.15 761.05 613.75 760.9 617.85 763.55 621.95 768.15 628.95 778.7 633.9 784.1 636.4 788.25 637.2 789.7 637.45 790.1 636 790.5 634.55 789.05 634.3 Z"
				/>
			</g>

			<g id="Layer1_35_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 864.5 576.35
Q 864.5 574.85 863.05 574.65 842.95 572.25 823 576.2 802.9 580.2 785.4 590.15 775.65 595.7 766.5 603.35 765.4 604.3 766.45 605.35 767.5 606.4 768.6 605.5 783.7 592.7 802.45 585.35 821.1 578 840.95 576.9 852 576.3 863.05 577.65 864.5 577.85 864.5 576.35 Z"
				/>
			</g>

			<g id="Layer1_36_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1101.25 704.2
Q 1099.6 704.2 1098.8 704.25 1097.45 704.4 1096.5 704.9 1095.55 705.4 1095.5 706.65 1095.45 707.9 1096.5 708.35 1098 709.05 1100.45 708.95 1101.85 708.95 1104.5 708.65 1107.3 708.55 1112.5 708.25 1117.75 708 1128.2 707.7 1159.8 706.65 1159.95 706.65
L 1168.9 706.3
Q 1175.1 706 1177.75 705.35 1178.6 705.15 1178.65 704.15 1178.65 703.1 1177.75 702.95 1174.45 702.4 1169.85 702.45 1164.55 702.65 1161.95 702.7
L 1146.2 703.15
Q 1114.65 704.05 1114.5 704.05
L 1101.25 704.2 Z"
				/>
			</g>

			<g id="Layer1_37_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1163.3 685.2
Q 1163.45 685.85 1164.05 686.55 1164.4 686.95 1165.15 687.7
L 1167.05 689.8
Q 1168.35 691.2 1170.85 694.2
L 1174.5 698.5
Q 1175.8 699.95 1176.3 700.45 1177.5 701.5 1178.6 701.7
L 1178.65 701.65
Q 1178.95 700 1177.05 696.9 1175.5 694.35 1173.6 691.95 1171.2 688.85 1169.9 687.35
L 1168.1 685.2
Q 1167.05 684 1166.95 683.9 1166.25 683.25 1165.5 683 1164.5 682.7 1163.8 683.45 1163.1 684.2 1163.3 685.2 Z"
				/>
			</g>

			<g id="Layer1_38_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1104.95 686.7
Q 1103.8 686.15 1103.05 686.9 1101.9 688 1100.2 690.7
L 1098.05 694.4
Q 1096 698.4 1094.3 702.75 1094 703.6 1094.55 704.45 1095.05 705.3 1095.9 705.55 1096.85 705.8 1097.65 705.35 1098.45 704.85 1098.7 703.95 1100.15 699.45 1101.7 696.2
L 1103.6 692.35
Q 1105.35 689.1 1105.6 688.4 1106.05 687.3 1104.95 686.7 Z"
				/>
			</g>

			<g id="Layer1_39_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1098.1 710.6
Q 1098.1 713.15 1100.55 713.2 1132.7 713.95 1159.15 711.15 1163.35 710.7 1167.55 710.1 1170.45 709.7 1171.5 709.5 1173.65 709 1175.25 708.05 1175.7 707.8 1175.6 707.3 1175.55 706.8 1175.05 706.65 1173.5 706.1 1171.55 706.05 1170.6 706.05 1168 706.2
L 1146.35 707.4
Q 1133.05 708.2 1117.05 708.3 1107.9 708.3 1100.55 708.1 1098.1 708.05 1098.1 710.6 Z"
				/>
			</g>

			<g id="Layer1_40_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1101.05 667.55
L 1100.15 667.15
Q 1099.6 667.05 1099.35 667 1098.6 666.9 1098.35 667.5 1098.15 668.2 1098.7 668.5
L 1099.35 668.85 1100.2 669.15
Q 1100.4458984375 669.2482421875 1100.35 669.25
L 1100.45 669.25 1104.1 671.35
Q 1104.4 671.45 1104.55 671.35
L 1104.6 671.25
Q 1104.8 671.05 1104.7 670.75
L 1104.25 670 1103.7 669.45
Q 1103 668.75 1102.4 668.35
L 1101.05 667.55 Z"
				/>
			</g>

			<g id="Layer1_41_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1174.15 664.85
L 1173.6 665.35 1172.6 666.5
Q 1171.2 668 1170.6 668.8 1169.3 670.3 1169.2 671.35 1169.15 671.8 1169.45 672.15 1169.8 672.55 1170.25 672.45 1170.485546875 672.355859375 1170.3 672.1
L 1170.3 672.15 1170.3 672.1 1170.3 672.1 1170.65 671.8 1171.1 671.55
Q 1171.65 671.3 1173.05 670.1 1173.8 669.55 1175.1 668.35 1175.45 668.1 1176.05 667.5
L 1176.7 666.8
Q 1177.05 666.4 1177.05 665.7 1177.15 665.15 1176.6 664.65 1176.1 664.15 1175.55 664.2 1174.75 664.3 1174.15 664.85 Z"
				/>
			</g>

			<g id="Layer1_42_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1092.4 673.95
Q 1092.25 674.3 1092.4 674.55 1092.95 675.65 1095.15 675.75
L 1097.85 675.7
Q 1098 675.65 1098 675.55 1097.95 675.4 1097.85 675.4 1097.15 675.4 1096.55 675.25 1095.9 675.1 1095.4 674.8 1094.6 674.35 1094.2 674.1 1093.5 673.7 1092.95 673.65 1092.6 673.65 1092.4 673.95 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_0_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_0_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_1_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_2_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_3_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_4_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_5_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_6_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_7_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_8_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_9_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_10_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_11_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_12_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_13_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_14_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_15_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_16_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_17_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_18_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_19_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_20_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_21_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_22_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_23_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_24_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_25_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_26_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_27_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_28_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_29_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_30_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_31_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_32_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_33_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_34_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_35_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_36_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_37_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_38_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_39_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_40_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_41_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_42_MEMBER_1_FILL" />
		</g>
	</svg>
);
