import React from "react";

export const homePage5 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="500px"
		height="356px"
		viewBox="0 0 500 356"
		id="frame-5"
	>
		<defs>
			<linearGradient
				id="Gradient_1"
				gradientUnits="userSpaceOnUse"
				x1="942.95"
				y1="558.95"
				x2="1200.95"
				y2="558.95"
				spreadMethod="pad"
			>
				<stop offset="15.686274509803921%" stopColor="#0C0C0C" />

				<stop offset="31.372549019607842%" stopColor="#0C0F0E" />

				<stop offset="42.745098039215684%" stopColor="#0C1816" />

				<stop offset="52.94117647058823%" stopColor="#0D2824" />

				<stop offset="61.96078431372549%" stopColor="#0E3F37" />

				<stop offset="70.98039215686275%" stopColor="#125C50" />

				<stop offset="79.2156862745098%" stopColor="#17806E" />

				<stop offset="87.45098039215686%" stopColor="#1EAA92" />

				<stop offset="95.29411764705883%" stopColor="#27DABB" />

				<stop offset="100%" stopColor="#2DFCD8" />
			</linearGradient>

			<g id="Layer1_0_FILL">
				<path
					fill="url(#Gradient_1)"
					stroke="none"
					d="
M 942.95 441.2
L 942.95 676.75 1201 604.95 1201 513 942.95 441.2 Z"
				/>
			</g>

			<g id="Layer5_0_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 937.65 390.05
Q 934.55 391.35 931.6 392.85 928.15 394.6 925.4 396.45 923.35 397.75 922.35 398.5 920.65 399.85 919.9 401.15 919.7 401.55 919.95 401.9 920.25 402.3 920.75 402.2 922.05 401.9 923.85 400.8 924.9 400.15 926.8 398.9 930.3 396.8 933.1 395.4 936.3 393.75 939.25 392.6 941.55 391.7 942.65 391.5 944.5 391.15 946.05 391.9 947.3 392.55 948.85 393.8 949.2 394.1 950.05 394.7 950.7 395.35 950.9 395.9 951.15 396.45 950.85 397.15 950.75 397.45 950.2 398.25
L 948.05 401.1
Q 945.9 403.9 943.4 406.5 939.95 409.75 938.3 411.5 937.55 412.3 938.3 413.3 939.05 414.3 939.95 413.6 943.7 410.85 949 404.55 952 400.85 952.95 399.4 954.75 396.65 953.4 394.2 952.85 393.15 951.6 392.1 951.1 391.65 949.35 390.4 946.5 388.35 943.9 388.3 942.45 388.25 940.7 388.85 939.7 389.15 937.65 390.05 Z"
				/>
			</g>

			<g id="Layer5_1_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 946.2 410.4
Q 946.5 410.75 946.85 410.6 948.6 410 951.75 408.05 954.2 406.8 957.15 405.75 962.05 404 968.8 402.9 973.7 402.2 975.1 402.2 978.2 402.3 981.15 403.6 984.3 404.9 986.45 406.35 989.15 408 988.4 409.9 987.95 411 986.7 411.95 985.95 412.5 984.35 413.4
L 977.25 417.3
Q 976 418 976.75 419.3 977.5 420.6 978.75 419.9 985.2 416.35 986.05 415.85 989.1 414.25 990.5 412.25 991.35 410.95 991.5 409.5 991.6 408 990.9 406.65 989.9 404.75 986.75 403.05 982.95 400.95 980.1 400 976.8 398.95 972.8 399.35 965.95 400 959 402.2 954.65 403.65 951.95 405.15 947.55 407.55 946.15 409.75 945.95 410.05 946.2 410.4 Z"
				/>
			</g>

			<g id="Layer5_2_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 954.75 418.4
Q 954.75 418.65 954.95 418.75 956.1 419.3 957.95 419.3 959 419.35 961.05 419.25 965.45 419.25 967.65 419.35 975.2 419.6 980.9 420.85 987.55 422.25 993 425.15 994.95 426.2 995.85 426.8 997.4 427.85 998.2 429.05 999 430.15 999.05 431.4 999.1 432.85 998 433.55 997.1 434.1 994.85 434 992.5 433.85 990.7 433.5 988.9 433.25 986.65 432.65 985.4 432.3 984.8 432.2 983.75 432 982.9 432.2 982.35 432.35 982.2 432.9 982.1 433.4 982.45 433.85 983 434.55 984.05 434.9 984.15 434.95 985.9 435.45 988.15 436 990.15 436.3 995.45 437.15 997.75 436.7 999.4 436.35 1000.5 435.15 1001.65 433.95 1001.9 432.3 1002.35 428.95 999.85 426.3 998.2 424.5 993.8 422.2 990.05 420.35 986.7 419.3 979.95 417.15 971.7 416.65 967.6 416.35 963.2 416.6 961.75 416.65 958.95 416.95 957.55 417.1 956.9 417.25 955.7 417.5 954.95 418 954.75 418.15 954.75 418.4 Z"
				/>
			</g>

			<g id="Layer5_3_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 924.6 468.7
Q 924.2 468.55 923.95 468.85 922.2 471.4 921.25 472.6 919.5 474.75 917.3 474.85 915.15 474.9 911.95 473.65 909.35 472.55 908.45 470.9 907.85 469.8 906.75 470.45 905.6 471.1 906.2 472.2 907.45 474.5 910.8 475.9 914.65 477.4 917.45 477.2 920.5 476.9 922.55 474.45 924.75 471.8 924.95 469.25 925 468.85 924.6 468.7 Z"
				/>
			</g>

			<g id="Layer5_4_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 896.25 444
Q 896.38515625 444.039453125 896.5 444.1
L 896.2 444 896.25 444
Q 895.3685546875 443.7693359375 894.05 445 893.3 445.7 892.65 447.05 892.1 448.35 891.95 449.5 891.85 450.25 891.9 450.6 891.9 451.65 892.65 452.15 893.3 452.6 893.85 451.85
L 893.9 451.8
Q 894.1 451.5 894.1 451.3 894.15 451.2 894.15 450.6
L 894.15 449.6 894.2 449.45 894.2 449.3 894.3 448.8 894.55 448.1
Q 894.55 448.05 894.6 448
L 894.6 448.05
Q 894.75 447.55 895.05 447.15
L 895.35 446.7
Q 895.35 446.65 895.4 446.65
L 896.15 445.7
Q 896.5873046875 445.2626953125 896.7 444.85
L 896.85 444.45
Q 896.95 444.15 896.65 444.05
L 896.25 444 Z"
				/>
			</g>

			<g id="Layer5_5_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 880.65 455.4
Q 880.75 455.35 880.7 455.25 880.05 452.65 878.85 451.1 877.4 449.25 875.45 447.8 870.55 444.25 866.75 445.3 864.4 445.95 863.55 448.3 862.8 450.25 863.2 452.85 863.9 458.25 865.85 464.3 867.9 470.5 871.15 476.5 871.75 477.6 872.85 476.95 873.9 476.35 873.3 475.25 870.2 469.5 868.3 463.6 865.9 456.3 865.5 451.75 865.2 448.15 867.3 447.4 869.35 446.7 872.4 448.15 876.75 450.2 880.45 455.35 880.55 455.45 880.65 455.4 Z"
				/>
			</g>

			<g id="Layer5_6_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 871.5 454.5
Q 871.15 454.65 871.15 455 871.25 456.05 872.3 458.8
L 873.7 462.6
Q 874.1 463.55 874.95 463.25 875.3 463.15 875.55 462.8 875.8 462.4 875.65 462.05
L 874 458.2
Q 872.8 455.45 872.1 454.6 871.9 454.35 871.5 454.5 Z"
				/>
			</g>

			<g id="Layer5_7_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 963.4 509
Q 962.55 509 962.4 509.7 962.2 510.6 962.1 512.35
L 962.05 518.7
Q 962.05 519.75 962.75 520.2 963.4 520.6 964.05 520.05 964.5 519.7 964.55 518.8 964.5 517.75 964.5 517.3
L 964.45 514.85
Q 964.45 510.7 964.3 509.7 964.25 508.95 963.4 509 Z"
				/>
			</g>

			<g id="Layer5_8_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 952.5 512.6
L 951.95 512.7
Q 951.5 513.05 951.5 513.35
L 951.35 515.2
Q 951.35 516.05 951.2 516.85
L 951.1 517.55
Q 951.1 518.05 951.45 518.5 951.85 519 952.35 518.95 953.4 518.85 953.65 517.85
L 953.8 516.8
Q 953.9 515.95 953.8 515.2 953.7 513.95 953.5 513.35 953.2 512.55 952.5 512.6 Z"
				/>
			</g>

			<g id="Layer5_9_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 940.5 513.35
Q 939.6 513.35 939.5 514.05 939.35 514.8 939.25 517.35
L 939.15 519.55
Q 938.991796875 519.803125 938.95 520.2 938.9 520.8 939.5 521.5 940.1 522.15 941 521.8 941.9 521.4 941.85 520.55
L 941.7 517.35
Q 941.6 514.8 941.45 514.05 941.35 513.35 940.5 513.35 Z"
				/>
			</g>

			<g id="Layer5_10_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 932.35 515.85
L 932.05 515.5
Q 931.7 515.25 931.35 515.25
L 930.75 515.45
Q 930.35 515.65 930.25 516.1
L 930 517.6
Q 929.9 518.85 930.2 519.35 930.6 520 931.35 520 932.1 520 932.5 519.35 932.8 518.85 932.7 517.6
L 932.45 516.1 932.35 515.85 Z"
				/>
			</g>

			<g id="Layer5_11_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 961.8 542.25
L 961.65 543.05
Q 961.5 544 961.6 544.65 961.7 545.5 962.75 545.7 962.853515625 545.7103515625 962.95 545.7 963.001171875 545.70546875 963.05 545.7 963.8130859375 545.6740234375 964.15 545 964.45 544.5 964.55 543.65 964.65 542.7 964.7 542.25
L 964.8 540.05
Q 964.8 538.8 964.65 537.6 964.5 536.55 964.45 536.3 964.2 535.5 963.65 535.25 963 534.95 962.5 535.4 962.05 535.75 962 536.55 961.95 536.95 962 537.8
L 961.95 540.05
Q 961.95 541.15 961.8 542.25 Z"
				/>
			</g>

			<g id="Layer5_12_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 953.6 539.15
Q 953.3 539.2 953.1 539.45 953 539.65 952.75 540.5
L 952.35 543.65 952.35 543.7 952 545.5
Q 951.75 546.5 951.9 546.9 952 547.4 952.4 547.7 952.85 548.05 953.35 548 954.3 547.85 954.65 546.75
L 955.05 543.55
Q 955.1 542.6 954.95 541.45 954.85 540 954.15 539.35 953.95 539.1 953.6 539.15 Z"
				/>
			</g>

			<g id="Layer5_13_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 946.25 539.95
Q 946.3359375 539.9671875 946.4 540 946.1255859375 539.794140625 945.7 539.95 945.383984375 539.9955078125 945.2 540.4 944.9 541 944.6 542.3
L 944.3 544.4
Q 944.2 545.15 944.95 545.55 945.4 545.85 946 545.7 946.4 545.55 946.7 545.2 946.95 544.9 947 544.4
L 947.1 543.5 947 541.65
Q 947 541.15 946.85 540.6 946.7671875 540.1443359375 946.4 540 946.4701171875 540.0408203125 946.5 540.1
L 946.2 539.95 946.25 539.95 Z"
				/>
			</g>

			<g id="Layer5_14_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 917.95 551.7
Q 919.1 552.4 923.5 555.25 930.95 560.1 934.4 562 940.35 565.35 946 566.8 953.6 568.8 957.6 567.1 960.1 566 961.15 563.25 962.25 560.25 959.95 558.55 959.35 558.1 958.85 558.7 958.35 559.25 958.6 559.9 959.25 561.2 958.95 562.45 958.6 563.75 957.35 564.5 955.2 565.8 950.75 565.05 944.7 564.1 937.8 560.55 934.2 558.7 925.75 553.25 922.2 550.85 919.65 549.3 917.6 548 916.55 547.4 914.75 546.35 913.3 545.85 912.15 545.45 911.7 546.65 911.25 547.8 912.25 548.45 914.05 549.65 917.95 551.7 Z"
				/>
			</g>

			<g id="Layer5_15_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 928.45 557.75
Q 927.8 557.45 927.45 558.1 925.65 561.35 923.5 562.95 921.3 564.55 916.95 565.95 907.05 568.8 902.1 570.25 901.3 570.45 901.25 571.25 901.2 572.05 901.8 572.5
L 906.3 575.85
Q 908.05 577.2 908.3 577.45 908.7 577.85 908.8 578.15 908.9 578.55 908.35 578.5 907.6 578.5 907.35 579.25 907.05 579.95 907.55 580.5 908.2 581.2 909.15 581.1 910 581 910.7 580.3 912.15 578.75 911.05 576.7 910.6 575.9 909.6 575.1 908.4 574.25 907.8 573.8
L 905.15 571.9
Q 907.535546875 571.22109375 910.95 570.25 915.7 568.85 919 567.65 928.05 564.4 929 558.75 929.1 558.05 928.45 557.75 Z"
				/>
			</g>

			<g id="Layer5_16_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 819.95 592.1
Q 819.2 592.35 819.25 593.25 819.3 594.15 820.2 594.05 823.45 593.65 827.8 592.8 830.35 592.35 835.4 591.3 843.05 589.85 850.6 588.75 866.7 586.4 881.55 586.25 897.95 586.15 912.2 588.85 924.25 591.05 940.15 596.9 949.05 600.1 966.8 606.65 982.3 611.8 994.35 613 1001.3 613.7 1009.55 613.05 1015.55 612.6 1024.6 611.1 1029.8 610.25 1040.15 608.4 1049.2 606.95 1055.85 606.4 1062.15 605.85 1071.8 605.5
L 1079.1 605.2
Q 1083.25 605.1 1086.35 605.35 1094.15 606 1098.35 609.5 1100.45 611.25 1101.7 613.45 1103.1 615.9 1103.2 618.4 1103.3 621 1101.95 623.2 1101 624.8 1102.65 625.75 1104.3 626.7 1105.25 625.1 1108.4 620.25 1106.35 614.05 1104.45 608.25 1099.45 604.9 1094.15 601.35 1085.5 600.85 1082.95 600.75 1070.65 601.25 1051.4 602.05 1038 604.4 1027.5 606.45 1022.2 607.4 1012.95 609.05 1006.25 609.35 993.15 609.95 977.35 605.7 968.3 603.25 949.6 596.2 931.6 589.5 921.85 587 907.25 583.35 890.7 582.8 863.4 581.95 827.45 589.95 824 590.7 819.95 592.1 Z"
				/>
			</g>

			<g id="Layer5_17_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1113 613.85
Q 1112.85 614.1 1112.9 614.35 1113.25 615.3 1114.75 616.6
L 1124.5 624.5 1123.1 626.85
Q 1121.3 629.6 1119.3 632 1116.15 636 1110.85 641.3
L 1106.45 645.65
Q 1105.8 646.4 1105.55 646.45 1105.4 646.5 1104.95 646.25 1104.5 645.95 1104.35 645.85 1103.25 645.25 1102.7 645 1101.7 644.5 1100.9 644.35 1099.95 644.1 1099.35 645.05 1098.7 646 1099.55 646.7 1100.25 647.35 1102.15 648.25 1104.3 649.35 1104.75 649.45 1106 649.8 1107.15 649.05 1107.65 648.75 1109.1 647.35 1115.3 641.15 1117.3 639 1121.4 634.5 1124.55 630 1125.1 629.25 1126.2 627.65 1127.15 626.25 1127.5 625.2 1127.95 623.9 1127 622.8 1126.75 622.5 1124.95 621.1
L 1116.4 614.9
Q 1114.5 613.65 1113.5 613.6 1113.2 613.6 1113 613.85 Z"
				/>
			</g>

			<g id="Layer5_18_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1123.25 631.55
Q 1123.15 631.75 1123.35 631.95 1123.8 632.35 1124.7 633.25 1125 633.45 1126.2 634.05
L 1136.95 641.3
Q 1144 646.45 1150.25 651.95 1153.95 655.2 1156.7 657.85 1160.5 661.55 1161.7 663.75 1162.6 665.35 1162.8 667 1162.95 668.25 1162.7 668.65 1162.5 669 1161.95 669.05 1161.35 669.05 1161 669.05 1157 668.95 1153.35 668.1 1144.95 666.1 1137.75 660.05 1136.5 659.05 1135.35 660.2 1134.15 661.35 1135.35 662.4 1141.9 668.15 1151.15 670.65 1155.3 671.8 1160 672.05 1162.95 672.15 1163.95 671.6 1165.65 670.65 1165.85 668.15 1166.1 664.25 1162.95 660.15 1161.45 658.2 1156.85 653.85 1149.8 647.35 1141.95 641.3 1136.95 637.5 1133.05 634.95 1131.05 633.55 1128.45 632.35 1126.4 631.5 1126.15 631.45 1125.2 631.1 1123.6 631.3 1123.35 631.3 1123.25 631.55 Z"
				/>
			</g>

			<g id="Layer5_19_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1133.4 669.95
Q 1132.5 670.3 1132.55 671.2 1132.6 672.45 1134.3 675.25 1135.35 677 1136.8 678.85 1138.4 680.9 1139.55 681.95 1141.35 683.5 1143.15 683.8 1145.4 684.15 1146.85 683.1 1148.4 682 1148.75 679.6 1149.2 676 1147.55 672.25 1146.7 670.4 1145.25 668.95 1143.7 667.4 1141.9 666.7 1141.6 666.6 1141.5 666.9 1141.35 667.15 1141.5 667.4 1146.2 672.65 1146.2 677.9 1146.2 679.85 1145.7 680.5 1145.15 681.4 1143.7 681.1 1141.7 680.75 1139.05 677.1 1137.75 675.4 1136.85 673.8
L 1135.95 672.15
Q 1135.25 670.65 1134.9 670.25 1134.25 669.6 1133.4 669.95 Z"
				/>
			</g>

			<g id="Layer5_20_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1130.65 673.45
Q 1130.05 673.2 1129.6 673.65 1129.2 674.05 1128 675.85 1127.5 676.7 1126.55 677.75 1124.6 679.75 1122.4 680.6 1119.65 681.8 1117.95 681.25 1117.05 680.95 1117.4 679.6 1117.5 679.15 1118.15 677.55 1118.6 676.5 1119.7 674.5 1120.05 673.95 1120.85 672.85 1121.5 671.85 1121.6 671 1121.7 670.25 1121 669.75 1120.3 669.2 1119.6 669.45 1118.75 669.75 1117.95 670.75 1117.65 671.15 1116.75 672.6 1115.7 674.3 1115.05 676.15 1114.2 678.35 1114.15 679.8 1114 681.95 1115.35 683.2 1117.45 685.25 1122.05 683.95 1125.1 683.1 1127.7 680.8 1129.05 679.6 1130.15 677.95 1131.45 675.8 1131.3 674.35 1131.2 673.7 1130.65 673.45 Z"
				/>
			</g>

			<g id="Layer5_21_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 932.6 591.15
Q 932.4 591.4 932.7 591.6
L 934.9 592.9
Q 936.35 593.9 936.95 594.15 938.5 594.8 942.55 595.65 948.15 596.85 953.9 597.6 964.45 599.05 976.8 599.3 994.6 599.65 1023.05 596.4 1030.8 595.5 1046.2 593.5 1062.15 591.4 1069.25 591.05 1081.95 590.3 1092.2 592.9 1101.25 595.1 1113.6 600.45 1119.2 602.85 1125.1 605.8 1129.6 608.05 1130 608.3 1132.8 609.8 1134.55 611.35 1136.4 613.05 1136.9 614.9 1137.45 617.05 1136.05 618.95 1134.6 620.9 1131.75 621.95 1130.2 622.55 1126.5 623.3 1124.8 623.65 1125.3 625.35 1125.8 627.05 1127.45 626.75 1130.6 626.2 1132.55 625.55 1135.3 624.65 1137.2 623.1 1139.15 621.5 1140.15 619.35 1141.15 617.05 1140.8 614.65 1140.15 610.4 1135.3 607 1133.5 605.75 1126.6 602.3 1115.95 596.95 1105 592.95 1092.35 588.25 1082.2 587.35 1072.5 586.5 1058.15 588.2 1052.8 588.8 1034.15 591.25 1019.75 593.15 1010.1 594.1 996.95 595.4 985.95 595.7 970.85 596.2 959.05 595.15 952.35 594.65 945.65 593.5
L 939.2 592.4
Q 937.1 592.05 936.05 591.85 934.25 591.55 933.05 591 932.75 590.85 932.6 591.15 Z"
				/>
			</g>

			<g id="Layer5_22_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1142.2 617.9
Q 1141.2 617.25 1140.2 617.35 1139.05 617.5 1138.85 618.7 1138.75 619.15 1139.15 619.5 1139.5 619.8 1139.95 619.55 1140.5 619.2 1141.9 620.9 1142.05 621.05 1143.5 623.1 1144.6 624.5 1147.25 628.1 1151.1 633.45 1153.9 638.2 1157.05 643.6 1159.5 648.9 1161.45 653.3 1161.9 654.7 1162.95 658.1 1160.85 659.5 1159.9 660.2 1160.35 661.25 1160.75 662.35 1161.95 662.05 1164.65 661.35 1165.2 658.3 1165.65 655.75 1164.6 653 1162.55 647.8 1159.6 642.25 1153.75 631.05 1146.55 622.5 1145.3 620.85 1144.55 620 1143.3 618.55 1142.2 617.9 Z"
				/>
			</g>

			<g id="Layer5_23_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 814 566.95
Q 821.55 566.6 831.1 567.25 848.4 568.3 863.55 571.4 873 573.4 880.95 576.05 885 577.35 889.65 579.15 891.55 579.9 898.25 582.15 898.45 582.25 898.55 582 898.65 581.8 898.45 581.7 894 578.65 891.15 577.35 887.25 575.6 883.1 574.15 874.75 571.25 866.45 569.4 851.5 566 832.5 564.65 822.5 563.95 814.5 564.25 804.9 564.65 796.6 566.45 788.15 568.25 780.3 571.55 771.35 575.25 765.7 579.15 761.6 582.05 759.9 585.35 758.15 588.95 758.05 594.5 757.95 599.4 758.5 601.95 759.5 606.2 762.65 607.7 764.2 608.4 765.05 606.85 765.95 605.35 764.45 604.65 762.1 603.6 761.6 599.95 761.35 598.45 761.5 594.05 761.6 589.2 762.95 586.65 764.45 583.9 768 581.45 780.3 573.1 797.1 569.15 804.9 567.35 814 566.95 Z"
				/>
			</g>

			<g id="Layer5_24_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 828 578.95
Q 836.45 577.1 845.15 576.25 848.85 575.9 853.9 575.8 856.8 575.75 862.65 575.7 862.95 575.7 863 575.35 863.05 575.05 862.75 575 856.95 574.15 846.6 574.9 839.6 575.5 830.5 577.1 819.75 579 814.35 580.1 803.3 582.3 798.7 583.8 786.8 587.55 773.5 598.3 765.8 604.65 762.6 607.85 756.7 613.7 759.25 619.75 760.6 622.9 763.9 625.85 766.2 627.95 770.2 630.45 777.35 634.85 785.4 636.9 787 637.3 787.45 635.65 787.9 634 786.3 633.6 779.6 631.85 773.6 628.5 766.4 624.35 763.45 620.5 761.25 617.55 761.75 614.85 762.2 612.5 765.05 609.75 768.25 606.55 775.15 600.85 787.5 590.65 798.5 586.6 801.5 585.5 805.6 584.4 806.25 584.25 812.8 582.6 821.7 580.3 828 578.95 Z"
				/>
			</g>

			<g id="Layer5_25_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 796.6 616.35
Q 795.95 616.05 795.5 616.7 793.35 620.15 793.25 627 793.25 632.2 795.05 638 797.95 646.95 806.2 656.6 819.8 672.5 839.8 684.75 852.25 692.35 862.05 695.95 863.5 696.45 864.15 694.85 864.85 693.25 863.45 692.65 850.75 687.15 844.5 683.6 835.3 678.4 826.95 672 818.5 665.55 811.2 658 802.75 649.25 798.95 640.8 796.45 635.2 795.95 629.35 795.7 626.1 796.05 623.35 796.15 622.25 796.55 620.35 797 618.1 797.1 617.35 797.2 616.6 796.6 616.35 Z"
				/>
			</g>

			<g id="Layer5_26_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1163.45 684.55
Q 1163.148046875 684.9814453125 1163.35 685.45
L 1164.65 685.45 1164.75 685.55
Q 1164.85 685.65 1164.75 685.8 1164.6 685.9 1164.45 685.8
L 1164.35 685.75
Q 1163.8 686.15 1163.45 685.6 1163.401953125 685.524609375 1163.35 685.45
L 1163.35 685.45
Q 1163.5 685.8 1164 686.15 1164.7 686.6 1164.8 686.7 1165.55 687.35 1166.4 688.5 1167.5 689.9 1169.85 692.65 1172.9 696.3 1176.8 701.2
L 1178.35 703.2
Q 1178.85 703.75 1179.5 703.85 1180.25 703.95 1180.7 703.4 1181.45 702.4 1180.4 701.15
L 1178.6 698.9
Q 1175.45 694.9 1171.7 690.6 1168.55 686.95 1167.65 686.05 1164.65 683.05 1163.45 684.55 Z"
				/>
			</g>

			<g id="Layer5_27_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1102.7 685.75
Q 1102.6 685.3 1102.1 685.4 1101 685.7 1099.95 687.3 1099.55 687.95 1098.5 690 1097.15 692.55 1095.75 695.95 1094.75 698.45 1093.6 702 1092.8 704.75 1092.7 705.25 1092.05 707.45 1092.35 708.45 1092.5 709.05 1093.15 709.25 1093.8 709.45 1094.2 708.95 1094.65 708.45 1094.9 707.65 1095 707.45 1095.3 706.2
L 1096.15 703.1
Q 1097.1 699.85 1098.25 697.05 1099.45 693.95 1100.85 691.1
L 1102.25 688.2
Q 1102.65 687.15 1102.7 687 1102.85 686.35 1102.7 685.75 Z"
				/>
			</g>

			<g id="Layer5_28_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1112.25 706.45
L 1094.9 707.35
Q 1093.45 707.45 1093.45 708.95 1093.45 710.45 1094.9 710.35
L 1164.9 706.65
Q 1166.35 706.55 1169.3 706.45 1171.95 706.25 1173.7 705.9 1174.85 705.65 1174.5 704.45 1174.1888671875 703.383203125 1173.25 703.55 1171.751953125 703.353125 1169.5 703.5 1166.95 703.65 1165.65 703.7
L 1142.8 704.85
Q 1132.65 705.35 1112.25 706.45 Z"
				/>
			</g>

			<g id="Layer5_29_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1115.55 713.05
Q 1122.2 712.8 1128.65 712.45 1141.35 711.8 1154.85 710.4
L 1162 709.5
Q 1163.25 709.35 1165.75 709.05 1167.9 708.75 1169.45 708.25 1170.3 708 1170.2 707.1 1170.05 706.25 1169.15 706.15 1167.85 706.1 1165.95 706.3 1163.8 706.55 1162.75 706.65
L 1143.25 708.45
Q 1130.75 709.55 1117.25 710 1107.3 710.05 1102.4 710.35 1101 710.45 1101 711.85 1101 713.25 1102.4 713.3 1105.15 713.45 1108.95 713.3 1111.15 713.25 1115.55 713.05 Z"
				/>
			</g>

			<g id="Layer5_30_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1198.7 498.3
Q 1197.75 498.45 1197.8 499.3 1198.1 503.3 1198.9 512.2 1199.65 520.6 1199.95 525.15 1200.65 537.55 1200.9 551.05 1201.3 579.25 1199.8 602.9 1199.25 611.45 1198.6 617.05 1198 621.95 1197.6 624.35
L 1196.5 630.35
Q 1196.4541015625 630.4 1196.4 630.45 1195.5 631.2 1196.1 632.2 1196.65 633.25 1197.7 633.05 1198.95 632.85 1199.45 631.1 1199.8 629.05 1199.9 628.3 1201.15 621.1 1201.8 614.6 1202.95 603.15 1203.5 586.65 1204.45 559.25 1202.6 530.35 1201.7 516.85 1201.5 514.65 1200.8 505.7 1199.8 499 1199.7 498.15 1198.7 498.3 Z"
				/>
			</g>

			<g id="Layer5_31_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1198.2 497.85
Q 1198.1 498 1198.3 498.1 1199.1 498.55 1201 498.95
L 1223.9 508.05
Q 1224.0060546875 508.4091796875 1224.35 508.65 1224.75 508.9 1225.3 508.9 1226.05 509 1226.5 508.25
L 1226.6 508.05
Q 1227.05 507.15 1226.6 506.5 1226.2 505.85 1225.35 505.45 1224.35 505.05 1223.8 504.8
L 1211.85 500.1
Q 1205.95 498 1203.85 497.4 1201.55 496.75 1200.75 496.65 1198.6 496.45 1198.2 497.85 Z"
				/>
			</g>

			<g id="Layer5_32_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1222.9 507.75
Q 1222.25 507.75 1222.2 508.4 1221.65 516.65 1222 530 1222.15 537.2 1222.15 551.6 1222.35 571.55 1223.8 594.25 1224.4 605.45 1225.55 618.5 1225.65 619.3 1226.45 619.3 1227.25 619.3 1227.2 618.5 1225.4 595 1224.65 575.4 1223.5 548.3 1223.45 532.75 1223.45 529.9 1223.6 520.6 1223.75 513.05 1223.6 508.4 1223.55 507.75 1222.9 507.75 Z"
				/>
			</g>

			<g id="Layer5_33_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1223.5 625.45
Q 1224.143359375 625.2400390625 1224.75 624.95 1225.5 624.55 1225.3 623.55 1225.05 622.6 1224.15 622.7 1221.25 623.05 1217.15 624.2 1212.55 625.55 1210.25 626.15
L 1203.25 628
Q 1200.95 628.65 1200.55 628.85 1198.95 629.6 1198.75 630.85 1198.65 631.55 1199.1 632.1 1199.6 632.75 1200.35 632.45 1200.75 632.3 1201.4 631.9 1202.05 631.5 1202.45 631.35 1203.45 631 1205.45 630.5
L 1211.95 628.7
Q 1214.1 628.15 1218.45 627.05 1221.3517578125 626.2482421875 1223.5 625.45 Z"
				/>
			</g>

			<g id="Layer5_34_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1196.9 514.9
Q 1196.8 514.7 1196.55 514.75 1193.7 515.55 1190.15 520.5 1187.85 523.8 1185.8 528.55 1183.05 535.35 1181.3 546.65 1179.3 560.05 1179.25 569.4 1179.15 581.95 1182.3 591.95 1185.95 603.65 1193.5 611.85 1194.5 612.95 1195.6 611.9 1196.7 610.8 1195.7 609.7 1189.15 602.7 1185.6 592.6 1182.5 583.6 1181.95 572.95 1181.4 562.6 1183.15 550.05 1184.65 538.8 1186.95 532 1188.65 526.95 1191 523.1 1192.2 521.1 1193.85 519.15 1194.4 518.5 1195.5 517.3 1196.5 516.15 1196.95 515.3 1197.05 515.1 1196.9 514.9 Z"
				/>
			</g>

			<g id="Layer5_35_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1225.5 546.4
Q 1224.8 546.7 1225.05 547.4 1225.7 549.2 1227.7 553.8 1229.45 557.8 1230.2 560.25 1232 566.2 1232.85 574.45 1234.4 588.55 1234.4 601.9 1234.35 629.55 1227.75 656.55 1224.35 670.3 1218.3 686.15 1217.95 687.05 1219 687.5 1220.05 687.95 1220.4 687.05 1230.95 662.15 1235.15 633.05 1239.3 604.15 1235.85 577 1234.75 567.95 1232.9 561.35 1230.55 553.05 1226.6 546.75 1226.2 546.15 1225.5 546.4 Z"
				/>
			</g>

			<g id="Layer5_36_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1169.8 656.75
Q 1170.05 657 1170.3 656.9 1172.15 655.95 1175.9 654.1
L 1178.55 652.55
Q 1179 652.3 1180.15 651.65 1181.05 651.05 1181.2 650.3 1181.3 649.8 1181.1 649.4 1180.85 648.95 1180.35 648.85 1179.6 648.65 1178.7 649.2 1177.7 650 1177.25 650.3
L 1174.7 652.2
Q 1171.45 654.95 1169.85 656.3 1169.6 656.5 1169.8 656.75 Z"
				/>
			</g>

			<g id="Layer5_37_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1170.65 637.15
Q 1170.15 637.4 1169.7 638.25
L 1166.05 646.2
Q 1165.8 646.8 1166.05 647.6 1166.1 647.95 1166.45 648.05 1166.75 648.2 1167.05 648.05 1167.65 647.7 1167.95 647.4
L 1168.65 646.3 1171.9 640.05
Q 1172.4 639 1172.5 638.7 1172.65 637.85 1172.15 637.3 1171.5 636.65 1170.65 637.15 Z"
				/>
			</g>

			<g id="Layer5_38_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1109.05 674.1
Q 1109.15 673.4 1108.45 673.2 1105 672.55 1104.4 672.35 1103.25 672 1102.55 671.65 1101.25 670.85 1100.55 670.7 1099.8 670.5 1099.45 671.15 1099.1 671.75 1099.6 672.3 1100 672.8 1101.65 673.5 1102.75 673.95 1103.8 674.25 1106.55 675.05 1108.25 674.85 1109 674.75 1109.05 674.1 Z"
				/>
			</g>

			<g id="Layer5_39_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 913.35 457.15
Q 913.5431640625 457.3431640625 913.8 457.45 913.8169921875 457.741796875 913.9 458.05 913.989453125 458.4076171875 914.15 458.7 913.7873046875 458.9423828125 913.65 459.4 913.5 459.9 913.7 460.35 913.85 460.8 914.35 461.05 914.5830078125 461.18984375 914.8 461.2 915.052734375 461.4552734375 915.4 461.55 917.25 462.05 918.5 460.45 918.9134765625 459.9951171875 919.2 459.2 919.417578125 458.891796875 919.5 458.5 919.5134765625 458.415234375 919.5 458.3 919.4982421875 458.3681640625 919.45 458.4
L 919.5 458.25
Q 919.5234375 458.2921875 919.5 458.3 920.0232421875 457.598828125 919.8 456.75 919.79375 456.7248046875 919.75 456.7 919.7 456.0896484375 919.2 455.65 919.215625 455.5740234375 919.2 455.5 918.95 454.6 918.1 454.1 917.5205078125 453.725 916.95 453.75 916.896484375 453.721875 916.85 453.7 916 453.3 915.2 453.75 915.001953125 453.8630859375 914.85 454 914.6341796875 454.003125 914.4 454.05 913.45 454.25 913.1 455.05 912.85 455.55 912.9 456.15 912.95 456.75 913.35 457.15 Z"
				/>
			</g>

			<g id="Layer5_40_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 915.15 457.75
L 915.15 457.65 915.2 457.75
Q 915.3 457.5 915.2 457.2 915.05 456.65 914.45 456.5 913.85 456.35 913.45 456.75
L 913.2 457.2
Q 913.1 457.5 913.2 457.75
L 913.2 457.85 913.25 457.95 913.25 457.9 913.35 458.15 913.25 457.95 913.35 458.2 913.85 458.75 913.9 458.75 914.1 458.85 914.25 458.85
Q 914.35 458.95 914.45 458.85
L 914.6 458.85 914.85 458.75
Q 915.2458984375 458.530078125 915.2 458.2 915.212109375 458.148046875 915.2 458.1
L 915.25 458.05 915.25 458 915.2 457.75
Q 915.184375 457.8283203125 915.15 457.9
L 915.1 457.9 915.15 457.75 Z"
				/>
			</g>

			<g id="Layer5_41_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 883.7 384.05
Q 883.35 388.55 887.1 394.9 888.6 397.35 890.9 400.7
L 892.9 403.6
Q 893.6 404.6 893.8 404.8 894.4 405.4 895 405.5 895.1 405.55 895.2 405.4 895.35 404.65 894.5 403.2
L 892.65 400.2
Q 889.9 395.75 889.15 394.4 887.2 390.85 886.5 388.4 886 386.65 886 385.2 885.95 384.7 886 384.4 886 383.85 886.25 383.65 886.55 383.45 887.5 383.6 892.55 384.05 896.25 391.3 897.5 393.8 899.15 397.65
L 901.95 404.05
Q 902.5 405.3 903.7 404.6 904.9 403.9 904.35 402.65
L 899.1 390.8
Q 896.1 384.5 891.95 382.3 889.45 380.95 887 381.05 883.95 381.2 883.7 384.05 Z"
				/>
			</g>

			<g id="Layer5_43_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 965.15 455.2
Q 964.95 455.55 965.3 455.75 968.95 457.7 980.95 463.3 990.75 467.85 996.35 471.3 1001.75 474.65 1008.8 481.6 1014.8 487.75 1018.15 493.15 1022.5 500.2 1023.3 506.75 1023.85 510.85 1022.75 513.55 1021.6 516.45 1017.6 518.2 1005.7 523.3 987.45 514.45 980.1 510.95 972.7 505.7 970.05 503.8 965.95 500.2 961.15 495.95 959.3 494.5 958.75 494 958.1 494.45 957.5 494.9 957.85 495.6 959.7 498.85 962.9 502.15 965.2 504.5 969.1 507.7 975.35 512.75 982.8 516.65 991.55 521.2 998.8 522.95 1008.15 525.15 1015.8 523 1020.25 521.75 1023.05 519.3 1026.3 516.5 1026.95 512.35 1028 505.55 1024.2 497.05 1018.6 484.35 1002.95 472.45 999.45 469.8 994.4 467.15 992.7 466.3 985.35 462.75 977.55 459.1 975.7 458.3 970.15 455.95 965.65 454.95 965.3 454.85 965.15 455.2 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_0_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_0_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_1_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_2_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_3_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_4_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_5_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_6_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_7_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_8_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_9_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_10_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_11_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_12_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_13_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_14_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_15_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_16_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_17_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_18_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_19_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_20_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_21_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_22_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_23_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_24_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_25_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_26_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_27_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_28_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_29_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_30_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 0.8053131103515625, -0.070098876953125, 0.08673095703125, 0.9962310791015625, -557.95,-286.6) ">
			<use xlinkHref="#Layer5_31_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_32_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_33_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_34_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_35_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_36_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_37_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_38_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_39_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_40_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_41_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer5_43_MEMBER_1_FILL" />
		</g>
	</svg>
);
