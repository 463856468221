import React from "react";

export const contactPage4 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="264px"
		height="153px"
		viewBox="0 0 264 153"
		id="frame-4"
	>
		<defs>
			<g id="Layer4_0_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 367.55 -2390.45
Q 365.8 -2387.8 366.3 -2384.15 368.5 -2369.05 374.2 -2348.1 381.1 -2324.3 384.3 -2312.5
L 403.4 -2242.25
Q 429.35 -2147.95 442.3 -2100.85 494.1 -1912.95 519.85 -1819
L 552.3 -1700.65
Q 555.8 -1687.1 557.65 -1680.35 560.95 -1668.6 564.35 -1660.5 566.05 -1656.5 569.4 -1654.9 572.45 -1653.4 575.8 -1654.3 579.1 -1655.2 580.95 -1658.05 583 -1661.2 582.4 -1665.5 581.3 -1673.25 578.6 -1683.55 577 -1689.5 573.55 -1701.35
L 544.25 -1807.1
Q 507.9 -1938.2 505.1 -1948.45 475.7 -2055.3 427.35 -2230.2 420.1 -2256.5 405.4 -2309
L 394.4 -2348.4
Q 389.9 -2364.55 388.65 -2368.6 385.2 -2380 381.7 -2388.4 380.3 -2391.8 377.4 -2393.2 374.8 -2394.45 372 -2393.7 369.15 -2392.9 367.55 -2390.45 Z"
				/>
			</g>

			<g id="Layer4_1_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 387.5 -2405.4
Q 385 -2408.15 380.8 -2407.85 127 -2390.3 -83.1 -2377.65 -303 -2364.5 -547.3 -2353.45 -575.7 -2352.15 -677.65 -2348.3 -758.25 -2345.25 -808 -2342.55 -812.45 -2342.3 -815.1 -2339.15 -817.5 -2336.3 -817.5 -2332.35 -817.55 -2328.4 -815.15 -2325.7 -812.55 -2322.75 -808 -2322.8 -758.05 -2323.4 -691.8 -2326.1 -684.35 -2326.4 -575.7 -2331.4 -474.35 -2336.05 -343.55 -2343.4 -238.25 -2349.35 -111.5 -2357.3 -19.7 -2363.05 120.45 -2372.2 148.4 -2374 250.6 -2380.75 331 -2386.05 380.8 -2389.25 385 -2389.55 387.45 -2392.6 389.7 -2395.35 389.7 -2399.15 389.7 -2402.95 387.5 -2405.4 Z"
				/>
			</g>

			<g id="Layer4_2_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -618.6 -1451.7
Q -616.45 -1455.3 -617.65 -1458.95 -647.45 -1546.05 -666.9 -1621.55 -685.05 -1691.95 -704.3 -1787.45 -718.5 -1857.8 -737.4 -1958.85 -748.1 -2016.05 -769.35 -2130.45
L -787.75 -2228.35
Q -790.8 -2244.65 -796.75 -2277.35 -802.1 -2305.8 -807.05 -2326.05 -807.8 -2329.1 -810.6 -2330.65 -813.1 -2332 -816.25 -2331.65 -819.35 -2331.25 -821.25 -2329.3 -823.35 -2327.15 -822.95 -2323.9 -820.5 -2305.15 -815.85 -2279.45 -810.3 -2249.95 -807.6 -2235.25 -796.85 -2177.1 -791.5 -2148
L -759.05 -1972.6
Q -731.65 -1824.85 -727.6 -1804.1 -708.95 -1708.5 -691.25 -1636.65 -678.45 -1584.85 -666.5 -1544.35 -652 -1495.3 -636.2 -1453.85 -634.75 -1450.05 -631.6 -1448.1 -628.25 -1446.05 -624.4 -1447.15 -620.7 -1448.15 -618.6 -1451.7 Z"
				/>
			</g>

			<g id="Layer4_3_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1063.45 -1321.1
Q 1106.55 -1326.95 1131 -1330.6 1209.15 -1342.35 1264.7 -1356.6 1281.05 -1360.75 1297.45 -1365.75 1302.15 -1367.15 1304.05 -1371.25 1305.75 -1374.95 1304.6 -1379.2 1303.45 -1383.45 1300.1 -1385.6 1296.45 -1388.05 1291.7 -1386.6 1235.1 -1369.65 1158 -1357.1 1130.55 -1352.6 1090.15 -1347 1044.9 -1340.85 1022.3 -1337.65 893.1 -1319.3 757.4 -1297.15 622.45 -1275.1 488 -1250.35 359.95 -1226.75 218.5 -1197.7 131.9 -1179.9 87.35 -1170.4 14.7 -1154.85 -43.25 -1141
L -74.7 -1133.4
Q -79 -1132.35 -80.75 -1128.9 -82.3 -1125.75 -81.25 -1122 -80.2 -1118.25 -77.2 -1116.15 -73.85 -1113.85 -69.55 -1114.75 -61.15 -1116.55 60.85 -1142.95 139.45 -1160 191.35 -1170.65 315.95 -1196.3 459.3 -1222.9 593.4 -1247.8 728.9 -1270.3 863 -1292.55 995.95 -1311.8 1015.3 -1314.6 1063.45 -1321.1 Z"
				/>
			</g>

			<g id="Layer4_4_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 739.05 -1593.05
Q 700.9 -1603.85 660.5 -1614.85 647.4 -1618.4 621.1 -1625.3 605.95 -1629.25 601.2 -1630.35 589.95 -1632.95 581 -1634.05 578.1 -1634.4 575.6 -1632 573.2 -1629.75 572.85 -1626.85 572.5 -1623.65 574.15 -1621.25 575.7 -1619.05 578.8 -1617.8 586.15 -1614.75 596.25 -1611.85 597.65 -1611.45 614.05 -1607
L 647.75 -1597.8
Q 688.9 -1586.6 716.95 -1578.65 784.9 -1559.55 855.75 -1538.1 996.8 -1495.4 1129 -1449.5 1163.8 -1437.4 1204.95 -1422.7
L 1243.25 -1408.8
Q 1249.65 -1406.45 1262.35 -1401.7 1273.55 -1397.6 1281.65 -1395.25 1285.35 -1394.2 1288.7 -1396.15 1291.75 -1397.9 1293.25 -1401.35 1294.8 -1404.85 1293.9 -1408.05 1292.95 -1411.6 1289.25 -1413.25 1275.4 -1419.55 1255.15 -1426.8 1231.95 -1434.8 1220.45 -1438.9 1187.1 -1450.8 1151.8 -1463.05 1083.1 -1486.8 1015.65 -1508.8 877.6 -1553.75 739.05 -1593.05 Z"
				/>
			</g>

			<g id="Layer4_5_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 750 -1626.1
Q 765.35 -1627.8 793.2 -1631 823.25 -1634.5 836.35 -1635.95 849.2 -1637.35 875.1 -1640.4 897.5 -1642.65 913.95 -1642.6 923.65 -1642.55 941.45 -1641.4 953.3751953125 -1640.598828125 961.1 -1640.25 951.72109375 -1633.371484375 943.25 -1625.25 938.65 -1620.85 941.25 -1614.15 943.85 -1607.35 950.45 -1607.9 969.55 -1609.5 1007.9 -1613.2 1041.55 -1616.05 1065.5 -1616.1 1093.4 -1616.15 1114.45 -1611.2 1140.2 -1605.2 1159.05 -1591.25 1162.35 -1588.8 1166.9 -1590.2 1171.25 -1591.5 1173.3 -1595 1175.55 -1598.8 1174.3 -1602.85 1173.15 -1606.6 1169.6 -1609.25 1139 -1631.85 1091.95 -1635.8 1073.45 -1637.4 1049.95 -1636.35 1035.7 -1635.7 1008.55 -1633.3
L 983.8 -1631.15
Q 985.9197265625 -1632.397265625 988.05 -1633.6 998.613671875 -1639.5818359375 1009.95 -1643.8 1017.023046875 -1645.5392578125 1023.2 -1648.05 1027.2 -1649.65 1029.2 -1652.9 1031.35 -1656.4 1030.25 -1660.5 1029.15 -1664.45 1025.45 -1666.55 1021.8 -1668.65 1017.8 -1667.55 1011.026171875 -1665.70703125 1004.45 -1663.35 989.7853515625 -1659.865234375 971.3 -1660 962.4 -1660.1 947.45 -1661.1 929.6 -1662.35 923.75 -1662.55 906.55 -1663.2 884.05 -1661.25 877.4 -1660.65 844.45 -1656.95 787.15 -1650.15 758.45 -1646.75 706.85 -1640.6 672.3 -1637.75 628.75 -1634.15 581 -1633.3 577.5 -1633.25 575.5 -1631 573.7 -1629 573.75 -1626.05 573.75 -1623.15 575.6 -1620.95 577.6 -1618.55 581 -1618.25 599.4 -1616.45 621.5 -1616.65 638.05 -1616.85 662.05 -1618.3 701.35 -1620.75 750 -1626.1 Z"
				/>
			</g>

			<g id="Layer4_6_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1198.05 -1596.05
Q 1201.15 -1598.2 1208 -1602.6 1213.8 -1606.75 1216.3 -1610.85 1219.05 -1615.4 1215.7 -1619.35 1212.4 -1623.2 1207.45 -1622.3 1202.9 -1621.5 1196.95 -1617.4 1190.45 -1612.45 1187.45 -1610.4 1179.5 -1604.95 1169.15 -1597.7 1150.85 -1584.85 1132.15 -1571.1 1127.35 -1567.6 1127.85 -1561.8 1128.35 -1555.85 1134.35 -1554.2 1143.15 -1551.75 1168.2 -1544.75 1189.45 -1538.8 1202.1 -1535.35 1213.4 -1532.25 1235.95 -1525.85 1255.95 -1520.3 1269.95 -1517.05 1274.25 -1516.05 1277.5 -1518.25 1280.45 -1520.2 1281.45 -1523.85 1282.5 -1527.5 1280.95 -1530.7 1279.25 -1534.25 1275.05 -1535.55 1261.3 -1539.85 1241.25 -1545.15 1218.55 -1551 1207.25 -1554.05 1194.95 -1557.35 1173.25 -1563.05 1164.554296875 -1565.349609375 1157.7 -1567.15 1169.03046875 -1575.4474609375 1179.05 -1582.6 1185.35 -1587.15 1198.05 -1596.05 Z"
				/>
			</g>

			<g id="Layer4_7_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1352.2 -1563.6
Q 1349.2 -1564.4 1346.55 -1562.6 1336.65 -1556.1 1331.65 -1552.7 1322.85 -1546.7 1317.6 -1541.7 1315.15 -1539.35 1315.1 -1536.25 1315.1 -1533.45 1317 -1531.1 1318.85 -1528.7 1321.65 -1527.95 1324.7 -1527.15 1327.6 -1528.75 1333.95 -1532.2 1342.3 -1538.8 1347.05 -1542.55 1356.25 -1550.05 1358.75 -1552 1358.8 -1555.1 1358.8 -1557.85 1356.85 -1560.35 1354.95 -1562.85 1352.2 -1563.6 Z"
				/>
			</g>

			<g id="Layer4_8_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1505.15 -1610
Q 1504.85 -1606.55 1507.4 -1603.75 1542.55 -1565.5 1565.1 -1534.35 1591.8 -1497.4 1613.2 -1457.5 1623.353515625 -1438.5890625 1632.6 -1418.3 1631.9103515625 -1417.9666015625 1631.15 -1417.7 1628.45 -1416.8 1623.8 -1416.2 1614.55 -1415 1599.05 -1414.45 1576.25 -1413.6 1551.1 -1415.8 1528.4 -1417.75 1502.9 -1422.35 1481.5 -1426.25 1455.3 -1432.5 1443.9 -1435.2 1428 -1439.35 1409.9 -1444.15 1400.8 -1446.55 1396.7 -1447.65 1393.45 -1445.45 1390.6 -1443.45 1389.55 -1439.8 1388.55 -1436.1 1390.05 -1433 1391.7 -1429.55 1395.8 -1428.5 1461.15 -1411.45 1497.4 -1404.95 1554.55 -1394.7 1601 -1396.65 1617.1 -1397.25 1629.05 -1399.05 1642.85 -1401.15 1649.95 -1409.05 1654.1 -1413.65 1651.35 -1419.85 1614.05 -1504.2 1554.45 -1576.4 1536 -1598.8 1519.2 -1615.55 1516.5 -1618.25 1513.1 -1618 1510.1 -1617.8 1507.75 -1615.45 1505.4 -1613.1 1505.15 -1610 Z"
				/>
			</g>

			<g id="Layer4_9_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1366.4 -1603.45
Q 1347.25 -1608.05 1326.9 -1618.5 1310.35 -1627 1290.75 -1640.65 1284.7 -1644.9 1270.9 -1655.7 1258.3 -1665.55 1250.85 -1670.5 1247.9 -1672.45 1244.7 -1671.65 1241.8 -1670.9 1239.8 -1668.25 1237.75 -1665.6 1237.75 -1662.55 1237.7 -1659.2 1240.25 -1656.75 1248.6 -1648.8 1260.6 -1639.75 1267.1 -1634.85 1282.15 -1624.3 1305.8 -1607.75 1326.2 -1598.2 1352.2 -1586.05 1375 -1582.75 1390.5 -1580.5 1400.6 -1580.9 1415.4 -1581.45 1425.2 -1587.1 1430.95 -1590.45 1434.8 -1596.3 1438.5 -1602 1439.35 -1608.55 1441.35 -1623.5 1429.05 -1631.1 1425.3 -1633.4 1421.65 -1632.25 1418.4 -1631.2 1416.45 -1627.85 1414.55 -1624.55 1415.15 -1620.95 1415.8 -1617 1419.4 -1614.6 1420.55 -1613.85 1420.6 -1611.7 1420.65 -1609.65 1419.75 -1607.7 1417.2 -1602.3 1406.35 -1600.5 1390 -1597.8 1366.4 -1603.45 Z"
				/>
			</g>

			<g id="Layer4_10_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1310.15 -1713.2
Q 1317.25 -1717.05 1325.35 -1718.8 1341.9 -1722.45 1358.6 -1714.35 1373.5 -1707.15 1384.9 -1692.7 1396.55 -1677.9 1403.4 -1654.75 1404.6 -1650.7 1407.95 -1649.1 1411 -1647.65 1414.5 -1648.6 1418 -1649.55 1419.9 -1652.4 1422 -1655.5 1421.05 -1659.6 1415.8 -1682.1 1402.1 -1701 1387.6 -1721.1 1367.9 -1730.7 1347.8 -1740.5 1325.9 -1737.6 1303.65 -1734.6 1287.35 -1719.5 1278.75 -1711.45 1274.2 -1701.7 1266.8 -1685.65 1270.35 -1677.05 1272.2 -1672.7 1277.05 -1671.6 1282.15 -1670.45 1284.4 -1675.2 1285.6 -1677.8 1286.7 -1683.05 1287.85 -1688.5 1288.9 -1690.95 1291.75 -1697.7 1297.2 -1703.4 1302.85 -1709.2 1310.15 -1713.2 Z"
				/>
			</g>

			<g id="Layer4_11_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -438.75 -1786.2
L -327.25 -1802.8
Q -301.25 -1806.65 -263.3 -1813.05 -259.5 -1813.7 -257.65 -1817.05 -255.95 -1820.05 -256.45 -1823.9 -256.95 -1827.75 -259.35 -1830.1 -262 -1832.75 -265.9 -1832.25 -315.35 -1825.75 -378.3 -1816.2 -409.35 -1811.5 -490.5 -1798.75 -529.2 -1792.6 -554.1 -1787.1 -557.15 -1786.45 -558.65 -1783.8 -560 -1781.4 -559.6 -1778.4 -559.2 -1775.4 -557.3 -1773.5 -555.15 -1771.4 -552 -1771.65 -527.4 -1773.65 -495.25 -1778 -487.4 -1779.1 -438.75 -1786.2 Z"
				/>
			</g>

			<g id="Layer4_12_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -335.15 -1729.85
Q -369.45 -1724.5 -374.2 -1723.75 -396.35 -1720.15 -413.05 -1716.7 -416.3 -1716 -417.85 -1713.2 -419.3 -1710.65 -418.9 -1707.4 -418.5 -1704.2 -416.45 -1702.15 -414.2 -1699.95 -410.85 -1700.25 -393.8 -1701.85 -371.3 -1704.95 -368.95 -1705.3 -331.9 -1710.65 -324.2 -1711.75 -293.7 -1716.3 -280.3 -1718.1 -273.6 -1719.05 -261.65 -1720.75 -253.65 -1722.7 -250 -1723.6 -248.25 -1726.75 -246.6 -1729.65 -247.05 -1733.2 -247.5 -1736.75 -249.8 -1739.05 -252.35 -1741.55 -256.2 -1741.35 -264.45 -1740.85 -276.15 -1739 -282.8 -1738 -296 -1735.8
L -335.15 -1729.85 Z"
				/>
			</g>

			<g id="Layer4_13_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -527.1 -1596
Q -528.75 -1592.85 -527.9 -1589.7 -526.95 -1586.35 -524 -1584.85 -521.3 -1583.5 -517.8 -1584 -442.55 -1594.7 -399.65 -1601.55 -375.6 -1605.4 -281.95 -1620.65 -278.1 -1621.25 -276 -1625.3 -274 -1629.15 -275 -1632.9 -276.1 -1636.95 -279.7 -1638.8 -283 -1640.5 -287.25 -1639.85 -393.95 -1622.45 -404.9 -1620.6 -446.45 -1613.7 -522.15 -1599.85 -525.35 -1599.25 -527.1 -1596 Z"
				/>
			</g>

			<g id="Layer4_14_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -410.15 -2246.8
Q -412.85 -2246.45 -418.05 -2245.65
L -465.4 -2240.05
Q -496.3 -2236.4 -528.65 -2230.95 -531.7 -2230.45 -533.2 -2227.85 -534.55 -2225.55 -534.1 -2222.5 -533.7 -2219.5 -531.8 -2217.55 -529.65 -2215.4 -526.55 -2215.6 -493.5 -2217.7 -463.45 -2221.15
L -416.1 -2226.5
Q -410.65 -2226.9 -407.85 -2227.2 -402.9 -2227.7 -399.8 -2228.85 -392.3 -2231.7 -393.4 -2238.9 -393.85 -2241.95 -396.1 -2244.2 -398.55 -2246.55 -402.3 -2247.05 -405.4 -2247.45 -410.15 -2246.8 Z"
				/>
			</g>

			<g id="Layer4_15_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -492.15 -2113.5
Q -498.4 -2111.9 -501.25 -2111.4 -513.25 -2109.3 -537.55 -2106.35
L -610.1 -2096.2
Q -613.15 -2095.75 -614.65 -2093.15 -615.95 -2090.8 -615.55 -2087.7 -615.15 -2084.6 -613.25 -2082.7 -611.15 -2080.55 -608.05 -2080.95
L -535.4 -2090.65
Q -504.85 -2094.8 -500.05 -2095.4 -497 -2095.75 -490.8 -2095.9 -484.5 -2096.05 -481.4 -2096.4 -472.65 -2097.3 -462.2 -2099.1 -458.45 -2099.75 -456.65 -2102.95 -455 -2105.85 -455.5 -2109.55 -456 -2113.3 -458.35 -2115.6 -460.95 -2118.2 -464.75 -2117.85 -473.55 -2117 -483 -2115.5 -486.25 -2114.95 -492.15 -2113.5 Z"
				/>
			</g>

			<g id="Layer4_16_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -608.45 -2009.2
Q -609.75 -2007 -609.35 -2004.2 -608.95 -2001.4 -607.05 -1999.5 -605 -1997.45 -602 -1997.45 -588.5 -1997.35 -570.35 -1999 -549.3 -2001.15 -538.85 -2002.25 -517.4 -2004.45 -477.65 -2008.75 -473.55 -2009.2 -353.25 -2022.4 -349.15 -2022.85 -346.5 -2025.3 -343.65 -2028 -343.65 -2032 -343.65 -2035.75 -346.5 -2038.8 -349.5 -2042 -353.25 -2041.6 -436.85 -2032.7 -478.6 -2028.15 -510.3 -2024.7 -540.8 -2021.25 -551.3 -2020.05 -572.55 -2017.65 -590.75 -2015.35 -604 -2012.35 -607 -2011.65 -608.45 -2009.2 Z"
				/>
			</g>

			<g id="Layer4_17_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -475.15 -1894.2
Q -395.1 -1902.85 -355.05 -1907.25
L -286.55 -1914.7
Q -282.5 -1915.15 -279.85 -1917.6 -277 -1920.25 -277 -1924.3 -277 -1928.05 -279.85 -1931.1 -282.85 -1934.3 -286.55 -1933.9 -326.9 -1929.6 -407.7 -1921 -477.25 -1913.55 -528.75 -1907.75 -557.55 -1904.5 -563.65 -1903.75 -583.4 -1901.25 -598.3 -1898.25 -601.3 -1897.65 -602.75 -1895.2 -604.05 -1892.95 -603.65 -1890.1 -603.2 -1887.25 -601.35 -1885.4 -599.25 -1883.3 -596.25 -1883.35 -583 -1883.55 -565.95 -1885 -558.75 -1885.6 -535.7 -1887.95 -494.25 -1892.15 -475.15 -1894.2 Z"
				/>
			</g>

			<g id="Layer4_18_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 448.9 -1611
Q 366.5 -1596.65 331.35 -1590.85 218.9 -1572.4 98 -1554.15 -133.65 -1519.25 -372 -1490.1 -406.55 -1485.85 -504.8 -1474.8 -588.2 -1465.4 -637.5 -1458.9 -641.35 -1458.4 -643.75 -1456.15 -646.35 -1453.75 -646.4 -1450 -646.45 -1446.55 -643.75 -1443.75 -640.95 -1440.85 -637.5 -1441.15 -586.05 -1445.25 -518.85 -1452.8 -499.55 -1455 -400.45 -1466.8 -280.25 -1481.05 -163.8 -1496.7 76.65 -1529 308.35 -1567.15 420.45 -1585.55 440.15 -1588.85 514.85 -1601.55 571.5 -1612.85 575.15 -1613.6 577.15 -1617.25 579.05 -1620.8 578.05 -1624.35 577 -1628.2 573.65 -1629.95 570.55 -1631.55 566.55 -1630.9 524.4 -1624.15 448.9 -1611 Z"
				/>
			</g>

			<g id="Layer4_19_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -176.6 -1192.6
Q -213.25 -1212.3 -286.7 -1251.5
L -598.25 -1418.65
Q -611.45 -1425.6 -614.35 -1427.05 -623.6 -1431.65 -631 -1434.2 -633.85 -1435.2 -636.85 -1433.65 -639.8 -1432.15 -640.9 -1429.35 -642.15 -1426.35 -641.1 -1423.55 -640.15 -1420.95 -637.45 -1418.9 -627.35 -1411.2 -611.25 -1402.5 -592.75 -1392.9 -583.8 -1388.05
L -419.45 -1299.3
Q -229.3 -1196.75 -200.6 -1181.2 -158.7 -1158.6 -139 -1148.1 -104.6 -1129.8 -76.95 -1115.8 -73.05 -1113.85 -69.3 -1115.2 -65.9 -1116.4 -63.95 -1119.7 -62 -1123 -62.7 -1126.4 -63.45 -1130.15 -67.3 -1132.35 -112.55 -1158.15 -176.6 -1192.6 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.100830078125, 97.4,259.45) ">
			<use xlinkHref="#Layer4_0_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_1_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0970916748046875, 96.6,251.05) ">
			<use xlinkHref="#Layer4_2_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0939788818359375, 0, 0, 0.0959930419921875, 96.9,248.85) ">
			<use xlinkHref="#Layer4_3_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_4_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_5_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_6_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_7_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_8_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_9_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_10_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_11_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_12_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_13_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_14_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_15_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_16_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer4_17_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.096466064453125, 0, 0, 0.0959930419921875, 97.85,248.85) ">
			<use xlinkHref="#Layer4_18_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959014892578125, 0.00408935546875, -0.00408935546875, 0.0959014892578125, 91.3,249.15) ">
			<use xlinkHref="#Layer4_19_MEMBER_20_FILL" />
		</g>
	</svg>
);
