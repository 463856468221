import React from "react";

export const contactPage5 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="264px"
		height="153px"
		viewBox="0 0 264 153"
		id="frame-5"
	>
		<defs>
			<g id="Layer5_0_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 364.45 -2384.8
Q 362.8 -2382.25 363.55 -2378.9 367.15 -2363.1 373.15 -2341.65 380.2 -2316.95 383.65 -2304.65
L 404.6 -2230.6
Q 419.9 -2176.45 446.05 -2084.5 484.45 -1949.8 530.95 -1790.7 562.85 -1680.65 579 -1625.65 580 -1622.3 582.9 -1620.95 585.5 -1619.75 588.5 -1620.55 591.5 -1621.35 593.05 -1623.75 594.8 -1626.35 593.85 -1629.75 565.75 -1730.75 511.05 -1924.1 472.55 -2060 426.75 -2218.05
L 402.8 -2300.6
Q 398.85 -2314.35 390.95 -2341.9 383.95 -2365.75 378.05 -2382.9 376.95 -2386.15 374.15 -2387.45 371.65 -2388.65 368.8 -2387.85 366 -2387.1 364.45 -2384.8 Z"
				/>
			</g>

			<g id="Layer5_1_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 367.6 -2389.2
Q 367.6 -2393.3 365.15 -2396.15 362.45 -2399.35 357.85 -2399.45 252.4 -2401.7 127.35 -2395.45 32.4 -2390.65 -102.65 -2379.15 -179.25 -2372.6 -332.5 -2359.2 -465.15 -2348.35 -562.75 -2344.8 -627.1 -2342.4 -691.55 -2342.55 -711.6 -2342.6 -756 -2343.85 -797.85 -2345 -820.45 -2344.9 -824.15 -2344.9 -826.2 -2342.6 -828.1 -2340.5 -828 -2337.45 -827.95 -2334.4 -826.05 -2332.1 -823.95 -2329.6 -820.45 -2329.15 -795.2 -2326.1 -763 -2324.85 -745.4 -2324.15 -705.4 -2323.6 -651.45 -2322.85 -590.35 -2324.5 -536.65 -2325.95 -475.25 -2329.4 -424.25 -2332.2 -360.3 -2336.85 -304.15 -2340.95 -130.3 -2355.9 13.1 -2368.25 99.85 -2373.3 241.3 -2381.55 357.85 -2379.3 362.45 -2379.2 365.15 -2382.3 367.6 -2385.1 367.6 -2389.2 Z"
				/>
			</g>

			<g id="Layer5_2_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 468.4 -1616.5
Q 379.4 -1598.85 349.15 -1593.15 241.6 -1572.8 108.45 -1551.75 23 -1538.3 -133.15 -1515.55 -272.05 -1495.4 -358.8 -1485.15 -481.65 -1470.5 -584.8 -1463.55 -594.05 -1462.95 -612.45 -1461.4 -616.55 -1461.1 -619 -1458.15 -621.25 -1455.45 -621.25 -1451.8 -621.3 -1448.15 -619.1 -1445.75 -616.65 -1443 -612.45 -1443.1 -560.85 -1444.35 -498.9 -1449.65 -453.85 -1453.5 -385.7 -1461.2 -286.4 -1472.4 -160.45 -1490.65 -4.3 -1513.2 82.25 -1526.6 214.85 -1547 324.35 -1567.15 373.9 -1576.25 443.8 -1589.85 483.55 -1597.6 563.1 -1613.15 586.45 -1617.75 593.35 -1619.25 597.4 -1620.1 599.6 -1624.15 601.7 -1628.05 600.6 -1632.05 599.45 -1636.3 595.7 -1638.25 592.15 -1640.15 587.8 -1639.35 543.05 -1631.25 468.4 -1616.5 Z"
				/>
			</g>

			<g id="Layer5_3_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 742.5 -1589
Q 692.2 -1604.6 667 -1612.25 640.35 -1620.5 627 -1624.6 603.65 -1631.65 586.7 -1635.9 582.9 -1636.85 580.05 -1635 577.45 -1633.3 576.6 -1630.1 575.75 -1626.9 577.05 -1624 578.55 -1620.8 582.15 -1619.5 596.7 -1614.25 617.35 -1607.8 629.2 -1604.1 652.85 -1596.9
L 721.5 -1575.6
Q 785.45 -1555.85 852.5 -1534.8 1014.4 -1484 1112.9 -1451.9
L 1227.45 -1414.35
Q 1234.15 -1412.15 1247.5 -1407.6 1259.2 -1403.8 1267.8 -1401.8 1271.25 -1401 1274.4 -1402.5 1277.55 -1404 1278.85 -1407.25 1280.15 -1410.35 1279.2 -1413.85 1278.15 -1417.65 1275.05 -1418.95 1260.9 -1424.95 1239.95 -1431.65 1216 -1438.95 1204.2 -1442.75
L 1004.1 -1506.75
Q 883.25 -1545.4 742.5 -1589 Z"
				/>
			</g>

			<g id="Layer5_4_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -645.2 -1425.15
Q -644.4 -1421.9 -641.55 -1420.25 -630.35 -1413.65 -613.05 -1404.95 -593.65 -1395.35 -584.1 -1390.4 -552.2 -1373.9 -529.6 -1362 -482.55 -1337.15 -421.25 -1303.6 -350.45 -1264.7 -315.05 -1245.3 -252.4 -1210.95 -208.45 -1187.6 -137.55 -1149.9 -81.5 -1123 -78 -1121.3 -74.5 -1122.15 -70.8 -1123 -68.9 -1126.3 -67.05 -1129.45 -68 -1133.3 -68.95 -1137.25 -72.2 -1138.9 -182.1 -1194.6 -293.65 -1255.35 -329.25 -1274.75 -400.45 -1313.75 -463.05 -1347.95 -507.55 -1371.5 -542.7 -1390.1 -569.55 -1403.75 -580.35 -1409.25 -602 -1420.35 -620.8 -1429.75 -635.1 -1435.6 -638.25 -1436.9 -641 -1435.4 -643.45 -1434.05 -644.7 -1431.05 -645.9 -1428.05 -645.2 -1425.15 Z"
				/>
			</g>

			<g id="Layer5_5_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1277.25 -1394
Q 1274.5 -1396 1270.95 -1395.35 1249.1 -1391.35 1205.4 -1383.65 1199.9 -1382.65 1188.85 -1381.3 1177.8 -1379.95 1172.3 -1378.95 1166.85 -1377.95 1139.6 -1372.85
L 1009.2 -1348
Q 835.8 -1315 749.1 -1298.3 607 -1270.95 487.95 -1247.3 358.65 -1221.7 228.55 -1194.65 61.9 -1159.9 -30.15 -1139.2 -46.05 -1135.65 -62.1 -1130.8 -66 -1129.65 -67.55 -1126.6 -68.9 -1123.85 -67.95 -1120.65 -67 -1117.45 -64.3 -1115.6 -61.35 -1113.55 -57.5 -1114.1 -30.55 -1117.9 7.55 -1126.15 29.15 -1130.8 72.2 -1140.45 140.2 -1154.85 201.75 -1167.55 314.65 -1190.85 461.2 -1220.1 580.05 -1243.75 722.3 -1271.35 822.95 -1290.9 982.3 -1321.45
L 1112.5 -1346.4
Q 1166 -1356.6 1177.8 -1358.95 1183.3 -1360.05 1193.95 -1362.85 1204.75 -1365.65 1210.05 -1366.75
L 1275.2 -1379.95
Q 1278.8 -1380.65 1280.2 -1383.45 1281.5 -1386 1280.6 -1389.1 1279.75 -1392.2 1277.25 -1394 Z"
				/>
			</g>

			<g id="Layer5_6_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 710.05 -1635.05
Q 694 -1634.2 668.55 -1633.65 634.8 -1632.95 627.05 -1632.7 623.1 -1632.55 620.8 -1629.95 618.75 -1627.55 618.75 -1624.15 618.75 -1620.75 620.85 -1618.3 623.15 -1615.65 627.05 -1615.45 643.8 -1614.65 664.25 -1615.05 678.55 -1615.3 701.4 -1616.3 737.55 -1617.95 774.55 -1621.5 846.4 -1628.5 921.1 -1643.45 942.476171875 -1647.6880859375 963.9 -1652.7 954.65 -1644.3318359375 942.5 -1633.35 938.2 -1629.45 940.05 -1624.1 941.75 -1619.05 946.85 -1617 958.45 -1612.2 974.35 -1614.2 978.3 -1614.7 1001.8 -1619.95 1026.5 -1625.55 1053.5 -1624.55 1079.55 -1623.55 1104.5 -1616.25 1129.75 -1608.9 1151.8 -1595.7 1155.4 -1593.55 1159 -1594.75 1162.2 -1595.8 1164.05 -1599.05 1165.95 -1602.25 1165.35 -1605.6 1164.7 -1609.35 1161.1 -1611.55 1137.75 -1625.8 1111.8 -1634 1085.75 -1642.2 1058.55 -1643.85 1032.4 -1645.5 1004.85 -1640.55 1000.6 -1639.75 991.8 -1637.5 982.9 -1635.2 978.45 -1634.4 975.011328125 -1633.766796875 971.8 -1633.5
L 1006.3 -1664.65
Q 1008.95 -1667.05 1009.2 -1670.4 1009.45 -1673.4 1007.7 -1676.25 1006 -1679.05 1003.15 -1680.4 1000.05 -1681.9 996.75 -1681 856.25 -1642.75 710.05 -1635.05 Z"
				/>
			</g>

			<g id="Layer5_7_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1197.65 -1614.7
Q 1194.6 -1614.75 1192.55 -1612.25 1179.75 -1596.8 1164.4 -1585.3 1148.25 -1573.25 1130.4 -1566.35 1124.25 -1563.95 1123.9 -1557.25 1123.55 -1550.45 1130.4 -1548.65
L 1261.7 -1515.4
Q 1265.85 -1514.35 1269.05 -1516.55 1271.9 -1518.55 1272.95 -1522.2 1273.95 -1525.85 1272.45 -1528.95 1270.85 -1532.35 1266.7 -1533.4
L 1158.95 -1560.45
Q 1166.836328125 -1565.13125 1174.05 -1570.65 1191.95 -1584.4 1205.05 -1602.6 1206.9 -1605.2 1206.1 -1608.15 1205.4 -1610.85 1202.9 -1612.8 1200.45 -1614.7 1197.65 -1614.7 Z"
				/>
			</g>

			<g id="Layer5_8_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1322.4 -1599.7
Q 1346.3 -1591.15 1373.45 -1587.8 1404 -1584.15 1425.6 -1588.45 1433.15 -1590 1438.6 -1593.9 1444.65 -1598.25 1447.05 -1604.9 1449.4 -1611.45 1447.4 -1617.95 1445.35 -1624.65 1439.5 -1628.15 1436.2 -1630.1 1433.1 -1629.05 1430.25 -1628.15 1428.65 -1625.3 1427.05 -1622.45 1427.55 -1619.4 1428.15 -1616 1431.2 -1613.9 1432.9 -1612.7 1431.1 -1609.95 1429.6 -1607.7 1427.95 -1606.75 1424.3 -1604.65 1418.4 -1604.15 1417.65 -1604.05 1408.4 -1603.85 1384.6 -1603.35 1365.55 -1606.65 1322.2 -1614.15 1285 -1637.6 1265.35 -1649.9 1246.95 -1667.85 1244.15 -1670.6 1240.65 -1670.35 1237.55 -1670.1 1235.1 -1667.7 1232.7 -1665.25 1232.45 -1662.1 1232.2 -1658.55 1234.85 -1655.7 1252.45 -1636.95 1275.3 -1622.5 1297.2 -1608.7 1322.4 -1599.7 Z"
				/>
			</g>

			<g id="Layer5_9_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1342.05 -1706.45
Q 1351.25 -1705.7 1360.55 -1701.85 1369.3 -1698.2 1377.05 -1692.3 1384.55 -1686.5 1390.75 -1677.9 1395.75 -1671 1400.9 -1660.75 1402.45 -1657.65 1405.55 -1656.8 1408.35 -1656.05 1411.35 -1657.3 1414.3 -1658.6 1415.8 -1661.2 1417.45 -1664.05 1416.55 -1667.35 1411.65 -1685.2 1396.6 -1699.9 1382.6 -1713.55 1364.15 -1720.5 1346.3 -1727.2 1326.45 -1724.65 1306.95 -1722.15 1290.85 -1711.4 1281.4 -1705.1 1274.4 -1697.2 1266.9 -1688.7 1262.75 -1678.95 1261.25 -1675.5 1261.95 -1672.3 1262.7 -1668.85 1265.9 -1667 1268.9 -1665.2 1272.55 -1666.1 1276.25 -1667 1277.9 -1670.1 1287.35 -1687.95 1304.6 -1697.9 1322.15 -1708.05 1342.05 -1706.45 Z"
				/>
			</g>

			<g id="Layer5_10_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1505.65 -1621.95
Q 1502.6 -1621.7 1500.4 -1619.55 1498.15 -1617.4 1498 -1614.55 1497.8 -1611.35 1500.4 -1608.6 1522.3 -1585.5 1529.7 -1577.3 1545.8 -1559.45 1556.85 -1544.15 1582.35 -1508.9 1600.45 -1469.65 1609 -1451 1616 -1430.55 1621.3 -1414.9 1621.5 -1414.05 1624.15 -1403.5 1621.45 -1397.1 1620.15 -1393.95 1616.65 -1392.95 1614.3 -1392.3 1609.65 -1392.45 1604.2 -1392.6 1597.35 -1393 1593.15 -1393.25 1585 -1393.85 1558.6 -1395.8 1534.1 -1398.9 1509.1 -1402.15 1478.4 -1408.45 1460.15 -1412.2 1423.25 -1420.8 1418.75 -1421.85 1415.3 -1419.5 1412.2 -1417.45 1411.1 -1413.55 1410.05 -1409.7 1411.65 -1406.35 1413.4 -1402.65 1417.85 -1401.3 1442.1 -1394.1 1471.25 -1388.6 1494.7 -1384.15 1525.5 -1380.05 1553.7 -1376.25 1579.25 -1374.55 1594.15 -1373.55 1606.2 -1373.15 1613.85 -1372.9 1618.75 -1373.75 1625.45 -1374.95 1630.2 -1378.4 1638.45 -1384.5 1640.4 -1395.8 1641.95 -1404.7 1639.5 -1416.5 1637.5 -1426.05 1633.25 -1437.85 1630.8 -1444.7 1625.15 -1458.6 1606.85 -1503.55 1581.2 -1541.25 1564.9 -1565.3 1549.45 -1583.3 1530.85 -1604.95 1511.8 -1620 1508.95 -1622.25 1505.65 -1621.95 Z"
				/>
			</g>

			<g id="Layer5_11_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1350.75 -1562.9
Q 1348 -1564.75 1344.2 -1564.15 1330.55 -1561.85 1319.95 -1551.8 1309.1 -1541.5 1307.1 -1528.05 1306.5 -1523.95 1308.45 -1520.95 1310.25 -1518.2 1313.45 -1517.3 1316.6 -1516.45 1319.55 -1517.9 1322.8 -1519.45 1324.4 -1523.25 1327.95 -1531.7 1334.05 -1538.1 1340.5 -1544.9 1348.6 -1548.05 1352.2 -1549.45 1353.65 -1552.45 1354.95 -1555.2 1354.15 -1558.2 1353.3 -1561.25 1350.75 -1562.9 Z"
				/>
			</g>

			<g id="Layer5_12_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -472.1 -1895.55
L -360.35 -1910.15
Q -333.7 -1913.65 -295.4 -1919.4 -291.6 -1920 -289.75 -1923.25 -288.05 -1926.25 -288.55 -1930.1 -289.05 -1933.95 -291.45 -1936.35 -294.1 -1939.05 -298 -1938.6 -347.35 -1933 -410.7 -1924.55 -438.3 -1920.8 -523.2 -1909 -561.25 -1903.65 -586.9 -1898.5 -589.95 -1897.9 -591.45 -1895.25 -592.85 -1892.9 -592.45 -1889.9 -592.05 -1886.85 -590.1 -1884.95 -587.95 -1882.8 -584.8 -1883 -560.1 -1884.6 -528.35 -1888.35 -515.2 -1889.9 -472.1 -1895.55 Z"
				/>
			</g>

			<g id="Layer5_13_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -425.75 -1797.05
Q -423.5 -1794.85 -420.15 -1795.15 -403 -1796.75 -380.6 -1799.85 -375.4 -1800.55 -341.2 -1805.5
L -303 -1811.2
Q -289.6 -1812.95 -282.9 -1813.95 -271.05 -1815.6 -262.95 -1817.6 -259.3 -1818.5 -257.55 -1821.65 -255.9 -1824.55 -256.35 -1828.1 -256.8 -1831.65 -259.1 -1833.9 -261.65 -1836.45 -265.5 -1836.2 -273.75 -1835.75 -285.45 -1833.9 -292.1 -1832.85 -305.3 -1830.65 -331.4 -1826.75 -344.45 -1824.7 -375.75 -1819.9 -383.5 -1818.65 -405.5 -1815.05 -422.35 -1811.55 -425.6 -1810.9 -427.15 -1808.1 -428.6 -1805.55 -428.2 -1802.3 -427.8 -1799.1 -425.75 -1797.05 Z"
				/>
			</g>

			<g id="Layer5_14_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -536.4 -1690.85
Q -538.05 -1687.7 -537.2 -1684.6 -536.25 -1681.25 -533.3 -1679.75 -530.65 -1678.35 -527.1 -1678.85 -449.2 -1690 -408.95 -1696.4 -398 -1698.15 -291.25 -1715.55 -287.4 -1716.15 -285.3 -1720.2 -283.3 -1724 -284.3 -1727.75 -285.4 -1731.8 -289 -1733.7 -292.3 -1735.4 -296.55 -1734.7 -386.4 -1720.15 -414.2 -1715.5 -458.3 -1708.2 -531.45 -1694.7 -534.65 -1694.1 -536.4 -1690.85 Z"
				/>
			</g>

			<g id="Layer5_15_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -385.7 -1612.7
Q -383.55 -1610.55 -380.45 -1610.75 -349.1 -1612.75 -317.35 -1616.3
L -270 -1621.65
Q -264.55 -1622.05 -261.75 -1622.35 -256.8 -1622.85 -253.75 -1624.05 -250.1 -1625.4 -248.4 -1628.3 -246.8 -1630.95 -247.3 -1634.05 -247.75 -1637.1 -250 -1639.35 -252.45 -1641.7 -256.2 -1642.2 -259.35 -1642.6 -264.05 -1642 -266.75 -1641.65 -271.95 -1640.85
L -319.3 -1635.25
Q -348.6 -1631.8 -382.55 -1626.15 -385.6 -1625.65 -387.1 -1623.05 -388.45 -1620.7 -388.05 -1617.7 -387.65 -1614.65 -385.7 -1612.7 Z"
				/>
			</g>

			<g id="Layer5_16_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -467.65 -2237.2
Q -470.25 -2239.8 -474.05 -2239.45 -483.25 -2238.55 -492.3 -2237.05 -495.55 -2236.55 -501.45 -2235.05 -507.7 -2233.5 -510.55 -2233 -517.85 -2231.7 -528.65 -2230.3 -534.7 -2229.5 -546.85 -2227.95
L -619.4 -2217.8
Q -622.45 -2217.35 -623.95 -2214.75 -625.25 -2212.35 -624.85 -2209.25 -624.45 -2206.15 -622.55 -2204.25 -620.45 -2202.1 -617.35 -2202.55
L -544.7 -2212.25
Q -521.15 -2215.55 -509.4 -2216.95 -506.2 -2217.35 -500.1 -2217.5 -493.65 -2217.65 -490.7 -2217.95 -480.55 -2219.05 -471.5 -2220.7 -467.75 -2221.35 -465.95 -2224.55 -464.3 -2227.45 -464.8 -2231.15 -465.3 -2234.85 -467.65 -2237.2 Z"
				/>
			</g>

			<g id="Layer5_17_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -487.9 -2117.75
Q -519.6 -2114.3 -550.1 -2110.85 -560.6 -2109.65 -581.85 -2107.25 -600 -2104.95 -613.3 -2101.95 -616.3 -2101.25 -617.75 -2098.8 -619.05 -2096.55 -618.65 -2093.75 -618.25 -2090.95 -616.35 -2089.1 -614.3 -2087 -611.3 -2087 -597.8 -2086.95 -579.65 -2088.6 -558.6 -2090.75 -548.15 -2091.85 -528.75 -2093.85 -486.95 -2098.35 -482.85 -2098.8 -362.55 -2112 -358.5 -2112.45 -355.8 -2114.9 -352.95 -2117.55 -352.95 -2121.6 -352.95 -2125.35 -355.8 -2128.4 -358.8 -2131.6 -362.55 -2131.2
L -487.9 -2117.75 Z"
				/>
			</g>

			<g id="Layer5_18_MEMBER_20_FILL">
				<path
					fill="#2DFCD8"
					stroke="none"
					d="
M -289.15 -2042.7
Q -292.15 -2045.9 -295.85 -2045.5 -411.35 -2033.2 -417 -2032.6 -486.1 -2025.2 -538.1 -2019.35 -570.4 -2015.65 -572.95 -2015.35 -592.8 -2012.8 -607.6 -2009.85 -610.6 -2009.25 -612.05 -2006.75 -613.35 -2004.55 -612.95 -2001.7 -612.5 -1998.85 -610.65 -1996.95 -608.55 -1994.85 -605.55 -1994.9 -592.45 -1995.1 -575.25 -1996.55 -570.5 -1996.95 -545 -1999.5 -503.95 -2003.7 -484.45 -2005.8
L -295.85 -2026.3
Q -291.8 -2026.75 -289.15 -2029.2 -286.3 -2031.85 -286.3 -2035.9 -286.3 -2039.65 -289.15 -2042.7 Z"
				/>
			</g>

			<g id="Layer5_19_MEMBER_20_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M -625.55 -1451.05
Q -623.25 -1454.4 -624.15 -1458.65 -639.5 -1530.4 -660.85 -1626 -673.3 -1681.75 -698.45 -1793.2
L -794.6 -2221.15
Q -803.6 -2261.05 -805 -2266.95 -806.8 -2274.85 -810.4 -2290.85 -813.75 -2304.55 -817.5 -2314.2 -818.6 -2317.05 -820.75 -2318.55 -823.1 -2320.2 -826.05 -2319.75 -828.85 -2319.35 -830.85 -2317.2 -832.95 -2314.95 -832.75 -2312.15 -832.1 -2302.6 -829.7 -2290.25 -829.35 -2288.35 -825.1 -2268.65 -824.55 -2266.2 -816.1 -2227.65
L -685.4 -1641.5
Q -660.75 -1531.2 -642.65 -1453.5 -641.65 -1449.25 -638.2 -1447.55 -635.1 -1446 -631.35 -1447.05 -627.65 -1448.1 -625.55 -1451.05 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_0_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0966033935546875, -0.0014801025390625, 0.001373291015625, 0.095947265625, 100.55,249.65) ">
			<use xlinkHref="#Layer5_1_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0969390869140625, 0, 0, 0.0959930419921875, 95.25,249.25) ">
			<use xlinkHref="#Layer5_2_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_3_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.100738525390625, 0.004913330078125, -0.00506591796875, 0.0951690673828125, 92.8,248.85) ">
			<use xlinkHref="#Layer5_4_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_5_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.1040191650390625, 0, 0, 0.0959930419921875, 89.4,248.85) ">
			<use xlinkHref="#Layer5_6_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_7_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_8_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_9_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_10_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_11_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_12_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_13_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_14_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_15_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_16_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_17_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.0959930419921875, 96.6,248.85) ">
			<use xlinkHref="#Layer5_18_MEMBER_20_FILL" />
		</g>

		<g transform="matrix( 0.0959930419921875, 0, 0, 0.096435546875, 96.6,249.7) ">
			<use xlinkHref="#Layer5_19_MEMBER_20_FILL" />
		</g>
	</svg>
);
