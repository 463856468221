import React from "react";

export const skillsPage5 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="275px"
		height="480px"
		viewBox="0 0 275 480"
		id="frame-5"
	>
		<defs>
			<g id="Layer5_0_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1134.1 431.35
Q 1134.1 431.3 1134 431.4 1137.5 449.85 1154.8 468.15 1162.05 475.75 1171.7 483.25 1179.4 489.2 1190.25 496.3 1197.05 500.75 1210.7 509.5 1222.35 517.25 1230.35 523.85 1249 539.35 1260 558.9 1271.3 578.95 1274.7 601.35 1275.15 604.2 1275.45 607.05 1275.55 608.25 1276.85 608.25 1278.1 608.3 1278 607.05 1275.75 584.35 1265.6 563.85 1255.3 543.15 1238.5 527.65 1230.6 520.3 1219.35 512.6 1213.1 508.25 1198.9 499.35 1185.95 491.15 1179.4 486.6 1168.8 479.2 1161.15 472.2 1142.7 455.15 1135.95 436.5
L 1134.1 431.35 Z"
				/>
			</g>

			<g id="Layer5_1_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1000.15 361.2
Q 1000.15 361.25 1000.25 361.25 1003.15 361.1 1006.8 360.35 1008.25 360 1013.25 358.8 1017.6 357.75 1026.3 355.6 1040.25 352.25 1052.75 349.85 1068.05 346.8 1078.3 345.65 1092.35 344.05 1104.1 344.85 1117.2 345.8 1128.55 350.15 1138.4 353.85 1149.2 361.2 1157.85 367.15 1167.45 376.1 1172.95 381.25 1175.4 384.2 1179.45 389.15 1180.8 394 1182.05 398.4 1180.95 402.75 1179.75 407.6 1176.15 409.9 1173.4 411.6 1167.3 412.3 1161 413.05 1158.35 414.5 1156.55 415.5 1154.95 417.55 1154.2 418.6 1155.35 419.45 1156.45 420.25 1157.35 419.35 1160 416.7 1165.85 415.9 1173.8 414.8 1175.15 414.3 1179.85 412.55 1182.5 408.15 1185 404.05 1185.05 398.85 1185.2 389.35 1175.75 379.25 1168.05 371.05 1157.65 363.1 1135.85 346.15 1113.25 342.5 1101.1 340.55 1086.65 341.55 1075.4 342.35 1060.25 345.2 1048.75 347.45 1033.15 351.15 1014.7 355.65 1006.1 358.5 1003.1 359.5 1000.2 361.1 1000.15 361.15 1000.15 361.2 Z"
				/>
			</g>

			<g id="Layer5_2_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 966.85 494.35
Q 965.55 478.75 967.9 463.3 970.05 448.75 975.85 431.85 978.85 423.25 982.3 414.75
L 985.8 406.25
Q 987 403.55 987.45 402.2 987.7 401.5 988.2 399.65 988.6 398.05 989 397.15 989 397.1 988.9 397.1 988.5 397.85 987.8 398.9 986.8 400.35 986.65 400.6 985.8 401.95 984.7 404.25
L 981.2 411.8
Q 977.8 419.65 974.95 427.05 968.85 443.15 965.9 459.2 963.1 474.75 963.95 490.55 964.75 506.45 969.15 521.6 971.45 529.85 974.95 537.85 977.35 543.5 978.6 545.95 980.85 550.5 983.15 553.6 983.85 554.55 984.8 554 985.75 553.45 985.25 552.4 984.2 550.05 981.75 545.55 979.35 541 978.25 538.65 975.05 531.55 972.75 524.2 968.1 509.45 966.85 494.35 Z"
				/>
			</g>

			<g id="Layer5_3_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 793.25 377.75
Q 783.9 386.3 779.2 390.5
L 765.5 402.85
Q 755.95 411.5 753.2 414.3 747.15 420.4 743.7 425.6 739.1 432.4 737.8 439 736.55 445.35 738.45 451.45 740.3 457.5 744.85 462.05 752.15 469.25 766.1 472.95 772.4 474.6 780.65 475.55 784.85 476.05 795.4 476.85 813.1 478.15 832.35 478.3 843.55 478.35 865.9 477.95 885.05 477.9 898.2 479.55 915.65 481.7 929.45 487.35 931.2 488.05 936.85 490.55 937.45 490.8 937.8 490.25 938.1 489.7 937.55 489.35 926.55 482.25 908.45 478.7 895.15 476.05 877 475.55 868.9 475.35 844.65 475.7 826.4 475.95 808.95 475.05 800.95 474.65 790.7 473.9 779.25 472.85 775.9 472.3 761.5 470.05 752.7 464.7 747.4 461.55 744.15 457.05 740.6 452.1 740.05 446.4 738.95 434.4 750.9 420.7 755.2 415.85 761.5 410.2
L 802.75 373.15
Q 806.7 369.65 809.35 367.05 810.3 366.2 809.4 365.35 808.5 364.45 807.55 365.25 801.55 370.25 793.25 377.75 Z"
				/>
			</g>

			<g id="Layer5_4_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 868.65 330.05
Q 869.2 330.3 869.5 329.75 872.6 323.65 883.75 322.8 887.2 322.55 889.2 322.7 892.25 322.95 894.4 324.1 896.5 325.25 898.55 327.7 899.3 328.65 901.8 332.15 908.6 341.7 913.7 351.5 916.25 356.3 918.7 361.85 920.6 366.35 920.95 367.2 922.15 370.25 922.6 372.7 923.2 375.55 922.45 378.1 921.55 381.05 919.1 382.25 918.1 382.75 914.3 383 911.35 383.2 910.05 384.95 909.45 385.7 910.25 386.25 911.05 386.8 911.7 386.25 912.55 385.6 913.95 385.4 914.75 385.3 916.35 385.25 919.25 385.15 921 384.1 923.05 382.8 924.25 380.45 925.4 378.3 925.5 375.75 925.65 372.95 924.6 369.6 924 367.6 922.35 363.75 920.25 358.5 917.15 352.35 911.55 341.15 904.05 330.85 901.35 327.1 900.6 326.2 898.5 323.65 896.4 322.3 892.55 319.8 885.1 320.3 870.05 321.3 868.2 329.2 868.05 329.75 868.65 330.05 Z"
				/>
			</g>

			<g id="Layer5_5_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 977.4 290.85
Q 976.05 287.8 973.65 285.8 971.05 283.65 967.85 283.25 964.65 282.8 961.55 284.1 958.6087890625 285.3361328125 956.65 287.65 956.53984375 285.6884765625 956.4 284.45 955.8 278.4 953.95 273.9 952.15 269.5 948.95 266.25 945.55 262.85 941.2 261.4 936.8 259.9 932.45 261.2 928.2 262.5 925.15 266.05 919.65 272.55 918.35 284.75 917.6 292.05 919.1 297.8 919.65 300.05 921.45 300.4 922.85 300.65 922.9 299.5 922.9 299.3 922.75 299.45 922.2 300.3 921.25 298.75 920.85 298.2 920.75 296.9 920.45 294.35 920.45 291.75 920.4 286.85 921.3 281.85 923.15 272.15 927.85 267.2 930.5 264.4 934.1 263.65 937.7 262.9 941.3 264.4 944.8 265.8 947.5 268.8 950.05 271.55 951.5 275.25 953.2 279.5 953.75 285.1 954.0869140625 288.3423828125 954.1 293.3 954.1078125 294.2556640625 954.1 295.25 954.2 296.6 955.6 296.6 956.95 296.65 956.9 295.25 956.8931640625 295.0703125 956.9 294.9
L 956.9 294.75
Q 956.9447265625 289.825390625 961.9 287.3 967.05 284.65 971.25 287.85 973.15 289.3 974.3 291.7 975.45 293.95 975.55 296.4 975.85 301.85 971.4 305.75 969.05 307.8 965.9 308.75 962.9 309.6 959.7 309.3 958.35 309.15 958.15 310.6 957.95 312.05 959.25 312.35 968.3 314.55 972.75 316.45 980.2 319.7 983.7 324.9 984.8 326.5 985.25 327.65 985.7 328.75 985.1 329.4 984.75 329.75 983.45 330.35 979.2 332.25 975.05 333.85 966.4 337.25 957.45 339.55 947.45 342.25 937.05 343.6 935.5 343.8 935.5 345.15 935.5 345.7 935.95 346.2 936.45 346.7 937.05 346.65 957.5 344 976.3 336.7 982.25 334.4 985.55 332.8 987.85 331.65 988.4 330 989 328.2 987.8 325.7 984.8 319.4 977.1 315.2 973.429296875 313.240234375 967.75 311.45 970.298828125 310.518359375 972.65 308.75 978.75 304.1 978.7 297.05 978.7 293.85 977.4 290.85 Z"
				/>
			</g>

			<g id="Layer5_6_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1005.85 181.05
Q 1009.3 182.15 1011.15 181.95 1013.4 181.8 1014.4 179.9 1014.45 179.8 1014.4 179.7 1014.3 179.6 1014.2 179.65 1012.25 181 1008.75 180.1 1008.55 180 1003.4 178.1
L 990.15 173.25
Q 976.95 168.15 964.2 162.65 949.4 156.2 942.55 151.15 931.2 142.85 928.8 131.7 927.95 127.85 928.75 125.9 929.55 124.05 932.1 123.05 934.15 122.25 937.15 121.95 938.45 121.8 942.35 121.7 953.05 121.25 963.75 123 985.25 126.55 1004.1 138.05 1023.35 149.9 1035.7 167.3 1037.6 169.95 1039 171.5 1039.65 172.25 1040.4 171.6 1041.1 171 1040.7 170.2 1039.05 166.55 1034.2 160.6 1031 156.6 1026.6 152.1 1018.55 144.05 1008.75 137.55 989 124.6 966.45 120.3 954.95 118.1 943.45 118.4 935.55 118.65 932.35 119.6 925.9 121.5 925.4 127.05 924.9 131.9 928.25 138.8 930.8 144.05 935.3 148.55 939.6 152.85 945.8 156.6 950.55 159.4 957.6 162.55 970.8 168.45 984.8 173.7 993.35 176.85 998.7 178.7
L 1005.85 181.05 Z"
				/>
			</g>

			<g id="Layer5_7_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1126.7 239.3
Q 1126.55 240 1127.25 240.1 1139.15 242 1145.75 248 1150.85 252.5 1152 256.45 1152.7 258.8 1151.3 261.05 1150.05 263 1147.7 264.1 1145 265.35 1141.05 264.9 1136.65 264.05 1134.4 263.9 1133.65 263.85 1133.4 264.7 1133.15 265.55 1133.85 265.85 1137 267.25 1140.6 267.6 1144.5 267.95 1147.5 266.9 1150.2 266.05 1152.15 264.05 1154.25 261.9 1154.7 259.25 1155.15 256.55 1153.55 253.5 1152.45 251.35 1150 248.65 1145.9 244.1 1140.45 241.45 1133.7 238.05 1127.45 238.55 1126.85 238.6 1126.7 239.3 Z"
				/>
			</g>

			<g id="Layer5_8_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1124.85 255.7
Q 1124.244921875 255.9353515625 1124.1 256.35 1123.9162109375 256.45078125 1123.85 256.55 1123.65 257.05 1123.35 257.1 1123.35 257.25 1123.4 257.2 1123.7 257.15 1124.1 257.55
L 1124.3 257.55
Q 1124.5892578125 257.9 1125.15 257.9
L 1129.8 257.8
Q 1131.2 257.9 1131.6 257.85 1131.95 257.8 1132.25 257.35 1132.55 256.95 1132.55 256.55 1132.45 255.6 1131.6 255.3 1131.45 255.25 1129.8 255.1
L 1128.2 255.15
Q 1126.25 255.25 1124.85 255.7 Z"
				/>
			</g>

			<g id="Layer5_9_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1135.85 111.3
L 1135.8 104.55
Q 1135.8 100 1135.6 98.7 1135.25 96.35 1133.7 94.8 1132 93.15 1129.65 93.35 1125.6 93.6 1122.1 97.95 1119.75 100.95 1117.45 107.45 1115.65 112.6 1114.8 118.45 1114.2302734375 121.96328125 1113.9 126.15 1112.0142578125 118.2572265625 1108.8 110.6 1102.1 94.55 1090.6 81.5 1083.9 74 1076.25 68.15 1075.85 67.8 1075.15 67.9 1074.45 68 1074.1 68.45 1069.15 74.45 1068.9 85.35 1068.7 93.4 1070.7 102.25 1074.55 119.15 1085.65 132.7 1092.4 140.95 1099.75 145.45 1100 145.6 1100.2 145.35 1100.45 145.1 1100.2 144.9 1092.65 138.35 1088.65 133.55 1083.65 127.5 1079.75 119.85 1072.15 104.8 1072.15 88.5 1072.2 82.75 1072.9 79.3 1073.716796875 75.134765625 1075.65 72.2 1087.85703125 82.1416015625 1096.6 95.35 1105.8 109.35 1110.25 125.6 1112.75 134.95 1113.4 144 1113.5 145.5 1115.05 145.5 1116.6 145.55 1116.55 144 1116.45 130.8 1117.35 123.3 1118.7 111.75 1122.8 103.45 1125.2 98.7 1128.25 97.25 1129.8 96.5 1130.8 96.95 1131.95 97.55 1132.2 99.95 1132.3 101.9 1132.3 111.55 1132.3 117.55 1132.15 123.45 1131.85 134.15 1130.95 150.3 1130.9 151.25 1131.85 151.65 1132.8 152 1133.45 151.35 1138.55 146.35 1147.5 144 1155.35 141.95 1163.4 143.25 1171.65 144.65 1178.15 149.15 1183 152.5 1184.2 155.35 1185.05 157.45 1183.55 158.6 1182.35 159.55 1180.1 159.75 1177.7 159.95 1174.15 159.95 1170.15 159.95 1168.15 159.95
L 1154.9 160.1
Q 1153.5 160.15 1153.5 161.6 1153.5 163.1 1154.9 163.05
L 1167.9 163
Q 1169.95 163 1174 163.05 1177.65 163.1 1180.1 162.9 1186.2 162.5 1187.45 158.45 1188.8 154.25 1183.7 149.55 1177.05 143.35 1167.75 140.95 1158.75 138.65 1149.5 140.35 1144.7 141.25 1139.9 143.5 1137.4171875 144.705859375 1134.15 147.1 1135.251171875 136.1921875 1135.6 124.2 1135.75 119.9 1135.85 111.3 Z"
				/>
			</g>

			<g id="Layer5_10_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1156.7 172.3
Q 1156.65 172.3 1156.7 172.35 1157.95 173.2 1160.25 173.55
L 1164.45 174.35
Q 1168.1 175.05 1172.3 176.25 1180.6 178.6 1187.9 182.15 1195.3 185.7 1202.1 190.75 1204.2 192.35 1205 193.35 1206.55 195.25 1205.65 196.85 1204.95 198.15 1202.85 198.85 1202.25 199.05 1199.35 199.6 1189.3 201.55 1178.3 200.55 1177.2 200.45 1177.2 201.6 1177.2 202.8 1178.3 202.9 1188.8 203.9 1199.05 202.1 1202.15 201.55 1203.1 201.3 1205.4 200.7 1206.75 199.5 1208.25 198.2 1208.5 196.3 1208.7 194.5 1207.7 192.75 1206.75 191.1 1204.85 189.55 1204.05 188.85 1201.35 187 1197.8 184.5 1193.4 182.05 1185.25 177.55 1176.2 174.85 1171.15 173.3 1166.25 172.5 1164.15 172.1 1161.35 171.85 1159.65 171.75 1158.95 171.8 1157.65 171.85 1156.7 172.3 Z"
				/>
			</g>

			<g id="Layer5_11_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1176.55 218.8
Q 1176.45 219.25 1176.9 219.35 1178 219.6 1180.2 219.95 1182.4 220.25 1183.45 220.5 1186.7 221.3 1189.65 222.45 1195.8 224.85 1201.2 229.4 1203.6 231.45 1205.9 234.15 1207.3 235.8 1207.8 237.05 1208.45 238.85 1207.55 240.3 1205.85 243.1 1202.15 242.75 1199.25 242.5 1196.25 240.55 1189.8 236.55 1189.65 236.5 1185.6 234.55 1182.2 235.6 1181.3 235.9 1181.55 236.85 1181.8 237.8 1182.75 237.55 1186.2 236.65 1190.5 239 1192.9 240.35 1197.55 243.25 1200.55 244.65 1203.45 244.4 1206.7 244.1 1208.6 241.65 1210.7 239 1208.9 235.55 1207.85 233.5 1204.7 230.35 1199.55 225.2 1192.8 222.05 1188.85 220.2 1185.2 219.3 1180.15 218 1177.05 218.35 1176.65 218.4 1176.55 218.8 Z"
				/>
			</g>

			<g id="Layer5_12_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1029.05 217.2
Q 1028.35 217.5 1028.55 218.15 1030.15 223.9 1030.55 227.45 1030.65 228.45 1031.55 228.45 1031.9 228.45 1032.2 228.15 1032.55 227.85 1032.5 227.45 1031.9 221.8 1030 217.5 1029.7 216.9 1029.05 217.2 Z"
				/>
			</g>

			<g id="Layer5_13_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1018.9 214.6
Q 1018.45 214.55 1018.2 214.95 1017.85 215.8 1017.65 216.25
L 1017.6 216.4 1017.6 216.45 1017.3 217.2 1017.2 217.35 1016.6 218.55 1016.2 219.05
Q 1016.3 219 1016.2 219.1
L 1016.1 219.2
Q 1015.8 219.6 1015.75 219.85 1015.6 220.45 1016.1 220.9 1016.65 221.3 1017.2 220.95 1017.45 220.85 1017.95 220.2
L 1018.35 219.6 1019 218.35
Q 1019.4 217.35 1019.5 216.8
L 1019.6 216
Q 1019.65 215.6 1019.5 215.1 1019.4 214.65 1018.9 214.6 Z"
				/>
			</g>

			<g id="Layer5_14_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1008.5 209.2
Q 1008 209.2 1007.45 209.75 1006.8 210.5 1006.3 212.3 1005.95 213.9 1005.1 215.3 1004.95 215.7 1004.3 216.65 1003.55 217.55 1003.3 218.05 1002.95 218.75 1003.5 219.2 1004.1 219.6 1004.7 219.15 1005.05 218.85 1005.65 217.9
L 1006.4 216.65
Q 1007.15 215.25 1007.5 213.9 1007.85 212.2 1008.1 211.35 1008.25 210.8 1008.55 210.35 1008.6 210.3 1008.85 210 1008.925 209.9 1008.95 209.8
L 1008.9 209.85 1009.05 209.6
Q 1009.025 209.7 1008.95 209.8
L 1009.05 209.75 1009.1 209.65 1009.3 209.75 1009.35 209.65 1009 209.4 1008.95 209.4 1008.75 209.2 1008.5 209.2 Z"
				/>
			</g>

			<g id="Layer5_15_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1001.25 202.55
Q 1000.95 202.35 1000.6 202.45 999.6 202.9 997.2 205.3
L 988 214.95
Q 987.15 215.9 988.05 216.8 988.95 217.7 989.85 216.8
L 995.85 210.1
Q 998.4 207.3 998.85 206.75 1000.25 205.1 1000.3 205.05 1001.15 204 1001.45 203.15 1001.6 202.75 1001.25 202.55 Z"
				/>
			</g>

			<g id="Layer5_16_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1003.3 253.95
Q 1003 253.75 1002.75 254 1001.8 255 1000.65 257.6 999.55 260.5 999.45 261.65 999.4 261.95 999.65 262.25 999.85 262.45 1000.15 262.5 1000.85 262.6 1001.1 261.9
L 1002.15 258.1
Q 1003.2 255.7 1003.55 254.45 1003.65 254.1 1003.3 253.95 Z"
				/>
			</g>

			<g id="Layer5_17_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 994.55 248.4
Q 994.85 248.4 994.55 248.25
L 994.35 248.2
Q 994.35 248 994.15 247.95 993 247.45 992 247.6 991 247.65 989.8 248.3 988.7 248.9 988.05 249.7 986.75 251.15 987.05 251.95 987.3 252.5 987.8 252.25 988.15 252.05 988.45 251.4
L 989 250.65
Q 989.6 249.95 990.45 249.45 991.05 249 992.15 248.75 993.45 248.45 994.1 248.45
L 994.35 248.35 994.55 248.4 Z"
				/>
			</g>

			<g id="Layer5_18_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 982.1 240.25
Q 982 240.1 981.85 240.1 979.9 240.4 976.2 242.05 974.7 242.75 973.6 243.4
L 972.3 244.2
Q 971.35 244.8 971.2 245.1 971.1 245.3 971.25 245.5 971.35 245.7 971.55 245.7 971.9 245.65 972.7 245.1
L 973.95 244.35
Q 975.15 243.6 976.6 242.85 978.35 242 982 240.5 982.2 240.45 982.1 240.25 Z"
				/>
			</g>

			<g id="Layer5_19_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 985.8 285.8
Q 982.35 283.4 976.7 280 969.1 275.35 967.55 274.35 964.8 272.6 963.4 271.25 961.25 269.25 960.55 267 959.85 264.65 960.4 262.5 960.65 261.65 961.25 260.55 961.6 259.95 962.3 258.75
L 962.25 258.7
Q 960.75 258.7 959.7 260.15 958.6 261.7 958.35 263.7 957.85 267.75 960.5 271.4 962.6 274.25 967.85 277.65
L 977.4 283.8
Q 988.6 291.25 994.95 296.75 1003.95 304.55 1009.25 313.05 1015.45 322.95 1016.75 334.75 1016.9 336.15 1018.3 336.2 1019.75 336.2 1019.7 334.75 1019.55 325.4 1014.45 315.6 1010 307.1 1002.55 299.5 996.05 292.85 985.8 285.8 Z"
				/>
			</g>

			<g id="Layer5_20_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 970.55 599.95
Q 969.4 603.75 969.45 606.95 969.5 612.75 973.8 619.1 975.05 620.95 977.65 624.5 979.9 627.7 980.95 630.2 982.25 633.45 982.25 636.65 982.15 640.2 980 644.8 978.35 648.25 975.6 653.15 972.45 658.6 970.9 661.35 967.9 666.7 966.45 669.35 963.95 674 962.45 677.55 958.5 686.85 959.85 694.05 960.65 698.3 963.75 702.85 964.75 704.4 969.7 710.5
L 976.4 719
Q 977.45 720.35 979.55 723.15 981.45 725.55 983.25 726.7 983.3 726.7 983.3 726.65 982.75 724.8 981.2 722.65 979.35 720.2 978.45 719
L 967.6 703.85
Q 964.7 699.65 963.65 696.6 961.25 689.65 964.85 680.3 966.25 676.75 968.65 672.2 970.1 669.55 973.05 664.35 974.55 661.65 977.6 656.2 980.3 651.35 982 648.05 986.7 639 984.6 632.6 983.7 630 981.7 627 980.8 625.6 977.95 621.85 975.1 618.05 974.1 616.45 972.1 613.3 971.25 610.55 970.15 606.85 971.15 602.3 971.9 599 974.05 594.45 976.6 589.55 977.8 587.05 979.9 582.55 980.3 579.15 980.35 578.8 979.95 578.75 979.55 578.7 979.45 579.05 977.55 584.3 976.55 586.55 975.5 588.85 973.25 593.35 971.4 597.25 970.55 599.95 Z"
				/>
			</g>

			<g id="Layer5_23_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1131.2 713.5
Q 1132.1 715.1 1133.6 714.2 1145.75 706.85 1161.6 699.8 1172.25 695.1 1190.8 688 1209.7 680.7 1220.15 676.2 1237.5 668.8 1248.15 662.8 1256.05 658.4 1261.75 653.7 1265.05 651.05 1267.5 648.25 1269.15 646.35 1269.95 645.15 1271.15 643.35 1271.55 641.6 1271.6 641.5 1271.5 641.4 1271.35 641.35 1271.25 641.4 1270.1 642.25 1268.7 643.7 1267.15 645.45 1266.35 646.25 1264 648.75 1261.2 651.15 1256.25 655.4 1249.1 659.5 1237.4 666.35 1221.6 672.9 1212.1 676.85 1192.4 684.2 1172.95 691.4 1163.2 695.5 1144.95 703.2 1131.75 711 1130.25 711.9 1131.2 713.5 Z"
				/>
			</g>

			<g id="Layer5_24_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1091.1 665
Q 1083.9 673.35 1084 680.75 1084.15 688.45 1089.9 697.6 1094.7 705.3 1102 710.95 1110.2 717.3 1117.95 719.05 1118.75 719.2 1118.95 718.45 1119.15 717.7 1118.4 717.4 1109.65 713.8 1104.6 710.05 1098.3 705.45 1093.65 698.9 1087.8 690.4 1087.05 683.6 1086.2 676.05 1092.7 668.35 1095.25 665.35 1099.15 661.25 1101.35 658.9 1105.8 654.3 1114.5 645 1118.95 640.4 1132.9 625.9 1143 618.6 1150.85 612.95 1158.55 609.65 1165.4 606.8 1175.65 605.25 1177.3 605.05 1177.05 603.2 1176.75 601.35 1175.15 601.4 1160.7 602.05 1144.05 613.35 1132.8 621.05 1118.25 636.05
L 1105.05 650.05
Q 1100.35 655 1098 657.45 1093.8 661.85 1091.1 665 Z"
				/>
			</g>

			<g id="Layer5_25_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1036.45 707.15
Q 1035.95 706.8 1035.6 707.2 1030.5 712.9 1027.05 722.2 1024 730.6 1023.7 739.35 1023.55 743.8 1024.15 748.2 1024.85 753.55 1025.9 756.25 1027.5 760.3 1029.95 761.75 1031.6 762.75 1033.5 762.55 1035.3 762.35 1036.9 761.2 1038.8 759.85 1041.05 757.5 1042.3 756.1 1044.75 753.3
L 1050.6 746.95
Q 1051.15234375 756.0111328125 1052.1 762.15 1053.75 772.65 1057.2 780.7 1059.1 785.2 1061.5 788.8 1065 793.8 1067.7 795.65 1069.75 797.05 1071.95 797.15 1074.4 797.25 1076 795.5 1078.3 793.1 1077.55 787.7 1076.4212890625 778.8890625 1074.6 768.8 1078.153515625 773.76953125 1080.9 776.8 1087.7 784.25 1095.25 787.25 1099.25 788.85 1103.05 789 1107.45 789.1 1110.65 787.1 1114.35 784.75 1114.75 780.55 1115.05 776.5 1111.3 771.25 1104.6 761.95 1095.4 753.9 1094.2 752.85 1093.05 754 1091.85 755.15 1093.05 756.2 1100.2 762.45 1106.15 769.9 1109.7 774.45 1110.7 776.7 1112.7 781.35 1109.5 783.85 1107 785.8 1103.1 785.65 1100.05 785.55 1096.5 784.2 1090.25 781.8 1084.1 775.3 1079.3 770.2 1073.05 760.7 1072.25 759.55 1071.05 760.1 1069.8 760.6 1070.05 762 1071.9 771.35 1073.75 783.25 1073.95 784.65 1074.5 787.65 1074.85 790.3 1074.4 792.05 1073.85 794.05 1072 793.95 1070.45 793.9 1068.8 792.6 1066.4 790.65 1063.55 786.25 1061.3 782.7 1059.7 778.85 1056.85 771.8 1055.4 762.25 1054.55 756.45 1053.7 742.95 1053.6 741.9 1052.65 741.45 1051.6 740.95 1050.85 741.8 1040.85 752.8 1037.7 756.45 1036 758.45 1035.1 759.15 1033.35 760.45 1031.65 760 1029 759.3 1027.6 755.05 1025.4 748.8 1025.65 739.75 1025.8 731.9 1028.35 724 1030.7 716.85 1036.65 708 1036.95 707.55 1036.45 707.15 Z"
				/>
			</g>

			<g id="Layer5_26_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 978.2 763.3
Q 978.2 763.25 978.15 763.3 978.55 766.9 980.55 774.95
L 989.35 810.55
Q 999.1 850.2 1001 858.1 1011.1 899.95 1023.2 953.55
L 1035.3 1007.15
Q 1035.65 1008.7 1037.25 1008.3 1038.85 1007.85 1038.5 1006.3 1024.65 945.25 1016.5 910.8 1003.15 854.1 993.15 815.65 991.9 810.75 986.15 789.35
L 982.35 776
Q 979.85 767.1 978.2 763.3 Z"
				/>
			</g>

			<g id="Layer5_27_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1066.35 188.05
Q 1062.45 185.45 1061.55 181.6 1061.35 180.8 1060.45 181.05 1059.6 181.3 1059.9 182.05 1061.6 186.85 1063.75 189.2 1066.35 192.2 1071.3 193.65 1075.15 194.8 1081.1 195.45
L 1088.3 195.75
Q 1089.1 195.85 1090.75 196.1 1091.55 196.15 1091.9 195.35 1092.2 194.55 1091.65 193.95 1090.4 192.7 1087.8 192.4 1084.8 192.3 1083.5 192.25 1079.55 192.15 1074 191.05 1069.55 190.2 1066.35 188.05 Z"
				/>
			</g>

			<g id="Layer5_28_MEMBER_0_FILL">
				<path
					fill="#dddbdb"
					stroke="none"
					d="
M 1089.25 176.9
Q 1089.7 176.55 1089.75 176 1089.9 174.8 1088.65 174.4 1087.2044921875 173.8662109375 1085.65 173.6
L 1085.85 173.65
Q 1085.539453125 173.5810546875 1083.65 173.45 1082.8458984375 173.37734375 1081.7 173.3 1081.15 173.3 1080.7 173.7 1080.3 174.1 1080.3 174.7 1080.3048828125 174.7607421875 1080.3 174.8 1080.153515625 174.952734375 1080 175.1 1079.5 175.55 1079.7 176.3 1079.85 177.05 1080.55 177.2 1081.5529296875 177.45078125 1082.5 177.6 1082.521484375 177.759375 1082.55 177.95 1082.65 178.4 1082.95 178.7 1083.35 179.05 1083.8 179.05 1085.55 178.9 1086.9 177.4 1086.962890625 177.3318359375 1087 177.25
L 1088.3 177.3
Q 1088.8 177.3 1089.25 176.9 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_0_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_1_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_2_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_3_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_4_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_5_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_6_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_7_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_8_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_9_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_10_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_11_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_12_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_13_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_14_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_15_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_16_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_17_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_18_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_19_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_20_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_23_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_24_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_25_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_26_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_27_MEMBER_0_FILL" />
		</g>

		<g transform="matrix( 0.4724578857421875, 0, 0, 0.4724578857421875, -338.55,-14.5) ">
			<use xlinkHref="#Layer5_28_MEMBER_0_FILL" />
		</g>
	</svg>
);
