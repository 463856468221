import React from "react";

export const homePage3 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		preserveAspectRatio="none"
		x="0px"
		y="0px"
		width="500px"
		height="356px"
		viewBox="0 0 500 356"
		id="frame-3"
	>
		<defs>
			<linearGradient
				id="Gradient_1"
				gradientUnits="userSpaceOnUse"
				x1="942.95"
				y1="558.95"
				x2="1200.95"
				y2="558.95"
				spreadMethod="pad"
			>
				<stop offset="15.686274509803921%" stopColor="#0C0C0C" />

				<stop offset="31.372549019607842%" stopColor="#0C0F0E" />

				<stop offset="42.745098039215684%" stopColor="#0C1816" />

				<stop offset="52.94117647058823%" stopColor="#0D2824" />

				<stop offset="61.96078431372549%" stopColor="#0E3F37" />

				<stop offset="70.98039215686275%" stopColor="#125C50" />

				<stop offset="79.2156862745098%" stopColor="#17806E" />

				<stop offset="87.45098039215686%" stopColor="#1EAA92" />

				<stop offset="95.29411764705883%" stopColor="#27DABB" />

				<stop offset="100%" stopColor="#2DFCD8" />
			</linearGradient>

			<g id="Layer1_0_FILL">
				<path
					fill="url(#Gradient_1)"
					stroke="none"
					d="
M 942.95 441.2
L 942.95 676.75 1201 604.95 1201 513 942.95 441.2 Z"
				/>
			</g>

			<g id="Layer3_0_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 913.2 454
Q 913.05 453.95 912.8 454.3 911.9 456.95 912.9 458.8 913.4 459.8 914.4 460.3 915.4 460.85 916.45 460.6 917.6 460.25 918.35 459.3 919.1 458.3 919.15 457.15 919.177734375 456.5685546875 919 455.9 918.949609375 455.06875 918.45 454.3 917.75 452.95 916.5 452.65 914.7544921875 452.310546875 913.8 454.1
L 913.65 454.1
Q 913.5 454 913.2 454
M 916.15 456.2
Q 916.1732421875 456.246484375 916.15 456.25 916.1767578125 456.3232421875 916.15 456.35
L 916.15 456.2
M 916.1 456.85
Q 916.1 456.9 916.05 457
L 916.05 456.95 916.1 456.85
M 915.85 457.15
L 915.8 457.15 915.85 457.15
Q 915.8458984375 457.3283203125 915.75 457.15
L 915.8 457.15 915.75 457.1 915.8 457.1 915.85 457.15
M 916.4 455.35
L 916.4 455.35 916.4 455.35 916.4 455.35 Z"
				/>
			</g>

			<g id="Layer3_1_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 925.85 468.4
Q 925.5609375 468.809375 925.2 469.4 924.6 470.4 924.25 470.8 923.45 471.65 921.55 473.05 919.2 474.65 918.3 475.1 916.35 476.15 914.65 476.1 911.4 476.05 908.05 473.65 906.7 472.65 905.5 471.3 904.25 469.2 903.45 468.35 902.75 467.6 901.9 468.15 901 468.7 901.2 469.65 901.5 471.1 903.45 473.35 904.85 474.9 906.45 476 910.45 478.9 914.85 478.9 916.95 478.85 919.2 477.75 920.75 477 923.1 475.3 928.1 471.65 927.95 468.8 927.9 467.8 926.8 467.8 925.9640625 467.8 925.85 468.4 Z"
				/>
			</g>

			<g id="Layer3_2_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 897.15 444.4
Q 897.15 444.1 896.85 444.15 896.75 444.15 896.7 444.2
L 896.5 444.15
Q 896.25 444.2 895.85 444.5 895.15 445 894.75 445.55 893.95 446.8 893.65 448.05 892.7 451.9 894.7 453.55 895.15 453.9 895.6 453.7 896.05 453.55 896.1 453 896.2 451.55 896 450.6
L 896 450.5 895.95 450.25 895.95 448.8 896 448.7
Q 896.2 447.35 896.55 446.5
L 897.1 445 897.15 444.4 Z"
				/>
			</g>

			<g id="Layer3_3_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 884.15 385.55
Q 883.05 390 886.55 395.65 887.75 397.6 890.35 400.65
L 892.75 403.2
Q 893.15 403.55 893.9 404.3 894.6 404.9 895.25 405.05 895.65 405.15 895.9 404.9 896.15 404.6 896.05 404.25 895.85 403.6 895 402.65
L 893.2 400.6
Q 891.25 398.45 890.1 396.9 886.65 392.3 886.2 388.45 886 385.95 886.85 384.8 888.15 383.2 890.55 385.25 893.5 387.6 896.15 392.2 898.15 395.65 899.8 401.85 900.05 402.7 901 402.6 901.95 402.5 901.85 401.6 901.25 396.8 898.2 391.4 894.9 385.55 891.35 383.1 888.95 381.35 886.75 382.25 884.75 383.05 884.15 385.55 Z"
				/>
			</g>

			<g id="Layer3_4_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 947.7 393.7
Q 945.15 390.05 938.25 391.2 932.6 392.15 927.75 394.5 924.55 395.95 922.4 397.6 919.45 399.75 918.7 401.65 918.55 401.95 918.85 402.1 919.15 402.25 919.4 402.1 920.35 401.3 922 400.4 922.9 399.95 924.7 399 927.4 397.6 929.8 396.65 936.2 394.1 940.95 393.9 944.5 393.65 945.45 395.8 946.25 397.75 943.5 400.65 942.55 401.65 939.15 404.95
L 935.6 408.45
Q 935 409.15 934.85 409.8 934.7 410.5 935.15 411 935.65 411.45 936.35 411.3 936.95 411.2 937.5 410.75 937.85 410.5 938.5 409.85
L 945.35 403.05
Q 947.5 400.85 948.25 398.7 949.25 395.9 947.7 393.7 Z"
				/>
			</g>

			<g id="Layer3_5_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 945.7 408.75
Q 945.9 409.1 946.35 409 948.7 408.65 952.25 407.4 957.05 405.75 958.1 405.45 964.25 403.75 970.25 403.3 976.65 402.75 982.75 403.5 985.3 403.8 988.75 404.5 989.1 404.6 990 404.75 990.75 404.95 990.95 405.35 991.4 406.3 990.35 407.9 988.9 410.25 984.7 411.4 979.95 412.4 978.05 413.25 977.1 413.7 977.45 414.8 977.75 415.95 978.75 415.85 980.95 415.65 986.05 414.15 990.15 412.95 992 410.9 994.55 408.2 994.1 405.05 993.85 403.3 992.45 402.4 991.55 401.85 989.4 401.35 975.15 398.3 960.4 401.7 956.05 402.75 952.75 404 950.25 405.05 949.05 405.7 947.1 406.75 945.8 408.05 945.45 408.4 945.7 408.75 Z"
				/>
			</g>

			<g id="Layer3_6_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 956.45 419.65
Q 956.6 420.05 957.05 419.95 959.4 419.55 963.95 418.5 968 417.65 970.9 417.55 978.3 417.15 984.85 418.95 991.75 420.8 996.2 424.4 998.65 426.3 1000.2 428.65 1003.05 433 1001.7 434.25 999.9 435.95 994.75 434.95
L 990.6 433.95
Q 988.1 433.3 986.45 433.25 985.45 433.25 985.15 434.3 984.85 435.4 985.75 435.8 986.8 436.25 989.75 436.9 992.55 437.55 994.15 437.85 998.85 438.8 1001.7 437.65 1005.8 436.05 1004.9 431.9 1004.15 428.8 1001.7 425.65 997.8 420.65 990.15 417.6 982.5 414.5 974.15 414.5 969.3 414.45 965.05 415.4 960 416.55 956.65 419 956.3 419.25 956.45 419.65 Z"
				/>
			</g>

			<g id="Layer3_7_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 872.6 457.6
Q 871.65 456.3 871.2 456 870.35 455.4 869.5 455.7 869.15 455.8 868.9 456.15 868.65 456.55 868.8 456.9
L 868.9 457.1
Q 869 457.35 869.25 457.5
L 869.5 457.6 869.35 457.55 869.6 457.7 869.6 457.75 869.55 457.7 869.65 457.8 869.65 457.8
Q 869.7921875 457.85 869.7 457.85
L 871.25 460.4
Q 871.65 461.3 871.75 461.45 872.15 462.05 872.7 462.15 873.55 462.3 874 461.65 874.35 461.1 874.1 460.3 874 460 873.5 459.05
L 872.6 457.6 Z"
				/>
			</g>

			<g id="Layer3_8_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 961.55 509.95
Q 961.5 510.4564453125 961.5 511.5
L 961.4 521.9
Q 961.3142578125 522.114453125 961.3 522.35 961.157421875 523.0896484375 961.8 523.8 962.3 524.35 963.1 524.35 963.9 524.35 964.4 523.8 965 523.2 965.1 522.05 965.15 520.75 965.15 520.1
L 965.1 516.55
Q 965.1 515 964.85 513.1 964.8 511.85 964.7 511.15 964.6 510 964.25 509.3 963.6 508.1 962.4 508.8 961.57890625 509.259765625 961.55 509.95 Z"
				/>
			</g>

			<g id="Layer3_9_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 953.05 512.8
Q 952.7 512.55 952.25 512.65 951.65 512.8 951.45 513.55 951.4 513.9 951.4 514.85
L 951.35 518.75
Q 951.35 519.65 951.45 519.9 951.6 520.4 952.05 520.65 952.5 520.95 953 520.8 954.05 520.55 954.05 519.4
L 953.65 514.6
Q 953.6 513.85 953.5 513.5 953.4 513.05 953.05 512.8 Z"
				/>
			</g>

			<g id="Layer3_10_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 940.5 513.4
Q 940.38984375 514.022265625 940.35 514.9
L 940.1 518.35
Q 940 519.35 940 519.65 940.05 520.4 940.55 520.85 941.55 521.65 942.45 521.05 943.15 520.55 943.25 520.05
L 943.35 519.35 943.35 516.75
Q 943.25 514.05 943 513.1 942.8 512.4 942.1 512.2 941.4 512.05 940.85 512.55 940.480078125 512.919921875 940.5 513.4 Z"
				/>
			</g>

			<g id="Layer3_11_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 932.3 517.05
Q 932.3 516.2 932.2 515.8 932.05 515.15 931.35 514.95 930.55 514.65 930.15 515.25
L 929.7 515.85 929.5 516.3
Q 929.3 516.7 929.1 517.5
L 928.8 518.45
Q 928.6 519.1 928.75 519.65 929.1 520.65 930.05 520.8 930.75 520.85 931.3 520.4 931.8 519.95 931.9 519.3
L 932.3 517.05 Z"
				/>
			</g>

			<g id="Layer3_12_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 961.65 534.2
Q 961.05 534.3 960.8 534.95 960.55 535.55 960.45 537.6
L 960.55 543.75
Q 960.4990234375 543.8009765625 960.45 543.85 960.1 544.2 960.1 544.75 960.05 545.35 960.5 545.8 960.85 546.25 961.45 546.35 962.05 546.45 962.6 546.1 963.1 545.8 963.35 545.2 963.55 544.75 963.55 543.5
L 963.35 538.35
Q 963.15 535.75 962.65 534.7 962.4 534.1 961.65 534.2 Z"
				/>
			</g>

			<g id="Layer3_13_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 952.3 539.9
L 952.3 539.9
Q 952.2705078125 539.96875 952.25 540.05 952.2126953125 540.17265625 952.2 540.3 952.15 540.573046875 952.15 540.9
L 952.15 542.35 952.25 542.85 952.35 544.55
Q 952.35 544.6326171875 952.35 544.8 952.05 545.1068359375 952.05 545.55 952.1 546.05 952.8 546.95 953.25 547.55 954.25 547.35 954.9 547.15 955.25 546.3 955.35 546.1 955.35 545.2
L 955.35 544.5
Q 955.35 543.7 955.2 542.85 955.1 542 954.85 541.25 954.3 539.95 953.7 539.55 953.35 539.3 952.9 539.45 952.5244140625 539.6169921875 952.3 539.9 Z"
				/>
			</g>

			<g id="Layer3_14_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 944.2 538.55
Q 943.7380859375 539.0732421875 943.65 540.35
L 943.5 543.2
Q 943.5 544.75 943.6 545 943.95 546.1 945.05 546.1 946.1 546 946.5 545 946.55 544.8 946.55 543.2
L 946.45 540.35
Q 946.35 538.9 945.75 538.45 945.25 537.95 944.65 538.25 944.3642578125 538.3818359375 944.2 538.55 Z"
				/>
			</g>

			<g id="Layer3_15_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 910.65 545.7
Q 909.6 546.7 910.7 547.6 922.25 557.45 933.95 564.35 938.15 566.75 940 567.6 943.4 569.2 946.5 569.55 949.2 569.85 953.65 569.55 957.45 569.35 959.3 568.05 961.55 566.45 962.05 563.6 962.6 560.65 960.6 558.75 960.3 558.5 959.95 558.7 959.6 558.9 959.7 559.25 960.5 561.55 959.85 563.7 959.15 566.1 956.95 566.85 955.3 567.45 951.2 567.45 947.05 567.55 944.75 566.95 942.25 566.35 939.25 564.6 938.5 564.2 934.15 561.35 929 558.05 923.85 554.35 917.75 549.85 912.75 545.55 911.7 544.65 910.65 545.7 Z"
				/>
			</g>

			<g id="Layer3_16_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 928 562.6
Q 928.65 562.2 928.6 561.95 928.4 561.05 927.4 561.25 926.75 561.4 925.95 561.95
L 921.65 564.9
Q 917.7 567.55 912.55 570.4 909.85 571.95 907.95 572.85
L 905.5 574
Q 904.35 574.5 904.1 575.1 903.55 576.1 904.5 577.2 904.65 577.4 906.25 578.75 906.6 579.1 908.4 580.65 909.9 582.15 909.3 582.75 908.9 583.15 909.2 583.75 909.5 584.3 910.1 584.1 910.9 583.8 911.3 583.05 911.65 582.3 911.45 581.4 911.2 580.45 910.05 579.3 908.65 578.1 908.1 577.55 906.9890625 576.4390625 906.5 575.95
L 908.1 575.25
Q 910.5 574.15 912.75 572.85 917.5 570.3 921.8 567.25 923.75 565.9 926.05 564.1
L 928 562.6 Z"
				/>
			</g>

			<g id="Layer3_17_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 813.35 565.65
Q 824.2 564.45 830.85 564.55 845.4 564.85 864.4 569.45 874.3 571.8 881.25 575.15 888.35 578.65 895.95 585.55 896.5 586.05 897 585.55 897.5 585 897.05 584.45 887.9 573.25 868.25 567.95 862.75 566.45 851.45 564.25 845.8 563.2 842.45 562.8 837.4 562.15 833.3 562.3 829.7 562.45 823.95 563 816.6 563.7 814.6 563.85 811.65 564.05 805.4 564.25 799.35 564.4 796.2 564.65 787.3 565.4 780.85 567.6 772.8 570.3 767.65 575.6 764.85 578.5 763.1 582.15 761.3 585.95 761 589.8 760.7 593.55 762.3 596.85 763.9 600.05 767 602.3 767.75 602.85 768.25 602 768.75 601.2 767.95 600.65 765.15 598.6 763.9 595.7 762.65 592.75 763.15 589.35 764.15 582.95 769.35 577.6 774.2 572.65 781.9 570.05 787.95 568 796.4 567.2 799.25 566.95 804.9 566.5 809.9 566.1 813.35 565.65 Z"
				/>
			</g>

			<g id="Layer3_18_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 828 577.9
Q 836.45 575.55 844.1 575.15 848.75 574.9 852.2 575.15
L 860.45 576.2
Q 860.8 576.25 860.85 575.9 860.85 575.5 860.55 575.4 854.9 573.15 845.5 573.35 838.65 573.5 830.5 575.4 823.65 577 815.45 579.95 810.25 581.85 800.85 585.75
L 785.6 592.1
Q 781 594 778.65 595 774.6 596.7 771.85 598.05 765 601.45 761.65 605.8 759.65 608.4 758.95 611.4 758.1 614.7 759.15 617.55 760.2 620.45 763.2 622.7 765.25 624.15 769.05 625.85
L 786 633.4
Q 787.3 634 787.85 632.75 788.35 631.5 787 630.95
L 772.95 625
Q 767.75 622.85 767.25 622.6 764.05 621.05 762.35 619 760.25 616.55 760.65 613.1 761.05 610.05 763.15 607.25 766.2 603.2 773 600 775.65 598.75 786.05 594.45
L 800.1 588.5
Q 812.55 583.25 813.9 582.75 821.8 579.6 828 577.9 Z"
				/>
			</g>

			<g id="Layer3_19_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 819.1 591.7
Q 815.95 592.45 811.5 593.85 810.95 594 811.15 594.5 811.3 595.05 811.85 594.95 816.95 594.1 827.05 592.25 835.9 590.75 842.35 590 857.25 588.3 872.9 588.15 903.75 587.85 933.85 594.4 941.45 596.05 963.1 601.75 981.4 606.55 992.45 608.5 1008.7 611.35 1021.95 610.95 1028.7 610.7 1036.95 609.35 1041.95 608.55 1051.7 606.5 1061.35 604.5 1066.65 603.75 1074.95 602.6 1081.7 602.85 1089.1 603.2 1094.6 605.55 1101.25 608.4 1104.35 614 1105.8 616.65 1106.1 619.6 1106.2 621.15 1107.8 621.15 1109.4 621.15 1109.3 619.6 1108.75 613.3 1104.15 608.35 1099.95 603.85 1093.6 601.7 1087.3 599.55 1079.15 599.55 1073 599.55 1064.55 600.85 1059.5 601.65 1049.25 603.8 1039.15 605.95 1033.95 606.75 1019.55 608.9 1003.4 607.05 990.6 605.65 972.85 601.2 952.8 595.85 942.75 593.4 911.5 585.85 881.05 585.4 849.2 584.85 819.1 591.7 Z"
				/>
			</g>

			<g id="Layer3_20_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1113.6 614.45
Q 1113 615.15 1113.7 615.85 1113.8580078125 616.0080078125 1114.15 616.15 1120.3384765625 619.8134765625 1124.35 623.55 1122.0654296875 625.9533203125 1119.3 629.85 1115 635.85 1113.8 637.3 1113.1 638.15 1107.65 643.65 1104.7869140625 646.4759765625 1103.25 648.95 1102.20625 647.8193359375 1101.05 647.15 1100.3 646.7 1099.85 647.4 1099.35 648.05 1099.85 648.65 1101.3 650.35 1102.35 651.95 1102.75 652.5 1103.45 652.55 1104.25 652.6 1104.55 651.95 1106 648.8 1109.8 645.05 1115 639.85 1115.85 638.8 1116.5 638 1121.1 631.35 1124.25 626.8 1126.9 624.4 1127.25 624.05 1127.25 623.55 1127.25 623.05 1126.9 622.7 1121.25 616.9 1115.05 614.1 1114.25 613.75 1113.6 614.45 Z"
				/>
			</g>

			<g id="Layer3_21_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 944.3 594.45
Q 944.25 594.6 944.45 594.65 954.05 597.55 962.55 598.65 970.75 599.75 981.5 600.1 994.3 600.6 1001.5 600.2 1010 599.8 1022.5 598.25 1053.5 594.45 1062.95 594.2 1086 593.55 1102.75 600.2 1112.45 604.05 1119.55 610 1124.3 613.95 1126.3 616.8 1127.65 618.65 1127.8 620.55 1127.95 622.85 1126.05 623.85 1124.85 624.45 1125.4 625.85 1126 627.2 1127.25 626.75 1129.25 626.1 1130.35 624.35 1131.4 622.65 1131.35 620.5 1131.3 616.9 1127.3 612.5 1121.6 606.2 1113.15 601.35 1097.8 592.5 1076.7 591 1066.85 590.3 1054.65 591.2 1046.8 591.8 1032.7 593.65 1018.1 595.6 1010.8 596.3 998.55 597.5 988.7 597.4 976.75 597.3 966.55 596.7 961.1 596.4 955.6 595.8 947.3 594.9 944.5 594.3 944.35 594.25 944.3 594.45 Z"
				/>
			</g>

			<g id="Layer3_22_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 795.95 617.75
Q 795.4 617.6 795.15 618.15 791.55 627.45 795.05 638.65 798.05 648.3 805.3 656.8 811 663.5 821.05 670.9 830.05 677.5 840.4 683.5 846.4 687 851.95 689.75
L 857.95 692.7
Q 858.95 693.15 860.9 694.1 862.65 694.8 864 694.9 864.8 695 865.1 694.15 865.45 693.35 864.85 692.8 864 692 862.65 691.35 861 690.65 860.15 690.25
L 854.75 687.7
Q 849.45 685.15 844.95 682.6 834.65 676.95 825.7 670.85 815.65 664.1 809.6 658.05 801.5 650 798 641.2 793.55 630.05 796.35 618.5 796.5 617.9 795.95 617.75 Z"
				/>
			</g>

			<g id="Layer3_23_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1197.7 497.35
Q 1197.6 497.8 1198 498.1 1199.15 498.95 1201.15 499.65 1202.3 500.1 1204.5 500.75
L 1222.05 506.75
Q 1223.1 507.1 1223.35 507.25 1223.95 507.5 1224.4 508.05 1224.75 508.45 1225.4 508.4 1226.05 508.3 1226.25 507.8 1226.95 506.25 1225.45 505.25 1224.8 504.8 1222.55 504
L 1202.35 497.6
Q 1199.2 496.85 1198.3 496.9 1197.8 496.95 1197.7 497.35 Z"
				/>
			</g>

			<g id="Layer3_24_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1223.3 506.6
Q 1223.15 506.6 1223.1 506.8 1222 517.05 1221.6 529.75 1221.35 538.3 1221.35 552.8 1221.35 576.4 1222.65 598.35 1223.4 611.2 1224.6 624 1224.7 625.3 1226.05 625.3 1227.4 625.3 1227.3 624 1223.15 577.85 1223.35 532.5 1223.45 523.95 1223.5 519.65 1223.6 511.95 1223.5 506.8 1223.5 506.6 1223.3 506.6 Z"
				/>
			</g>

			<g id="Layer3_25_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1224.4 628.55
L 1224.5 628.15
Q 1224.6 627.8 1224.45 627.45 1224.3 627.05 1224 626.9 1222.95 626.4 1221.45 626.4 1221 626.4 1218.85 626.6 1215.5 626.85 1212.45 627.4 1209.25 627.95 1206.25 628.75
L 1203.2 629.65
Q 1202.1 630.05 1201.9 630.1 1201.2 630.45 1200.85 631 1200.65 631.3 1200.8 631.65 1200.95 632 1201.3 632.1 1201.85 632.3 1202.65 632.15 1203.1 632.05 1204 631.75
L 1207.1 630.95
Q 1209.6 630.3 1212.9 629.7 1216.1 629.15 1218.85 628.85 1220.75 628.65 1221.7 628.65 1222.1 628.65 1222.9 628.8 1223.65 628.95 1224.1 628.85 1224.35 628.8 1224.4 628.55 Z"
				/>
			</g>

			<g id="Layer3_26_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1164.25 684.45
Q 1163.85 684.85 1164.1 685.25 1164.55 685.95 1165.25 686.65 1165.35 686.75 1165.4 686.7 1165.5 686.65 1165.5 686.55
L 1165.5 686.5
Q 1166.1 687.0634765625 1166.95 687.8 1168.65 689.25 1169.65 690.25 1172.2 692.8 1174.7 695.85 1177.2 699.1 1179 702.05 1180.35 704.15 1182.7 708.6 1183 709.05 1183.55 708.85 1184.15 708.6 1183.95 708.05 1181.75 702.35 1175.95 695.15 1173.9 692.6 1170.8 689.4 1167.1 685.8 1165 684.35 1164.6 684.1 1164.25 684.45 Z"
				/>
			</g>

			<g id="Layer3_27_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1106.1 689
Q 1105.65 687.9 1104.65 688.3 1103.6 688.7 1102.65 689.85 1102.55 689.95 1101.1 692 1099.1 695.05 1098.15 696.95 1096.8 699.65 1095.8 702.15 1095.5 703.05 1094.8 704.85 1094.503515625 705.640625 1094.3 706.3 1094.2705078125 706.3658203125 1094.25 706.4 1093.9 706.95 1093.9 708.05 1093.85 709.4 1095.2 709.55 1096.55 709.7 1096.95 708.45 1097.85 705.15 1098.5 703.55 1099.5 700.95 1100.5 698.9
L 1104.35 692.2
Q 1105.25 690.8 1105.6 690.6 1106.5 690.05 1106.1 689 Z"
				/>
			</g>

			<g id="Layer3_28_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1094.5 712.25
Q 1109.35 712.8 1129.25 710.8 1140.85 709.65 1163.85 706.7 1166.6 706.35 1172.15 705.7
L 1176.15 705.15
Q 1178.25 704.75 1178.75 703.65 1178.9 703.45 1178.7 703.25 1177.95 702.25 1175.95 702.35 1173.75 702.65 1172.9 702.7
L 1150.95 705.2
Q 1139.15 706.6 1133.25 707.3 1122.95 708.45 1115.5 708.9 1104.5 709.6 1094.5 709.25 1093.1 709.2 1093.1 710.7 1093.1 712.2 1094.5 712.25 Z"
				/>
			</g>

			<g id="Layer3_29_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1113.5 703.4
L 1104.5 704.05
Q 1100.2 704.5 1100.05 704.5 1098.65 704.7 1098.3 704.8 1097.3 705.1 1096.85 705.8 1096.75 705.95 1096.9 706.1 1097.35 706.65 1098.1 706.85 1098.4 706.9 1099.55 706.95 1101.5 707 1104 706.85 1106.6 706.75 1111.8 706.45
L 1168.6 702.65
Q 1170.15 702.6 1173 702.4 1173.85 702.35 1175.45 702.3 1176.85 702.2 1177.85 701.9 1179.15 701.6 1178.95 700.35 1178.7 699.15 1177.5 699 1176.65 698.9 1175.5 698.95 1174.2 699.05 1173.5 699.05
L 1145.4 701.05
Q 1124.55 702.5 1113.5 703.4 Z"
				/>
			</g>

			<g id="Layer3_30_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1198.7 510.3
Q 1198.7 510.15 1198.55 510.15 1197 510.25 1195 512 1192.75 513.95 1191.2 515.8 1187.9 519.7 1185.75 524.55 1181.9 533.15 1181.45 545.55 1180.75 567.2 1187.2 590.2 1191.4 604.75 1196.25 614.15 1196.95 615.5 1198.25 614.7 1199.5 613.9 1198.95 612.55 1193.15 599.3 1190.9 592 1187.8 581.95 1186.05 571.05 1184.3 559.9 1184.25 549.6 1184.1 537.25 1187.05 528.45 1189.05 522.8 1192.15 518.6 1193.8 516.25 1195.6 514.3 1196.2 513.7 1197.4 512.45 1198.35 511.35 1198.7 510.3 Z"
				/>
			</g>

			<g id="Layer3_31_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1123 624.35
Q 1122.75 624.55 1122.85 624.85 1123.2 625.95 1124.7 628.05 1125.25 628.7 1127.3 631.65 1129.5 634.9 1131.6 638.45 1135.75 645.8 1138.6 653.55 1141.4 661.3 1142.8 669.3 1143.7 674.35 1143.75 677.45 1143.8 682.1 1142.1 685.4 1140.65 688.4 1139.2 688.2 1138.15 688.05 1137.9 685.95 1137.7 684.55 1137.95 681.25 1138.15 678.15 1137.8 676.5 1137.55 675.45 1136.4 675.45 1135.25 675.45 1135 676.5 1134.65 677.8 1134.65 681.75 1134.65 685.2 1134.85 686.5 1135.2 688.35 1136.2 689.65 1137.45 691.1 1139.2 691.1 1142.75 691.1 1144.8 685.65 1146.2 681.9 1145.95 676.75 1145.55 668.65 1142.45 658.4 1139.8 649.45 1135.45 641.1 1132.9 636.3 1130.05 632 1128.75 630.1 1127.05 627.9 1125.85 626.3 1125.5 625.9 1124.55 624.8 1123.55 624.3 1123.3 624.15 1123 624.35 Z"
				/>
			</g>

			<g id="Layer3_32_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1143.15 658
Q 1142.65 658.4 1142.8 659 1143.05 659.95 1143.8 661.2 1144.25 661.9 1145.1 663.25
L 1147.4 667.15
Q 1150.05 671.75 1152 675.9 1153.35 678.55 1153.95 680.25 1154.35 681.1 1154.6 682.3 1154.75 683 1154.7 683.55 1154.7 683.841015625 1154.5 684.5
L 1153.5 683.85 1151.9 682.5
Q 1150.3 680.95 1149.05 678.95 1148.3 677.75 1147 678.5 1145.7 679.25 1146.45 680.5 1148.05 683.05 1150.1 684.9 1152.95 687.45 1154.35 687.7 1155.7 687.95 1156.65 686.8 1157.4 685.85 1157.6 684.35 1157.95 682.15 1156.65 678.85 1154.65 674.15 1151.1 667.95 1149.55 665.2 1147.85 662.65 1146.8 661 1146.2 660.15 1145.15 658.7 1144.15 657.95 1143.65 657.6 1143.15 658 Z"
				/>
			</g>

			<g id="Layer3_33_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1113.4 681.6
Q 1113.15 682.8 1113.45 683.85 1113.8 685 1114.75 685.65 1116.15 686.55 1119.35 686.25 1122.2 686 1124.25 685 1127.2 683.6 1129.8 679.45
L 1134.3 672.05
Q 1134.55 671.65 1134.25 671.25 1133.95 670.8 1133.5 671 1131.75 671.6 1130.3 673.3 1129.6 674.15 1128.05 676.65 1126.45 679.1 1125.9 679.8 1124.55 681.45 1122.95 682.25 1121.1 683.1 1118.4 683.2 1117.85 683.25 1117.45 683.25 1116.8 683.25 1116.6 683.05 1116.4 682.85 1116.65 682.2 1116.95 681.6 1118 680.75 1119.15 679.75 1119.75 679 1119.85 678.85 1120.9 676.9 1121.05 676.55 1121.5 676.2
L 1121.8 676.05
Q 1122.5 675.45 1122.05 675.9 1122.75 675.75 1122.9 675.05 1123.05 674.35 1122.6 673.85 1121.7 672.8 1120.35 673.1 1119.15 673.35 1118.3 674.45 1118 674.85 1117.7 675.45 1117.3 676.3 1117.2 676.5 1116.85 677.15 1115.8 678.1 1113.8 680 1113.4 681.6 Z"
				/>
			</g>

			<g id="Layer3_34_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1129.5 621.35
Q 1129.3 621.35 1129.35 621.5 1129.4 622.1 1129.95 622.95 1130.25 623.45 1130.85 624.35
L 1137.35 637.3
Q 1140.1 642.95 1141.1 647.5 1142.25 652.45 1142.35 658.5 1142.35 659.6 1143.5 659.6 1144.65 659.6 1144.6 658.5 1144.6 652.85 1143.4 647.3 1142.35 642.3 1139.45 636.6 1135.6 629 1134.5 627.15 1133.05 624.7 1132.05 623.45 1131.2 622.4 1130.95 622.15 1130.2 621.4 1129.5 621.35 Z"
				/>
			</g>

			<g id="Layer3_35_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1170.25 670.05
Q 1168.7 671 1165.95 674.05
L 1163.1 677.4
Q 1162.6 678 1162.6 678.7 1162.6 679.1 1163.1 679.2 1163.7 679.2 1164.35 678.75
L 1167.75 675.85
Q 1170.7 673.2 1171.75 671.55 1172.2 670.85 1171.6 670.2 1170.95 669.6 1170.25 670.05 Z"
				/>
			</g>

			<g id="Layer3_36_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1101.5 661.65
Q 1100.35 660.5 1099.35 659.7 1098.8 659.2 1098.3 659.7 1097.75 660.2 1098.25 660.8 1099.25 661.95 1100.2 662.85
L 1104.45 666.75
Q 1105.2 667.35 1106.85 668.5 1106.95 668.55 1107.05 668.5 1107.15 668.4 1107.05 668.3
L 1101.5 661.65 Z"
				/>
			</g>

			<g id="Layer3_37_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1199.35 498.1
Q 1198.75 498.25 1198.75 498.85 1198.8 501 1199.45 505.35 1199.65 507.55 1199.75 511.95 1199.8 515 1199.7 525.65 1199.65 530.25 1199.65 553.25 1199.65 584 1197.15 607.95 1196.2 616.9 1195.25 623.25
L 1194.75 626.9
Q 1194.3 629.25 1194.4 630.15 1194.55 631.8 1195.5 632.9 1196.55 634.2 1198.1 634 1200.45 633.7 1200.15 631.55 1199.9384765625 630.034375 1198.7 629.7
L 1200.05 619.2
Q 1200.8 612.85 1201.4 606.2 1202.65 592.8 1203 579.95 1203.3 571.1 1203.2 553.5 1203.1 532.8 1202.95 526.9 1202.85 517.1 1202.7 512.2 1202.55 508.5 1202.05 505.2 1201.45 500.7 1200.2 498.45 1199.9 497.95 1199.35 498.1 Z"
				/>
			</g>

			<g id="Layer3_38_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 1226.1 547.35
Q 1225.6 547.7 1225.95 548.2 1229 552.7 1230.5 559.4 1231.45 563.75 1233.1 573.2 1235.45 587.2 1236.15 601.75 1236.95 617.1 1235.8 630 1234.55 644.1 1230 657.7 1227.85 664.3 1224.6 672.95 1224.15 674.25 1218.85 688.1 1218.2 689.8 1220.05 690.25 1221.85 690.75 1222.45 689.1 1230.25 668.3 1232.55 661.4 1237.35 647.2 1238.8 633.35 1241.6 607.15 1236.15 575.35 1234.7 566.8 1232.75 559.45 1231.7 555.4 1230.7 553 1229.2 549.5 1226.95 547.4 1226.55 547 1226.1 547.35 Z"
				/>
			</g>

			<g id="Layer3_39_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 965.9 456.35
Q 965.85 456.5 966 456.55 976.4 459.8 981.55 462.45 984.7 464.05 988.45 466.7 990.2 467.9 994.95 471.5 1001.7 476.65 1006.35 481 1012.2 486.55 1016.15 492.15 1021.65 499.9 1023 505.95 1023.9 509.9 1022.85 513.75 1021.65 518 1018.6 520.2 1013.7 523.8 1003.65 522.3 996.15 521.25 988.5 517.1 981.6 513.4 973.1 506.5 963.55 498.35 958.75 494.4 957.65 493.5 956.6 494.55 955.55 495.55 956.65 496.5 965.95 504.5 970.65 508.4 978.5 514.85 984.95 518.7 993.1 523.4 1000.65 524.9 1010.55 526.95 1017.25 524.3 1021.05 522.8 1023.45 519.3 1025.65 516.05 1026.15 511.75 1027 504.75 1021.8 495.7 1015.1 483.85 1000.45 472.65 991.15 465.45 985.05 462.05 981.1 459.85 975.8 458.4 972.55 457.5 966.1 456.25 965.9 456.2 965.9 456.35 Z"
				/>
			</g>

			<g id="Layer3_40_MEMBER_1_FILL">
				<path
					fill="#DDDBDB"
					stroke="none"
					d="
M 881.6 455.55
Q 882.1 455.15 881.85 454.6 881.7 454.25 881.4 453.35 881.2 452.55 880.9 452.15 880.3 451.3 879.05 450.1 877.3 448.35 874.85 446.8 869.05 443.25 865.2 444.25 862.8 444.85 861.65 447.15 860.7 449.1 860.85 451.8 861.15 457.2 863.35 462.65 864.5 465.5 866.25 468.25 867.6 470.3 867.95 470.8 869.05 472.25 870.2 472.75 870.45 472.85 870.65 472.7 870.85 472.55 870.8 472.25 870.5 471.15 869.45 469.6 867.95 467.35 867.8 467.1 866.35 464.45 865.4 461.65 863.05 454.7 863.6 450.2 863.95 447.05 866.6 447 868.7 446.95 871.6 448.45 874.25 449.85 876.2 451.55 876.55 451.85 878.2 453.5 878.6 453.9 879.45 454.5 880.35 455.1 880.7 455.5 881.15 455.95 881.6 455.55 Z"
				/>
			</g>
		</defs>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer1_0_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_0_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_1_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_2_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_3_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_4_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_5_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_6_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_7_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_8_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_9_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_10_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_11_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_12_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_13_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_14_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_15_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_16_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_17_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_18_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_19_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_20_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_21_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_22_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 0.826385498046875, 0, 0, 1, -538.8,-373.05) ">
			<use xlinkHref="#Layer3_23_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_24_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_25_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_26_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_27_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_28_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_29_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_30_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_31_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_32_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_33_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_34_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_35_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_36_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_37_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_38_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_39_MEMBER_1_FILL" />
		</g>

		<g transform="matrix( 1, 0, 0, 1, -749.25,-373.05) ">
			<use xlinkHref="#Layer3_40_MEMBER_1_FILL" />
		</g>
	</svg>
);
